<template>
  <table-wrapper>
    <v-table fixed-header>
      <thead>
        <tr>
          <th class="text-left">
            {{ $t("devicesSettings.allowBlockList.table.value") }}
          </th>
          <th class="text-left">
            {{ $t("devicesSettings.allowBlockList.table.list") }}
          </th>
          <th class="text-left">
            {{ $t("devicesSettings.allowBlockList.table.description") }}
          </th>
          <th style="width: 15px"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="items.length === 0">
          <td colspan="4">
            <div style="height: calc(100vh - 400px)" class="d-flex align-center justify-center">
              {{ $t("general.noData") }}
            </div>
          </td>
        </tr>
        <tr
          v-else
          v-for="(item, index) in items"
          :key="item.id"
          :data-testid="`table-row-${index}`"
        >
          <td class="pa-4" :data-testid="`device-allow-list-table-value-col-${index}`">
            <div class="d-flex align-center value-col">
              <v-icon class="mr-2" size="35" color="primary" :icon="getIcon(item.type)" />
              <div>
                <ellipsified-copy-text
                  class="body2 font-weight-bold"
                  width="570px"
                  :text="item.value"
                />
                <span
                  v-if="item.timestamp"
                  class="caption text-gray-medium"
                  :data-testid="'device-allow-list-table-date'"
                >
                  {{ getFormattedDateTime(item.timestamp) }}
                </span>
              </div>
            </div>
          </td>
          <td :data-testid="`device-allow-list-table-status-col-${index}`">
            <span v-if="!item.blocked" class="text-green-dark">{{
              $t(`phishingSettings.allowed`)
            }}</span>
            <span v-else class="text-red-dark">{{ $t(`phishingSettings.blocked`) }}</span>
          </td>
          <td>
            <ellipsified-copy-text width="370px" :text="item.description" />
          </td>
          <td>
            <v-menu bottom left>
              <template v-slot:activator="{ props }">
                <v-icon
                  :class="{ 'icon-btn-disabled': item.inheritedFromParent }"
                  icon="$dots"
                  v-bind="props"
                ></v-icon>
              </template>
              <v-list>
                <v-list-item
                  v-for="(action, index) in actions"
                  :key="index"
                  :value="index"
                  :data-testid="`users-table-action-btn-row-${index}`"
                  @click="
                    onActionClick({
                      action: action.actionKey,
                      callback: action.callback,
                      item,
                    })
                  "
                >
                  <v-list-item-title
                    :data-testid="`device-allow-list-table-${action.actionKey}-action`"
                  >
                    {{ $t(`devicesSettings.allowBlockList.actions.${action.actionKey}`) }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </tbody>
    </v-table>
    <v-pagination
      v-if="showPagination"
      v-model="page"
      :length="totalPages"
      class="mt-2"
      variant="text"
      density="comfortable"
      @update:modelValue="handlePaginationChange($event)"
    ></v-pagination>
  </table-wrapper>
</template>

<script lang="ts">
import TableWrapper from "@/components/TableWrapper.vue";
import type { PropType } from "vue";
import { defineComponent, toRefs } from "vue";
import { confirmationDialogsConfigConstructor, getFormattedDateTime } from "@/_helpers/utils";
import { useDialogsStore } from "@/_store/dialogs.module";
import { useI18n } from "vue-i18n";
import type { DeviceAllowBlockListResponseItem } from "@/_store/endpoint-security/device-allow-block-list.module";
import { usePageableTable } from "@/composables/usePageableTable";
import type { GenericCallback, Pagination } from "@/types";
import EllipsifiedCopyText from "@/components/EllipsifiedCopyText.vue";

export default defineComponent({
  components: {
    EllipsifiedCopyText,
    TableWrapper,
  },
  props: {
    items: {
      type: Array as PropType<DeviceAllowBlockListResponseItem[]>,
      required: true,
    },
    actions: {
      type: Object as PropType<Array<{ actionKey: string; callback: GenericCallback }>>,
      required: true,
    },
    context: {
      type: String,
      default: "",
    },
    pagination: {
      type: Object as PropType<Pagination>,
      default: () => {
        return {
          page: 0,
          pageSize: 15,
        };
      },
    },
    totalItems: {
      type: Number,
      required: true,
    },
    disableActions: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["page-changed"],
  setup(props, { emit }) {
    const dialogsStore = useDialogsStore();
    const i18n = useI18n();
    const iconToTypeMap: Record<string, string> = {
      file: "$fileEmpty",
      process: "$process",
      folder: "$folder",
    };
    const { totalItems, items, pagination } = toRefs(props);
    const { showPagination, totalPages, page, handlePaginationChange } = usePageableTable(
      {
        totalItems,
        items,
        pagination,
      },
      (value: Pagination) => {
        emit("page-changed", value);
      }
    );

    const getIcon = (type: string) => {
      // get rid of "edr" prefix returned by BE for EDR Allow/Block page
      const normalizedType = type.replace("edr", "").toLowerCase();
      return iconToTypeMap[`${normalizedType}`];
    };

    const onActionClick = (event: {
      action: string;
      callback: GenericCallback;
      item: DeviceAllowBlockListResponseItem;
    }) => {
      const dialogConfig = confirmationDialogsConfigConstructor({
        item: event.item,
        action: event.action,
        callback: event.callback,
        text: event.item.blocked
          ? i18n.t(`modals.${event.action}.descriptionBlocked`)
          : i18n.t(`modals.${event.action}.descriptionAllowed`),
        disable: props.disableActions,
      });

      dialogsStore.openDialog(dialogConfig);
    };

    return {
      getIcon,
      showPagination,
      page,
      totalPages,
      handlePaginationChange,
      onActionClick,
      getFormattedDateTime,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(*) {
  .v-table__wrapper {
    max-height: calc(100vh - 340px) !important;
  }
}
</style>

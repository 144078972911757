import type { Pagination } from "@/types";
import { i18n } from "@/plugins/i18n";
import { defineStore } from "pinia";
import { useSnackbarStore } from "@/_store";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import type { Service } from "@/constants/cloud-apps";
import type { UserSettingsListItem } from "@/_store/users-settings/users-tab.module";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import { splitAndConvertUsersFilters } from "@/_store/users-settings/adapters";

export interface UserExceptionsState {
  exceptions: UserSettingsListItem[];
  totalItems: number;
  totalPages: number;
  pagination: Pagination;
  loading: boolean;
  filters: UserExceptionsFilters;
  showSkeletonLoader: boolean;
}

export type UserExceptionsFilterItem =
  | { type: "domain"; name: string }
  | { type: "cloudService"; name: Service };

export interface UserExceptionsFilters {
  searchTerm: string;
  cloudServices: UserExceptionsFilterItem[];
}

export const defaultUsersExceptionsTabFilters = {
  searchTerm: "",
  cloudServices: [],
};

const defaultExceptionsState = {
  exceptions: [],
  totalItems: 0,
  totalPages: 0,
  pagination: {
    page: 0,
    pageSize: 25,
  },
  loading: false,
  showSkeletonLoader: false,
  filters: {
    ...defaultUsersExceptionsTabFilters,
  },
};

export const useUsersExceptionsSettingsTabStore = defineStore("users-exceptions-tab", {
  state: (): UserExceptionsState => ({ ...defaultExceptionsState }),
  actions: {
    init() {
      this.getExceptions();
    },
    handleExceptionsChange() {
      const snackbarStore = useSnackbarStore();
      this.getExceptions();
      snackbarStore.addGenericSuccess(
        i18n.global.t("snackbar.messages.exceptions.exceptionsUpdated")
      );
    },
    getExceptions(showSkeletonLoader: boolean = false) {
      const { cloudServices, inboundGatewayDomains } = splitAndConvertUsersFilters(this.filters);

      const request = {
        ...api.exceptions(),
        params: {
          isExcepted: true,
          email: this.filters.searchTerm,
          ...this.pagination,
          cloudServices,
          inboundGatewayDomains,
        },
      };
      this.loading = true;
      this.showSkeletonLoader = showSkeletonLoader;
      axiosInstance
        .request(request)
        .then((response) => {
          const data = response.data;
          this.totalItems = data.total;
          this.exceptions = data.items;
          this.loading = false;
          this.showSkeletonLoader = false;
        })
        .catch(() => {
          this.loading = false;
          this.showSkeletonLoader = false;
        });
    },
    applyPaginationChange(payload: Pagination) {
      this.pagination = payload;
      this.getExceptions();
    },
    async addNewException(payload: { users: Array<any> }) {
      const snackbarStore = useSnackbarStore();
      if (payload.users.length > 30) {
        snackbarStore.addGenericSuccess(i18n.global.t("snackbar.messages.user.usersAreAdding"));
      }
      const request = {
        ...api.addExceptions,
        data: payload.users.map(
          ({ email, userId, service }: UserSettingsListItem & { service: Service }) => ({
            email,
            userId,
            service,
          })
        ),
      };
      try {
        await axiosInstance.request(request);
        this.handleExceptionsChange();
      } catch (error) {
        console.error(error);
      }
    },
    async removeException(payload: DialogDataConfig<UserSettingsListItem>) {
      const request = {
        ...api.removeException(payload.item.userId),
      };
      try {
        await axiosInstance.request(request);
        this.handleExceptionsChange();
      } catch (error) {
        console.error(error);
      }
    },
    resetState() {
      this.$reset();
    },
    setExceptionsFilters(filters: Partial<UserExceptionsFilters>) {
      this.filters = { ...this.filters, ...filters };
    },
    setExceptionsPagination(pagination: Pagination) {
      this.pagination = pagination;
    },
  },
});

import { defineStore } from "pinia";
import type { Pagination } from "@/types";
import api from "@/_helpers/api";
import { handleVirtualScrollData, downloadFile } from "@/_helpers/utils";
import { axiosInstance } from "@/plugins/https";
import {
  FilterContext,
  type SecureWebGatewayFilters,
  useFiltersStore,
} from "@/_store/filters.module";
import { SecureWebGatewayListFilterChoices } from "@/constants/network";
import { useSnackbarStore } from "@/_store/snackbar.module";
import { i18n } from "@/plugins/i18n";
import type { DeviceLabel } from "@/_store/devices-settings.module.ts";

export interface SecureWebGatewaySettings {
  dnsAnonymousModeEnabled: boolean;
  dnsFilteringEnabled: boolean;
  localDnsEnabled: boolean;
  labels: DeviceLabel[];
  dynamicLabels: DeviceLabel[];
}

export interface SecureWebGatewayUrlItem {
  id: string;
  category: string;
  disabled: boolean;
  allowed: boolean;
  name: string;
}

export interface SecureWebGatewayState {
  settings: SecureWebGatewaySettings;
  total: number;
  urlList: Array<SecureWebGatewayUrlItem>;
  urlListLoading: boolean;
  configurationLoading: boolean;
  showSkeletonLoader: boolean;
  pagination: Pagination;
}

const defaultSecureWebGatewayState: SecureWebGatewayState = {
  settings: {
    dnsAnonymousModeEnabled: false,
    dnsFilteringEnabled: false,
    localDnsEnabled: false,
    labels: [],
    dynamicLabels: [],
  },
  total: 0,
  urlList: [],
  urlListLoading: false,
  configurationLoading: false,
  showSkeletonLoader: false,
  pagination: {
    page: 0,
    pageSize: 25,
  },
};

const convertFiltersForBackend = (filters: SecureWebGatewayFilters) => {
  const allowedFilterMap = {
    [SecureWebGatewayListFilterChoices.ALLOWED]: { allowed: true, disabled: false },
    [SecureWebGatewayListFilterChoices.BLOCKED]: { allowed: false, disabled: false },
    [SecureWebGatewayListFilterChoices.ALLOWED_DISABLED]: { allowed: true, disabled: true },
    [SecureWebGatewayListFilterChoices.BLOCKED_DISABLED]: { allowed: false, disabled: true },
  };

  const { allowed, disabled } = filters.allowed
    ? allowedFilterMap[filters.allowed]
    : { allowed: undefined, disabled: undefined };

  return {
    ...filters,
    allowed,
    disabled,
  };
};

const convertPayloadForBE = ({
  allowed,
  nickname,
  file,
}: {
  allowed: boolean;
  nickname: string;
  file: File;
}) => {
  const formData = new FormData();
  formData.append("allowed", allowed.toString());
  formData.append("name", nickname);
  formData.append("file", file);
  return formData;
};
const SWG_TIMEOUT = 60000;

export const useSWGStore = defineStore("secureWebGateway", {
  state: (): SecureWebGatewayState => ({ ...defaultSecureWebGatewayState }),
  actions: {
    async getSwgUrlList(virtualScroll = false) {
      const filters = useFiltersStore().filters[FilterContext.SECURE_WEB_GATEWAY];
      const convertedFilters = convertFiltersForBackend(filters);

      const requestConfig = {
        ...api.swgUrlList(),
        method: "GET",
        params: {
          ...this.pagination,
          ...convertedFilters,
        },
      };

      try {
        this.urlListLoading = true;
        const {
          data: { items, total },
        } = await axiosInstance.request(requestConfig);
        this.urlList = virtualScroll ? handleVirtualScrollData(this.urlList, items, "id") : items;
        this.total = total;
      } catch (e) {
        console.error(e);
      } finally {
        this.urlListLoading = false;
      }
    },

    async addToSwgUrlList({ allowed, names }: { allowed: boolean; names: string[] }) {
      const requestConfig = {
        ...api.swgUrlList(),
        method: "POST",
        data: { allowed, names },
      };
      try {
        this.urlListLoading = true;
        const {
          data: { processed: quantity = 0 },
        } = await axiosInstance.request({ ...requestConfig, timeout: SWG_TIMEOUT });
        useSnackbarStore().addGenericSuccess(
          i18n.global.t(
            `snackbar.messages.secureWebGateway.${
              allowed ? "addUrlToAllowlist" : "addUrlToBlocklist"
            }`,
            { quantity },
            quantity
          )
        );
        this.pagination = { ...defaultSecureWebGatewayState.pagination };
        await this.getSwgUrlList();
      } catch (e) {
        console.error(e);
      } finally {
        this.urlListLoading = false;
      }
    },

    async importToSwgUrlList(payload: { nickname: string; file: File; allowed: boolean }) {
      const convertedPayload = convertPayloadForBE(payload);
      const requestConfig = api.importToSwgUrlList(convertedPayload);

      try {
        this.urlListLoading = true;
        const {
          data: { processed: quantity = 0 },
        } = await axiosInstance.request(requestConfig);
        this.pagination = { ...defaultSecureWebGatewayState.pagination };
        const { nickname, allowed } = payload;
        useSnackbarStore().addGenericSuccess(
          i18n.global.t(
            `snackbar.messages.secureWebGateway.${
              allowed ? "importUrlGroupToAllowlist" : "importUrlGroupToBlocklist"
            }`,
            { nickname, quantity },
            quantity
          )
        );
        await this.getSwgUrlList();
      } catch (error) {
        console.error(error);
      } finally {
        this.urlListLoading = false;
      }
    },

    async getSwgExampleFile() {
      const requestConfig = api.getSwgExampleFile();
      try {
        const { data, headers } = await axiosInstance.request(requestConfig);
        const blob = new Blob([data], { type: headers["content-type"] });
        const fileName = "SecureWebGatewayUrlsExample.txt";
        downloadFile(blob, fileName);
      } catch (e) {
        console.error(e);
      }
    },

    async viewUrlList(id: string) {
      const requestConfig = api.viewUrlList(id);
      return await axiosInstance.request(requestConfig);
    },

    async toggleDisabledSwgUrl({ disabled, id }: { id: string; disabled: boolean }) {
      const requestConfig = {
        ...api.swgUrlListItem(id),
        method: "PUT",
        params: {
          disabled,
        },
      };

      try {
        this.urlListLoading = true;
        await axiosInstance.request({ ...requestConfig, timeout: SWG_TIMEOUT });
        this.pagination = { ...defaultSecureWebGatewayState.pagination };
        await this.getSwgUrlList();
      } catch (e) {
        console.error(e);
      } finally {
        this.urlListLoading = false;
      }
    },

    async updateSwgSettings(payload: SecureWebGatewaySettings) {
      const requestConfig = api.updateSwgSettings(payload);
      try {
        this.urlListLoading = true;
        await axiosInstance.request({ ...requestConfig, timeout: SWG_TIMEOUT });
        this.pagination = { ...defaultSecureWebGatewayState.pagination };
        await this.getSwgUrlList();
      } catch (e) {
        console.error(e);
      } finally {
        this.urlListLoading = false;
      }
    },

    async removeFromSwgUrlList(id: string): Promise<void> {
      const requestConfig = {
        ...api.swgUrlListItem(id),
        method: "DELETE",
      };
      try {
        this.urlListLoading = true;
        await axiosInstance.request({ ...requestConfig, timeout: SWG_TIMEOUT });
        this.pagination = { ...defaultSecureWebGatewayState.pagination };
        await this.getSwgUrlList();
      } catch (e) {
        console.error(e);
      } finally {
        this.urlListLoading = false;
      }
    },

    async getSwgSettings(showSkeletonLoader = false) {
      if (showSkeletonLoader) this.showSkeletonLoader = true;

      try {
        const request = {
          ...api.getSwgSettings,
        };

        const { data } = await axiosInstance.request(request);

        this.settings = data;
      } catch (error) {
        console.error(error);
      }

      if (showSkeletonLoader) this.showSkeletonLoader = false;
    },
  },
});

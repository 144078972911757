import { defineStore } from "pinia";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import type { Service } from "@/constants/cloud-apps";
import type { JoinedViaFilterType } from "@/_store/users-settings/users-tab.module";

interface UsersSettingsState {
  protectedTotalUsers: number;
  excludedTotalUsers: number;
  totalGroups: number;
  showSkeletonLoader: boolean;
}

export interface UserSettingsFilterItem {
  name: string | Service;
  value: string | Service;
  amount: number;
  type: JoinedViaFilterType;
}

const defaultUsersSettingsState: UsersSettingsState = {
  protectedTotalUsers: 0,
  excludedTotalUsers: 0,
  totalGroups: 0,
  showSkeletonLoader: false,
};

export const useUsersSettingsStore = defineStore("users-settings", {
  state: (): UsersSettingsState => ({ ...defaultUsersSettingsState }),
  actions: {
    init(showSkeletonLoader = true) {
      this.showSkeletonLoader = showSkeletonLoader;
      Promise.all([getGroupsTotals(), getExcludedTotals(), getProtectedTotals()])
        .then((res) => {
          const groupsData = res[0].data;
          const excludedData = res[1].data;
          const protectedData = res[2].data;
          this.protectedTotalUsers = protectedData.total;
          this.excludedTotalUsers = excludedData.total;
          this.totalGroups = groupsData.total;
          this.showSkeletonLoader = false;
        })
        .catch((err) => {
          console.error(err);
          this.showSkeletonLoader = false;
        });
    },
  },
});

/**
 * Gets groups totals and returns promise for init method
 * @returns {Promise<AxiosResponse<T>>}
 */
function getGroupsTotals() {
  const request = {
    ...api.groupsTotal,
  };

  return axiosInstance.request(request);
}

/**
 * Gets excluded users totals and returns promise for init method
 * @returns {Promise<AxiosResponse<T>>}
 */
function getExcludedTotals() {
  const request = {
    ...api.exceptions(),
    params: {
      email: "",
      isExcepted: true,
      pageSize: 15,
      page: 0,
      cloudServices: [],
      inboundGatewayDomains: [],
    },
  };
  return axiosInstance.request(request);
}

/**
 * Gets protected users totals and returns promise for init method
 * @returns {Promise<AxiosResponse<T>>}
 */
function getProtectedTotals() {
  const request = {
    ...api.protectedUsers("", { page: 0, pageSize: 15 }),
  };
  return axiosInstance.request(request);
}

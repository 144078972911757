<template>
  <div class="mb-4 d-flex justify-space-between align-center">
    <span class="subtitle1">{{ title }}</span>
    <filter-wrapper
      class="d-flex"
      :show-clear-button="showClearFiltersButton()"
      @clear-filters-clicked="clearFilters()"
    >
      <v-select
        v-model="localFilters.status"
        :items="filterTypes"
        bg-color="white"
        rounded
        class="filter-menu mr-2"
        menu-icon="icon-triangle"
        :class="{ 'filter-active': localFilters.status }"
        :placeholder="$t('workspaceMapping.statusSelectPlaceholder')"
      >
        <template v-slot:selection="{ item }">
          <span>
            {{ $t(`workspaceMapping.mappingFilterType.${item.title}`) }}
          </span>
        </template>
        <template v-slot:item="{ props, item }">
          <v-list-item
            v-bind="props"
            :title="$t(`workspaceMapping.mappingFilterType.${item.title}`)"
          ></v-list-item>
        </template>
      </v-select>
      <v-text-field
        v-model="localFilters.search"
        data-testid="users-page-users-search"
        :class="{ 'filter-active': localFilters.search?.length }"
        density="compact"
        rounded
        persistent-clear
        bg-color="white"
        :placeholder="searchPlaceholder"
        class="search-field"
        prepend-inner-icon="icon-search"
        clearable
        variant="outlined"
        clear-icon="icon-x"
      />
    </filter-wrapper>
  </div>

  <v-progress-linear
    :style="{ visibility: loading ? 'visible' : 'hidden' }"
    class="allow-blocklist-tab-content"
    indeterminate
    height="2px"
  />
  <table-wrapper>
    <v-data-table-server
      :items="items"
      :items-per-page="showPagination ? pagination.pageSize : totalItems"
      :items-length="totalItems"
      @update:options="handlePagination($event)"
    >
      <template #headers>
        <tr>
          <th>{{ firstColumnTitle }}</th>
          <th class="w-25 text-right">{{ secondColumnTitle }}</th>
        </tr>
      </template>
      <template #item="{ item: tableItem, index }">
        <tr>
          <td>
            <h4 class="subtitle2">{{ tableItem.primaryItem }}</h4>
          </td>
          <td class="align-right" align="right">
            <v-select
              :placeholder="$t('connectors.psa.mappingSelectPlaceholder')"
              :model-value="tableItem.secondaryItem"
              class="company-select"
              :items="secondaryItems"
              :menu-props="{ contentClass: 'mapped-items-select' }"
              ref="mappingSelectRef"
              hide-details
              variant="plain"
              @update:menu="
                (isOpened: boolean) => (!isOpened ? secondaryItemSearch('', index) : '')
              "
              :loading="secondaryItemsLoading && currentlyLoadingIndex === index"
            >
              <template v-slot:item="{ item, props }">
                <v-list-item
                  :key="item.value"
                  v-bind="props"
                  :disabled="item.raw.isMapped"
                  @click="mappingUpdate(item.raw, index)"
                >
                </v-list-item>
              </template>
              <template v-slot:prepend-item>
                <v-text-field
                  density="compact"
                  rounded
                  style="width: 250px"
                  class="mr-4 ml-4 mb-3 mt-1 search-field"
                  clear-icon="$x"
                  :class="{ 'filter-active': !!currentlyLoadingIndex }"
                  :placeholder="$t('general.search')"
                  prepend-inner-icon="$search"
                  clearable
                  hide-details
                  @keydown.stop
                  @mousedown.stop
                  @update:modelValue="secondaryItemSearch($event, index)"
                >
                </v-text-field>
              </template>
              <template v-slot:append-item>
                <div class="clear-mapping-button" v-if="!!tableItem.secondaryItem">
                  <v-divider></v-divider>
                  <v-btn
                    color="link"
                    variant="text"
                    @click="(mappingUpdate(null, index), secondaryItemSearch('', index))"
                  >
                    {{ $t("connectors.psa.clearMapping") }}
                  </v-btn>
                </div>
              </template>
            </v-select>
          </td>
        </tr>
      </template>
      <template v-slot:bottom>
        <v-data-table-footer
          v-if="showPagination"
          :items-per-page-options="paginationOptions"
          :page="pagination.page + 1"
          v-model:items-per-page="pagination.pageSize"
        ></v-data-table-footer>
      </template>
    </v-data-table-server>
  </table-wrapper>
</template>

<script lang="ts">
import type { Ref, PropType } from "vue";
import { defineComponent, ref, watch } from "vue";
import type { PsaMappingSelectOption } from "@/_store/connectors/psa.module";
import {
  PsaConnectorMappingStatus,
  defaultPsaConnectorMappingFilter,
} from "@/_store/connectors/psa.module";
import isEqual from "lodash/isEqual";
import FilterWrapper from "@/components/FilterWrapper.vue";
import TableWrapper from "@/components/TableWrapper.vue";
import type { Pagination } from "@/types";
import { paginationOptions } from "@/constants/table";

export interface PsaMappedData {
  primaryItem: string;
  secondaryItem?: string;
}

export interface PsaMappingUpdate {
  selectedSecondaryItem: PsaMappingSelectOption;
  rowIndex: number;
}

export default defineComponent({
  components: { TableWrapper, FilterWrapper },
  name: "MappedItemsTable",
  props: {
    showPagination: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array as PropType<PsaMappedData[]>,
      required: true,
    },
    secondaryItems: {
      type: Array as PropType<PsaMappingSelectOption[]>,
      required: true,
      default: () => [],
    },
    secondaryItemsLoading: {
      type: Boolean,
      required: true,
    },
    localFilters: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
    },
    title: {
      type: String,
      required: true,
    },
    firstColumnTitle: {
      type: String,
      required: true,
    },
    secondColumnTitle: {
      type: String,
      required: true,
    },
    searchPlaceholder: {
      type: String,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    pagination: {
      type: Object as PropType<Pagination>,
      default: () => {
        return {
          page: 0,
          pageSize: 15,
        };
      },
    },
  },
  emits: ["mapping-update", "secondary-item-search", "clear-filters", "page-changed"],
  setup(props, { emit }) {
    const currentlyLoadingIndex: Ref<null | number> = ref(null);
    const localFilters = ref(props.localFilters);
    const localValue = ref<{ models: (string | null)[] }>({
      models: [],
    });
    const secondaryItemSearchQuery = ref("");
    watch(
      ref(props.items),
      (value: PsaMappedData[]) => {
        localValue.value = {
          models: value.map(({ secondaryItem }) => {
            return secondaryItem || null;
          }),
        };
      },
      { immediate: true }
    );

    const secondaryItemSearch = (query: string, index: number) => {
      currentlyLoadingIndex.value = index;
      emit("secondary-item-search", query);
    };

    const clearFilters = () => {
      emit("clear-filters");
    };

    const showClearFiltersButton = () => {
      return !isEqual(localFilters.value, defaultPsaConnectorMappingFilter);
    };

    const mappingSelectRef: Ref<null | InstanceType<typeof HTMLElement>[]> = ref(null);
    const mappingUpdate = (
      selectedSecondaryItem: PsaMappingSelectOption | null,
      rowIndex: number
    ) => {
      emit("mapping-update", {
        selectedSecondaryItem,
        rowIndex,
      });
    };
    // {key-string: number} because vuetify does not provide interface
    const handlePagination = (event: { [key: string]: number }) => {
      emit("page-changed", {
        page: event.page - 1,
        pageSize: event.itemsPerPage,
      });
    };

    return {
      secondaryItemSearch,
      mappingUpdate,
      currentlyLoadingIndex,
      localValue,
      showClearFiltersButton,
      clearFilters,
      mappingSelectRef,
      secondaryItemSearchQuery,
      filterTypes: Object.values(PsaConnectorMappingStatus),
      paginationOptions,
      handlePagination,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(*) {
  .icon.icon-triangle {
    font-size: 8px;
  }

  .filter-menu {
    width: 125px;
  }

  .company-select {
    width: fit-content;

    .v-field__input {
      min-width: 30px;
      margin-right: 10px;
      padding: 10px 0 !important;

      input::placeholder {
        color: rgb(var(--v-theme-link));
        opacity: 1;
      }

      .v-select__selection-text {
        color: rgb(var(--v-theme-primary));
      }
    }
  }

  .filter-menu .v-field {
    display: flex;
    align-items: center;
  }
}

:global(.v-menu > .mapped-items-select > .v-list) {
  padding-bottom: 0 !important;
}

.clear-mapping-button {
  position: sticky !important;
  bottom: 0;
  width: 100%;
  flex-direction: column;
  display: flex;
  background: white;

  > .v-btn {
    justify-content: flex-start;
    text-transform: none;
    font-weight: normal;
    font-size: inherit;
    letter-spacing: normal;
    height: 48px;
  }
}
</style>

<template>
  <div class="content-xl">
    <users-settings-table
      :items="users"
      :total-items="allUsers"
      :pagination="pagination"
      :loading="loading"
      @page-changed="applyPaginationChange($event)"
      @item-action-applied="handleAction($event)"
    ></users-settings-table>
  </div>
</template>

<script lang="ts">
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { computed, defineComponent } from "vue";
import UsersSettingsTable from "@/components/tables/UsersSettingsTable.vue";
import { useUsersSettingsTabStore } from "@/_store/users-settings/users-tab.module";
import { storeToRefs } from "pinia";
import cloneDeep from "lodash/cloneDeep";
import ConfirmationWithCheckboxModal from "@/components/modals/ConfirmationWithCheckboxModal.vue";
import { ModalWidth } from "@/constants/modals";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import type { UserSettingsListItem } from "@/_store/users-settings/users-tab.module";
import { UserAction } from "@/constants/users";
import { type DialogConfig, useDialogsStore } from "@/_store/dialogs.module";
import { useI18n } from "vue-i18n";
import AddUsersModal from "@/components/modals/AddUsersModal.vue";

export default defineComponent({
  components: {
    UsersSettingsTable,
  },
  setup() {
    const usersTabStore = useUsersSettingsTabStore();
    const dialogsStore = useDialogsStore();
    const i18n = useI18n();
    const { addUsers, applyPaginationChange, removeUsers, addToException, removeException } =
      usersTabStore;
    const { users, pagination, allUsers, showSkeletonLoader, loading } = storeToRefs(usersTabStore);

    const actionNotAllowed = computed(() =>
      isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.USERS
      )
    );

    const handleAction = ({ action, item }: { action: string; item: UserSettingsListItem }) => {
      let dialogConfig = {} as DialogConfig;
      switch (action) {
        case UserAction.ADD_USERS:
          dialogConfig = componentDialogsConfigConstructor({
            action: action,
            component: AddUsersModal,
            width: ModalWidth.LARGE,
            callback: addUsers,
            disable: actionNotAllowed.value,
          });
          break;
        case UserAction.REMOVE_USERS:
          dialogConfig = componentDialogsConfigConstructor({
            item: {
              ...cloneDeep(item),
            },
            action,
            component: ConfirmationWithCheckboxModal,
            width: ModalWidth.SMALL,
            callback: removeUsers,
            disable: actionNotAllowed.value,
          });
          dialogConfig.data.item.showCheckbox = item.groups.length > 0;
          dialogConfig.data.item.checkboxText = i18n.t(`modals.${action}.checkbox`);
          dialogConfig.data.item.checkboxValue = item.groups.length > 0;
          dialogConfig.data.item.text = i18n.t(`modals.${action}.description`, {
            email: item.email,
          });
          break;
        case UserAction.ADD_TO_EXCEPTION:
          dialogConfig = confirmationDialogsConfigConstructor({
            item,
            action,
            disable: actionNotAllowed.value,
            text: i18n.t(`modals.${action}.description`),
            callback: addToException,
          });
          break;
        case UserAction.REMOVE_EXCEPTION:
          dialogConfig = confirmationDialogsConfigConstructor({
            item,
            action,
            disable: actionNotAllowed.value,
            text: i18n.t(`modals.${action}.description`),
            callback: removeException,
          });
          break;
      }
      dialogsStore.openDialog(dialogConfig);
    };

    return {
      users,
      pagination,
      allUsers,
      showSkeletonLoader,
      loading,
      applyPaginationChange,
      handleAction,
    };
  },
});
</script>

<template>
  <div class="content-md margin-auto mt-4">
    <v-skeleton-loader
      :loading="showSkeletonLoader"
      :type="coronetSkeletonLoaderTypes.USERS_SETTINGS"
    >
      <div class="d-flex justify-end">
        <filter-wrapper
          class="mb-5"
          :show-clear-button="showClearFiltersButton"
          @clear-filters-clicked="clearFilters(clearFiltersCallback)"
        >
          <v-text-field
            v-model="localFilters.search"
            :class="{ 'filter-active': localFilters.search?.length }"
            density="compact"
            variant="outlined"
            :placeholder="$t('general.search')"
            class="search-field"
            prepend-inner-icon="icon-search"
            :clearable="true"
            rounded
            clear-icon="icon-x"
          />
        </filter-wrapper>
      </div>
      <table-wrapper>
        <v-table class="white" density="comfortable">
          <template #top>
            <v-progress-linear
              :style="{ visibility: loading ? 'visible' : 'hidden' }"
              :indeterminate="true"
              height="2px"
            />
          </template>
          <template #default>
            <thead>
              <tr>
                <th class="text-left caption">
                  {{ $t("accessControlPage.adminUsers.table.headers.name") }}
                </th>
                <th class="text-left caption">
                  {{ $t("accessControlPage.adminUsers.table.headers.email") }}
                </th>
                <th class="text-left caption">
                  {{ $t("accessControlPage.adminUsers.table.headers.role") }}
                </th>
                <th class="text-left caption status-column">
                  {{ $t("accessControlPage.adminUsers.table.headers.status") }}
                </th>
                <th class="text-left caption">
                  {{ $t("accessControlPage.adminUsers.table.headers.mfa") }}
                </th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in adminAccounts" :key="item.id" class="service-row">
                <td class="content-row">
                  <div class="d-flex align-center">
                    <v-icon icon="$user" size="40" class="mr-4"></v-icon>
                    <span class="subtitle2">{{ `${item.firstName} ${item.lastName}` }}</span>
                    <span v-if="!isNotCurrentAdmin(item)" class="subtitle2">
                      ({{ $t("accessControlPage.adminUsers.table.you") }})
                    </span>
                  </div>
                </td>
                <td class="content-row">
                  <span class="body2">{{ item.email }}</span>
                </td>
                <td class="content-row">
                  <span class="body2">{{ item.role?.roleName }}</span>
                </td>
                <td class="content-row">
                  <span :class="`body2 ${getStatusColor(item)}`">
                    {{
                      $t(`accessControlPage.adminUsers.status.${item.status.toLowerCase()}`, {
                        date: getInvitedOnDate(item),
                      })
                    }}
                  </span>
                </td>
                <td class="content-row">
                  <span :class="`body2 ${getMFAStatusColor(item)}`">
                    {{
                      $t(`accessControlPage.adminUsers.mfaStatus.${item.mfaConfig.toLowerCase()}`)
                    }}
                  </span>
                </td>
                <td class="text-right" @click="$event.stopPropagation()">
                  <v-menu bottom left>
                    <template v-slot:activator="{ props }">
                      <v-icon
                        icon="$dots"
                        :data-testid="`admin-account-table-menu-${i}`"
                        class="item-clickable"
                        v-bind="props"
                      ></v-icon>
                    </template>
                    <v-list>
                      <template v-for="(actionItem, index) in itemContextMenu">
                        <template v-if="actionItem.show(item)">
                          <v-list-item
                            :key="actionItem.action"
                            :data-testid="`admin-account-table-menu-item-${index}`"
                            @click="actionItem.callback(item, actionItem.action)"
                          >
                            <v-list-item-title>
                              <span class="body2">{{
                                $t(
                                  `accessControlPage.adminUsers.table.actions.${actionItem.action}`
                                )
                              }}</span>
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </template>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </template>
        </v-table>
      </table-wrapper>

      <v-card class="mt-8">
        <v-card-text class="d-inline-flex justify-space-between align-center">
          <v-checkbox
            :model-value="isTOTPRequired"
            :disabled="
              isWorkspaceFrozenOrActionRestricted(
                RolePermissionsScope.WORKSPACE_MANAGEMENT,
                WorkspaceManagementScopeSections.ADMIN_USERS
              )
            "
            density="compact"
            :label="$t('accessControlPage.adminUsers.requireMFA')"
            data-testid="admin-users-require-mfa-checkbox"
            v-on:click.prevent.stop.capture="onWorkspaceMFAToggle()"
          />
        </v-card-text>
      </v-card>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import TableWrapper from "@/components/TableWrapper.vue";
import { defineComponent, onMounted, watch } from "vue";
import { AdminAccountStatus, AdminUsersAction } from "@/constants/admin-accounts";
import router from "@/_helpers/router";
import CopyLinkModal from "@/components/modals/CopyLinkModal.vue";
import { useI18n } from "vue-i18n";
import { MfaStatus } from "@/constants/account";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  copyToClipboard,
  getFormattedDateTime,
  isActionRestricted,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { storeToRefs } from "pinia";
import { useDialogsStore } from "@/_store/dialogs.module";

import { WorkspaceType } from "@/constants/workplaces";
import { SnackbarTypes, useSnackbarStore } from "@/_store/snackbar.module";
import { useAccountStore } from "@/_store/account.module";
import { type AdminUser, useAdminUsersStore } from "@/_store/admin-users.module";
import { ModalWidth } from "@/constants/modals";
import AddAdminUserModal from "@/components/modals/AddAdminUserModal.vue";
import FilterWrapper from "@/components/FilterWrapper.vue";
import { useFilters } from "@/composables/useFilters";
import { FilterContext } from "@/_store/filters.module";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import type { GenericCallback } from "@/types";
import { RouteName } from "@/constants/routes";

export default defineComponent({
  components: {
    FilterWrapper,
    TableWrapper,
  },
  setup() {
    const { localFilters, showClearFiltersButton, clearFilters, filtersUpdating } = useFilters(
      FilterContext.ADMIN_USERS
    );
    const adminAccountStore = useAdminUsersStore();
    const dialogsStore = useDialogsStore();
    const accountStore = useAccountStore();
    const snackbarStore = useSnackbarStore();
    const i18n = useI18n();
    const {
      getAdminAccounts,
      deleteAdminUser,
      deleteMFA,
      resendInvite,
      getInviteLink,
      toggleWorkspaceMFA,
      getWorkspaceMFAConfig,
      editAdminUser,
    } = adminAccountStore;
    const { account } = accountStore;
    const {
      isTOTPRequired,
      adminUsers: adminAccounts,
      showSkeletonLoader,
      loading,
    } = storeToRefs(adminAccountStore);
    const getInvitedOnDate = (item: AdminUser) => {
      if (item.inviteSentOn) {
        return getFormattedDateTime(item.inviteSentOn, "ll");
      }
    };
    const isStatusActive = (item: AdminUser) => {
      return item.status === AdminAccountStatus.ACTIVE;
    };
    const goToAdminAccountDetails = (item: AdminUser) => {
      router.push({ name: RouteName.ACCESS_CONTROL_ADMIN_USER_DETAILS, params: { id: item.id } });
    };
    const isNotCurrentAdmin = (item: AdminUser) => {
      return item.email !== account.email;
    };
    const handleCopyInviteLink = async (item: AdminUser) => {
      const inviteLink = await getInviteLink(item);
      const dialogConfig = componentDialogsConfigConstructor({
        item: { inviteLink },
        action: AdminUsersAction.COPY_INVITE_LINK,
        component: CopyLinkModal,
        width: ModalWidth.LARGE,
        callback: () => {
          copyToClipboard(inviteLink);
          snackbarStore.add({
            html: i18n.t("snackbar.messages.adminAccounts.copyInviteLink"),
            type: SnackbarTypes.SUCCESS,
          });
        },
      });
      dialogsStore.openDialog(dialogConfig);
    };
    const isMFAEnabled = (payload: AdminUser) => {
      return payload.mfaConfig === MfaStatus.TOTP;
    };
    const isStatusInvited = (item: AdminUser) => {
      return item.status === AdminAccountStatus.INVITED;
    };
    const doAction = (item: AdminUser, action: AdminUsersAction) => {
      let callback: GenericCallback = () => {};
      let disable = false;
      switch (action) {
        case AdminUsersAction.DELETE:
          dialogsStore.openDialog({
            ...confirmationDialogsConfigConstructor({
              item,
              action,
              callback: deleteAdminUser,
              disable: isActionRestricted(
                RolePermissionsScope.WORKSPACE_MANAGEMENT,
                WorkspaceManagementScopeSections.ADMIN_USERS,
                "remove"
              ),
            }),
            content: {
              html:
                accountStore.account.workspaceType === WorkspaceType.CHANNEL
                  ? i18n.t(`modals.${action}.channelDescription`, { ...item })
                  : i18n.t(`modals.${action}.description`, { ...item }),
            },
          });
          return;
        case AdminUsersAction.DELETE_MFA:
          callback = deleteMFA;
          disable = isActionRestricted(
            RolePermissionsScope.WORKSPACE_MANAGEMENT,
            WorkspaceManagementScopeSections.ADMIN_USERS,
            "removeMfaData"
          );
          break;
        case AdminUsersAction.TOGGLE_WORKSPACE_MFA:
          callback = toggleWorkspaceMFA;
          disable = isActionRestricted(
            RolePermissionsScope.WORKSPACE_MANAGEMENT,
            WorkspaceManagementScopeSections.ADMIN_USERS,
            "edit"
          );
          break;
      }
      const dialogConfig = confirmationDialogsConfigConstructor({
        item,
        action,
        callback,
        disable,
      });
      dialogsStore.openDialog(dialogConfig);
    };

    const editAdmin = (item: AdminUser) => {
      const dialogConfig = componentDialogsConfigConstructor({
        item,
        action: AdminUsersAction.EDIT,
        component: AddAdminUserModal,
        width: ModalWidth.LARGE,
        callback: editAdminUser,
        disable: isWorkspaceFrozenOrActionRestricted(
          RolePermissionsScope.WORKSPACE_MANAGEMENT,
          WorkspaceManagementScopeSections.ADMIN_USERS,
          "edit"
        ),
      });
      dialogsStore.openDialog(dialogConfig);
    };
    const getStatusColor = (item: AdminUser) => {
      return isStatusActive(item) ? "text-green-dark" : "text-indigo-medium";
    };
    const getMFAStatusColor = (item: AdminUser) => {
      return isMFAEnabled(item) ? "text-green-dark" : "text-indigo-medium";
    };
    const onWorkspaceMFAToggle = () => {
      if (isTOTPRequired.value) {
        doAction({} as AdminUser, AdminUsersAction.TOGGLE_WORKSPACE_MFA);
      } else {
        toggleWorkspaceMFA();
      }
    };

    const itemContextMenu = [
      {
        action: AdminUsersAction.EDIT,
        show: (item: AdminUser) => isNotCurrentAdmin(item),
        callback: editAdmin,
      },
      {
        action: AdminUsersAction.CONTENT_INSPECTION,
        show: isStatusActive,
        callback: goToAdminAccountDetails,
      },
      {
        action: AdminUsersAction.DELETE_MFA,
        show: (item: AdminUser) => isMFAEnabled(item) && isNotCurrentAdmin(item),
        callback: doAction,
      },
      {
        action: AdminUsersAction.RESEND_INVITE,
        show: (item: AdminUser) =>
          isStatusInvited(item) &&
          !isWorkspaceFrozenOrActionRestricted(
            RolePermissionsScope.WORKSPACE_MANAGEMENT,
            WorkspaceManagementScopeSections.ADMIN_USERS,
            "add"
          ),
        callback: resendInvite,
      },
      {
        action: AdminUsersAction.DELETE,
        show: isNotCurrentAdmin,
        callback: doAction,
      },
      {
        action: AdminUsersAction.COPY_INVITE_LINK,
        show: (item: AdminUser) =>
          isStatusInvited(item) &&
          !isWorkspaceFrozenOrActionRestricted(
            RolePermissionsScope.WORKSPACE_MANAGEMENT,
            WorkspaceManagementScopeSections.ADMIN_USERS,
            "add"
          ),
        callback: handleCopyInviteLink,
      },
    ];

    const clearFiltersCallback = async () => {
      await getAdminAccounts();
    };

    watch(
      filtersUpdating,
      async (updating: boolean) => {
        if (updating) {
          await getAdminAccounts();
        }
      },
      { deep: true }
    );

    onMounted(() => {
      getAdminAccounts(true);
      getWorkspaceMFAConfig();
    });

    return {
      breadCrumbsItems: [
        {
          title: `‹ Control Panel`,
          disabled: false,
          to: { path: "/portal/settings" },
        },
      ],
      RolePermissionsScope,
      adminAccounts,
      isTOTPRequired,
      showSkeletonLoader,
      itemContextMenu,
      loading,
      localFilters,
      showClearFiltersButton,
      clearFilters,
      clearFiltersCallback,
      getInvitedOnDate,
      isNotCurrentAdmin,
      getStatusColor,
      getMFAStatusColor,
      onWorkspaceMFAToggle,
      coronetSkeletonLoaderTypes,
      isWorkspaceFrozenOrActionRestricted,
      WorkspaceManagementScopeSections,
    };
  },
});
</script>

<style lang="scss" scoped>
.icon-dots:before {
  color: rgb(var(--v-theme-primary)) !important;
}

.coronet-icon {
  height: 40px;
  width: 40px;
}

.status-column {
  width: 200px;
}
</style>

export enum ActivityLogsType {
  ACCOUNT = "account",
  CLOUD_APPS = "cloudApps",
  DEVICE = "device",
  DLP = "dlp",
  DEVICE_DLP = "deviceDlp",
  EMAIL = "email",
  MALWARE = "malware",
  PHISHING = "phishing",
  UNDO = "undo",
  USERS = "users",
  BILLING = "billing",
  DETECTION_LOGS = "detectionLogs",
  CSV_EXPORT = "csvExport",
  WORKSPACE = "workspace",
  MSP = "msp",
  SIEM_CONFIG = "siemConfig",
  SIEM_CONFIGS = "siemConfigs",
  API_CREDENTIALS = "apiCredentials",
  WORKSPACE_REPORT = "workspaceReport",
  WORKSPACE_SCHEDULED_REPORT = "workspaceScheduledReport",
  SOC = "soc",
  MOBILE = "mobile",
  PSA = "psa",
  WEBHOOK = "webhook",
  MDM_DEVICE = "mdmDevice",
  MDM_ACTION = "mdmAction",
  MDM_DEVICE_ACTION = "mdmDeviceAction",
  MDM_SYSTEM = "mdmSystem",
}

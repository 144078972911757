<template>
  <v-card class="h-100">
    <v-card-text class="px-8">
      <v-form ref="form" v-model="valid">
        <div class="d-flex align-center">
          <span
            class="subtitle1 mr-4"
            v-text="$t('network.secureWebGateway.dnsFilteringLabel')"
          ></span>

          <v-switch
            v-model="localValue.dnsFilteringEnabled"
            class="dns-switch"
            color="primary lighten-1"
            density="compact"
            hide-details
            :disabled="loading || actionNotAllowed"
            :ripple="false"
            @click.prevent.stop.capture="onChangeDnsEnabledToggle"
          />
        </div>
        <span v-html="$t('network.secureWebGateway.dnsFilteringDescription')" />
        <div class="mt-6">
          <div>
            <div
              class="body1 mt-4"
              :class="{ 'text-indigo-medium': actionNotAllowed }"
              v-html="$t('network.secureWebGateway.dnsFilteringInfo')"
            />

            <v-autocomplete
              v-model="localValue.labels"
              class="mt-4"
              :items="labels"
              :label="$t('network.secureWebGateway.labelsAutocompleteLabel')"
              :placeholder="$t('network.secureWebGateway.labelsAutocompletePlaceholder')"
              :disabled="actionNotAllowed"
              variant="outlined"
              multiple
              return-object
              chips
              closable-chips
              clear-on-select
              data-testid="device-settings-tamper-resistance-labels"
              item-value="id"
              item-title="name"
            >
              <template v-slot:chip="{ props, item }">
                <v-chip
                  v-bind="props"
                  :closable="!isDynamicLabel(item.raw)"
                  variant="flat"
                  size="default"
                  rounded="sm"
                  :color="item.raw.color"
                  close-icon="$close"
                  :text="item.raw.name"
                  class="label-chip"
                >
                  <div class="d-flex align-center">
                    <span class="ml-1 mr-2 body2" v-text="item.title" />
                  </div>
                </v-chip>
              </template>
              <template v-slot:item="{ item, props }">
                <v-list-item
                  :disabled="isDynamicLabel(item.raw)"
                  v-bind="props"
                  title=""
                  class="pointer-events-none"
                >
                  <template #default>
                    <div class="d-flex align-center label-popup-block">
                      <div
                        class="color-preview mr-2"
                        :style="{ 'background-color': item.raw.color }"
                      />
                      <span v-text="item.raw.name" />
                    </div>
                  </template>
                </v-list-item>
              </template>
            </v-autocomplete>
          </div>
        </div>
        <v-divider class="my-6" />
        <v-checkbox
          v-model="localValue.dnsAnonymousModeEnabled"
          :disabled="isFieldDisabled || loading"
          :ripple="false"
          :label="$t('network.secureWebGateway.dnsResolverLabel')"
          class="mt-3 mb-1"
        />
        <span
          class="body2 ml-7"
          :class="{
            'text-indigo-medium':
              !localValue.dnsFilteringEnabled || !localValue.dnsAnonymousModeEnabled,
          }"
          v-text="$t('network.secureWebGateway.dnsResolverDescription')"
        />
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed, type PropType, onMounted, type Ref } from "vue";
import { useDialogsStore } from "@/_store/dialogs.module";
import { type DeviceLabel, useDevicesSettingsStore } from "@/_store/devices-settings.module.ts";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import {
  confirmationDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { SecureWebGatewayAction } from "@/constants/network";
import { useI18n } from "vue-i18n";
import { RolePermissionsScope } from "@/_store/roles.module";
import { SubscriptionAddon } from "@/constants/workplaces";
import type { SecureWebGatewaySettings } from "@/_store/secure-web-gateway.module.ts";
import { storeToRefs } from "pinia";

export default defineComponent({
  props: {
    settings: {
      type: Object as PropType<SecureWebGatewaySettings>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const form = ref();
    const valid = ref(true);
    const displayedLabels: Ref<DeviceLabel[]> = ref([]);
    const localValue = ref({ ...props.settings });
    const dialogsStore = useDialogsStore();
    const devicesSettingsStore = useDevicesSettingsStore();

    const { openDialog } = dialogsStore;
    const { getLabels } = devicesSettingsStore;
    const { labels } = storeToRefs(devicesSettingsStore);
    const { t } = useI18n();

    onMounted(async () => {
      await getLabels();
      localValue.value.labels = [...props.settings.dynamicLabels, ...props.settings.labels];
    });

    const actionNotAllowed = computed(() =>
      isWorkspaceFrozenOrActionRestricted(RolePermissionsScope.PROTECTION, SubscriptionAddon.SWG)
    );

    const onChangeDnsEnabledToggle = (): void => {
      if (!localValue.value.dnsFilteringEnabled) {
        const modalConfig = {
          ...confirmationDialogsConfigConstructor({
            item: {},
            action: SecureWebGatewayAction.TOGGLE_DNS_FILTERING,
            callback: () => {
              localValue.value.dnsFilteringEnabled = !localValue.value.dnsFilteringEnabled;
            },
          }),
          header: {
            title: t(`modals.${SecureWebGatewayAction.TOGGLE_DNS_FILTERING}.title`),
            titleClass: "text-red-dark",
            titleIcon: "warning",
          },
        };
        openDialog(modalConfig);
      } else {
        localValue.value.dnsFilteringEnabled = !localValue.value.dnsFilteringEnabled;
      }
    };

    const isDynamicLabel = (label: DeviceLabel) => {
      return !!props.settings.dynamicLabels.find(
        (dynamicLabel: DeviceLabel) => dynamicLabel.id === label.id
      );
    };

    const isFieldDisabled = computed(
      () => actionNotAllowed.value || !localValue.value.dnsFilteringEnabled
    );

    watch(
      () => props.settings,
      (newVal) => {
        localValue.value = { ...newVal };
      },
      { immediate: true, deep: true }
    );
    watch(
      localValue,
      debounce((newVal) => {
        const payload = {
          ...newVal,
          labels: newVal.labels.filter((label: DeviceLabel) => !isDynamicLabel(label)),
        };

        if (
          (newVal.dnsFilteringEnabled === false || form.value?.validate()) &&
          !isEqual(newVal, props.settings)
        ) {
          emit("settings-updated", payload);
        }
      }, 500),
      { deep: true }
    );

    return {
      isDynamicLabel,
      displayedLabels,
      labels,
      form,
      valid,
      localValue,
      actionNotAllowed,
      onChangeDnsEnabledToggle,
      isFieldDisabled,
    };
  },
});
</script>

<style scoped lang="scss">
.color-preview {
  width: 20px !important;
  height: 20px !important;
  min-width: 20px !important;
  border-radius: 2px !important;
}

.dns-switch {
  position: relative;
  top: 1px;
}

.label-popup-block span {
  flex: unset !important;
}

.disabled-text {
  color: rgb(var(--v-theme-indigo-medium));
}

:deep(*) {
  .v-input--checkbox .v-input__slot {
    margin-bottom: 0 !important;
  }

  .v-input--reverse .v-input__slot {
    .v-label {
      order: -1;
      flex-grow: 0 !important;
    }
  }
}
</style>

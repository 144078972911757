<template>
  <div class="pb-6 px-12" data-testid="ticket-preview">
    <v-skeleton-loader
      v-if="ticket"
      :loading="ticketDetailsLoading"
      type="list-item, divider, list-item-avatar, list-item-three-line@4, list-item-avatar-two-line@4"
      class="skeleton-loader--table"
    >
      <ticket-preview-header
        :safe-vote-count="ticket.safeVoteCount"
        :suspicious-vote-count="ticket.suspiciousVoteCount"
        :ticket-details="ticketDetails"
        :is-soc="isSoc"
      />
      <div class="d-flex align-center mt-5 mb-7">
        <v-icon
          color="primary"
          class="event-type-icon mr-3"
          :icon="getIconName(ticketDetails)"
          size="40"
        >
        </v-icon>
        <span class="headline5" data-testid="ticket-preview-type">
          {{ $t(`tickets.eventTriggers.${camelCase(ticketDetails.eventTrigger)}`) }}
        </span>
      </div>
      <v-row>
        <v-col :cols="generalInfoCols">
          <ticket-general-info :ticket-details="ticketDetails" :is-soc="isSoc" />
        </v-col>
        <v-col v-if="showKeyDetails" cols="6">
          <key-details :ticket="ticketDetails"></key-details>
        </v-col>
      </v-row>
      <v-divider class="mt-2 mb-6"></v-divider>
      <ticket-full-details class="mb-5" :ticket-details="ticketDetails" />
      <entity-activity-logs
        :activity-logs="ticketDetails.activityLogs"
        :activity-logs-total="ticketDetails.activityLogsTotal"
        :entity-id="ticketDetails.eventId"
        :loading="activityLogsLoading"
        :hide-all-activity-link="hideAllActivityLink"
        :access-restricted="activityLogsAccessRestricted"
        show-load-more
        search-prefix="ticketId"
        class="mb-5"
        @undo-clicked="undoActivityLog($event)"
        @load-more-items-clicked="loadMoreActivityLogs($event)"
      />
      <ticket-comments :ticket="ticketDetails" :comments="comments" />
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import TicketPreviewHeader from "@/views/tickets/TicketPreviewHeader.vue";
import EntityActivityLogs from "@/components/EntityActivityLogs.vue";
import camelCase from "lodash/camelCase";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import { computed, defineComponent, type PropType, toRefs, watch } from "vue";
import TicketGeneralInfo from "@/components/tickets/ticket-preview-details/TicketGeneralInfo.vue";
import TicketFullDetails from "@/components/tickets/ticket-preview-details/full-details/TicketFullDetails.vue";
import KeyDetails from "@/components/tickets/ticket-preview-details/KeyDetails.vue";
import TicketComments from "@/components/tickets/ticket-preview-details/TicketComments.vue";
import { type Ticket, type TicketDetails, useTicketsStore } from "@/_store/tickets/tickets.module";
import { storeToRefs } from "pinia";
import { useTicketCommentsStore } from "@/_store/ticket-comments.module";
import { ticketTriggerIcons, TicketType, ticketTypeIcons } from "@/constants/tickets";
import { isAccessRestricted } from "@/_helpers/utils";

export default defineComponent({
  components: {
    TicketPreviewHeader,
    TicketGeneralInfo,
    TicketFullDetails,
    EntityActivityLogs,
    KeyDetails,
    TicketComments,
  },
  props: {
    ticket: {
      type: Object as PropType<Ticket>,
      required: false,
    },
    isSoc: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { ticket } = toRefs(props);
    const ticketsStore = useTicketsStore();
    const { ticketDetailsLoading, ticketDetails, activityLogsLoading } = storeToRefs(ticketsStore);
    const ticketCommentsStore = useTicketCommentsStore();
    const { comments } = storeToRefs(ticketCommentsStore);
    const showKeyDetails = computed(
      () => ticketDetails.value.eventType !== TicketType.EDR_DETECTION
    );
    const generalInfoCols = computed(() => (showKeyDetails.value ? 6 : 12));
    const activityLogsAccessRestricted = computed(() =>
      isAccessRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.ACTIVITY_LOGS
      )
    );
    const hideAllActivityLink = computed(() => props.isSoc || activityLogsAccessRestricted.value);

    watch(
      ticket,
      (newValue) => {
        if (newValue) {
          ticketsStore.getTicketDetails(newValue);
          ticketCommentsStore.getComments(newValue);
          ticketCommentsStore.$patch({
            pagination: {
              page: 0,
              pageSize: 5,
            },
          });
          document.querySelector("#scroll-container")?.scrollTo(0, 0);
        }
      },
      { deep: true, immediate: true }
    );

    const getIconName = ({ eventType, eventTrigger }: TicketDetails) => {
      const icon = ticketTypeIcons[eventType] || ticketTriggerIcons[eventTrigger];
      return `$${icon}`;
    };

    const loadMoreActivityLogs = (page: number) => {
      ticketsStore.getTicketActivityLogs({
        eventId: ticket.value!.eventId,
        workspaceId: ticket.value?.workspaceId,
        page,
      });
    };

    return {
      camelCase,
      RolePermissionsScope,
      ticketDetailsLoading,
      ticketDetails,
      activityLogsLoading,
      comments,
      generalInfoCols,
      hideAllActivityLink,
      activityLogsAccessRestricted,
      showKeyDetails,
      undoActivityLog: ticketsStore.undoActivityLog,
      getIconName,
      loadMoreActivityLogs,
    };
  },
});
</script>

<style scoped lang="scss">
.event-type-icon {
  width: 40px;
  height: 40px;
  font-size: 40px;
}
</style>

import axios from "axios";
import { AxiosError, type AxiosResponse, type InternalAxiosRequestConfig } from "axios";
import qs from "qs";
import { generateDeviceId } from "@/_helpers/utils";
import router from "@/_helpers/router";
import { AccountErrors } from "@/constants/account";
import type { RequestError } from "@/types";
import { useAccountStore } from "@/_store/account.module";
import type { App } from "vue";
import { useErrorsStore } from "@/_store/errors.module";
import { RouteName } from "@/constants/routes.ts";

export const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
  paramsSerializer: {
    serialize: (params) => {
      return qs.stringify(params, { arrayFormat: "brackets", skipNulls: true });
    },
  },
});

function handleError(error: AxiosError<RequestError>) {
  let errorMessage: string | AxiosError<RequestError> = error;
  let showDialog = false;
  if (error.isAxiosError && error.code === AxiosError.ERR_CANCELED) {
    return;
  }
  if (error.isAxiosError && error.code !== AxiosError.ECONNABORTED) {
    errorMessage = `Something went wrong while fetching “${error.response?.config?.url}”. Error ${error.response?.status}`;
  }
  const errorsStore = useErrorsStore();
  const data = error.response?.data;
  // @ts-ignore
  if (data?.errors?.length) {
    // @ts-ignore
    errorMessage = data.errors[0];
    showDialog = true;
  }
  errorsStore.push({ message: errorMessage, showDialog });
}

const onRequest = (config: InternalAxiosRequestConfig) => {
  // perform a task before the request is sent
  const accountStore = useAccountStore();
  const token = accountStore.account.token;
  const workplace = accountStore.account.workplace;

  if (config?.url?.includes("/login") || config?.url?.includes("/signup")) {
    if (!accountStore.account.deviceId) {
      accountStore.setDeviceId(generateDeviceId());
    }

    config.headers["X-Header-DeviceId"] = accountStore.account.deviceId;
  }

  if (token) {
    let bearerValue;
    switch (config.url) {
      case "/logout":
      case "/refreshToken":
        bearerValue = `Bearer ${accountStore.account.refreshToken}`;
        break;
      default:
        bearerValue = `Bearer ${token}`;
        break;
    }
    if (!config?.url?.startsWith("/v2/workspace") && workplace) {
      config.headers["Workspace"] = config.headers["Workspace"] || workplace;
    }
    config.headers["Authorization"] = bearerValue;
  }
  return config;
};

const onResponse = (response: AxiosResponse) => {
  return response;
};

const onError = (error: AxiosError<RequestError>) => {
  const accountStore = useAccountStore();
  // endpoints in which we should not show error modal
  const nonHandlableEndpoints = [
    "/login",
    "/resetPassword",
    "/forgotPassword",
    "/logout",
    "/refreshToken",
    "/services/box/configUpload",
    "/branding",
    "/devices/ssh/",
    "/tickets/", // todo: uncomment when report will contain workspaceid
    "/mobile/activate",
    "/mobile/resendInvite",
    "/msp/promo-codes",
  ];
  const response = error.response;
  const responseUrl = response?.config?.url ?? "";
  const code = error?.response?.status;
  const originalRequest = error.config as InternalAxiosRequestConfig;
  // @ts-ignore
  const errorCode = response?.data?.errorCode ?? "";
  if (code === 401) {
    if (responseUrl !== "/refreshToken" && responseUrl !== "/auth/transfer") {
      return accountStore
        .getRefreshToken()
        .then(() => {
          originalRequest.headers["Authorization"] = `Bearer ${accountStore.account.token}`;
          return new Promise((resolve, reject) => {
            axiosInstance
              .request(originalRequest)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          });
        })
        .catch((err) => {
          if (responseUrl !== "/admin-users/joinWorkspace") {
            accountStore.logout();
          }
          throw err;
        });
    }
  } else if (errorCode === AccountErrors.MFA_REQUIRED) {
    //MFA is required
    router.push({ name: RouteName.MFA_PAGE }).catch(() => {});
  } else {
    const shouldHandleError = !nonHandlableEndpoints.find((endpoint) =>
      responseUrl.startsWith(endpoint)
    );

    if (!shouldHandleError) {
      return Promise.reject(error);
    }
    handleError(error);
    return Promise.reject(error);
  }
  return Promise.reject(error);
};

export default {
  install: (app: App): void => {
    app.config.globalProperties.$http = axiosInstance;

    axiosInstance.interceptors.response.use(onResponse, onError);
    axiosInstance.interceptors.request.use(onRequest);
  },
};

const blockedDomainZones = ["ru"];
export default {
  ONLY_DIGITS: /^[0-9]\d*$/,
  DIGITS_RANGE: (min: number, max: number) => new RegExp(`^[0-9]{${min},${max}}$`),
  EMAIL:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/,
  IP: /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))($|\/([0-9]|[1-2][0-9]|3[1-2]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?($|\/([0-9]|[1-5][0-9]|6[1-4]))\s*$))/,
  DOMAIN: /^([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-z]{2,}$/,
  SUBDOMAIN: /^(?!-)[a-zA-Z0-9-]+([a-zA-Z0-9-]+)*[a-zA-Z0-9]$/,
  NO_SPECIAL_CHARACTERS: /^[a-zA-Z0-9]+$/,
  WILDCARD_DOMAIN: /^(\*\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/,
  NOT_ALLOWED_DOMAIN_ZONE: new RegExp(`.*[\`^.\`](${blockedDomainZones.join("|")})$`),
  HEX_COLOR: new RegExp("^#[0-9A-F]{6}$"),
  URL: /^https?:\/\/(?:www\.)?[-a-zA-Z\d@:%._+~#=]{1,256}\.[a-zA-Z\d()]{1,6}\b[-a-zA-Z\d()@:%_+.~#?&/=]*$/,
  SSID: /^[^!#;+\]/"\t ][^+\]/"\t]{0,30}[^ +\]/"\t]$|^[^ !#;+\]/"\t]$[ \t]+$/,
  IP_WITH_OPTIONAL_CIDR:
    /^(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(?:$|\/([0-9]|[12][0-9]|3[02]))$/,
  IP_WITH_REQUIRED_CIDR:
    /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/(0?[89]|1[0-9]|2[0-9]|3[0-2])$/,
  SHA_256: /\b[A-Fa-f0-9]{64}\b/,
  CD_HASH: /\b[A-Fa-f0-9]{40}\b/,
  IP_WITHOUT_CIDR: /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.(?!$)|$)){4}$/,
  PORT: /^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/,
  PUNYCODE_DOMAIN:
    /^((([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-z]{2,})|xn--[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*)$/,
  SEARCH_PREFIX: /^(.*):/,
  TIME: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
  YEAR: /^\d{4}$/,
  FILE_PATH: (path: string) => {
    const invalidCharacters = /.*['?,|].*/;
    if (path.includes(":")) {
      if (path.includes("/")) {
        return false;
      }
      if (!path.includes("\\")) {
        return false;
      }
    } else if (path.startsWith("__")) {
      // if path beginning with env variable like __USERPROFILE__\Documents\any.file
      if (path.includes("\\") && path.includes("/")) {
        return false;
      }
    } else {
      if (path.includes("\\")) {
        return false;
      }
      if (!path.includes("/")) {
        return false;
      }
    }

    return (
      !invalidCharacters.test(path) &&
      !path.includes("\\\\") &&
      !path.includes("//") &&
      path.length >= 2
    );
  },
  NETWORK_KEY: /^[a-zA-Z0-9!@#$%^&*()]*$/,
};

<template>
  <div>
    <div class="subtitle1" data-testid="ticket-preview-title">
      {{ $t("general.description") }}
    </div>
    <div class="body1" data-testid="ticket-preview-process-name">
      {{ edrMetaData.shortDescription }}
    </div>
    <template v-if="edrMetaData.fullDescription">
      <a v-if="!showFullDescription" class="coro-link body2" @click="showFullDescription = true">
        {{ $t("general.seeMore") }}
      </a>
      <template v-if="showFullDescription">
        <span class="body2 text-indigo-medium">
          {{ edrMetaData.fullDescription }}
        </span>
        <br />
        <a class="coro-link body2 mt-1" @click="showFullDescription = false">
          {{ $t("general.seeLess") }}
        </a>
      </template>
    </template>
    <div class="subtitle1 mt-4" data-testid="ticket-preview-title">
      {{ $t("ticketDetails.process") }}
    </div>
    <div v-if="hideEdrLink" class="body1" data-testid="ticket-preview-process-name">
      {{ edrMetaData.processName }}
    </div>
    <span
      v-else
      class="coro-link body1"
      data-testid="ticket-preview-process-name"
      @click="onEdrProcessLinkClick()"
    >
      {{ edrMetaData.processName }}
    </span>
    <div
      v-if="edrMetaData.allowBlockTimestamp"
      class="body1"
      :class="{
        'text-green-dark': edrMetaData.processStatus === EdrProcessStatus.ALLOWED,
        'text-red-dark': edrMetaData.processStatus === EdrProcessStatus.BLOCKED,
      }"
    >
      {{
        $t(`ticketDetails.${edrMetaData.processStatus}On`, {
          date: getFormattedDateTime(edrMetaData.allowBlockTimestamp, "ll"),
        })
      }}
    </div>

    <div class="subtitle1 mt-4">{{ $t("ticketDetails.hash") }}</div>
    <div class="body1 mb-4" data-testid="ticket-preview-process-hash">
      <ellipsified-copy-text max-width="520px" class="body1" :text="edrMetaData.processHash" />
    </div>

    <div class="subtitle1">{{ $t("ticketDetails.affectedDevices") }}</div>
    <div
      v-if="hideDeviceTableLink"
      class="body1 mb-4"
      data-testid="ticket-preview-affected-devices"
    >
      {{ edrMetaData.affectedDevicesCount }}
    </div>
    <span
      v-else
      class="coro-link body1 mb-4"
      data-testid="ticket-preview-affected-devices"
      @click="onDeviceLinkClick()"
      >{{ edrMetaData.affectedDevicesCount }}</span
    >
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType, ref } from "vue";
import type { TicketDetails } from "@/_store/tickets/tickets.module";
import { useTicketPreviewGeneralInfo } from "@/composables/useTicketPreviewGeneralInfo";
import { isAccessRestricted } from "@/_helpers/utils";
import { useI18n } from "vue-i18n";
import { Service } from "@/constants/cloud-apps";
import { TicketsModal } from "@/constants/tickets";
import { ProxyDirection } from "@/constants/email-proxy";
import { RolePermissionsScope } from "@/_store/roles.module";
import { RouteName } from "@/constants/routes";
import { useRouter } from "vue-router";
import { EdrProcessStatus } from "@/constants/edr";
import EllipsifiedCopyText from "@/components/EllipsifiedCopyText.vue";
import { SubscriptionModule } from "@/constants/workplaces";
import { SearchSuggestion } from "@/constants/general";

export default defineComponent({
  components: {
    EllipsifiedCopyText,
  },
  props: {
    ticketDetails: {
      type: Object as PropType<TicketDetails>,
      required: true,
    },
    isSoc: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter();
    const i18n = useI18n();
    const showFullDescription = ref(false);
    const hideEdrLink = computed(() => {
      return isAccessRestricted(RolePermissionsScope.PROTECTION, SubscriptionModule.EDR);
    });
    const hideDeviceTableLink = computed(() => {
      return isAccessRestricted(RolePermissionsScope.VIEWS, "devicesView");
    });
    const edrMetaData = computed(() => props.ticketDetails?.sections?.edrMetaData);

    const { getFormattedDateTime, addUsersNotAllowed, usersToAdd, addUsers, openListModal } =
      useTicketPreviewGeneralInfo(props.ticketDetails);

    const users = computed(() => {
      const lastLoggedInUsers = props.ticketDetails?.lastLoggedInUsers ?? [];
      return lastLoggedInUsers.length
        ? lastLoggedInUsers.join(", <br>")
        : props.ticketDetails.sections.triggeredUser;
    });
    const usersTitle = computed(() => {
      const lastLoggedInUsers = props.ticketDetails?.lastLoggedInUsers?.length;
      return lastLoggedInUsers
        ? i18n.t("ticketDetails.lastUsersOnDevice")
        : i18n.t("ticketDetails.users");
    });

    const serviceNameTitle = computed(() => {
      const serviceName = i18n.t(`services.${props.ticketDetails.sections.serviceName}`);
      if (props.ticketDetails.sections.serviceName === Service.PROXY) {
        const direction =
          props.ticketDetails.sections.emailMetaData?.emailProxyDirection === ProxyDirection.INBOUND
            ? i18n.t("emailProxy.directions.inbound")
            : i18n.t("emailProxy.directions.outbound");

        return `${direction} ${serviceName}`;
      }
      return serviceName;
    });

    const onDeviceLinkClick = () => {
      if (props.isSoc && props.ticketDetails.workspaceId) {
        router.push({
          name: RouteName.DEVICES,
          query: {
            search: `${SearchSuggestion.TICKET_ID}${props.ticketDetails.eventId}`,
            workspaceId: props.ticketDetails.workspaceId,
          },
        });
      } else {
        router.push({
          name: RouteName.DEVICES,
          query: {
            search: `${SearchSuggestion.TICKET_ID}${props.ticketDetails.eventId}`,
          },
        });
      }
    };

    const onEdrProcessLinkClick = () => {
      if (props.isSoc && props.ticketDetails.workspaceId) {
        router.push({
          name: RouteName.EDR_PROCESSES_TAB,
          query: {
            search: `${SearchSuggestion.PROCESS_HASH}${edrMetaData.value.processHash}`,
            workspaceId: props.ticketDetails.workspaceId,
          },
        });
      } else {
        router.push({
          name: RouteName.EDR_PROCESSES_TAB,
          query: {
            search: `${SearchSuggestion.PROCESS_HASH}${edrMetaData.value.processHash}`,
          },
        });
      }
    };

    return {
      showFullDescription,
      users,
      getFormattedDateTime,
      addUsersNotAllowed,
      usersToAdd,
      addUsers,
      hideEdrLink,
      hideDeviceTableLink,
      edrMetaData,
      serviceNameTitle,
      openListModal,
      TicketsModal,
      Service,
      onDeviceLinkClick,
      onEdrProcessLinkClick,
      usersTitle,
      EdrProcessStatus,
    };
  },
});
</script>

<template>
  <div>
    <div class="subtitle1">{{ $t("ticketDetails.users") }}</div>
    <div class="body1 mb-6" data-testid="ticket-preview-users">
      {{ ticketDetails.sections.triggeredUser }}
      <div v-if="showAddToProtectionBtn">
        <div class="body2 text-red-dark mb-3">
          {{
            $t(
              "ticketDetails.notProtectedUsersNotice",
              { n: ticketDetails.potentiallyProtectableUsers.length },
              ticketDetails.potentiallyProtectableUsers.length
            )
          }}
        </div>
        <v-btn
          :disabled="addUsersNotAllowed"
          outlined
          rounded
          data-testid="tickets-page-add-users-btn"
          @click="addUsers(usersToAdd)"
        >
          {{ $t("ticketDetails.addProtectionBtn") }}
        </v-btn>
      </div>
    </div>

    <template v-if="ticketDetails.eventType === TicketType.USER_INACTIVITY">
      <div class="subtitle1">
        {{ $t("ticketDetails.inactiveSince") }}
      </div>
    </template>
    <template v-else>
      <div class="subtitle1 mb-4">{{ $t("ticketDetails.when") }}</div>
      <div class="subtitle1">{{ $t("ticketDetails.from") }}</div>
    </template>
    <div class="body1 mb-4" data-testid="ticket-preview-when">
      {{ getFormattedDateTime(ticketDetails.startTime) }}
    </div>

    <template
      v-if="ticketDetails.endTime && ticketDetails.eventType !== TicketType.USER_INACTIVITY"
    >
      <div class="subtitle1">{{ $t("ticketDetails.to") }}</div>
      <div class="body1" data-testid="ticket-preview-endtime">
        {{ getFormattedDateTime(ticketDetails.endTime) }}
      </div>
    </template>

    <div class="subtitle1 mt-6">{{ $t("general.service") }}</div>
    <div class="d-flex align-center mb-4">
      <coro-icon
        class="service-icon mr-2"
        :icon-name="ticketDetails.sections.serviceName"
      ></coro-icon>
      <div
        class="subtitle1"
        :data-testid="`ticket-preview-service-${ticketDetails.sections.serviceName}`"
      >
        {{ $t(`services.${ticketDetails.sections.serviceName}`) }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TicketDetails } from "@/_store/tickets/tickets.module";
import CoroIcon from "@/components/CoroIcon.vue";
import { useTicketPreviewGeneralInfo } from "@/composables/useTicketPreviewGeneralInfo";
import { TicketType } from "@/constants/tickets";

const props = defineProps<{
  ticketDetails: TicketDetails;
}>();

const { getFormattedDateTime, addUsersNotAllowed, usersToAdd, showAddToProtectionBtn, addUsers } =
  useTicketPreviewGeneralInfo(props.ticketDetails);
</script>

<style lang="scss" scoped>
.service-icon {
  width: 40px;
  height: 40px;
}
</style>

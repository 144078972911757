<template>
  <div data-testid="entity-activity-logs">
    <div class="d-flex justify-space-between mb-4">
      <div class="headline6">{{ $t("general.activityLog") }}</div>
      <router-link v-if="activityLogs.length && !hideAllActivityLink" :to="activityLogsLink">
        <a class="coro-link">{{ $t("general.allActivity") }} ›</a>
      </router-link>
    </div>
    <div class="table-container" data-testid="tickets-activity-logs-container">
      <div v-if="accessRestricted" class="d-flex flex-column align-center justify-center pa-7">
        <v-icon size="80" icon="$alertTriangle" />
        <span class="subtitle1">{{ $t("general.noAccess") }}</span>
      </div>
      <template v-else-if="activityLogs.length">
        <activity-logs-table
          :items="activityLogs"
          :total="activityLogsTotal"
          :loading="loading"
          :pagination="pagination"
          virtual-scrollable
          :show-icon-column="false"
          @undo-action="$emit('undo-clicked', $event)"
        />
        <template v-if="showLoadMore">
          <div v-if="logsToLoad > 0" class="d-flex justify-center pt-3 mb-3">
            <div class="coro-link cursor-pointer" @click="loadMoreLogs()">
              {{ $t("activityLogs.plusNLogs", { n: logsToLoad }) }}
            </div>
          </div>
        </template>
      </template>
      <div v-else class="d-flex flex-column align-center justify-center pa-7">
        <v-icon size="80" icon="$activityLogs" />
        <span class="subtitle1">{{ $t("general.noActivity") }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ActivityLogsTable from "@/components/tables/ActivityLogsTable.vue";
import { computed, defineComponent, type PropType, ref } from "vue";
import type { ActivityLogItem } from "@/_store/activity-logs.module";

export default defineComponent({
  components: {
    ActivityLogsTable,
  },
  emits: ["load-more-items-clicked", "undo-clicked"],
  props: {
    activityLogs: {
      type: Array as PropType<ActivityLogItem[]>,
      default() {
        return [];
      },
    },
    activityLogsTotal: {
      type: Number,
      default: 5,
    },
    entityId: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    searchPrefix: {
      type: String,
      default: null,
    },
    hideAllActivityLink: {
      type: Boolean,
      default: false,
    },
    accessRestricted: {
      type: Boolean,
      default: false,
    },
    showLoadMore: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const pagination = ref({
      page: 0,
      pageSize: props.activityLogsTotal,
    });

    const loadMoreLogs = () => {
      pagination.value.page++;
      emit("load-more-items-clicked", pagination.value.page);
    };

    const activityLogsLink = computed(() => {
      if (props.searchPrefix) {
        return `/portal/settings/activity-logs?search=${props.searchPrefix}:${props.entityId}`;
      }
      return `/portal/settings/activity-logs?search=${props.entityId}`;
    });

    const logsToLoad = computed(() => {
      return props.activityLogsTotal - (props.activityLogs ?? []).length;
    });

    return {
      pagination,
      activityLogsLink,
      logsToLoad,
      loadMoreLogs,
    };
  },
});
</script>

<style lang="scss" scoped>
.table-container {
  border: 1px solid rgb(var(--v-theme-indigo-faint));
}

.icon-activity-logs:before {
  color: rgb(var(--v-theme-indigo-light)) !important;
}

.icon-alert-triangle:before {
  color: rgb(var(--v-theme-indigo-light)) !important;
}

:deep(*) {
  .v-data-table tr {
    height: 88px !important;
  }
  .v-table__wrapper {
    box-shadow: unset !important;
    border-radius: unset !important;
  }
  td {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
}
</style>

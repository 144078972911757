<template>
  <div class="d-flex align-center justify-space-between pb-4 pt-4 details-preview-header bg-white">
    <div class="d-flex flex-column">
      <span class="body2 text-indigo-medium" data-testid="ticket-preview-id">
        {{ ticketDetails.eventId }}
      </span>
      <span v-if="ticketDetails.processed" class="body2 text-green-dark">
        <v-icon icon="$circleCheck"></v-icon>
        {{ $t("general.processedOn") }} {{ processedTime }}
      </span>
      <span
        v-if="showNotProtectedUsersNotice"
        class="body2 text-red-dark"
        data-testid="ticket-preview-unprotected-user-msg"
      >
        <v-icon icon="$noProtection"></v-icon>
        {{
          $t(
            "ticketDetails.notProtectedUsersNotice",
            { n: ticketDetails.potentiallyProtectableUsers.length },
            ticketDetails.potentiallyProtectableUsers.length
          )
        }}
      </span>
      <soc-status-selector
        v-if="ticketDetails.socStatus"
        :soc-status="ticketDetails.socStatus"
        @status-updated="updateSocStatus($event)"
      />
    </div>
    <div class="d-flex align-baseline">
      <users-say
        class="mr-4 mb-4"
        :suspicious-vote-count="suspiciousVoteCount"
        :safe-vote-count="safeVoteCount"
      ></users-say>
      <v-btn
        v-if="buttonType === buttonTypes.ADD_TO_PROTECTION"
        :disabled="disableAddToProtection"
        rounded
        variant="outlined"
        class="ml-2"
        data-testid="tickets-page-header-add-users-btn"
        @click.stop="addUsers(getPotentiallyProtectableUsers(ticketDetails))"
      >
        {{ $t("ticketDetails.addProtectionBtn") }}
      </v-btn>
      <v-menu v-if="buttonType === buttonTypes.ACTIONS" location="bottom right">
        <template #activator="{ props }">
          <v-btn rounded color="primary" data-testid="tickets-page-actions-btn" v-bind="props">
            {{ $t("general.actions") }}
            <v-icon class="ml-1 mt-1" size="10" icon="$triangle"> </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="action in actionsList"
            :key="action.name"
            :data-testid="`tickets-page-action-${action.name}`"
            @click="onActionClick(action, ticketDetails)"
          >
            <v-list-item-title>
              <span
                >{{
                  $t(`ticketDetails.eventActions.${action.name}`, {
                    service: ticketDetails.sections.serviceName
                      ? $t(`services.${ticketDetails.sections.serviceName}`)
                      : "",
                  })
                }}
              </span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, type PropType } from "vue";
import SocStatusSelector from "@/components/tickets/SocStatusSelector.vue";
import UsersSay from "@/components/tickets/UsersSay.vue";
import {
  type TicketActionWithSeverity,
  type TicketDetails,
  useTicketsStore,
} from "@/_store/tickets/tickets.module";
import {
  TicketAction,
  TicketActionSeverity,
  TicketTrigger,
  TicketType,
  ViolationDirection,
} from "@/constants/tickets";
import moment from "moment/moment";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import pullAllWith from "lodash/pullAllWith";
import debounce from "lodash/debounce";
import camelCase from "lodash/camelCase";

import {
  GlobalRoleScopes,
  GlobalRoleScopeSection,
  RolePermissionsScope,
  type SocPortalAccessEditAccessModePermission,
  type TicketsScope,
  WorkspaceManagementScopeSections,
} from "@/_store/roles.module";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { useDialogsStore } from "@/_store/dialogs.module";
import { useEmailProxyStore } from "@/_store/email-security/email-proxy.module";
import { useUsersSettingsTabStore } from "@/_store/users-settings/users-tab.module";
import { getUsersToAddFromPotentiallyProtectable } from "@/_store/tickets/adapters";
import { isGlobalActionRestricted } from "@/_helpers/global-permissions";
import { ProxyDirection, ProxyStatus } from "@/constants/email-proxy";
import { useRouter } from "vue-router";
import { RouteName } from "@/constants/routes";
import { Service } from "@/constants/cloud-apps";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import capitalize from "lodash/capitalize";
import type { GenericCallback } from "@/types";
import TicketConfirmationModal from "@/components/modals/TicketConfirmationModal.vue";
import { ModalWidth } from "@/constants/modals";
import { useRedirectsStore } from "@/_store/redirects.module";
import intersection from "lodash/intersection";
import AllowBlockEmailTicketModal from "@/components/modals/AllowBlockEmailTicketModal.vue";
import ContactUserModal from "@/components/modals/ContactUserModal.vue";
import { useTicketStore } from "@/_store/tickets/ticket.module";

const buttonTypes = {
  ADD_TO_PROTECTION: "addToProtectionBtn",
  ACTIONS: "actionsBtn",
  CLOSE: "closeBtn",
  REOPEN: "reopenBtn",
} as const;

const approveEmailActions = [
  TicketAction.APPROVE_EMAILS_FROM_DOMAIN,
  TicketAction.APPROVE_EMAILS_FROM_SENDER,
  TicketAction.APPROVE_IP,
  TicketAction.APPROVE_EMAIL,
];
const discardEmailActions = [
  TicketAction.DISCARD_EMAILS_FROM_DOMAIN,
  TicketAction.DISCARD_EMAILS_FROM_SENDER,
  TicketAction.DISCARD_IP,
  TicketAction.DISCARD_EMAIL,
];

export default defineComponent({
  components: { SocStatusSelector, UsersSay },
  props: {
    suspiciousVoteCount: {
      type: Number,
      default: 0,
    },
    safeVoteCount: {
      type: Number,
      default: 0,
    },
    ticketDetails: {
      type: Object as PropType<TicketDetails>,
      required: true,
    },
    isSoc: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const i18n = useI18n();
    const router = useRouter();
    const emailProxyStore = useEmailProxyStore();
    const dialogsStore = useDialogsStore();
    const ticketsStore = useTicketsStore();
    const usersSettingsTabStore = useUsersSettingsTabStore();
    const redirectsStore = useRedirectsStore();
    const { proxyStatus } = storeToRefs(emailProxyStore);

    const actionsList = computed(() => {
      const filteredActions = cloneDeep(props.ticketDetails.actions);
      const approveTicketActions = filteredActions.filter((v) =>
        approveEmailActions.includes(v.name)
      );
      const discardTicketActions = filteredActions.filter((v) =>
        discardEmailActions.includes(v.name)
      );
      if (approveTicketActions.length) {
        pullAllWith(filteredActions, approveTicketActions, isEqual);
        filteredActions.push({
          name: TicketAction.APPROVE_EMAIL_WITH_OPTIONS,
          severity: approveTicketActions.at(0)?.severity ?? TicketActionSeverity.MEDIUM,
        });
      }
      if (discardTicketActions.length) {
        pullAllWith(filteredActions, discardTicketActions, isEqual);
        filteredActions.push({
          name: TicketAction.DISCARD_EMAIL_WITH_OPTIONS,
          severity: discardTicketActions.at(0)?.severity ?? TicketActionSeverity.MEDIUM,
        });
      }
      return filteredActions;
    });

    const buttonType = computed(() => {
      const { potentiallyProtectableUsers } = props.ticketDetails;
      if (actionsList.value.length > 0) {
        return buttonTypes.ACTIONS;
      }
      if (potentiallyProtectableUsers?.length) {
        return buttonTypes.ADD_TO_PROTECTION;
      }
      return null;
    });

    const showNotProtectedUsersNotice = computed(
      () => buttonType.value === buttonTypes.ADD_TO_PROTECTION
    );

    const processedTime = computed(() => {
      return (
        props.ticketDetails.processedTime &&
        moment(props.ticketDetails.processedTime).format("llll")
      );
    });

    const disableAddToProtection = computed(() => {
      return props.isSoc
        ? false
        : isWorkspaceFrozenOrActionRestricted(
            RolePermissionsScope.WORKSPACE_MANAGEMENT,
            WorkspaceManagementScopeSections.USERS
          );
    });

    const addUsers = debounce((usersToAdd: { users: { email: string; service: Service }[] }) => {
      usersSettingsTabStore.addUsers({
        ...usersToAdd,
        workspaceId: props.ticketDetails.workspaceId,
      });
    }, 500);

    onMounted(() => {
      const { shouldHandleSocAutomationRedirect, socAutomationAction, $reset } = redirectsStore;

      // Handle navigation from SOC automation
      if (shouldHandleSocAutomationRedirect && socAutomationAction) {
        const { processed } = props.ticketDetails;

        if (!processed) {
          // Find action by name or create default action
          const action = actionsList.value.find(({ name }) => name === socAutomationAction) ?? {
            name: socAutomationAction as TicketAction,
            severity: TicketActionSeverity.MEDIUM,
          };

          onActionClick(action, props.ticketDetails);
        }

        $reset(); // Reset the redirectsStore
      }
    });

    const getPotentiallyProtectableUsers = (ticket: TicketDetails) =>
      getUsersToAddFromPotentiallyProtectable(ticket.potentiallyProtectableUsers);

    const configProxy = (direction: ProxyDirection) => {
      const routeData = router.resolve({
        name:
          direction === ProxyDirection.INBOUND
            ? RouteName.EMAIL_SECURITY_INBOUND_GATEWAY_TAB
            : "outbound-gateway_tab",
      });
      window.open(routeData.href, "_blank");
    };

    const getDisablePolicy = (
      nestedPermissionKey: keyof SocPortalAccessEditAccessModePermission
    ): boolean =>
      props.isSoc
        ? isGlobalActionRestricted(
            GlobalRoleScopes.GLOBAL_SCOPE,
            GlobalRoleScopeSection.SOC_PORTAL,
            nestedPermissionKey
          )
        : isWorkspaceFrozenOrActionRestricted(
            RolePermissionsScope.TICKETS,
            props.ticketDetails.moduleName as keyof TicketsScope,
            nestedPermissionKey
          );

    const handleContactUserAction = async (
      item: TicketDetails,
      action: TicketAction.CONTACT_USER,
      severity: TicketActionSeverity
    ) => {
      const isDeviceEvent = (item.lastLoggedInUsers ?? []).length > 0;
      const recipients = await useTicketStore().getTicketRecipients(
        item.eventId,
        isDeviceEvent,
        item.workspaceId
      );
      const disclaimer =
        props.isSoc && getDisablePolicy(severity) ? i18n.t("general.noPermissions") : undefined;
      dialogsStore.openDialog(
        componentDialogsConfigConstructor({
          action,
          item: {
            id: item.eventId,
            by: "event",
            isDeviceEvent,
            workspaceId: item.workspaceId,
            recipients,
          },
          callback: ticketsStore.onContactUser,
          component: ContactUserModal,
          disable: getDisablePolicy(severity),
          disclaimer,
          width: ModalWidth.LARGE,
        })
      );
    };

    const handleGeneralApproveAction = async (
      item: TicketDetails,
      action: TicketAction.GENERAL_APPROVE,
      severity: TicketActionSeverity,
      showCheckbox: boolean
    ) => {
      const dialogConfig = createTicketModalWithConfirmationConfig(
        action,
        item,
        getDisablePolicy(severity),
        ticketsStore.applyTicketAction,
        showCheckbox
      );
      const isProxy =
        item.sections?.serviceName === Service.PROXY ? i18n.t(`modals.${action}.proxy`) : "";
      const applySpecificDescription = [
        TicketTrigger.MISSING_REQUIRED_AUTHENTICATION,
        TicketTrigger.CROWDBLOCKED_SENDER,
        TicketTrigger.BLOCKLISTED_SENDER,
      ].includes(item.eventTrigger);
      dialogConfig.data.item.text = applySpecificDescription
        ? `${i18n.t(`modals.${action}.specificDescription.${item.eventTrigger}`)} ${isProxy}`
        : i18n.t(`modals.${action}.description`);
      dialogsStore.openDialog(dialogConfig);
    };

    const onActionClick = (
      { name: action, severity: actionSeverity }: TicketActionWithSeverity,
      item: TicketDetails
    ) => {
      const showCheckbox = !item.processed && !getDisablePolicy(TicketActionSeverity.LOW);
      // Fallback in case severity doesn't exist
      const severity = actionSeverity ?? TicketActionSeverity.MEDIUM;
      if (showConfigProxyModal(action, severity)) {
        dialogsStore.openDialog({
          ...confirmationDialogsConfigConstructor({
            action: TicketAction.CONFIGURE_PROXY,
            item: {
              proxyDirection: capitalize(
                props.ticketDetails.sections?.emailMetaData?.emailProxyDirection
              ),
            },
            callback: () =>
              configProxy(props.ticketDetails.sections?.emailMetaData?.emailProxyDirection!),
          }),
          header: {
            title: i18n.t("modals.configureProxy.title", {
              proxyDirection: i18n.t(
                `emailProxy.directions.${item.sections?.emailMetaData?.emailProxyDirection}`
              ),
            }),
            titleClass: "text-red-dark",
            titleIcon: "warning",
          },
        });
        return;
      }
      const disclaimer =
        props.isSoc && getDisablePolicy(severity) ? i18n.t("general.noPermissions") : undefined;

      switch (action) {
        case TicketAction.MARK_AS_PROCESSED:
          ticketsStore.applyTicketAction({
            action,
            item: {
              ...item,
              closeTicket: redirectsStore.shouldHandleSocAutomationRedirect,
            },
          });
          break;
        case TicketAction.UN_LOG_FOR_AUDIT_REPORTS:
        case TicketAction.LOG_FOR_AUDIT_REPORTS:
        case TicketAction.MARK_AS_UNPROCESSED:
          ticketsStore.applyTicketAction({
            action,
            item,
          });
          break;
        case TicketAction.DOWNLOAD_EML_FILE:
        case TicketAction.EXPORT_MASS_DELETE_FILES:
        case TicketAction.EXPORT_MASS_DOWNLOAD_FILES:
          dialogsStore.openDialog(
            createTicketModalWithConfirmationConfig(
              action,
              item,
              getDisablePolicy(severity),
              ticketsStore.applyDownloadEmailAction,
              showCheckbox
            )
          );
          break;
        case TicketAction.APPROVE_EMAIL_WITH_OPTIONS:
        case TicketAction.DISCARD_EMAIL_WITH_OPTIONS:
          dialogsStore.openDialog(
            componentDialogsConfigConstructor({
              action,
              item: {
                ...item,
                allowAction: action === TicketAction.APPROVE_EMAIL_WITH_OPTIONS,
                enabledActions: intersection(
                  props.ticketDetails.actions.map((v) => v.name),
                  action === TicketAction.APPROVE_EMAIL_WITH_OPTIONS
                    ? approveEmailActions
                    : discardEmailActions
                ),
              },
              callback: ticketsStore.applyTicketAction,
              component: AllowBlockEmailTicketModal,
              disable: getDisablePolicy(severity),
              width: ModalWidth.MEDIUM,
              disclaimer,
            })
          );
          break;
        case TicketAction.CONTACT_USER:
          handleContactUserAction(item, action, severity);
          break;
        case TicketAction.GENERAL_APPROVE:
          handleGeneralApproveAction(item, action, severity, showCheckbox);
          break;
        case TicketAction.SUSPEND_FROM_SERVICE:
        case TicketAction.ADD_TO_DATA_GOVERNANCE_PERMISSIONS:
        case TicketAction.ENFORCE_UAC:
        case TicketAction.ALLOW_NO_ENCRYPTION:
        case TicketAction.ENCRYPT_DRIVE:
        case TicketAction.ENABLE_FIREWALL:
          dialogsStore.openDialog(
            createTicketModalWithConfirmationConfig(
              action,
              item,
              getDisablePolicy(severity),
              ticketsStore.applyTicketAction,
              showCheckbox,
              redirectsStore.shouldHandleSocAutomationRedirect
            )
          );
          break;
        case TicketAction.ALLOW_PROCESS:
          dialogsStore.openDialog(
            createTicketModalWithConfirmationConfig(
              action,
              item,
              getDisablePolicy(severity),
              ticketsStore.applyTicketAction,
              showCheckbox,
              true
            )
          );
          break;
        default:
          dialogsStore.openDialog(
            createTicketModalWithConfirmationConfig(
              action,
              item,
              getDisablePolicy(severity),
              ticketsStore.applyTicketAction,
              showCheckbox
            )
          );
          break;
      }
    };

    const showConfigProxyModal = (
      action: TicketAction,
      severity: TicketActionSeverity
    ): boolean => {
      const isExcludedAction = [
        TicketAction.MARK_AS_PROCESSED,
        TicketAction.MARK_AS_UNPROCESSED,
      ].includes(action);
      const isEmailPhishingTicket = props.ticketDetails.eventType === TicketType.EMAIL_PHISHING;
      const isProxyService = props.ticketDetails.sections?.serviceName === Service.PROXY;
      const isProxyNotPassed = proxyStatus.value !== ProxyStatus.PASSED;
      const isPolicyEnabled = !getDisablePolicy(severity);

      if (isExcludedAction) {
        return false;
      }

      if (!isEmailPhishingTicket) {
        return false;
      }

      if (!isProxyService) {
        return false;
      }

      if (!isProxyNotPassed) {
        return false;
      }

      return isPolicyEnabled;
    };

    function createTicketModalWithConfirmationConfig(
      action: TicketAction,
      item: TicketDetails,
      disable: boolean,
      callback: GenericCallback = ticketsStore.applyTicketAction,
      showCheckbox: boolean = false,
      closeTicket: boolean = false,
      checkboxText = i18n.t("general.closeUponConfirmation")
    ) {
      const disclaimer = props.isSoc && disable ? i18n.t("general.noPermissions") : undefined;
      const serviceNameRaw = item.serviceName ?? item.sections.serviceName;
      const serviceName = i18n.t(`services.${serviceNameRaw}`);
      const violationUsers = item?.sections?.userDataAccessViolation?.users ?? [];
      const violationDirection =
        item?.sections?.userDataAccessViolation?.violationDirection ?? ViolationDirection.SHARE;
      const violationTrigger = i18n.t(
        `ticketDetails.fullDetails.accessViolations.${item.eventTrigger}`
      );
      if (showCheckbox) {
        const dialogConfig = componentDialogsConfigConstructor({
          item: {
            ...item,
            serviceName,
            violationUsers: violationUsers.join(","),
            violationDirection,
            violationTrigger,
          },
          action,
          callback,
          component: TicketConfirmationModal,
          disable,
          disclaimer,
          width: ModalWidth.SMALL,
        });
        dialogConfig.data.item.showCheckbox = true;
        dialogConfig.data.item.checkboxText = checkboxText;
        dialogConfig.data.item.closeTicket = closeTicket;
        dialogConfig.data.item.text = i18n.t(`modals.${action}.description`, {
          ...item,
          serviceName,
          violationUsers: violationUsers.join(","),
          violationDirection,
          violationTrigger,
        });
        return dialogConfig;
      }

      return confirmationDialogsConfigConstructor({
        item: {
          ...item,
          serviceName,
          violationUsers: violationUsers.join(","),
          violationDirection,
          violationTrigger,
        },
        action,
        disclaimer,
        callback,
        disable,
      });
    }

    return {
      buttonType,
      showNotProtectedUsersNotice,
      TicketAction,
      buttonTypes,
      processedTime,
      disableAddToProtection,
      addUsers,
      actionsList,
      getPotentiallyProtectableUsers,
      onActionClick,
      updateSocStatus: ticketsStore.updateSocStatus,
      camelCase,
    };
  },
});
</script>

<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <div class="subtitle1 mb-4">
      {{ $t("userDataGovernanceSettings.dataLossModals.selectDataType") }}
    </div>
    <v-select
      v-model="localValue.selectedDataType"
      class="mb-8"
      :items="dataTypes"
      variant="outlined"
      hide-details
      data-testid="add-permission-modal-permission-select"
    >
      <template #selection="{ item }">
        <span class="body2">
          {{ $t(`userDataGovernanceSettings.dataLossModals.dataTypes.${item.title}`) }}
        </span>
      </template>
      <template #item="{ props, item }">
        <v-list-item
          v-bind="props"
          :title="$t(`userDataGovernanceSettings.dataLossModals.dataTypes.${item.title}`)"
        ></v-list-item>
      </template>
    </v-select>
    <div class="subtitle1 mb-4">
      {{ $t("userDataGovernanceSettings.permissionsTab.permission") }}
    </div>
    <div class="mb-8">
      <v-radio-group v-model="localValue.choice">
        <v-radio
          v-for="choice in choices"
          :key="choice"
          :data-testid="`add-permission-modal-permission-select-item-${camelCase(choice)}`"
          :label="$t(`userDataGovernanceSettings.permissionsTab.actions.${camelCase(choice)}`)"
          :value="choice"
        ></v-radio>
      </v-radio-group>
    </div>
    <template v-if="localValue.action !== DataLossAction.ADD_PERMISSION_FOR_ALL_USERS">
      <div class="subtitle1 mb-4">
        {{ $t("userDataGovernanceSettings.dataLossModals.setPermissions") }}
      </div>
      <div class="mb-8">
        <v-combobox
          v-if="localValue.action === DataLossAction.ADD_PERMISSION_FOR_SPECIFIC_USERS"
          v-model="localValue.users"
          data-testid="add-permission-modal-users-domains-autocomplete"
          class="mt-3 mb-10"
          :loading="dataLoading"
          :items="users"
          :label="$t(`modals.${localValue.action}.label`)"
          :placeholder="$t(`modals.${localValue.action}.placeholder`)"
          :rules="[emailOrDomainRules, atLeastOneIsRequired()]"
          maxlength="150"
          variant="outlined"
          multiple
          no-filter
          return-object
          item-value="userId"
          item-title="email"
        >
          <template v-slot:chip="{ props, item }">
            <v-chip
              v-bind="props"
              :closable="true"
              variant="flat"
              size="default"
              close-icon="$closeCircle"
              :color="getChipColor(item.value)"
              :text="item.title"
            >
              <div class="d-flex align-center">
                <span class="ml-1 mr-2">{{ item.title }}</span>
              </div>
            </v-chip>
          </template>
          <template #item="{ item }">
            <v-list-item :data-testid="`item-${camelCase(item.raw.email)}`">
              <span class="ml-1">{{ item.raw.email }}</span>
            </v-list-item>
          </template>
        </v-combobox>
        <v-autocomplete
          v-if="localValue.action === DataLossAction.ADD_PERMISSION_FOR_SPECIFIC_GROUPS"
          v-model="localValue.groups"
          class="mt-6"
          :label="$t('modals.addNewGroup.label')"
          :placeholder="$t('modals.addNewGroup.placeholder')"
          item-title="name"
          return-object
          :no-filter="true"
          multiple
          auto-select-first="exact"
          clear-on-select
          :rules="[atLeastOneIsRequired()]"
          :hide-no-data="!groupsSearchTerm?.length"
          :loading="dataLoading"
          :menu-props="{
            maxHeight: '250',
          }"
          :items="groups"
          variant="outlined"
          @update:search="onGroupsUpdate($event)"
        >
          <template v-slot:chip="{ props, item }">
            <v-chip
              v-bind="props"
              :closable="true"
              variant="flat"
              size="default"
              close-icon="$closeCircle"
              :text="item.title"
              color="indigo-faint"
            >
              <div class="d-flex align-center">
                <coro-icon class="service-icon" :icon-name="item.raw.service"></coro-icon>
                <span class="ml-1 mr-2">{{ item.title }}</span>
              </div>
            </v-chip>
          </template>
          <template #item="{ props, item }">
            <v-list-item v-bind="props" title="" :data-testid="`item-${camelCase(item.title)}`">
              <div class="d-flex align-center">
                <coro-icon class="service-icon" :icon-name="item.raw.service"></coro-icon>
                <span class="ml-1">{{ item.title }}</span>
              </div>
            </v-list-item>
          </template>
        </v-autocomplete>
      </div>
    </template>
  </v-form>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import CoroIcon from "@/components/CoroIcon.vue";
import { useI18n } from "vue-i18n";
import { DataLossAction, DataLossPermissionChoice } from "@/constants/user-data-governance";
import { TicketTrigger } from "@/constants/tickets";
import type { VuetifyFormRef } from "@/types";
import { camelCase, debounce } from "lodash";
import Patterns from "@/constants/patterns";
import { atLeastOneIsRequired } from "@/_helpers/validators";
import { useGroupsSettingsTabStore } from "@/_store/users-settings/user-groups-tab.module";

interface AddPermissionModalForm {
  action: string;
  choice: DataLossPermissionChoice;
  selectedDataType: TicketTrigger;
  users: Array<{ email: string }>;
  groups: Array<{ name: string; service: string }>;
}

export default defineComponent({
  components: {
    CoroIcon,
  },
  props: {
    value: {
      type: [Array, Object],
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:localValue", "update:valid"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const userGroupsStore = useGroupsSettingsTabStore();
    const dataLoading = ref(false);
    const localValue = ref<AddPermissionModalForm>({
      users: [],
      groups: [],
      action: props.config.action,
      choice: DataLossPermissionChoice.CAN_ACCESS,
      selectedDataType: TicketTrigger.DLP_PII,
    });
    const users = ref([] as { email: string }[]);
    const groups = ref([] as { name: string; service: string }[]);
    const valid = ref(true);
    const form = ref<VuetifyFormRef>();
    const groupsSearchTerm = ref("");

    watch(
      localValue,
      debounce((value) => {
        emit("update:localValue", value);
      }, 500),
      { deep: true }
    );

    onMounted(() => {
      emit("update:localValue", localValue.value);
    });

    const validate = async () => {
      const validationResult = await form.value?.validate();
      return validationResult?.valid;
    };

    watch(valid, (value) => {
      if (value === null) {
        return;
      }

      emit("update:valid", value);
    });

    const onGroupsUpdate = debounce(async (newVal) => {
      if (!newVal) {
        return;
      }
      dataLoading.value = true;

      const response = await userGroupsStore.searchProtectedGroups(newVal);

      groups.value = [...localValue.value.groups, ...response.data.items];
      dataLoading.value = false;
    }, 500);

    const getChipColor = (email: string) => {
      const isEmailOrDomainValid = Patterns.EMAIL.test(email) || Patterns.DOMAIN.test(email);
      return isEmailOrDomainValid ? "indigo-faint" : "error";
    };

    const emailOrDomainRules = (values: string[]) => {
      if (!values || !values?.length) {
        return true;
      }

      for (const value of values) {
        if (!Patterns.EMAIL.test(value) && !Patterns.DOMAIN.test(value)) {
          return i18n.t("validations.emailOrDomain");
        }
      }

      return true;
    };

    return {
      camelCase,
      users,
      form,
      groups,
      valid,
      validate,
      currentGroupsInputValue: "",
      dataLoading: false,
      choices: [
        DataLossPermissionChoice.CAN_ACCESS,
        DataLossPermissionChoice.CAN_ACCESS_AND_EXPOSE,
      ],
      dataTypes: [
        TicketTrigger.DLP_PII,
        TicketTrigger.DLP_PCI,
        TicketTrigger.DLP_PHI,
        TicketTrigger.DLP_NPI,
        TicketTrigger.CRITICAL_DATA_PASSWORD,
        TicketTrigger.CRITICAL_DATA_CERTIFICATE,
        TicketTrigger.CRITICAL_DATA_SOURCE_CODE,
        TicketTrigger.CRITICAL_DATA_KEYWORDS,
        TicketTrigger.CRITICAL_DATA_FILE_TYPES,
      ],
      localValue,
      DataLossAction,
      atLeastOneIsRequired,
      emailOrDomainRules,
      onGroupsUpdate,
      groupsSearchTerm,
      getChipColor,
    };
  },
});
</script>

<style lang="scss" scoped>
.service-icon {
  height: 22px;
  width: 22px;
  padding-top: 2px;
}
</style>

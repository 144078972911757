<template>
  <table-wrapper class="activity-logs-table">
    <div class="coronet-table--loader">
      <v-progress-linear
        :style="{ visibility: loading ? 'visible' : 'hidden' }"
        indeterminate
        height="2px"
      />
    </div>
    <v-data-table-server
      class="coronet-table--highlitable"
      :items="items"
      :items-per-page="pageSize"
      :items-length="total"
      @update:options="
        $emit('pagination', { pageSize: $event.itemsPerPage, page: $event.page - 1 })
      "
    >
      <template #item="{ item, index }">
        <tr
          :id="`${index}`"
          :key="item.id"
          :class="{ 'activity-logs-table-row--disabled': item.hidden }"
          :row-disabled="item.hidden ? item.hidden : null"
        >
          <td v-if="showIconColumn" class="type-col">
            <v-icon
              class="activity-logs-table__icon"
              v-if="icons[item.type]"
              :icon="`$${icons[item.type]}`"
            >
            </v-icon>
          </td>
          <td>
            <div :class="{ 'pl-4': !showIconColumn }">
              <div :data-testid="`activity-logs-text-${index}`" class="activity-logs-table__text">
                <span v-if="item.undo" class="activity-logs-table__undone-text"
                  >{{ $t("activityLogs.undone") }}:</span
                >
                {{ item.description }}
              </div>
              <div class="activity-logs-table__date">
                {{ getFormattedDateTime(item.date) }}
                <template v-if="item.autoGenerated">
                  {{ $t("activityLogs.automaticallyByCoro") }}
                  <template v-if="item.eventId">
                    (<span v-if="$route.name === 'tickets'">{{ item.eventId }}</span>
                    <router-link v-else :to="getEventIdLink(item)">
                      <a class="coro-link">{{ item.eventId }}</a> </router-link
                    >)
                  </template>
                </template>
                <template v-else-if="item.operatorEmail">
                  {{ $t("general.by") }} {{ item.operatorEmail }}
                </template>
              </div>
            </div>
          </td>
          <td class="actions-col">
            <v-btn
              v-if="item.showUndo"
              :disabled="isActionRestricted"
              :data-testid="`activity-logs-undo-btn-${index}`"
              class="mr-3"
              rounded
              color="primary"
              @click="undoAction(item.id)"
            >
              {{ $t(`activityLogs.actions.undo`) }}
            </v-btn>
            <v-btn
              v-if="showDownloadButton(item)"
              :data-testid="`activity-logs-export-btn-${index}`"
              :disabled="isActionRestricted"
              class="mr-3"
              rounded
              color="primary"
              @click="handleFileDownload(item)"
            >
              {{ $t(`activityLogs.actions.download`) }}
            </v-btn>
          </td>
        </tr>
      </template>

      <template #no-data>
        <div class="headline5 mb-1">
          {{ $t("activityLogs.noData") }}
        </div>
      </template>
      <template v-slot:bottom>
        <v-data-table-footer
          v-if="showPagination"
          :items-per-page-options="paginationOptions"
          :page="pagination.page + 1"
          v-model:items-per-page="pagination.pageSize"
        ></v-data-table-footer>
      </template>
    </v-data-table-server>
  </table-wrapper>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import type { PropType } from "vue";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import {
  dialogsConfigConstructor,
  downloadFileByLink,
  getFormattedDateTime,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import moment from "moment/moment";
import { ActivityLogsType } from "@/constants/activity-logs";
import { TicketStatus } from "@/constants/tickets";
import TableWrapper from "@/components/TableWrapper.vue";
import { useDialogsStore } from "@/_store/dialogs.module";
import { ModalWidth } from "@/constants/modals";
import ErrorModal from "@/components/modals/ErrorModal.vue";
import { useI18n } from "vue-i18n";
import type { ActivityLogItem } from "@/_store/activity-logs.module";
import type { Pagination } from "@/types";
import { paginationOptions } from "@/constants/table";

export default defineComponent({
  components: {
    TableWrapper,
  },
  props: {
    items: {
      type: Array as PropType<ActivityLogItem[]>,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    virtualScrollable: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    pageSize: {
      type: Number,
      default: 25,
    },
    pagination: {
      type: Object as PropType<Pagination>,
      default: () => {
        return {
          page: 0,
          pageSize: 15,
        };
      },
    },
    showPagination: {
      type: Boolean,
      default: false,
    },
    showIconColumn: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["undo-action", "pagination"],
  setup(props, { emit }) {
    const dialogStore = useDialogsStore();
    const i18n = useI18n();
    const icons: Partial<Record<ActivityLogsType, string>> = {
      [ActivityLogsType.UNDO]: "undone",
      [ActivityLogsType.USERS]: "groups",
      [ActivityLogsType.CLOUD_APPS]: "cloud",
      [ActivityLogsType.MALWARE]: "listMalware",
      [ActivityLogsType.DEVICE]: "laptop",
      [ActivityLogsType.ACCOUNT]: "listAuthentication",
      [ActivityLogsType.DEVICE_DLP]: "dlp",
      [ActivityLogsType.DLP]: "dlp",
      [ActivityLogsType.EMAIL]: "email",
      [ActivityLogsType.PHISHING]: "emailPhishing",
      [ActivityLogsType.BILLING]: "billing",
      [ActivityLogsType.DETECTION_LOGS]: "graphSquared",
      [ActivityLogsType.WORKSPACE]: "listAuthentication",
      [ActivityLogsType.MSP]: "manageWorkspaces",
      [ActivityLogsType.CSV_EXPORT]: "export",
      [ActivityLogsType.WORKSPACE_REPORT]: "export",
      [ActivityLogsType.WORKSPACE_SCHEDULED_REPORT]: "export",
      [ActivityLogsType.SIEM_CONFIGS]: "connectors",
      [ActivityLogsType.SOC]: "user",
      [ActivityLogsType.API_CREDENTIALS]: "connectors",
      [ActivityLogsType.MOBILE]: "smartphone",
      [ActivityLogsType.WEBHOOK]: "connectors",
    };

    const isActionRestricted = computed(() =>
      isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.ACTIVITY_LOGS
      )
    );

    const downloadActivityLogsFile = (item: ActivityLogItem) => {
      const logCreatedHoursAgo = moment().diff(moment(item.date), "hours");
      if (logCreatedHoursAgo > 24) {
        const errorModal = {
          width: ModalWidth.SMALL,
          header: undefined,
          content: {
            component: ErrorModal,
          },
          footer: null,
          data: {
            item:
              item.type === ActivityLogsType.CSV_EXPORT
                ? i18n.t("activityLogs.errors.csvDownloadLinkExpired")
                : i18n.t("activityLogs.errors.reportDownloadLinkExpired"),
          },
        };
        const dialogConfig = dialogsConfigConstructor(errorModal);
        dialogStore.openDialog(dialogConfig);
        return;
      }
      downloadFileByLink(item.metadata.downloadLink);
    };

    const undoAction = (id: string) => {
      emit("undo-action", id);
    };

    const handleFileDownload = (item: ActivityLogItem) => {
      switch (item.type) {
        case ActivityLogsType.CSV_EXPORT:
        case ActivityLogsType.WORKSPACE_REPORT:
          return downloadActivityLogsFile(item);
        default:
          return void 0;
      }
    };
    const showDownloadButton = (item: ActivityLogItem) => {
      return (
        item?.metadata?.downloadLink &&
        [ActivityLogsType.CSV_EXPORT, ActivityLogsType.WORKSPACE_REPORT].includes(item.type)
      );
    };
    const getEventIdLink = (item: ActivityLogItem) => {
      return {
        path: "/portal/tickets",
        query: {
          search: item.eventId,
          status: TicketStatus.ALL,
        },
      };
    };

    return {
      RolePermissionsScope,
      isActionRestricted,
      handleFileDownload,
      getFormattedDateTime,
      icons,
      undoAction,
      showDownloadButton,
      getEventIdLink,
      paginationOptions,
    };
  },
});
</script>

<style lang="scss" scoped>
.icon-graph-squared:before {
  color: rgb(var(--v-theme-primary)) !important;
  font-size: 30px !important;
}
.icon-manage-workspace:before {
  color: rgb(var(--v-theme-primary)) !important;
  font-size: 30px !important;
}

.activity-logs-table__icon {
  font-size: 40px;
}

.activity-logs-table__text {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
}

.activity-logs-table__date {
  font-size: 16px;
  line-height: 24px;
  color: rgb(var(--v-theme-indigo-medium));
}

.activity-logs-table__undone-text {
  color: rgb(var(--v-theme-orange-base));
  text-transform: uppercase;
}

.activity-logs-table-row--disabled {
  .activity-logs-table__text {
    text-decoration: line-through;
    color: rgb(var(--v-theme-indigo-medium));
  }
  i:before {
    color: rgb(var(--v-theme-indigo-medium));
  }
}

td {
  &.type-col {
    padding: 16px 0 !important;
    width: 70px;
    text-align: center;
  }

  &.actions-col {
    width: 56px;
    text-align: center;
  }
}

:deep(.v-data-table) {
  .icon-export {
    &::before {
      color: rgb(var(--v-theme-primary));
      font-size: 30px !important;
    }
  }

  thead {
    display: none;
  }

  tr {
    height: 73px;
  }

  td {
    padding: 0 !important;
    white-space: normal;
  }
}
</style>

<template>
  <div>
    <v-data-table
      class="coronet-table--list"
      :headers="headers"
      :items="items"
      :items-per-page="pagination.pageSize"
      :server-items-length="totalItems"
      :loading="loading"
      show-select
      return-object
      v-model="selectedItems"
    >
      <template #header.data-table-select>
        <v-checkbox
          v-model="allItemsSelected"
          data-testid="table-select-all-checkbox"
          :indeterminate="isIndeterminate"
          class="ml-3"
          primary
          hide-details
          @update:modelValue="handleAllRowsSelection"
        ></v-checkbox>
      </template>
      <template #header.type>
        <div class="d-flex align-center justify-space-between">
          <list-table-header
            :pagination="pagination"
            :total-items="totalItems"
            :items="items"
            :show-pagination="showPagination"
            @pagination-change="handleListTablePaginationChange"
          >
            <template #prepend>
              <span class="table-selected-text" v-if="selectedItemsQuantity > 0">
                {{ $t("usersPage.selected", { n: selectedItemsQuantity }) }}
              </span>
            </template>
            <template #append>
              <v-menu v-if="selectedItems.length > 0" offset-y bottom right>
                <template #activator="{ props }">
                  <v-btn
                    data-testid="users-table-bulk-actions-btn"
                    class="ml-8"
                    rounded
                    color="primary"
                    v-bind="props"
                    @click="getBulkActions()"
                  >
                    {{ $t("general.actions") }}
                    <v-icon class="ml-1 mt-1" size="10" icon="$triangle" />
                  </v-btn>
                </template>
                <v-list>
                  <template v-if="availableBulkActions.length === 0">
                    <div v-if="actionsLoading" class="d-flex align-center justify-center">
                      <v-progress-circular
                        size="30"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                    <v-list-item v-else data-testid="users-table-no-actions-available-item">
                      <v-list-item-title>
                        {{ $t("general.noActionsAvailable") }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-for="action in availableBulkActions" :key="action">
                    <v-list-item
                      :data-testid="`users-table-${kebabCase(action)}-action`"
                      @click="onBulkActionClick(action)"
                    >
                      <v-list-item-title>
                        {{ $t(`usersPage.actions.${action}`) }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-list>
              </v-menu>
            </template>
          </list-table-header>
        </div>
      </template>
      <template #item="{ item, isSelected, index, internalItem }">
        <tr
          :key="index"
          :class="{
            'coro-table-row--selected': isSelected([internalItem]),
            'coro-table-row--active': isRowActive(item[itemKey]),
          }"
          @click="handleRowClick(item)"
        >
          <td class="pl-2">
            <v-checkbox-btn
              :data-testid="`table-checkbox-row-${index}`"
              :model-value="isSelected([internalItem])"
              @update:modelValue="handleOneRowSelection($event, item)"
              @click.stop
            ></v-checkbox-btn>
          </td>
          <td :id="`events-table-type-col-row-${index}`" class="pl-0 pr-0">
            <div class="user-grid">
              <div class="user-icon">
                <v-icon icon="$user" size="40" />
              </div>

              <div class="user-info d-flex flex-column justify-center pr-2">
                <div :data-testid="`users-table-name-row-${index}`" class="subtitle2">
                  {{ item?.name }}
                </div>
                <div :data-testid="`users-table-email-row-${index}`" class="body2">
                  {{ item?.email }}
                  <template v-if="item.aliases.length"> +{{ item.aliases.length }} </template>
                </div>
              </div>

              <div class="status-icon mt-4 mr-4">
                <v-tooltip v-if="item.hasOpenTickets" open-delay="300" location="top">
                  <template #activator="{ props }">
                    <v-icon
                      :data-testid="`users-table-icon-row-${index}`"
                      icon="$warning"
                      v-bind="props"
                    />
                  </template>
                  {{ $t("general.unresolvedTickets") }}
                </v-tooltip>
                <v-tooltip v-else open-delay="300" location="top">
                  <template #activator="{ props }">
                    <v-icon
                      :data-testid="`users-table-icon-row-${index}`"
                      v-bind="props"
                      icon="$circleCheck"
                    />
                  </template>
                  {{ $t("general.noTickets") }}
                </v-tooltip>
              </div>
            </div>
          </td>
        </tr>
      </template>
      <template #bottom></template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType, ref, toRefs } from "vue";
import { TicketType, TicketAction } from "@/constants/tickets";
import { usePageableTable } from "@/composables/usePageableTable";
import type { Pagination } from "@/types";
import { useSelectableTable } from "@/composables/useSelectableTable";
import kebabCase from "lodash/kebabCase";
import { useListTable } from "@/composables/useListTable";
import { ModalWidth } from "@/constants/modals";
import { RolePermissionsScope } from "@/_store/roles.module";
import { useDialogsStore } from "@/_store/dialogs.module";
import { type UserListItem, useUsersStore } from "@/_store/users.module";
import ListTableHeader from "@/components/ListTableHeader.vue";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import BulkTicketCloseModal from "@/components/modals/BulkTicketCloseModal.vue";
import { SubscriptionModule } from "@/constants/workplaces";
import { useSelectorStore } from "@/_store/selector.module";

export default defineComponent({
  components: {
    ListTableHeader,
  },
  props: {
    items: {
      type: Array as PropType<UserListItem[]>,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    pagination: {
      type: Object as PropType<Pagination>,
      default: () => {
        return {
          page: 0,
          pageSize: 15,
        };
      },
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["selection-changed", "page-changed", "update-preview", "update-page-count"],
  setup(props, { emit }) {
    const headers = [
      {
        value: "type",
      },
    ];
    const itemKey: keyof UserListItem = "id";
    const dialogStore = useDialogsStore();
    const usersStore = useUsersStore();
    const { totalItems, items, pagination } = toRefs(props);
    const availableBulkActions = ref<string[]>([]);
    const { selection } = toRefs(useSelectorStore());
    const actionsLoading = ref(false);
    const { totalPages, page, showPagination, handleListTablePaginationChange } = usePageableTable(
      {
        totalItems,
        items,
        pagination,
      },
      (value: Pagination) => {
        emit("page-changed", value);
      }
    );

    const {
      selectedItems,
      handleOneRowSelection,
      handleAllRowsSelection,
      isIndeterminate,
      allItemsSelected,
      selectedItemsQuantity,
    } = useSelectableTable({
      totalItems,
      items,
    });

    const { handleRowClick, isRowActive, activeRowId } = useListTable<UserListItem>(
      { items, itemKey: "id" },
      (item) => {
        emit("update-preview", item);
      }
    );

    const onBulkActionClick = (action: string) => {
      switch (action) {
        case TicketAction.MARK_AS_PROCESSED:
          dialogStore.openDialog(
            componentDialogsConfigConstructor({
              item: {
                selection: {
                  ...selection.value,
                },
                callback: usersStore.onProtectedUsersAction,
                modules: [
                  SubscriptionModule.EMAIL_SECURITY,
                  SubscriptionModule.CLOUD_SECURITY,
                  SubscriptionModule.USER_DATA_GOVERNANCE,
                ],
              },
              action,
              component: BulkTicketCloseModal,
              width: ModalWidth.MEDIUM,
              callback: () => {},
              hideFooter: true,
              disable: isWorkspaceFrozenOrActionRestricted(RolePermissionsScope.VIEWS, "usersView"),
            })
          );
          break;
        default:
          dialogStore.openDialog(
            confirmationDialogsConfigConstructor({
              action,
              disable: isWorkspaceFrozenOrActionRestricted(RolePermissionsScope.VIEWS, "usersView"),
              callback: () => {
                usersStore.onProtectedUsersAction({
                  selection: {
                    ...selection.value,
                  },
                  action,
                });
              },
            })
          );
          break;
      }
    };

    const getBulkActions = async () => {
      availableBulkActions.value = [];
      actionsLoading.value = true;
      try {
        const { data } = await useUsersStore().getActions(selection.value);
        availableBulkActions.value = data.items;
        actionsLoading.value = false;
      } catch {
        actionsLoading.value = false;
      }
    };

    return {
      headers,
      eventTypes: TicketType,
      itemKey,
      onBulkActionClick,
      totalPages,
      page,
      handleListTablePaginationChange,
      kebabCase,
      handleRowClick,
      isRowActive,
      activeRowId,
      selectedItems,
      showPagination,
      handleOneRowSelection,
      handleAllRowsSelection,
      isIndeterminate,
      allItemsSelected,
      selectedItemsQuantity,
      getBulkActions,
      availableBulkActions,
      actionsLoading,
    };
  },
});
</script>

<style lang="scss">
.user-grid {
  display: grid;
  grid-template-columns: auto 1fr 40px;
  grid-template-areas: "user-icon user-info status-icon";
  align-items: center;
  width: 100%;
  height: 100%;

  .user-icon {
    grid-area: user-icon;
  }

  .user-info {
    grid-area: user-info;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: normal;
  }

  .status-icon {
    grid-area: status-icon;
  }
}
</style>

<template>
  <div>
    <div class="pa-4">
      <div v-if="valid === false" class="error-block d-flex align-center">
        <v-icon class="mr-3 ml-4" icon="$warning"></v-icon>
        <span class="body2 text-red-dark">{{ $t("validations.general") }}</span>
      </div>
      <v-card class="account-form__wrapper">
        <v-form ref="form" v-model="valid" class="pa-6">
          <div class="subtitle1 mb-6">{{ $t("myAccount.profile.title") }}</div>
          <v-text-field
            class="mb-4"
            v-model="localValue.firstName"
            variant="outlined"
            :rules="[required()]"
            :label="$t('myAccount.profile.firstName.label')"
          />
          <v-text-field
            v-model="localValue.lastName"
            variant="outlined"
            mb-6
            :rules="[required()]"
            :label="$t('myAccount.profile.lastName.label')"
            @keyup.enter="onUpdateProfile()"
          />
          <div class="subtitle1 mb-4">
            {{ $t("myAccount.profile.language") }}
          </div>
          <v-select
            v-model="localValue.preferredLanguageCode"
            :items="Object.values(WorkspaceLocale)"
            class="mb-6"
            :label="$t('myAccount.profile.language')"
            :placeholder="$t('myAccount.profile.language')"
            persistent-hint
            variant="outlined"
          >
            <template #selection="{ item }">
              <span class="body2">
                {{ $t(`general.localeDisplayNames.${item.raw}`) }}
              </span>
            </template>
            <template #item="{ item, props }">
              <v-list-item v-bind="props" :title="$t(`general.localeDisplayNames.${item.raw}`)">
              </v-list-item>
            </template>
          </v-select>
          <div class="subtitle1">{{ $t("myAccount.profile.email.label") }}</div>
          <div class="body2">{{ account.email }}</div>
          <div class="caption text-indigo-medium">
            {{ $t("myAccount.profile.email.hint") }}
          </div>
          <v-btn
            color="primary"
            rounded
            size="large"
            class="mt-6"
            :loading="loading"
            :disabled="!valid"
            @click="onUpdateProfile()"
          >
            {{ $t("myAccount.profile.updateProfile") }}
          </v-btn>
        </v-form>
      </v-card>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { type ProfileData, useMyAccountStore } from "@/_store/my-account.module";
import { required } from "@/_helpers/validators";
import type { VuetifyFormRef } from "@/types";
import { storeToRefs } from "pinia";
import { useAccountStore } from "@/_store";
import cloneDeep from "lodash/cloneDeep";
import { WorkspaceLocale } from "@/constants/workplaces";

export default defineComponent({
  setup() {
    const localValue = ref<ProfileData>({ allowMfa: false });
    const valid = ref(true);
    const loading = ref(false);
    const form = ref<VuetifyFormRef>();
    const showValidationAlert = ref(false);
    const myAccountStore = useMyAccountStore();
    const accountStore = useAccountStore();
    const { myAccount } = storeToRefs(myAccountStore);
    const { account } = storeToRefs(accountStore);

    const onUpdateProfile = async () => {
      const validationResult = await form.value?.validate();
      if (validationResult?.valid) {
        loading.value = true;
        await myAccountStore.updateProfileData(localValue.value!);
        loading.value = false;
      }
    };

    watch(
      () => myAccount.value.profileData,
      (value) => {
        localValue.value = cloneDeep(value);
      },
      { deep: true, immediate: true }
    );

    return {
      WorkspaceLocale,
      loading,
      localValue,
      required,
      valid,
      form,
      showValidationAlert,
      myAccount,
      account,
      onUpdateProfile,
    };
  },
});
</script>

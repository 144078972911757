<template>
  <div>
    <span class="subtitle1">
      {{ $t("connectors.psa.addPsaConnector.autotask.subtitle") }}
    </span>
    <div>
      {{ $t("connectors.psa.addPsaConnector.autotask.description") }}
    </div>
    <v-form ref="form" v-model="valid" validate-on="input" class="mb-5">
      <v-text-field
        v-model="localValue.item.username"
        data-testid="add-admin-modal-lastname"
        class="mt-6 mb-2"
        :rules="[required()]"
        variant="outlined"
        :label="$t('modals.addAutotaskConnector.userName.label')"
        :placeholder="$t('modals.addAutotaskConnector.userName.placeholder')"
      ></v-text-field>
      <v-text-field
        v-model="localValue.item.secret"
        data-testid="add-admin-modal-email"
        class="mt-6 mb-2"
        variant="outlined"
        :rules="[required()]"
        :label="$t('modals.addAutotaskConnector.secret.label')"
        :placeholder="$t('modals.addAutotaskConnector.secret.placeholder')"
      ></v-text-field>
      <!--    <v-checkbox-->
      <!--        v-model="localValue.item.ticketingEnabled"-->
      <!--        :label="$t('modals.addConnectwiseConnector.enableTicketing')"-->
      <!--    ></v-checkbox>-->
      <!--    <v-checkbox-->
      <!--        v-model="localValue.item.billingEnabled"-->
      <!--        :label="$t('modals.addConnectwiseConnector.enableBilling')"-->
      <!--    ></v-checkbox>-->
    </v-form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { usePsaStore } from "@/_store/connectors/psa.module";
import cloneDeep from "lodash/cloneDeep";
import { storeToRefs } from "pinia";
import { required } from "@/_helpers/validators";

const defaultAutotaskConnectConfig = {
  username: "",
  secret: "",
  ticketingEnabled: false,
  billingEnabled: false,
};

export default defineComponent({
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  emits: ["cancel", "update:valid", "update:value"],
  setup(props, { emit }) {
    const psaStore = usePsaStore();
    const localValue = ref({
      item: cloneDeep(props.config.item ?? defaultAutotaskConnectConfig),
    });

    const valid = ref(true);
    const { loading } = storeToRefs(psaStore);

    const form = ref();

    watch(
      valid,
      (newVal) => {
        if (newVal === null) {
          return;
        }

        emit("update:valid", newVal);
      },
      { immediate: false }
    );

    watch(
      localValue,
      async (value) => {
        emit("update:value", value.item);
      },
      { deep: true }
    );

    const cancel = () => {
      emit("cancel");
    };

    return {
      localValue,
      valid,
      form,
      required,
      cancel,
      loading,
    };
  },
});
</script>

<style scoped lang="scss"></style>

<template>
  <v-skeleton-loader
    :loading="showSkeletonLoader"
    :type="coronetSkeletonLoaderTypes.USERS_SETTINGS"
  >
    <table-wrapper class="mt-3" v-if="items.length > 0">
      <v-table class="white">
        <template #default>
          <thead>
            <tr>
              <th class="text-left caption">
                {{ $t("endpointDataGovernance.sensitiveDataScansTab.table.os") }}
              </th>
              <th class="text-left caption">
                {{ $t("endpointDataGovernance.sensitiveDataScansTab.table.action") }}
              </th>
              <th class="text-left caption status-column">
                {{ $t("endpointDataGovernance.sensitiveDataScansTab.table.drives") }}
              </th>
              <th class="text-left caption">
                {{ $t("endpointDataGovernance.sensitiveDataScansTab.table.labels") }}
              </th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, i) in items"
              :key="item.id"
              :data-testid="`table-row-${i}`"
              class="sensitive-data-scans-row"
            >
              <td class="content-row">
                <div class="d-flex align-center">
                  <v-icon
                    :icon="item.osType === osTypes.WINDOWS ? '$windowsPc' : '$macPc'"
                    :size="40"
                  />
                  <span class="subtitle2 ml-3">
                    {{ $t(`general.osVersion.${camelCase(item.osType)}`) }}
                  </span>
                </div>
              </td>
              <td>
                <span class="body2"
                  >{{ $t("general.everyXDays", { n: item.daysCount }) }}&nbsp;</span
                >
                <span class="body2">{{ $t("general.at") }}&nbsp;</span>
                <span class="body2">{{
                  moment({
                    hour: item.preferredHour,
                    minutes: 0,
                  }).format("LT")
                }}</span>
              </td>
              <td>
                <span class="body2">
                  {{
                    $t(
                      `endpointDataGovernance.sensitiveDataScansTab.table.appliedDriveTypes.${item.appliedDriveType}`
                    )
                  }}
                </span>
              </td>
              <td>
                <coro-label
                  v-for="label in item.labels"
                  :key="label.name"
                  :background-color="label.color"
                  class="mr-2"
                >
                  {{ label.name }}
                </coro-label>
              </td>
              <td class="text-right" @click="$event.stopPropagation()">
                <v-menu bottom left>
                  <template v-slot:activator="{ props }">
                    <v-icon icon="$dots" v-bind="props"> </v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(action, index) in actions"
                      :key="index"
                      :value="index"
                      :data-testid="`sensitive-data-scans-table-menu-item-${index}`"
                      @click="action.callback(item)"
                    >
                      <v-list-item-title>
                        {{
                          $t(
                            `endpointDataGovernance.sensitiveDataScansTab.table.actions.${action.action}`
                          )
                        }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </template>
      </v-table>
    </table-wrapper>
    <div v-else class="d-flex align-center justify-center">
      <empty-state class="mt-12">
        <template #icon>
          <v-icon icon="$endpointDataGovernance" :size="80" />
        </template>
        <template #description>
          {{ $t("endpointDataGovernance.sensitiveDataScansTab.emptyState.description") }}
        </template>
        <template #subtitle>
          {{ $t("endpointDataGovernance.sensitiveDataScansTab.emptyState.subtitle") }}
        </template>
        <template #button>
          <v-btn
            rounded
            color="primary"
            density="default"
            size="large"
            elevation="0"
            @click="addSensitiveDataScan()"
          >
            {{ $t("endpointDataGovernance.sensitiveDataScansTab.addBtn") }}
          </v-btn>
        </template>
      </empty-state>
    </div>
  </v-skeleton-loader>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from "vue";
import type { SensitiveDataScanItem } from "@/_store/endpoint-data-governance/sensitive-data-scans.module";
import { useSensitiveDataScansStore } from "@/_store/endpoint-data-governance/sensitive-data-scans.module";
import { storeToRefs } from "pinia";
import { OsType } from "@/constants/devices";
import moment from "moment/moment";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import TableWrapper from "@/components/TableWrapper.vue";
import EmptyState from "@/components/EmptyState.vue";
import CoroLabel from "@/components/CoroLabel.vue";
import camelCase from "lodash/camelCase";
import {
  defaultSensitiveDataItem,
  SensitiveDataScansActions,
} from "@/constants/endpoint-data-governance";
import {
  componentDialogsConfigConstructor,
  confirmationDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { ModalWidth } from "@/constants/modals";
import { useDialogsStore } from "@/_store/dialogs.module";
import SensitiveDataScansModal from "@/components/modals/SensitiveDataScansModal.vue";
import cloneDeep from "lodash/cloneDeep";
import { RolePermissionsScope } from "@/_store/roles.module";
import { SubscriptionModule } from "@/constants/workplaces";

export default defineComponent({
  components: {
    TableWrapper,
    EmptyState,
    CoroLabel,
  },
  setup() {
    const sensitiveDataScansModule = useSensitiveDataScansStore();
    const { items, showSkeletonLoader } = storeToRefs(sensitiveDataScansModule);
    const {
      editSensitiveDataScanPolicy,
      deleteSensitiveDataScanPolicy,
      createSensitiveDataScanPolicy,
    } = sensitiveDataScansModule;
    const dialogsStore = useDialogsStore();
    const actionNotAllowed = computed(() => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.PROTECTION,
        SubscriptionModule.ENDPOINT_DATA_GOVERNANCE
      );
    });
    const deleteItem = (item: SensitiveDataScanItem) => {
      const dialogConfig = confirmationDialogsConfigConstructor({
        item,
        action: SensitiveDataScansActions.DELETE,
        callback: deleteSensitiveDataScanPolicy,
        disable: actionNotAllowed.value,
      });
      dialogsStore.openDialog(dialogConfig);
    };
    const editItem = (item: SensitiveDataScanItem) => {
      const dialogConfig = componentDialogsConfigConstructor({
        item,
        action: SensitiveDataScansActions.EDIT,
        component: SensitiveDataScansModal,
        width: ModalWidth.LARGE,
        callback: editSensitiveDataScanPolicy,
        disable: actionNotAllowed.value,
      });
      dialogsStore.openDialog(dialogConfig);
    };
    const addSensitiveDataScan = () => {
      const dialogConfig = componentDialogsConfigConstructor({
        item: {
          ...cloneDeep(defaultSensitiveDataItem),
        },
        action: SensitiveDataScansActions.CREATE,
        component: SensitiveDataScansModal,
        width: ModalWidth.LARGE,
        callback: createSensitiveDataScanPolicy,
        disable: actionNotAllowed.value,
      });
      dialogsStore.openDialog(dialogConfig);
    };
    const actions = [
      {
        action: SensitiveDataScansActions.EDIT,
        callback: editItem,
      },
      {
        action: SensitiveDataScansActions.DELETE,
        callback: deleteItem,
      },
    ];
    onMounted(async () => {
      await sensitiveDataScansModule.getSensitiveDataScans();
    });
    return {
      showSkeletonLoader,
      coronetSkeletonLoaderTypes,
      items,
      camelCase,
      moment,
      osTypes: OsType,
      actions,
      addSensitiveDataScan,
    };
  },
});
</script>

<style scoped lang="scss">
.icon-endpoint-data-governance:before {
  color: rgba(var(--v-theme-primary), 0.65);
}

.sensitive-data-scans-row {
  height: 72px;
}
</style>

<template>
  <div>
    <template v-if="createWorkspaceInProgress">
      <div class="h-100 w-100 d-flex align-center justify-center">
        <div class="progress-container d-flex flex-column align-center text-center">
          <v-progress-circular color="primary" indeterminate size="48" class="mb-5 mt-12" />
          <h5 class="headline5 text-center px-6">
            {{ $t("modals.createWorkspace.requestInProgress") }}
          </h5>
        </div>
      </div>
    </template>
    <div
      v-else-if="createWorkspaceCompleted"
      class="h-100 w-100 d-flex align-center justify-center"
    >
      <div class="progress-container d-flex flex-column align-center text-center">
        <v-icon size="80" class="mb-6" icon="$noIssues" />
        <h5 class="headline5 mr-12 ml-12 text-center">
          {{
            $t("modals.createWorkspace.workspaceCreated", {
              workspace: localValue.item.displayName,
            })
          }}
        </h5>
        <div class="body2 w-60 mt-2 mb-6 mr-12 ml-12">
          {{
            adminExistsInOtherHierarchy
              ? $t("modals.createWorkspace.mspAdminExists", {
                  email: localValue.item.adminEmail,
                })
              : $t("modals.createWorkspace.inviteSent", {
                  email: localValue.item.adminEmail,
                })
          }}
        </div>
        <v-btn size="large" rounded color="primary" @click="$emit('dismissed')">
          {{ $t("general.done") }}
        </v-btn>
      </div>
    </div>
    <div v-else-if="createWorkspaceFailed" class="h-100 w-100 d-flex align-center justify-center">
      <div class="progress-container d-flex flex-column align-center">
        <v-icon size="80" class="mb-6" icon="$warning" />
        <h5 class="headline5 mb-6 mr-12 ml-12 text-center">
          {{ $t("modals.createWorkspace.requestFailed") }}
        </h5>
        <v-btn size="large" rounded color="primary" @click="$emit('dismissed')">
          {{ $t("general.ok") }}
        </v-btn>
      </div>
    </div>
    <v-form ref="form" v-else v-model="valid" @submit.prevent>
      <v-stepper v-model="currentStep" width="100vw">
        <template v-slot:default>
          <div class="d-flex justify-center w-100 scroll-area">
            <div class="dialog-content">
              <v-stepper-header>
                <div class="mt-6 progress-bar__content">
                  <div class="body3 mb-4">
                    {{ $t("general.step") }}
                    {{ getCurrentStepNumber(steps) }}
                    {{ $t("general.of") }}
                    {{ steps.length }}
                  </div>
                  <v-progress-linear color="#46A762" v-model="stepsProgress"></v-progress-linear>
                </div>
                <template v-for="n in steps" :key="`${n}-step`">
                  <v-stepper-item
                    hidden
                    :complete="currentStep > n"
                    :step="`Step {{ n }}`"
                    :value="n"
                    editable
                  ></v-stepper-item>
                </template>
              </v-stepper-header>
              <v-stepper-window :key="currentStep">
                <v-stepper-window-item v-for="step in steps" :key="`${step}-content`" :value="step">
                  <div class="stepper-step__wrapper">
                    <div>
                      <template v-if="step === workspaceCreationSteps.COMPANY_DETAILS">
                        <div class="headline5 mb-3">
                          {{ $t("modals.createWorkspace.companyDetails") }}
                        </div>
                        <v-card>
                          <v-card-title>
                            <v-row>
                              <v-col class="mt-2 mb-2" :cols="8">
                                <v-text-field
                                  v-model="localValue.item.companyName"
                                  type="text"
                                  data-testid="add-workspace-modal-company-name"
                                  :rules="companyNameRules"
                                  variant="outlined"
                                  :label="$t('modals.createWorkspace.companyName')"
                                  :placeholder="$t('modals.createWorkspace.companyName')"
                                ></v-text-field>
                                <v-text-field
                                  v-model="localValue.item.displayName"
                                  type="text"
                                  data-testid="add-workspace-modal-display-name"
                                  class="mt-4"
                                  :rules="displayNameRules"
                                  counter="30"
                                  :label="$t('modals.createWorkspace.displayName')"
                                  :placeholder="$t('modals.createWorkspace.displayName')"
                                  :hint="$t('modals.createWorkspace.displayNameHint')"
                                  persistent-hint
                                  variant="outlined"
                                ></v-text-field>
                                <v-text-field
                                  v-model="localValue.item.domain"
                                  type="text"
                                  data-testid="add-workspace-modal-domain"
                                  class="mt-4"
                                  :rules="domainRules"
                                  :placeholder="$t('modals.createWorkspace.domainPlaceholder')"
                                  :label="$t('modals.createWorkspace.domain')"
                                  :hint="$t('modals.createWorkspace.domainHint')"
                                  persistent-hint
                                  variant="outlined"
                                >
                                </v-text-field>
                                <v-autocomplete
                                  v-if="showParentWorkspaceIdInput"
                                  v-model="localValue.item.parentWorkspaceId"
                                  class="mt-4"
                                  :placeholder="$t('modals.createWorkspace.parentWorkspace')"
                                  :label="$t('modals.createWorkspace.parentWorkspace')"
                                  item-title="workspaceName"
                                  item-value="workspaceId"
                                  :hide-no-data="false"
                                  :no-filter="true"
                                  auto-select-first="exact"
                                  :rules="parentWorkspaceIdRules"
                                  :loading="parentWorkspaceInputLoading"
                                  :menu-props="{
                                    maxHeight: '250',
                                  }"
                                  :items="parentWorkspaces"
                                  variant="outlined"
                                  @update:search="onParentWorkspaceSearchUpdate($event)"
                                >
                                  <template #item="{ item, props }">
                                    <v-list-item v-bind="props" title="">
                                      <v-list-item-title title="">
                                        {{ `${item.raw.workspaceName} (${item.raw.workspaceId})` }}
                                      </v-list-item-title>
                                    </v-list-item>
                                  </template>
                                </v-autocomplete>
                                <v-text-field
                                  v-model="localValue.item.adminEmail"
                                  class="mt-4"
                                  type="text"
                                  data-testid="add-workspace-modal-admin-email"
                                  :rules="adminEmailRules"
                                  :label="$t('modals.createWorkspace.adminEmail')"
                                  :placeholder="$t('modals.createWorkspace.adminEmailPlaceholder')"
                                  :hint="$t('modals.createWorkspace.adminEmailHint')"
                                  persistent-hint
                                  variant="outlined"
                                ></v-text-field>
                                <div
                                  v-if="showLimitField"
                                  class="subtitle1 mt-4 mb-4 text-capitalize"
                                >
                                  {{ $t("general.optional") }}
                                </div>
                                <v-text-field
                                  v-if="showLimitField"
                                  v-model="localValue.item.maxProtectedUsers"
                                  type="number"
                                  :rules="limitProtectedUsersRules"
                                  data-testid="add-workspace-modal-limit-protected-users"
                                  class="mt-3"
                                  :label="$t('modals.createWorkspace.limitProtectedUsers')"
                                  :placeholder="
                                    $t('modals.createWorkspace.limitProtectedUsersPlaceholder')
                                  "
                                  :hint="$t('modals.createWorkspace.limitProtectedUsersHint')"
                                  persistent-hint
                                  variant="outlined"
                                  @keydown="filterKey"
                                  @paste.prevent
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-card-title>
                        </v-card>
                      </template>
                      <template v-if="step === workspaceCreationSteps.MSP_SETTINGS">
                        <div class="headline5 mb-3">
                          {{ $t("modals.createWorkspace.workspaceSettings") }}
                        </div>
                        <v-card>
                          <v-card-text>
                            <v-select
                              v-model="localValue.item.languageCode"
                              :items="Object.values(WorkspaceLocale)"
                              class="mt-4"
                              :rules="[required()]"
                              :label="$t('modals.createWorkspace.language.label')"
                              :placeholder="$t('modals.createWorkspace.language.placeholder')"
                              persistent-hint
                              variant="outlined"
                            >
                              <template #selection="{ item }">
                                <span class="body2">
                                  {{ $t(`general.localeDisplayNames.${item.raw}`) }}
                                </span>
                              </template>
                              <template #item="{ item, props }">
                                <v-list-item
                                  v-bind="props"
                                  :title="$t(`general.localeDisplayNames.${item.raw}`)"
                                >
                                </v-list-item>
                              </template>
                            </v-select>
                            <template v-if="!showBrandingSettings">
                              <div class="subtitle1 mt-4 mb-4">
                                {{ $t("modals.createWorkspace.notifications") }}
                              </div>
                              <v-radio-group v-model="localValue.item.notificationsLevel">
                                <v-radio
                                  v-for="notificationLevel in notificationLevels"
                                  class="mb-2"
                                  :key="notificationLevel"
                                  :value="notificationLevel"
                                  :data-testid="`add-workspace-modal-${notificationLevel}-radio`"
                                >
                                  <template #label>
                                    <span
                                      class="body1 notification-label"
                                      v-html="
                                        getNotificationLevelText(
                                          localValue.item.type,
                                          notificationLevel,
                                          domainDisplayName
                                        )
                                      "
                                    ></span>
                                  </template>
                                </v-radio>
                              </v-radio-group>
                            </template>
                            <div class="subtitle1 mt-4 mb-4">
                              {{ $t("modals.createWorkspace.advancedSettings") }}
                            </div>
                            <v-switch
                              v-if="showBrandingSettings"
                              v-model="localValue.item.supportEnabled"
                              class="mb-2"
                              :ripple="false"
                              density="compact"
                              hide-details
                              color="primary"
                              inset
                              flat
                            >
                              <template #label>
                                <div class="body2">
                                  {{ $t("modals.createWorkspace.showSupportLink") }}
                                </div>
                              </template>
                            </v-switch>
                            <v-switch
                              v-if="showScanUnprotectedUsers"
                              v-model="localValue.item.scanUnprotectedUsers"
                              class="mb-2"
                              :ripple="false"
                              density="compact"
                              hide-details
                              color="primary"
                              inset
                              flat
                            >
                              <template #label>
                                <div class="body2">
                                  {{ $t("modals.createWorkspace.scanUnprotectedUsers") }}
                                </div>
                              </template>
                            </v-switch>
                            <v-switch
                              v-model="localValue.item.showDisabledModules"
                              class="mb-2"
                              :ripple="false"
                              density="compact"
                              hide-details
                              color="primary"
                              inset
                              flat
                            >
                              <template #label>
                                <div class="body2">
                                  {{ $t("modals.createWorkspace.showDisabledModules") }}
                                </div>
                              </template>
                            </v-switch>
                            <v-switch
                              v-if="isGlobalAdmin"
                              v-model="localValue.item.isCoroEmployeeWorkspace"
                              class="mb-2"
                              :ripple="false"
                              density="compact"
                              hide-details
                              color="primary"
                              inset
                              flat
                            >
                              <template #label>
                                <div class="body2">
                                  {{ $t("modals.createWorkspace.coroEmployeeWorkspace") }}
                                </div>
                              </template>
                            </v-switch>
                          </v-card-text>
                        </v-card>
                      </template>
                      <template v-if="step === workspaceCreationSteps.BRANDING">
                        <div class="headline5 mb-3">
                          {{ $t("modals.createWorkspace.brandingSettings") }}
                        </div>
                        <div class="d-flex justify-space-between w-100">
                          <v-card height="610px" width="818px" class="mr-5">
                            <v-card-text class="pb-4 pt-4">
                              <v-text-field
                                v-model="localValue.item.branding.alias"
                                type="text"
                                data-testid="edit-workspace-modal-alias"
                                class="mt-3"
                                counter="40"
                                :rules="aliasRules"
                                :label="$t('modals.createWorkspace.branding.aliasLabel')"
                                :placeholder="$t('modals.createWorkspace.branding.aliasLabel')"
                                :hint="aliasHint"
                                persistent-hint
                                variant="outlined"
                              ></v-text-field>
                              <v-text-field
                                v-model="localValue.item.branding.brandColor"
                                type="text"
                                data-testid="edit-workspace-modal-color-input"
                                class="mt-3"
                                :rules="brandColorRules"
                                :label="$t('modals.createWorkspace.branding.brandColorLabel')"
                                :placeholder="$t('modals.createWorkspace.branding.brandColorLabel')"
                                :hint="$t('modals.createWorkspace.branding.brandColorHint')"
                                persistent-hint
                                variant="outlined"
                                @update:model-value="
                                  localValue.item.branding.brandColor = $event?.toUpperCase()
                                "
                              >
                                <template v-slot:append-inner>
                                  <div
                                    class="color-preview"
                                    :style="{
                                      'background-color': localValue.item.branding.brandColor,
                                    }"
                                  ></div>
                                </template>
                              </v-text-field>
                              <v-text-field
                                v-model="localValue.item.branding.noReplyEmail"
                                type="text"
                                data-testid="edit-workspace-modal-no-reply-email"
                                class="mt-3"
                                readonly
                                :label="$t('modals.createWorkspace.branding.noReplyEmailLabel')"
                                :placeholder="
                                  $t('modals.createWorkspace.branding.noReplyEmailLabel')
                                "
                                :hint="$t('modals.createWorkspace.branding.noReplyEmailHint')"
                                persistent-hint
                                variant="outlined"
                              ></v-text-field>
                              <v-text-field
                                v-model="localValue.item.branding.supportEmail"
                                type="text"
                                data-testid="create-workspace-modal-support-email"
                                class="mt-3"
                                counter="100"
                                :rules="supportEmailRules"
                                :label="$t('modals.createWorkspace.branding.supportEmailLabel')"
                                :placeholder="
                                  $t('modals.createWorkspace.branding.supportEmailLabel')
                                "
                                :hint="$t('modals.createWorkspace.branding.supportEmailHint')"
                                persistent-hint
                                variant="outlined"
                              ></v-text-field>
                              <v-textarea
                                v-model="localValue.item.branding.companyAddress"
                                type="text"
                                data-testid="create-workspace-modal-company-address"
                                class="mt-3"
                                :label="$t('modals.editWorkspace.branding.companyAddressLabel')"
                                :placeholder="
                                  $t('modals.editWorkspace.branding.companyAddressLabel')
                                "
                                :hint="$t('modals.editWorkspace.branding.companyAddressHint')"
                                persistent-hint
                                variant="outlined"
                              ></v-textarea>
                            </v-card-text>
                          </v-card>

                          <v-card width="306px" height="610px">
                            <v-card-text>
                              <div class="subtitle1 mt-2">
                                {{ $t("modals.createWorkspace.replaceCoroIcons") }}
                              </div>
                              <div class="d-flex flex-column align-center justify-center mt-4 mb-4">
                                <logo-uploader
                                  class="mb-5"
                                  :brand-color="localValue.item.branding.brandColor"
                                  :default-logo="localValue.item.branding.headerIconUrl"
                                  @logo-changed="handleImageChange($event)"
                                ></logo-uploader>
                                <logo-uploader
                                  color="primary"
                                  brand-color="#eeeef3"
                                  :title="$t('logoUploader.emailIcon')"
                                  :hint="$t('logoUploader.emailImageHint')"
                                  :description="$t('logoUploader.emailDescription')"
                                  :default-logo="
                                    localValue.item.branding.emailIconUrl || defaultLogoUrl.PRIMARY
                                  "
                                  @logo-changed="handleEmailImageChange($event)"
                                ></logo-uploader>
                              </div>
                            </v-card-text>
                          </v-card>
                        </div>
                      </template>
                      <template v-if="step === workspaceCreationSteps.SUBSCRIPTION">
                        <div class="headline5 mb-3">
                          {{ $t("modals.createWorkspace.chooseYourSubscription") }}
                        </div>
                        <div
                          v-if="valid === false"
                          class="error-block d-flex mb-2 align-center w-100"
                        >
                          <v-icon class="mr-3 ml-4" icon="$warning"></v-icon>
                          <span class="body2 text-red-dark">
                            {{ $t("validations.bundleOrModuleIsRequired") }}</span
                          >
                        </div>
                        <!--Faking validation for bundles and modules-->
                        <v-select
                          :items="[bundles.CORO_CLASSIC]"
                          v-model="localValue.item.subscription.bundles"
                          multiple
                          :rules="[bundleValidationRule()]"
                          class="d-none"
                          :validation-value="moduleOrBundleValidationValue"
                        >
                        </v-select>
                        <v-tabs
                          v-model="selectedTab"
                          align-with-title
                          class="edit-subscription-tabs"
                          color="orange-base"
                          background-color="white"
                          slider-color="orange-base"
                          :height="48"
                        >
                          <v-tab v-for="item in tabs" :key="item" class="mr-8" :height="42">
                            {{ item }}
                          </v-tab>
                        </v-tabs>
                        <v-window v-model="selectedTab">
                          <v-window-item v-for="item in tabs" :key="item">
                            <div v-if="selectedTab === 1" class="mt-4">
                              <div class="subtitle1 mb-4" v-if="atLeastOneModuleNotSelected">
                                {{ $t("modals.createWorkspace.recommendedModules") }}
                              </div>
                              <div class="d-flex flex-wrap row-gap">
                                <template
                                  v-for="(module, moduleName) in localValue.item.subscription
                                    .modules"
                                  :key="moduleName"
                                >
                                  <module-or-addon-selector
                                    v-model="module.enabled"
                                    :name="moduleName"
                                    v-if="!moduleIncludedInBundle(moduleName)"
                                  >
                                    <template #content:after>
                                      <div class="mt-5">
                                        <template v-for="addon in module.addons" :key="addon.name">
                                          <v-btn-toggle
                                            v-if="isSocAddon(addon.name)"
                                            v-model="addon.enabled"
                                            color="indigo-pale"
                                            group
                                            mandatory
                                            density="comfortable"
                                            rounded="xl"
                                          >
                                            <v-btn :value="false">
                                              {{ $t("moduleOrAddonSelector.unmanaged") }}
                                            </v-btn>
                                            <v-btn :value="true">
                                              {{ $t("moduleOrAddonSelector.managed") }}
                                            </v-btn>
                                          </v-btn-toggle>
                                        </template>
                                      </div>
                                    </template>
                                  </module-or-addon-selector>
                                </template>
                              </div>

                              <template v-if="showAddonsSelection">
                                <v-divider class="mt-8"></v-divider>
                                <div class="subtitle1 mb-4 mt-4">
                                  {{ $t("modals.createWorkspace.addons") }}
                                </div>

                                <div class="d-flex flex-wrap row-gap">
                                  <template
                                    v-for="(module, moduleName) in localValue.item.subscription
                                      .modules"
                                    :key="moduleName"
                                  >
                                    <template v-for="addon in module.addons" :key="addon.name">
                                      <module-or-addon-selector
                                        :is-addon="true"
                                        v-model="addon.enabled"
                                        :name="addon.name"
                                        :disabled="!module.enabled"
                                        v-if="
                                          !isSocAddon(addon.name) &&
                                          !addonIncludedInBundle(addon.name)
                                        "
                                      >
                                        <template #content:after>
                                          <div
                                            class="info-block info-block--large align-center mt-5 d-flex"
                                            v-if="!module.enabled"
                                          >
                                            <v-icon class="mr-3 ml-4" icon="$warning"></v-icon>
                                            <span
                                              class="body2 text-primary mr-2"
                                              v-html="
                                                $t(`moduleOrAddonSelector.addonDisabled`, {
                                                  module: $t(`subscriptions.modules.${moduleName}`),
                                                })
                                              "
                                            ></span>
                                          </div>
                                        </template>
                                      </module-or-addon-selector>
                                    </template>
                                  </template>
                                </div>
                              </template>

                              <template v-if="hasAtLeastOneModuleIncludedInBundle">
                                <v-divider
                                  v-if="atLeastOneModuleNotSelected"
                                  class="mt-8"
                                ></v-divider>
                                <div class="subtitle1 mb-4 mt-4">
                                  {{ $t("modals.createWorkspace.includedInBundles") }}
                                </div>
                                <div class="d-flex flex-wrap row-gap">
                                  <template
                                    v-for="moduleName in getSelectedModules(
                                      localValue.item.subscription
                                    )"
                                    :key="moduleName"
                                  >
                                    <module-or-addon-selector
                                      :model-value="false"
                                      :name="moduleName"
                                      v-if="moduleIncludedInBundle(moduleName)"
                                    >
                                      <template #content:after>
                                        <div
                                          class="info-block included-in-bundle-block mt-5"
                                          v-if="moduleIncludedInBundle(moduleName)"
                                        >
                                          <span class="text-primary mr-1">
                                            {{ $t("modals.createWorkspace.includedInBundle") }}
                                          </span>
                                          <v-icon size="18" icon="$warning"></v-icon>
                                        </div>
                                      </template>
                                      <template #footer>
                                        <div class="w-100">
                                          <v-divider class="mt-3 mb-3"></v-divider>
                                          <div class="d-flex align-center justify-center">
                                            <v-icon class="mr-2" icon="$check"></v-icon>
                                            <span class="text-uppercase text--semibold body2">
                                              {{ $t("general.added") }}
                                            </span>
                                          </div>
                                        </div>
                                      </template>
                                    </module-or-addon-selector>
                                  </template>
                                  <template v-for="addon in getSelectedAddons()" :key="addon">
                                    <module-or-addon-selector
                                      :model-value="false"
                                      :is-addon="true"
                                      :name="addon"
                                      v-if="addonIncludedInBundle(addon) && !isSocAddon(addon)"
                                    >
                                      <template #content:after>
                                        <div class="info-block included-in-bundle-block mt-5">
                                          <span class="text-primary mr-1">
                                            {{ $t("modals.createWorkspace.includedInBundle") }}
                                          </span>
                                          <v-icon size="18" icon="$warning"></v-icon>
                                        </div>
                                      </template>
                                      <template #footer>
                                        <div class="w-100">
                                          <v-divider class="mt-3 mb-3"></v-divider>
                                          <div class="d-flex align-center justify-center">
                                            <v-icon class="mr-2" icon="$check"></v-icon>
                                            <span class="text-uppercase text--semibold body2">
                                              {{ $t("general.added") }}
                                            </span>
                                          </div>
                                        </div>
                                      </template>
                                    </module-or-addon-selector>
                                  </template>
                                  <!--Displaying only one SOC addon -->
                                  <module-or-addon-selector
                                    :is-addon="true"
                                    :name="subscriptionAddons.SOC"
                                    :model-value="false"
                                    v-if="hasAtLeastOneSocAddon"
                                  >
                                    <template #content:after>
                                      <div class="info-block included-in-bundle-block mt-5">
                                        <span class="text-primary mr-1">
                                          {{ $t("modals.createWorkspace.includedInBundle") }}
                                        </span>
                                        <v-icon size="18" icon="$warning"></v-icon>
                                      </div>
                                    </template>
                                    <template #footer>
                                      <div class="w-100">
                                        <v-divider class="mt-3 mb-3"></v-divider>
                                        <div class="d-flex align-center justify-center">
                                          <v-icon class="mr-2" icon="$check"></v-icon>
                                          <span class="text-uppercase text--semibold body2">
                                            {{ $t("general.added") }}
                                          </span>
                                        </div>
                                      </div>
                                    </template>
                                  </module-or-addon-selector>
                                </div>
                              </template>
                            </div>
                            <div class="d-flex mt-4 row-gap" v-else-if="selectedTab === 2">
                              <range-bundle-selector
                                v-model="coroCompleteBundle"
                                :managed-option="bundles.MANAGED_CORO_COMPLETE"
                                :unmanaged-option="bundles.CORO_COMPLETE"
                              />
                            </div>
                            <div class="d-flex mt-4 row-gap" v-else>
                              <range-bundle-selector
                                v-model="coroEssentialsBundle"
                                :managed-option="bundles.MANAGED_CORO_ESSENTIALS"
                                :unmanaged-option="bundles.CORO_ESSENTIALS"
                              >
                              </range-bundle-selector>
                              <range-bundle-selector
                                v-model="endpointBundle"
                                :managed-option="bundles.MANAGED_ENDPOINT_PROTECTION"
                                :unmanaged-option="bundles.ENDPOINT_PROTECTION"
                              >
                              </range-bundle-selector>
                              <range-bundle-selector
                                v-model="emailBundle"
                                :managed-option="bundles.MANAGED_EMAIL_PROTECTION"
                                :unmanaged-option="bundles.EMAIL_PROTECTION"
                              >
                              </range-bundle-selector>
                              <range-bundle-selector
                                v-model="accessBundle"
                                :managed-option="bundles.MANAGED_SASE"
                                :unmanaged-option="bundles.SASE"
                              >
                              </range-bundle-selector>
                            </div>
                          </v-window-item>
                        </v-window>
                      </template>
                      <template v-if="step === workspaceCreationSteps.REVIEW_SUBSCRIPTION">
                        <div class="headline5 mb-3">
                          {{ $t("modals.createWorkspace.reviewSubscription") }}
                        </div>
                        <div class="d-flex w-100">
                          <v-card width="100%">
                            <v-card-text>
                              <div class="subtitle1 mb-3">
                                {{ $t("modals.createWorkspace.summary.title") }}
                              </div>
                              <div class="w-100">
                                <template v-if="localValue.item.subscription.bundles.length > 0">
                                  <div class="caption mb-3">
                                    {{ $t("modals.createWorkspace.summary.bundles") }}
                                  </div>

                                  <template
                                    v-for="bundle in localValue.item.subscription.bundles"
                                    :key="bundle"
                                  >
                                    <subscription-summary-item
                                      :item="bundle"
                                      :type="subscriptionSummaryType.BUNDLE"
                                      class="mb-3"
                                    />
                                  </template>
                                </template>

                                <template v-if="showSummaryModulesAndAddons">
                                  <div class="caption mt-3 mb-3">
                                    {{ $t("modals.createWorkspace.summary.modulesAndAddons") }}
                                  </div>

                                  <template
                                    v-for="(module, moduleName) in localValue.item.subscription
                                      .modules"
                                    :key="moduleName"
                                  >
                                    <subscription-summary-item
                                      v-if="!moduleIncludedInBundle(moduleName) && module.enabled"
                                      :item="moduleName"
                                      :module="module"
                                      :type="subscriptionSummaryType.MODULE"
                                      class="mb-3"
                                    />
                                    <template v-for="addon in module.addons" :key="addon.name">
                                      <subscription-summary-item
                                        v-if="
                                          !isSocAddon(addon.name) &&
                                          addon.enabled &&
                                          !addonIncludedInBundle(addon.name)
                                        "
                                        :item="addon.name"
                                        :type="subscriptionSummaryType.ADDON"
                                        class="mb-3"
                                      />
                                    </template>
                                  </template>
                                </template>
                              </div>
                            </v-card-text>
                          </v-card>
                        </div>
                      </template>
                    </div>
                  </div>
                </v-stepper-window-item>
              </v-stepper-window>
            </div>
          </div>
          <v-divider class="mb-4"></v-divider>
        </template>
        <template v-slot:actions="{ prev, next }">
          <div class="d-flex align-center justify-center w-100">
            <div class="d-flex justify-end align-center pt-1 dialog-content">
              <div>
                <v-btn
                  variant="text"
                  color="primary"
                  size="large"
                  @click="onPrevStepClick(prev)"
                  v-if="showPreviousStepBtn"
                >
                  {{ $t("general.back") }}
                </v-btn>
                <v-btn
                  rounded
                  v-if="!showCompleteBtn"
                  color="primary"
                  size="large"
                  class="ml-2"
                  :disabled="disable"
                  @click="onNextStepClick(next)"
                >
                  {{ $t("general.next") }}
                </v-btn>
                <v-btn
                  rounded
                  v-else
                  color="primary"
                  size="large"
                  class="ml-2"
                  :disabled="disable"
                  :loading="createWorkspaceClicked"
                  @click="createWorkspace()"
                >
                  {{ $t(`modals.${config.action}.actionBtn`) }}
                </v-btn>
              </div>
            </div>
          </div>
        </template>
      </v-stepper>
    </v-form>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, type PropType, ref, toRef, watch } from "vue";
import LogoUploader, { defaultLogoUrl } from "@/components/LogoUploader.vue";
import { useMspWorkspaceModal, WorkspaceCreationSteps } from "@/composables/useMspWorkspaceModal";
import cloneDeep from "lodash/cloneDeep";
import {
  type MspWorkspaceDialogItem,
  NotificationsLevel,
  type ParentWorkspaceSearchPermission,
  type Subscription,
  useMspStore,
} from "@/_store/msp.module";
import {
  bundlesContent,
  defaultSubscriptionModulesConfig,
  MspWorkspaceAction,
  SubscriptionAddon,
  SubscriptionBundle,
  SubscriptionModule,
  WorkspaceLocale,
  WorkspaceType,
} from "@/constants/workplaces";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import { useAccountStore } from "@/_store";
import { storeToRefs } from "pinia";
import { maxItems, required } from "@/_helpers/validators";
import debounce from "lodash/debounce";
import { useI18n } from "vue-i18n";
import {
  accessBundles,
  coroClassicBundles,
  coroCompleteBundles,
  coroEssentialsBundles,
  emailBundles,
  endpointBundles,
  useSubscriptionEditor,
} from "@/composables/useSubscriptionEditor";
import ModuleOrAddonSelector from "@/components/subscription-selectors/ModuleOrAddonSelector.vue";
import RangeBundleSelector from "@/components/subscription-selectors/RangeBundleSelector.vue";
import SubscriptionSummaryItem, {
  SubscriptionSummaryType,
} from "@/components/subscription-selectors/SubscriptionSummaryItem.vue";
import type { SubscriptionAddonInfo, SubscriptionModuleInfo } from "@/_store/subscription.module";
import { isGlobalActionRestricted } from "@/_helpers/global-permissions";
import { GlobalRoleScopes, MspPortalScopeSection } from "@/_store/roles.module";

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<DialogDataConfig<MspWorkspaceDialogItem>>,
      required: true,
    },
    disable: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    ModuleOrAddonSelector,
    RangeBundleSelector,
    LogoUploader,
    SubscriptionSummaryItem,
  },
  emits: ["dismissed", "update:localValue"],
  setup(props, { emit }) {
    const parentWorkspaceInputLoading = ref(false);
    const parentWorkspaces = ref<{ workspaceName: string; workspaceId: string }[]>([]);
    const valid = ref(true);
    const accountStore = useAccountStore();
    const selectedTab = ref(0);
    const { isGlobalAdmin } = storeToRefs(accountStore);
    const adminExistsInOtherHierarchy = ref(false);
    const mspStore = useMspStore();
    const i18n = useI18n();
    const { createWorkspaceInProgress, createWorkspaceFailed, createWorkspaceCompleted } =
      storeToRefs(mspStore);
    const createWorkspaceClicked = ref(false);
    const form = ref();
    const type = computed(() => {
      switch (props.config.action) {
        case MspWorkspaceAction.CREATE_REGULAR_WORKSPACE:
          return WorkspaceType.REGULAR;
        case MspWorkspaceAction.CREATE_CHILD_WORKSPACE:
          return WorkspaceType.CHILD;
        case MspWorkspaceAction.CREATE_CHANNEL_WORKSPACE:
          return WorkspaceType.CHANNEL;
        default:
          return null;
      }
    });

    const localValue = ref<DialogDataConfig<MspWorkspaceDialogItem>>(
      cloneDeep({
        ...props.config,
        item: {
          companyName: null,
          displayName: null,
          domain: null,
          adminEmail: null,
          notificationsLevel: NotificationsLevel.ALL,
          type: type.value,
          supportEnabled: true,
          scanUnprotectedUsers: true,
          psaEnabled: true,
          showDisabledModules: true,
          isCoroEmployeeWorkspace: false,
          languageCode: WorkspaceLocale.EN_US,
          branding: {},
          subscription: {
            bundles: [],
            modules: cloneDeep(defaultSubscriptionModulesConfig),
          },
        },
      }) as unknown as DialogDataConfig<MspWorkspaceDialogItem>
    );

    const {
      limitProtectedUsersRules,
      domainRules,
      companyNameRules,
      displayNameRules,
      brandColorRules,
      adminEmailRules,
      supportEmailRules,
      aliasRules,
      filterKey,
      aliasHint,
      notificationLevels,
      currentStep,
      showPreviousStepBtn,
      showCompleteBtn,
      getCurrentStepNumber,
      getStepsProgress,
      getNotificationLevelText,
    } = useMspWorkspaceModal(localValue);

    const {
      accessBundle,
      endpointBundle,
      emailBundle,
      coroEssentialsBundle,
      coroClassicBundle,
      coroCompleteBundle,
      isBundleSelected,
      moduleIncludedInBundle,
      addonIncludedInBundle,
      getSelectedModules,
      getSelectedAddons,
      isSocAddon,
      hasAtLeastOneSocAddon,
      hasAtLeastOneModuleIncludedInBundle,
    } = useSubscriptionEditor(
      toRef(localValue.value.item.subscription),
      (newData: Subscription) => {
        localValue.value.item.subscription = newData;
      }
    );

    const steps = computed(() => {
      const allSteps = [
        WorkspaceCreationSteps.COMPANY_DETAILS,
        WorkspaceCreationSteps.MSP_SETTINGS,
        WorkspaceCreationSteps.SUBSCRIPTION,
        WorkspaceCreationSteps.REVIEW_SUBSCRIPTION,
      ];
      if (showBrandingSettings.value) {
        const mspIndex = allSteps.indexOf(WorkspaceCreationSteps.MSP_SETTINGS) + 1;
        allSteps.splice(mspIndex, 0, WorkspaceCreationSteps.BRANDING);
      }
      return allSteps;
    });

    const tabs = computed(() => [
      i18n.t("subscriptions.bundles.title"),
      i18n.t("subscriptions.modules.title"),
      i18n.t("subscriptions.packages"),
    ]);

    const moduleOrBundleValidationValue = computed(() => {
      const isModuleEnabled = Object.values(localValue.value.item.subscription.modules).some(
        (module: SubscriptionModuleInfo) => module.enabled
      );
      const isBundleSelected = localValue.value.item.subscription.bundles.length > 0;
      return isBundleSelected || isModuleEnabled;
    });

    const bundleValidationRule = (
      errorMessage: string = i18n.t("validations.bundleOrModuleIsRequired")
    ) => {
      return (value: boolean) => {
        if (!value) return errorMessage;
        return true;
      };
    };

    const showParentWorkspaceIdInput = computed(() => {
      return type.value !== WorkspaceType.REGULAR;
    });

    const showSummaryModulesAndAddons = computed(() => {
      const modules = localValue.value.item.subscription.modules;
      return (
        Object.keys(modules).filter((module) => {
          return !moduleIncludedInBundle(module) && modules[module as SubscriptionModule].enabled;
        }).length > 0
      );
    });

    const showImageUploader = computed(() => {
      return props.config.action === MspWorkspaceAction.CREATE_CHANNEL_WORKSPACE;
    });

    const showAddonsSelection = computed(() => {
      const modulesArray = Object.values(localValue.value.item.subscription.modules);
      return modulesArray.some((module: SubscriptionModuleInfo) => {
        return module.addons.some((addon: SubscriptionAddonInfo) => {
          return !isSocAddon(addon.name) && !addonIncludedInBundle(addon.name);
        });
      });
    });

    const atLeastOneModuleNotSelected = computed(() => {
      const modulesArray = Object.values(localValue.value.item.subscription.modules);
      return modulesArray.some((module: SubscriptionModuleInfo) => !module.enabled);
    });

    const showBrandingSettings = computed(
      () =>
        props.config.action === MspWorkspaceAction.CREATE_CHANNEL_WORKSPACE &&
        isGlobalAdmin.value &&
        !localValue.value.item.parentWorkspaceId
    );

    const showLimitField = computed(
      () => props.config.action === MspWorkspaceAction.CREATE_CHILD_WORKSPACE
    );

    const domainDisplayName = computed(() => {
      return isGlobalAdmin.value ? "coro.net" : accountStore.account.domain;
    });

    const stepsProgress = computed(() => {
      return getStepsProgress(steps.value);
    });

    const showScanUnprotectedUsers = computed(() => {
      if (type.value === WorkspaceType.CHILD) {
        return true;
      }
      return showBrandingSettings.value;
    });

    const parentWorkspaceIdRules = computed(() => {
      // for child workspaces this field is required
      if (type.value === WorkspaceType.CHILD) {
        return [required()];
      }
      if (type.value === WorkspaceType.CHANNEL) {
        if (isGlobalAdmin) {
          // if is global admin and is restricted to create ROOT channel workspaces - field should be required
          return isGlobalActionRestricted(
            GlobalRoleScopes.MSP_PORTAL_SCOPE,
            MspPortalScopeSection.MSP_WORKSPACES,
            "createChannelWorkspace"
          )
            ? [required()]
            : [];
        }
        return [required()];
      }
      return [];
    });

    const onNextStepClick = async (callback: () => void) => {
      const validationResult = await form.value?.validate();
      if (validationResult?.valid) {
        callback();
      }
    };

    const onPrevStepClick = async (callback: () => void) => {
      await form.value?.resetValidation();
      callback();
    };

    const handleImageChange = (image: File) => {
      localValue.value!.item.image = image;
    };
    const handleEmailImageChange = (image: File) => {
      localValue.value!.item.emailImage = image;
    };

    const onParentWorkspaceSearchUpdate = debounce(function (searchString) {
      if (!searchString && !localValue.value.item.parentWorkspaceId) {
        fetchParentWorkspaces();
      }
      if (searchString !== localValue.value.item.parentWorkspaceId) {
        fetchParentWorkspaces(searchString);
      }
    }, 500);

    const fetchParentWorkspaces = async (search: string = "") => {
      const permission: ParentWorkspaceSearchPermission =
        props.config.action === MspWorkspaceAction.CREATE_CHANNEL_WORKSPACE
          ? "CREATE_CHANNEL_WORKSPACE"
          : "CREATE_CHILD_WORKSPACE";
      parentWorkspaceInputLoading.value = true;
      parentWorkspaces.value = await mspStore.getWorkspaceCandidates(search, permission);
      parentWorkspaceInputLoading.value = false;
    };

    const createWorkspace = async () => {
      // create workspace clicked is here to prevent double-click on create workspace
      // createWorkspaceInProgress can not be used because user can click on button twice
      // while he/she is waiting for response from checkInvitedAdminAlreadyMspAdmin
      createWorkspaceClicked.value = true;
      if (showParentWorkspaceIdInput.value && localValue.value.item.parentWorkspaceId) {
        adminExistsInOtherHierarchy.value = await mspStore.checkInvitedAdminAlreadyMspAdmin({
          parentWorkspaceId: localValue.value.item.parentWorkspaceId!,
          invitedAdmin: localValue.value.item.adminEmail!,
        });
      }
      try {
        const data = localValue.value;
        await mspStore.createWorkspace(data);
      } catch (e) {
        console.error(e);
      } finally {
        createWorkspaceClicked.value = false;
      }
    };

    watch(
      () => localValue.value?.item.branding.alias,
      (alias) => {
        localValue.value!.item.branding.noReplyEmail = alias ? `no-reply@${alias}.coro.net` : "";
      }
    );

    watch(
      localValue,
      (value) => {
        emit("update:localValue", value);
      },
      { deep: true }
    );

    onMounted(async () => {
      mspStore.$patch({
        createWorkspaceInProgress: false,
        createWorkspaceFailed: false,
        createWorkspaceCompleted: false,
      });
      if (showParentWorkspaceIdInput.value) {
        await fetchParentWorkspaces();
      }
    });

    return {
      createWorkspaceInProgress,
      createWorkspaceClicked,
      createWorkspaceFailed,
      createWorkspaceCompleted,
      localValue,
      form,
      valid,
      limitProtectedUsersRules,
      domainRules,
      companyNameRules,
      displayNameRules,
      brandColorRules,
      adminEmailRules,
      supportEmailRules,
      aliasRules,
      filterKey,
      defaultLogoUrl,
      adminExistsInOtherHierarchy,
      showImageUploader,
      showBrandingSettings,
      showLimitField,
      domainDisplayName,
      aliasHint,
      showScanUnprotectedUsers,
      handleImageChange,
      handleEmailImageChange,
      notificationLevels,
      onParentWorkspaceSearchUpdate,
      parentWorkspaceInputLoading,
      parentWorkspaces,
      fetchParentWorkspaces,
      required,
      showParentWorkspaceIdInput,
      maxItems,
      currentStep,
      steps,
      workspaceCreationSteps: WorkspaceCreationSteps,
      selectedTab,
      endpointBundle,
      emailBundle,
      accessBundle,
      coroEssentialsBundle,
      coroClassicBundle,
      coroCompleteBundle,
      showAddonsSelection,
      modulesAndAddonsInBundles: bundlesContent,
      endpointBundles,
      accessBundles,
      coroEssentialsBundles,
      coroClassicBundles,
      emailBundles,
      coroCompleteBundles,
      bundles: SubscriptionBundle,
      isBundleSelected,
      moduleIncludedInBundle,
      addonIncludedInBundle,
      tabs,
      showPreviousStepBtn,
      showCompleteBtn,
      getCurrentStepNumber,
      stepsProgress,
      onNextStepClick,
      onPrevStepClick,
      isSocAddon,
      getSelectedModules,
      getSelectedAddons,
      createWorkspace,
      showSummaryModulesAndAddons,
      moduleOrBundleValidationValue,
      bundleValidationRule,
      subscriptionSummaryType: SubscriptionSummaryType,
      subscriptionAddons: SubscriptionAddon,
      hasAtLeastOneModuleIncludedInBundle,
      hasAtLeastOneSocAddon,
      isGlobalAdmin,
      parentWorkspaceIdRules,
      WorkspaceLocale,
      getNotificationLevelText,
      atLeastOneModuleNotSelected,
    };
  },
});
</script>

<style lang="scss" scoped>
.progress-container {
  height: 300px;
}

.v-stepper.v-sheet {
  box-shadow: unset !important;
  background-color: transparent;
}

.v-stepper-header {
  box-shadow: unset !important;
}

.v-stepper-window {
  margin: unset !important;
}

.stepper-step__wrapper {
  padding-bottom: 20px;
  padding-top: 20px;
  .v-card {
    border-radius: 8px !important;
  }
}

.dialog-content {
  min-width: 650px;
  max-width: 1150px;
  width: 100%;
  margin-right: 30px;
  margin-left: 30px;
}

.scroll-area {
  height: calc(100vh - 160px);
  overflow: auto;
}

.v-switch {
  .v-label {
    opacity: 1 !important;
  }
}

.color-preview {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

.notification-label {
  width: 500px;
}

.row-gap {
  gap: 23px;
}

:deep(*) {
  .v-btn-group--density-default.v-btn-group {
    height: unset !important;
  }

  .bundle-selector {
    background-color: white;
  }

  .v-btn-group {
    border: 1px solid rgb(var(--v-theme-primary)) !important;
    padding: 4px;
    width: 100%;
    .v-btn__overlay,
    .v-btn--active,
    .v-btn__underlay {
      border-radius: 30px;
    }
    .v-btn {
      width: 50%;
      .v-btn__content {
        text-transform: none !important;
        color: rgb(var(--v-theme-primary)) !important;
        letter-spacing: 0 !important;
      }
    }
  }
}

.info-block--large {
  min-height: 68px;
}

.summary-item {
  border: 1px solid rgb(var(--v-theme-indigo-faint)) !important;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.progress-bar__content {
  width: 200px;
}

.included-in-bundle-block {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
}
</style>

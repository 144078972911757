<template>
  <div class="content-md margin-auto mt-4">
    <div v-if="settings.dnsFilteringEnabled" class="d-flex align-center justify-end mb-4">
      <date-range-picker
        v-model="localFilters.reportTimeFrame"
        :preset-dates="presets"
        :class="{
          'filter-active': localFilters.reportTimeFrame.start && localFilters.reportTimeFrame.end,
        }"
        :placeholder="$t('reports.executiveSummaryReport.dateRange')"
        class="date-range-picker"
      ></date-range-picker>
    </div>
    <v-card class="pa-12" v-if="loading || showSkeletonLoader">
      <v-skeleton-loader color="transparent" type="heading,list-item-three-line" />
      <v-row>
        <v-col v-for="item in 3" :key="item" cols="4">
          <v-skeleton-loader color="transparent" type="avatar, list-item-two-line">
          </v-skeleton-loader>
        </v-col>
      </v-row>
      <v-skeleton-loader
        color="transparent"
        type="heading,list-item-three-line, article, article"
      />
      <v-skeleton-loader class="mx-3 rounded-lg" color="transparent" type="image" />
    </v-card>
    <v-card
      class="pa-12 mt-1"
      v-else-if="dnsSummaryReport && settings.dnsFilteringEnabled"
      id="report-card"
    >
      <div class="d-flex justify-space-between align-start">
        <coro-label background-color="rgb(var(--v-theme-indigo-faint))" mix-blend-mode="initial">
          {{ account.customerName }}
        </coro-label>
        <img alt="Logo" :src="logoPath" width="56" />
      </div>
      <div class="mb-14">
        <div class="headline5">
          {{ $t("reports.dnsSummaryReport.title") }}
        </div>
        <div class="body2 mb-2">
          {{ formattedSelectedDateRange }}
        </div>
        <div class="body3 text--semitransparent mb-2">
          {{ $t("reports.executiveSummaryReport.generatedOn") }} {{ generatedOn }}
        </div>
        <div class="body3 text--semitransparent">
          {{ $t("reports.executiveSummaryReport.version") }} {{ reportVersion }}
        </div>
      </div>
      <BarChart
        class="mb-8 html2pdf__page-break"
        :total-count="dnsSummaryReport.totalQueries"
        :total-label="$t('reports.dnsSummaryReport.totalQueries')"
        :data="dnsSummaryReport.generalChartData"
      />
      <BarChart
        class="mb-8 html2pdf__page-break"
        :total-count="dnsSummaryReport.totalBlockedQueries"
        :total-label="$t('reports.dnsSummaryReport.blockedQueries')"
        :data="dnsSummaryReport.blockedChartData"
        :barColor="'#EFB3AB'"
      />
      <DnsSummaryReportTilesBlock :data="dnsSummaryReport" />
    </v-card>
    <empty-state v-else class="margin-auto mt-12 w-65">
      <template #icon>
        <v-icon :size="80" icon="$accessRestrictions"> </v-icon>
      </template>
      <template #description>
        <div class="headline5 mb-3 mt-3">
          {{ $t("reports.dnsSummaryReport.noItems.title") }}
        </div>
      </template>
      <template #subtitle>
        {{ $t("reports.dnsSummaryReport.noItems.description") }}
      </template>
      <template #button>
        <v-btn class="mb-4" color="primary" rounded large :to="{ name: 'secure-web-gateway' }">
          {{ $t("reports.dnsSummaryReport.noItems.actionBtn") }}
        </v-btn>
      </template>
    </empty-state>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useAccountStore } from "@/_store/account.module";
import moment from "moment";
import { ReportType, useReportsStore } from "@/_store/reports.module";
import DateRangePicker from "@/components/DateRangePicker.vue";
import { i18n } from "@/plugins/i18n";
import CoroLabel from "@/components/CoroLabel.vue";
import BarChart from "@/components/BarChart.vue";
import DnsSummaryReportTilesBlock from "@/components/reports/DnsSummaryReportTilesBlock.vue";
import { useSWGStore } from "@/_store/secure-web-gateway.module";
import EmptyState from "@/components/EmptyState.vue";
import { firstDayThisMonth, lastDayThisMonth, yesterday } from "@/constants/date-range-picker";
import { useReportsTab } from "@/composables/useReportsTab";

const presets = [
  {
    label: i18n.global.t("dateRangePicker.today"),
    value: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
  },
  {
    label: i18n.global.t("dateRangePicker.yesterday"),
    value: [yesterday, yesterday],
  },
  {
    label: i18n.global.t("dateRangePicker.lastSevenDays"),
    value: [moment().subtract(7, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
  },
  {
    label: i18n.global.t("dateRangePicker.lastThirtyDays"),
    value: [moment().subtract(30, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
  },
  {
    label: i18n.global.t("dateRangePicker.thisMonth"),
    value: [firstDayThisMonth, lastDayThisMonth],
  },
];
export default defineComponent({
  components: {
    EmptyState,
    DnsSummaryReportTilesBlock,
    CoroLabel,
    DateRangePicker,
    BarChart,
  },
  setup() {
    const i18n = useI18n();
    const accountStore = useAccountStore();
    const reportsStore = useReportsStore();
    const swgStore = useSWGStore();
    const reportType = ref(null);
    const { account } = storeToRefs(accountStore);
    const { showSkeletonLoader, settings } = storeToRefs(swgStore);
    const { dnsSummaryReport, loading, reportVersion } = storeToRefs(reportsStore);
    const { localFilters, logoPath, formattedSelectedDateRange } = useReportsTab(
      ReportType.DNS_SUMMARY,
      0
    );

    const generatedOn = computed(() =>
      moment(reportVersion.value, "YYYYMMDD_HHmmss").format("L LT")
    );

    onMounted(() => {
      swgStore.getSwgSettings(true);
    });

    const columnsLayout = {
      totalTicketsColumns: 0,
      vulnerabilitiesColumns: 7,
      topViolatorsColumns: 4,
    };

    return {
      generatedOn,
      account,
      dnsSummaryReport,
      loading,
      logoPath,
      localFilters,
      formattedSelectedDateRange,
      presets,
      reportVersion,
      reportType,
      columnsLayout,
      showSkeletonLoader,
      settings,
      breadCrumbsItems: [
        {
          title: i18n.t("general.backToControlPanel"),
          disabled: false,
          to: { path: "/portal/settings" },
        },
      ],
    };
  },
});
</script>

<style scoped lang="scss">
.range-picker {
  max-width: 280px;
}

:deep(*) {
  .coro-chip--default .coro-chip__content,
  .coro-chip--default.coro-chip__content {
    color: rgb(var(--v-theme-primary)) !important;
    mix-blend-mode: initial !important;
  }
}

.v-chip {
  height: 160px !important;
  border-radius: 6px;
}

.provider-selection-chip {
  max-height: 71px;
}

.v-chip .v-chip__content {
  width: 100% !important;
  text-align: center;
}

.date-range-picker {
  width: 280px !important;
}
</style>

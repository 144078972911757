import {
  defaultSubscriptionModulesConfig,
  SubscriptionAddon,
  SubscriptionBundle,
  SubscriptionModule,
  SubscriptionType,
} from "@/constants/workplaces";
import cloneDeep from "lodash/cloneDeep";
import { defineStore } from "pinia";

interface SubscriptionModuleState {
  subscription: {
    currentPlan: CurrentPlan;
    modules: ModulesSettings;
    bundles: Array<SubscriptionBundle>;
  };
}

export interface CurrentPlan {
  subscriptionType: string;
  planEndDate: number | null;
}

export type ModulesSettings = {
  [key in SubscriptionModule]: SubscriptionModuleInfo;
};

export interface SubscriptionModuleInfo {
  enabled: boolean;
  addons: SubscriptionAddonInfo[];
}

export interface SubscriptionAddonInfo {
  enabled: boolean;
  name: SubscriptionAddon;
}

const defaultSubscriptionState: SubscriptionModuleState = {
  subscription: {
    currentPlan: {
      subscriptionType: SubscriptionType.FREEZE,
      planEndDate: null,
    },
    modules: {
      ...cloneDeep(defaultSubscriptionModulesConfig),
    },
    bundles: [],
  },
};

export const useSubscriptionStore = defineStore("subscription", {
  state: (): SubscriptionModuleState => ({ ...cloneDeep(defaultSubscriptionState) }),
  persist: {
    paths: ["subscription"],
  },
  actions: {
    setCurrentPlan(payload: CurrentPlan) {
      this.subscription.currentPlan = payload;
    },
  },
});

<template>
  <div>
    <template v-if="!createdKey">
      <MenuDatePicker @update:date="onDateSelect($event)">
        <v-text-field
          :modelValue="convertExpirationTime()"
          data-testid="add-psa-connector-modal-textfield"
          class="datepicker-activator"
          :label="$t('modals.addApiKey.expirationDate')"
          :placeholder="$t('modals.addApiKey.expirationDate')"
          variant="outlined"
          :disabled="loading"
        ></v-text-field>
      </MenuDatePicker>
      <v-textarea
        v-model="localValue.description"
        data-testid="add-api-key-modal-description"
        class="coro-scrollable-combobox"
        :label="$t('modals.addApiKey.description')"
        :placeholder="$t('modals.addApiKey.description')"
        :disabled="loading"
        variant="outlined"
      >
      </v-textarea>
    </template>
    <template v-if="createdKey">
      <div class="d-flex align-center justify-center flex-column">
        <v-icon size="80" icon="$success"></v-icon>
        <div class="mt-3 subtitle1">{{ $t("modals.addApiKey.yourNewToken") }}</div>
        <div class="caption mt-3 mb-6">
          {{ $t("modals.addApiKey.makeSureYouSaveIt") }}
        </div>
      </div>
      <v-text-field
        class="mb-5 mr-12 ml-12 copy-text-input"
        :label="$t('modals.addApiKey.clientId')"
        density="compact"
        hide-details
        :full-width="false"
        variant="outlined"
        readonly
        :modelValue="createdKey!.clientId"
      >
        <template #append>
          <v-btn color="primary" @click="onCopyToClipboard(createdKey!.clientId)">
            {{ $t("general.copy") }}
          </v-btn>
        </template>
      </v-text-field>
      <v-text-field
        class="mb-12 pb-12 mr-12 ml-12 copy-text-input"
        :label="$t('modals.addApiKey.clientSecret')"
        variant="outlined"
        density="compact"
        hide-details
        :full-width="false"
        readonly
        :modelValue="createdKey!.clientSecret"
      >
        <template #append>
          <v-btn color="primary" @click="onCopyToClipboard(createdKey!.clientSecret)">
            {{ $t("general.copy") }}
          </v-btn>
        </template>
      </v-text-field>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, watch } from "vue";
import moment from "moment";
import type { AddApiKeyPayload } from "@/_store/connectors/api-credentials.module";
import { useApiCredentialsStore } from "@/_store/connectors/api-credentials.module";
import { useSnackbarStore } from "@/_store";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { copyToClipboard, getFormattedDateTime } from "@/_helpers/utils";
import MenuDatePicker from "@/components/MenuDatePicker.vue";

export default defineComponent({
  name: "AddApiKey",
  components: { MenuDatePicker },
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:valid", "update:localValue", "change-footer", "change-header-title"],
  setup(props, { emit }) {
    const localValue: AddApiKeyPayload = reactive({ expirationTime: null, description: "" });
    const apiCredentialsStore = useApiCredentialsStore();
    const snackbarStore = useSnackbarStore();
    const i18n = useI18n();
    const { addApiKey, clearCreatedApiKey, loading } = apiCredentialsStore;
    const { createdKey } = storeToRefs(apiCredentialsStore);

    onMounted(() => {
      clearCreatedApiKey();
    });
    emit("update:localValue", localValue);
    emit("update:valid", true);

    const min = () => {
      return getFormattedDateTime(new Date(), "YYYY-MM-DD");
    };

    const convertExpirationTime = () => {
      return localValue.expirationTime
        ? getFormattedDateTime(localValue.expirationTime, "YYYY-MM-DD")
        : null;
    };
    const onCopyToClipboard = (valueToCopy: string) => {
      copyToClipboard(valueToCopy);
      snackbarStore.addGenericSuccess(i18n.t("snackbar.messages.general.copiedToClipboard"));
    };

    emit("change-footer", {
      buttons: [
        {
          title: i18n.t("general.cancel"),
          spacer: "before",
          type: "text",
          cancel: true,
        },
        {
          title: i18n.t("general.create"),
          color: "primary",
          callback: async () => {
            await addApiKey(localValue);
            emit("change-footer", null);
            emit("change-header-title", "");
          },
        },
      ],
    });

    const onDateSelect = (val: Date) => {
      localValue.expirationTime = moment(val).endOf("day").utc().valueOf();
    };

    watch(
      localValue,
      (newVal) => {
        emit("update:localValue", newVal);
        emit("update:valid", true);
      },
      { deep: true }
    );

    return {
      min,
      loading,
      localValue,
      copiedToClipboard: false,
      convertExpirationTime,
      onCopyToClipboard,
      onDateSelect,
      createdKey,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(.copy-text-input) {
  .v-field {
    height: 36px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    .v-field__field {
      height: 36px;
    }
  }

  .v-btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

:deep(*) {
  .v-input__append {
    margin: 0;
  }

  .v-input__slot {
    padding: 0 0 0 12px !important;
  }

  .v-text-field.v-input--dense .v-input__prepend-inner,
  .v-text-field.v-input--dense .v-input__append-inner {
    margin-top: unset !important;

    .v-btn:not(.v-btn--round).v-size--default {
      height: 40px !important;
    }
  }
}
.datepicker-activator {
  cursor: pointer;
}
</style>

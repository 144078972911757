<template>
  <div>
    <data-access-violation-full-details
      :access-violation="userDataAccessViolation"
      :ticket-trigger="ticketDetails.eventTrigger"
    />
    <template v-if="hideSensitiveData">
      <h6
        v-if="!userDataAccessViolation"
        class="headline6 mb-4"
        data-testid="tickets-page-email-preview-title"
      >
        {{ $t("ticketDetails.fullDetails.title") }}
      </h6>
      <div class="d-flex flex-column align-center justify-center pa-7 sensitive-data-container">
        <v-icon size="80" icon="$dataNoHexagon"></v-icon>
        <span class="subtitle1">{{ $t("general.accessToSensitiveDataRestricted") }}</span>
      </div>
    </template>

    <component
      :is="fullDetailsComponent"
      v-else
      :ticket="ticketDetails"
      :show-title="!userDataAccessViolation"
    ></component>
  </div>
</template>

<script lang="ts">
import MalwareInCloudDriveFullDetails from "./MalwareInCloudDriveFullDetails.vue";
import FindingsFullDetails from "./FindingsFullDetails.vue";
import FileFindingsFullDetails from "./FileFindingsFullDetails.vue";
import EmailTicketsFullDetails from "./EmailTicketFullDetails.vue";
import AnomalyDetectionFullDetails from "./AnomalyDetectionFullDetails.vue";
import DataAccessViolationFullDetails from "./UserDataAccessViolationFullDetails.vue";
import DevicePasswordMissingFullDetails from "./DevicePasswordMissingFullDetails.vue";
import MassDeleteDownloadFullDetails from "./MassDeleteDownloadFullDetails.vue";
import EdrAffectedDevicesFullDetails from "@/components/tickets/ticket-preview-details/full-details/EdrAffectedDevicesFullDetails.vue";
import { type Component, computed, defineComponent, type PropType } from "vue";
import type { TicketDetails } from "@/_store/tickets/tickets.module";
import { TicketTrigger, TicketType, ViolationDirection } from "@/constants/tickets";

export default defineComponent({
  components: {
    DataAccessViolationFullDetails,
    MalwareInCloudDriveFullDetails,
    FindingsFullDetails,
    EmailTicketsFullDetails,
    AnomalyDetectionFullDetails,
    DevicePasswordMissingFullDetails,
    FileFindingsFullDetails,
    MassDeleteDownloadFullDetails,
    EdrAffectedDevicesFullDetails,
  },
  props: {
    ticketDetails: {
      type: Object as PropType<TicketDetails>,
      required: true,
    },
  },
  setup(props) {
    const fullDetailsComponent = computed(() => {
      const ticketTypeToComponentMap: { [type in TicketType]?: Component } = {
        [TicketType.MALWARE_IN_CLOUD_DRIVE]: MalwareInCloudDriveFullDetails,
        [TicketType.SUSPECTED_DATA_EXPOSURE_BY_EMAIL]: FindingsFullDetails,
        [TicketType.SUSPECTED_DATA_EXPOSURE_BY_SHARING]: FindingsFullDetails,
        [TicketType.SUSPECTED_COMPLIANCE_VIOLATION_BY_EMAIL]: FindingsFullDetails,
        [TicketType.SUSPECTED_COMPLIANCE_VIOLATION_BY_SHARING]: FindingsFullDetails,
        [TicketType.USER_INACTIVITY]: AnomalyDetectionFullDetails,
        [TicketType.SUSPECTED_COMPLIANCE_VIOLATION_BY_DEVICE]: FileFindingsFullDetails,
        [TicketType.MALWARE_IN_EMAIL_ATTACHMENTS]: EmailTicketsFullDetails,
        [TicketType.EMAIL_PHISHING]: EmailTicketsFullDetails,
        [TicketType.ABNORMAL_ADMIN_ACTIVITY]: AnomalyDetectionFullDetails,
        [TicketType.SUSPECTED_BOT_ATTACKS]: AnomalyDetectionFullDetails,
        [TicketType.ACCESS_PERMISSIONS_VIOLATION]: AnomalyDetectionFullDetails,
        [TicketType.SUSPECTED_IDENTITY_COMPROMISE]: AnomalyDetectionFullDetails,
        [TicketType.EDR_DETECTION]: EdrAffectedDevicesFullDetails,
      };

      const ticketTriggerToComponentMap: { [trigger in TicketTrigger]?: Component } = {
        [TicketTrigger.DEVICE_PASSWORD_MISSING]: DevicePasswordMissingFullDetails,
        [TicketTrigger.MASS_DELETE]: MassDeleteDownloadFullDetails,
        [TicketTrigger.MASS_DOWNLOAD]: MassDeleteDownloadFullDetails,
      };

      if (props.ticketDetails.eventType in ticketTypeToComponentMap) {
        return ticketTypeToComponentMap[props.ticketDetails.eventType];
      }

      if (props.ticketDetails.eventTrigger in ticketTriggerToComponentMap) {
        return ticketTriggerToComponentMap[props.ticketDetails.eventTrigger];
      }

      return null;
    });
    const hideSensitiveData = computed(() => {
      const ticketsToHideData = [
        TicketType.SUSPECTED_DATA_EXPOSURE_BY_EMAIL,
        TicketType.SUSPECTED_DATA_EXPOSURE_BY_SHARING,
        TicketType.SUSPECTED_COMPLIANCE_VIOLATION_BY_EMAIL,
        TicketType.SUSPECTED_COMPLIANCE_VIOLATION_BY_SHARING,
        TicketType.MALWARE_IN_EMAIL_ATTACHMENTS,
        TicketType.EMAIL_PHISHING,
      ];
      return (
        ticketsToHideData.includes(props.ticketDetails.eventType) &&
        props.ticketDetails.sensitiveDataRestricted
      );
    });
    const userDataAccessViolation = computed(() => {
      const isDlpEvent = [
        TicketTrigger.DLP_PII,
        TicketTrigger.DLP_PHI,
        TicketTrigger.DLP_PCI,
        TicketTrigger.DLP_NPI,
        TicketTrigger.CRITICAL_DATA_KEYWORDS,
        TicketTrigger.CRITICAL_DATA_CERTIFICATE,
        TicketTrigger.CRITICAL_DATA_SOURCE_CODE,
        TicketTrigger.CRITICAL_DATA_PASSWORD,
        TicketTrigger.CRITICAL_DATA_FILE_TYPES,
      ].includes(props.ticketDetails.eventTrigger);
      if (isDlpEvent) {
        const accessViolation = props.ticketDetails?.sections?.userDataAccessViolation;
        return accessViolation
          ? accessViolation
          : {
              violationDirection: ViolationDirection.SHARE,
              users: [props.ticketDetails.sections.triggeredUser],
            };
      }
      return undefined;
    });

    return {
      fullDetailsComponent,
      hideSensitiveData,
      userDataAccessViolation,
    };
  },
});
</script>
<style scoped lang="scss">
.full-details-wrapper {
  border: 1px solid rgb(var(--v-theme-indigo-faint));
}
.icon-full-details:before {
  color: rgb(var(--v-theme-inactive)) !important;
}
.sensitive-data-container {
  border: 1px solid rgb(var(--v-theme-indigo-faint));
}

.icon-data-no-hexagon:before {
  color: rgb(var(--v-theme-indigo-light)) !important;
}
</style>

<template>
  <div class="content-md margin-auto">
    <v-skeleton-loader
      class="skeleton-loader--security-settings"
      :loading="showSkeletonLoader"
      :type="coronetSkeletonLoaderTypes.SECURITY_SETTINGS_DENSE"
    >
      <v-card width="50%" class="pa-2 pt-3" v-if="localValue">
        <v-card-text>
          <div class="subtitle1">
            {{ $t("userDataGovernanceSettings.monitoringTab.privacySensitiveData") }}
          </div>
          <v-checkbox
            v-model="localValue.monitorPII"
            :disabled="actionNotAllowed"
            data-testid="cloud-apps-monitor-pii-checkbox"
            :ripple="false"
            :label="$t('userDataGovernanceSettings.monitoringTab.monitorPII')"
            class="mt-3 mb-1"
          />
          <v-checkbox
            v-model="localValue.monitorPCI"
            :disabled="actionNotAllowed"
            data-testid="cloud-apps-monitor-pci-checkbox"
            :ripple="false"
            :label="$t('userDataGovernanceSettings.monitoringTab.monitorPCI')"
            class="mt-3 mb-1"
          />
          <v-checkbox
            v-model="localValue.monitorPHI"
            :disabled="actionNotAllowed"
            data-testid="cloud-apps-monitor-phi-checkbox"
            :ripple="false"
            :label="$t('userDataGovernanceSettings.monitoringTab.monitorPHI')"
            class="mt-3 mb-1"
          />
          <v-checkbox
            v-model="localValue.monitorNPI"
            :disabled="actionNotAllowed"
            data-testid="cloud-apps-monitor-npi-checkbox"
            :ripple="false"
            :label="$t('userDataGovernanceSettings.monitoringTab.monitorNPI')"
            class="mt-3 mb-1"
          />
          <div class="custom-data-wrapper mt-8">
            <div class="subtitle1 mb-4">
              {{ $t("userDataGovernanceSettings.monitoringTab.securityAndBusinessData") }}
            </div>
            <v-checkbox
              v-model="localValue.customData.monitorPasswords"
              :disabled="actionNotAllowed"
              data-testid="custom-data-monitor-passwords-checkbox"
              :ripple="false"
              :label="$t('userDataGovernanceSettings.monitoringTab.passwords')"
              class="mt-3 mb-1"
            />
            <v-checkbox
              v-model="localValue.customData.monitorCertificates"
              :disabled="actionNotAllowed"
              data-testid="custom-data-monitor-certificates-checkbox"
              :ripple="false"
              :label="$t('userDataGovernanceSettings.monitoringTab.certificates')"
              class="mt-3 mb-1"
            />
            <v-checkbox
              v-model="localValue.customData.monitorSourceCodes"
              :disabled="actionNotAllowed"
              data-testid="custom-data-monitor-source-codes-checkbox"
              :ripple="false"
              :label="$t('userDataGovernanceSettings.monitoringTab.sourceCode')"
              class="mt-3 mb-1"
            />
            <v-checkbox
              v-model="localValue.customData.monitorSensitiveKeywords"
              :disabled="actionNotAllowed"
              data-testid="custom-data-monitor-sensitive-keywords-checkbox"
              :ripple="false"
              :label="
                $t('userDataGovernanceSettings.monitoringTab.dataObjectsWithSpecificKeywords')
              "
              class="mt-3 mb-1"
            />
            <v-combobox
              v-model="localValue.customData.sensitiveKeywords"
              :disabled="actionNotAllowed || !localValue!.customData.monitorSensitiveKeywords"
              data-testid="custom-data-monitor-keywords-combobox"
              variant="outlined"
              multiple
              item-color="red"
              class="ml-7"
              :rules="sensitiveKeywordsRule"
              :placeholder="
                $t('userDataGovernanceSettings.monitoringTab.specificKeywordsPlaceholder')
              "
              @update:search="onKeywordInput('sensitiveKeywords')"
            >
              <template v-slot:chip="{ item, props }">
                <v-chip
                  v-bind="props"
                  :disabled="actionNotAllowed"
                  :data-testid="`chip-${item}`"
                  close-icon="$closeCircle"
                  :closable="true"
                  variant="flat"
                  color="indigo-faint"
                >
                  <div class="d-flex align-center justify-space-between">
                    <span class="ml-1 mr-2 chip-content__text">{{ item.value }}</span>
                  </div>
                </v-chip>
              </template>
              <template #item="data">
                <v-list-item>
                  <span class="ml-1">{{ data.item }}</span>
                </v-list-item>
              </template>
            </v-combobox>
            <v-checkbox
              v-model="localValue.customData.monitorSensitiveFileTypes"
              :disabled="actionNotAllowed"
              data-testid="custom-data-monitor-sensitive-file-types-checkbox"
              :ripple="false"
              :label="$t('userDataGovernanceSettings.monitoringTab.specificFileTypesTitle')"
              class="mt-3 mb-1"
            />
            <v-combobox
              v-model="localValue.customData.sensitiveFileTypes"
              :disabled="actionNotAllowed"
              data-testid="custom-data-monitor-file-types-combobox"
              variant="outlined"
              multiple
              class="ml-7"
              :placeholder="
                $t('userDataGovernanceSettings.monitoringTab.specificFileTypesPlaceholder')
              "
              @update:search="onKeywordInput('sensitiveFileTypes')"
            >
              <template v-slot:chip="{ item, props }">
                <v-chip
                  v-bind="props"
                  :disabled="actionNotAllowed"
                  :data-testid="`chip-${item}`"
                  close-icon="$closeCircle"
                  :closable="true"
                  variant="flat"
                  color="indigo-faint"
                >
                  <div class="d-flex align-center justify-space-between">
                    <span class="ml-1 mr-2 chip-content__text">{{ item.value }}</span>
                  </div>
                </v-chip>
              </template>
              <template #item="data">
                <v-list-item>
                  <span class="ml-1">{{ data.item }}</span>
                </v-list-item>
              </template>
            </v-combobox>
          </div>
        </v-card-text>
      </v-card>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import type { Ref } from "vue";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { useUserDataGovernanceModule } from "@/_store/user-data-governance/user-data-governance.module";
import type { UserDataGovernanceSettings } from "@/_store/user-data-governance/user-data-governance.module";
import { storeToRefs } from "pinia";
import cloneDeep from "lodash/cloneDeep";
import { SubscriptionModule } from "@/constants/workplaces";
import { hasSeparator, isWorkspaceFrozenOrActionRestricted } from "@/_helpers/utils";
import { RolePermissionsScope } from "@/_store/roles.module";
import { useI18n } from "vue-i18n";
import { debounce, uniq } from "lodash";
import isEqual from "lodash/isEqual";

export default defineComponent({
  setup() {
    const userDataStore = useUserDataGovernanceModule();
    const { settings, showSkeletonLoader } = storeToRefs(userDataStore);
    const localValue: Ref<UserDataGovernanceSettings | null> = ref(null);
    const i18n = useI18n();

    onMounted(async () => {
      await userDataStore.getSettings();
      localValue.value = cloneDeep(settings.value);
    });

    const actionNotAllowed = isWorkspaceFrozenOrActionRestricted(
      RolePermissionsScope.PROTECTION,
      SubscriptionModule.USER_DATA_GOVERNANCE
    );

    const sensitiveKeywordsRule = [
      (keywords: string[]) => {
        if (keywords?.length > 200) {
          return i18n.t("validations.maximumAllowedKeywords");
        }
        return true;
      },
    ];

    watch(
      () => localValue.value,
      debounce(async (newVal) => {
        if (!isEqual(newVal, settings.value)) {
          await userDataStore.updateSettings(newVal);
        }
      }, 500),
      { immediate: false, deep: true }
    );

    const onKeywordInput = (arrayKey: "sensitiveKeywords" | "sensitiveFileTypes") => {
      const previousValues = localValue.value!.customData[arrayKey];
      const lastKeyword = previousValues?.at(-1);
      const hasCommaSeparator = hasSeparator(lastKeyword!);

      if (hasCommaSeparator) {
        const split = lastKeyword!.split(",");

        localValue.value!.customData[arrayKey] = uniq(
          [...localValue.value!.customData[arrayKey].slice(0, -1), ...split].map((item) =>
            item.toLowerCase().trim()
          )
        );
      }
    };

    return {
      coronetSkeletonLoaderTypes,
      settings,
      showSkeletonLoader,
      localValue,
      actionNotAllowed,
      sensitiveKeywordsRule,
      onKeywordInput,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(*) {
  .v-field__input {
    opacity: 1;
  }

  .v-field .v-chip {
    --v-chip-height: 32px;
  }
}
.chip-item {
  height: 36px;
  border-radius: 18px;
}

.category-icon {
  font-size: 40px;
}

.chip-content__text {
  max-width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>

<template>
  <v-form v-model="valid" lazy-validation>
    <v-combobox
      ref="combobox"
      v-model="localValue.items"
      v-model:search="currentInputValue"
      class="mt-3 coro-scrollable-combobox"
      :label="$t('modals.addUrlToURLList.label')"
      :placeholder="$t('modals.addUrlToURLList.placeholder')"
      :rules="rules"
      variant="outlined"
      multiple
      chips
      clearable
      no-filter
      hide-no-data
      @update:search="handleCommaDelimiterPaste"
      @blur="handleBlur"
    >
      <template v-slot:chip="{ props, item }">
        <v-chip
          v-bind="props"
          variant="flat"
          size="default"
          class="url-chip"
          :color="getChipColor(item.raw)"
          :closable="true"
          close-icon="$closeCircle"
          :text="item.raw.name"
        >
          <div class="d-flex align-center justify-space-between">
            <span class="ml-1 mr-2" v-text="item.title" />
          </div>
        </v-chip>
      </template>
    </v-combobox>
  </v-form>
</template>

<script lang="ts">
import { defineComponent, onMounted, type PropType, ref, watch } from "vue";
import Patterns from "@/constants/patterns";
import { useI18n } from "vue-i18n";
import { hasSeparator } from "@/_helpers/utils";
import uniq from "lodash/uniq";
import trim from "lodash/trim";

interface AddVpnModalConfig {
  action: string;
  item: {
    excluded: boolean;
  };
}

export default defineComponent({
  props: {
    config: { type: Object as PropType<AddVpnModalConfig>, required: true },
  },
  emits: ["update:valid", "update:localValue"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const currentInputValue = ref("");

    const combobox = ref();

    const valid = ref(false);
    const localValue = ref<{ items: string[]; excluded?: boolean }>({
      items: [],
    });

    onMounted(() => {
      const {
        item: { excluded },
      } = props.config;
      localValue.value = {
        ...localValue.value,
        excluded,
      };
    });

    watch(
      localValue,
      (newVal) => {
        if (newVal?.items?.length) {
          setTimeout(() => emit("update:valid", newVal.items.length > 0 && valid));
        }

        emit("update:localValue", localValue.value);
      },
      { deep: true }
    );

    watch(currentInputValue, (newVal) => {
      if (!newVal) {
        return;
      }
      const hasCommaSeparator = hasSeparator(newVal);

      if (!hasCommaSeparator) {
        const isValid = checkPatternMatch(newVal);
        emit("update:valid", isValid);
      }
      if (hasCommaSeparator) {
        handleCommaDelimiterPaste(newVal);
      }
    });

    const rules = [
      (values: string[]) => {
        if (!values?.length) return true;
        const isInValid: boolean = values.some((value: string) => !checkPatternMatch(value));
        return isInValid ? t("validations.ipOrDomain") : true;
      },
    ];

    const handleCommaDelimiterPaste = (newVal: string) => {
      const hasCommaSeparator = hasSeparator(newVal);
      if (!hasCommaSeparator) return;
      const importedIpsAndDomains: string[] = newVal
        .split(",")
        .filter((item: string) => item)
        .map((e) => trim(e));

      combobox.value.search = "";

      localValue.value.items = uniq([...localValue.value.items].concat(importedIpsAndDomains));

      const isValid = localValue.value.items.some((item) => checkPatternMatch(item));

      emit("update:valid", isValid);
    };

    const checkPatternMatch = (value: string): boolean => {
      return Patterns.IP_WITH_OPTIONAL_CIDR.test(value) || Patterns.DOMAIN.test(value);
    };

    const handleBlur = (): void => {
      if (!currentInputValue.value) {
        return;
      }
      const hasCommaSeparator = hasSeparator(currentInputValue.value);
      if (hasCommaSeparator) {
        currentInputValue.value = "";
      }
    };

    const getChipColor = (itemName: string): string =>
      checkPatternMatch(itemName) ? "indigo-faint" : "error";

    return {
      currentInputValue,
      handleCommaDelimiterPaste,
      handleBlur,
      getChipColor,
      valid,
      localValue,
      combobox,
      rules,
    };
  },
});
</script>
<style scoped lang="scss">
:deep(*) {
  .url-chip {
    .icon-rules-close:before {
      color: rgb(var(--v-theme-primary)) !important;
    }
  }
}
</style>

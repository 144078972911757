<template>
  <div class="content-md mt-2 margin-auto">
    <div>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    </div>
    <div class="settings-header-with-btn">
      <div class="headline5">
        {{ $t("backOffice.agentsGradualUpdate") }}
      </div>
      <v-btn v-if="!isEditMode" color="primary" rounded large @click="toggleEditMode">
        <span>{{ $t("general.edit") }}</span>
      </v-btn>
      <div v-else>
        <v-btn color="primary" variant="text" @click="handleCancel">
          <span>{{ $t("general.cancel") }}</span>
        </v-btn>
        <v-btn color="primary" rounded large @click="handleSave">
          <span>{{ $t("general.save") }}</span>
        </v-btn>
      </div>
    </div>
    <v-skeleton-loader
      class="skeleton-loader--security-settings"
      :loading="showSkeletonLoader"
      :type="coronetSkeletonLoaderTypes.SECURITY_SETTINGS"
    >
      <v-card class="pa-4">
        <v-card-text>
          <div :class="{ 'mb-4': !isEditMode }">
            <span class="body1">{{ `${$t("backOffice.workspaceGroup")}: ` }}</span>
            <span v-if="!isEditMode" class="body1">{{ localValue.workspacesGroup }}</span>
            <v-select
              v-else
              v-model="localValue.workspacesGroup"
              :items="[0, 1, 2, 3]"
              :menu-props="{ maxHeight: '300' }"
              density="compact"
              variant="outlined"
              class="filter-menu mb-6"
              hide-details
              rounded
            >
              <template v-slot:item="{ props, item }">
                <v-list-item v-bind="props" :title="item.value"> </v-list-item>
              </template>
              <template v-slot:selection="{ item }">
                <span class="body2">{{ item.value }}</span>
              </template>
            </v-select>
          </div>
          <div :class="{ 'mb-4': !isEditMode }">
            <span class="body1">{{ `${$t("backOffice.deviceGroup")}: ` }}</span>
            <span v-if="!isEditMode" class="body1">{{ localValue.devicesGroup }}</span>
            <v-select
              v-else
              v-model="localValue.devicesGroup"
              :items="[0, 1, 2, 3]"
              :menu-props="{ maxHeight: '300' }"
              density="compact"
              variant="outlined"
              class="filter-menu mb-6"
              hide-details
              rounded
            >
              <template v-slot:item="{ props, item }">
                <v-list-item v-bind="props" :title="item.value"> </v-list-item>
              </template>
              <template v-slot:selection="{ item }">
                <span class="body2">{{ item.value }}</span>
              </template>
            </v-select>
          </div>
          <div v-if="!isEditMode" class="body1 mb-4">
            {{ `${$t("backOffice.betaUpdateGroup")}: ${localValue.betaGroupUpdate}` }}
          </div>
          <v-checkbox
            v-else
            v-model="localValue.betaGroupUpdate"
            :ripple="false"
            :label="$t('backOffice.betaUpdateGroup')"
          />
          <div class="body1 mb-4">
            {{ `${$t("backOffice.totalWorkspaces")}: ${agentGradualUpdateData.workspacesCount}` }}
          </div>
          <div class="body1 mb-4">
            {{ `${$t("backOffice.totalDevices")}: ${agentGradualUpdateData.devicesCount}` }}
          </div>
        </v-card-text>
      </v-card>
      <table-wrapper class="mt-8">
        <v-data-table
          class="permissions-tab-table"
          v-model:expanded="expanded"
          :items="localValue.agentVersions"
          item-key="id"
          :group-by="groupBy"
          :show-expand="true"
          hide-default-header
          :items-per-page="-1"
          :hover="false"
          :headers="headers"
        >
          <template #headers>
            <tr>
              <th>
                {{ $t("devicesSettings.agentDeploymentTab.version") }}
              </th>
              <th>
                {{ $t("devicesSettings.agentDeploymentTab.released") }}
              </th>
              <th>
                {{ $t("devicesSettings.agentDeploymentTab.channel") }}
              </th>
              <th>
                {{ $t("devicesSettings.agentDeploymentTab.devices") }}
              </th>
              <th class="w-10"></th>
            </tr>
          </template>
          <template #group-header="{ toggleGroup, item, isGroupOpen }">
            <tr class="group-header">
              <th :colspan="5">
                <v-btn
                  :icon="true"
                  variant="plain"
                  :ripple="false"
                  @click="toggleGroup(item)"
                  :ref="`${item.value.toLowerCase()}ExpandButton`"
                >
                  <v-icon :class="{ rotated: isGroupOpen(item) }" icon="$chevronDown"> </v-icon>
                </v-btn>
                <span class="subtitle1">
                  {{
                    $t(`devicesSettings.agentDeploymentTab.osTypes.${item?.value?.toLowerCase()}`)
                  }}
                </span>
              </th>
            </tr>
          </template>
          <template #item="{ item, index, isExpanded, toggleExpand, internalItem }">
            <tr class="agent-deployment-table-row" :data-testid="index">
              <td>
                <div class="d-inline-flex align-center">
                  <coro-icon class="coro-icon mr-4" icon-name="coronet"></coro-icon>
                  <div>
                    <div>
                      <span class="subtitle1">{{ item.version }}</span>
                      <span class="body1"> ({{ item.marketingVersion }})</span>
                    </div>
                    <div class="d-inline-flex">
                      <span class="body2 text--grey">{{
                        $t("devicesSettings.agentDeploymentTab.releaseNotes")
                      }}</span>
                      <v-icon
                        class="ml-1"
                        :class="{ rotated: isExpanded(internalItem) }"
                        @click="toggleExpand(internalItem)"
                        icon="$chevronDown"
                      >
                      </v-icon>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <span class="body2">{{ getFormattedDateTime(item.releaseDate, "ll") }}</span>
              </td>
              <td>
                <span v-if="!isEditMode" class="body2">
                  {{ $t(`devicesSettings.agentDeploymentTab.channels.${item.channel}`) }}
                </span>
                <v-select
                  v-else
                  v-model="item.channel"
                  :items="item.channelTransitions"
                  :menu-props="{ maxHeight: '300' }"
                  density="compact"
                  variant="outlined"
                  class="channel-selector"
                  hide-details
                  rounded
                >
                  <template v-slot:item="data">
                    <v-list-item
                      v-bind="data.props"
                      :title="$t(`devicesSettings.agentDeploymentTab.channels.${data.item.value}`)"
                    />
                  </template>
                  <template v-slot:selection="data">
                    <span class="body2">
                      {{ $t(`devicesSettings.agentDeploymentTab.channels.${data.item.value}`) }}
                    </span>
                  </template>
                </v-select>
              </td>
              <td>
                <span class="body2">{{ item.installedOnDevices }}</span>
              </td>
              <td>
                <v-menu class="justify-end" offset-y bottom right>
                  <template #activator="{ props }">
                    <v-btn
                      class="ml-auto"
                      rounded
                      color="primary"
                      data-testid="user-preview-action-button"
                      v-bind="props"
                    >
                      {{ $t("general.actions") }}
                      <v-icon class="ml-1 mt-1" icon="$triangle" size="8"> </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="action in actions"
                      :key="action"
                      :data-testid="`agent-deployment-${kebabCase(action)}-action`"
                      @click="onActionClick(action, item)"
                    >
                      <v-list-item-title>
                        {{ $t(`devicesSettings.agentDeploymentTab.actions.${action}`) }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
          <template #expanded-row="{ item, columns }">
            <td :colspan="columns.length">
              <div class="mt-3 mb-3 ml-5">
                <div
                  v-for="releaseNote in item.releaseNotes"
                  :key="releaseNote"
                  class="body2 ml-12 text-gray-neutral"
                >
                  {{ releaseNote }}
                </div>
              </div>
            </td>
          </template>
          <template #bottom></template>
        </v-data-table>
      </table-wrapper>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, type Ref, watch } from "vue";
import {
  type AgentGradualUpdateData,
  useBackOfficeStore,
} from "@/_store/back-office/back-office.module";
import { storeToRefs } from "pinia";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import cloneDeep from "lodash/cloneDeep";
import { useI18n } from "vue-i18n";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import CoroIcon from "@/components/CoroIcon.vue";
import TableWrapper from "@/components/TableWrapper.vue";
import { AgentDeploymentAction } from "@/constants/devices";
import kebabCase from "lodash/kebabCase";
import { type DeviceVersionItem, useDevicesSettingsStore } from "@/_store/devices-settings.module";
import {
  componentDialogsConfigConstructor,
  copyToClipboard,
  downloadFileByLink,
  getConvertedOSTypeForAWS,
  getDownloadLink,
  getFormattedDateTime,
} from "@/_helpers/utils";
import { AdminUsersAction } from "@/constants/admin-accounts";
import CopyLinkModal from "@/components/modals/CopyLinkModal.vue";
import { ModalWidth } from "@/constants/modals";
import { useDialogsStore } from "@/_store/dialogs.module";
import { useSnackbarStore } from "@/_store";

export default defineComponent({
  components: { TableWrapper, CoroIcon },
  setup() {
    const i18n = useI18n();
    const backofficeStore = useBackOfficeStore();
    const devicesSettingsStore = useDevicesSettingsStore();
    const snackbarStore = useSnackbarStore();
    const {
      agentGradualUpdateData,
      showSkeletonLoader,
      defaultWorkspaceGroup,
      defaultDevicesGroup,
      defaultBetaGroupUpdate,
    } = storeToRefs(backofficeStore);
    const { downloadClientPageLink } = storeToRefs(devicesSettingsStore);
    const dialogsStore = useDialogsStore();
    const { getAgentGradualUpdateData, saveAgentGradualUpdateData } = backofficeStore;
    const localValue: Ref<Partial<AgentGradualUpdateData>> = ref({});
    const isEditMode: Ref<boolean> = ref(false);
    const expanded = ref([]);

    const toggleEditMode = () => {
      isEditMode.value = !isEditMode.value;
    };

    const handleSave = () => {
      saveAgentGradualUpdateData({
        workspacesGroup: localValue.value.workspacesGroup,
        devicesGroup: localValue.value.devicesGroup,
        betaGroupUpdate: localValue.value.betaGroupUpdate,
        agentVersions: localValue.value.agentVersions,
      });
      toggleEditMode();
    };

    const handleCancel = async () => {
      await getAgentGradualUpdateData({
        workspacesGroup: defaultWorkspaceGroup.value,
        devicesGroup: defaultDevicesGroup.value,
        betaGroupUpdate: defaultBetaGroupUpdate.value,
      });
      localValue.value = cloneDeep(agentGradualUpdateData.value);
      toggleEditMode();
    };

    watch(
      localValue,
      async (newVal) => {
        if (!isEmpty(newVal) && !isEqual(newVal, agentGradualUpdateData.value)) {
          await getAgentGradualUpdateData(
            {
              workspacesGroup: localValue.value.workspacesGroup,
              devicesGroup: localValue.value.devicesGroup,
              betaGroupUpdate: localValue.value.betaGroupUpdate,
            },
            false
          );
        }
      },
      { deep: true }
    );

    onMounted(async () => {
      await getAgentGradualUpdateData({ setDefaults: true });

      localValue.value = cloneDeep(agentGradualUpdateData.value);
    });

    async function createDownloadLink(item: DeviceVersionItem) {
      const url: { [key in "win" | "macos"]: string } = await getDownloadLink({
        id: downloadClientPageLink.value.split("/")[4],
        os: getConvertedOSTypeForAWS(item.osType),
        version: item.version,
      });
      const convertedOsType = getConvertedOSTypeForAWS(item.osType);
      return url[convertedOsType];
    }

    async function onActionClick(action: AgentDeploymentAction, item: DeviceVersionItem) {
      const downloadLink = await createDownloadLink(item);
      switch (action) {
        case AgentDeploymentAction.COPY_LINK:
          dialogsStore.openDialog(
            componentDialogsConfigConstructor({
              item: { inviteLink: downloadLink },
              action: AdminUsersAction.COPY_INVITE_LINK,
              component: CopyLinkModal,
              width: ModalWidth.LARGE,
              callback: () => {
                copyToClipboard(downloadLink);
                snackbarStore.addGenericSuccess(
                  i18n.t("snackbar.messages.general.copiedToClipboard")
                );
              },
            })
          );
          break;
        case AgentDeploymentAction.DOWNLOAD:
          await downloadFileByLink(downloadLink);
          break;
      }
    }

    return {
      breadcrumbs: [
        {
          title: `‹ ${i18n.t("backOffice.title")}`,
          disabled: false,
          to: { path: "/portal/back-office" },
        },
      ],
      localValue,
      showSkeletonLoader,
      agentGradualUpdateData,
      coronetSkeletonLoaderTypes,
      toggleEditMode,
      isEditMode,
      handleSave,
      handleCancel,
      expanded,
      getFormattedDateTime,
      kebabCase,
      onActionClick,
      groupBy: [
        {
          key: "osType",
        },
      ],
      headers: [
        { key: "data-table-expand", groupable: false },
        {
          title: i18n.t("devicesSettings.agentDeploymentTab.version"),
          key: "version",
          sortable: false,
        },
        {
          title: i18n.t("devicesSettings.agentDeploymentTab.released"),
          key: "releaseDate",
          sortable: false,
        },
        {
          title: i18n.t("devicesSettings.agentDeploymentTab.channel"),
          key: "isStable",
          sortable: false,
        },
        {
          title: i18n.t("devicesSettings.agentDeploymentTab.devices"),
          key: "installedOnDevices",
          sortable: false,
        },
        { title: "", key: "actions", sortable: false },
      ],
      actions: [AgentDeploymentAction.COPY_LINK, AgentDeploymentAction.DOWNLOAD],
    };
  },
});
</script>

<style scoped lang="scss">
.filter-menu {
  width: 80px;
}

.channel-selector {
  width: 182px;
}

.group-header {
  background: rgb(var(--v-theme-indigo-pale));
  border-bottom: 0 !important;
  position: sticky;
  top: 0;
  z-index: 2;
  padding-left: 4px !important;

  .icon-chevron:before {
    color: rgb(var(--v-theme-primary)) !important;
  }
}
.channels-button {
  text-transform: unset !important;
  background: transparent !important;
}

.agent-deployment-table-row {
  height: 80px;
}

.coro-icon {
  height: 40px;
  width: 40px;
}
</style>

<template>
  <div>
    <v-form ref="form" v-model="formValid" class="mt-3" lazy-validation @submit.prevent>
      <div class="subtitle1 mb-4">
        {{ $t("modals.addZtnaRule.titles.resourceDetails") }}
      </div>

      <v-text-field
        class="mb-2"
        v-model="localValue.item.name"
        variant="outlined"
        :counter="64"
        :rules="[required(), maxCharactersRule(64)]"
        :label="$t('modals.addZtnaRule.placeholders.resourceName')"
      />
      <v-text-field
        class="mb-2"
        v-model="localValue.item.description"
        variant="outlined"
        :rules="[required()]"
        :label="$t('modals.addZtnaRule.placeholders.resourceDescription')"
      />
      <div class="subtitle1 mb-4">
        {{ $t("modals.addZtnaRule.titles.resourceConfigurations") }}
      </div>
      <v-text-field
        class="mb-2 mr-2 ports-input"
        v-model="localValue.item.ips"
        variant="outlined"
        :rules="[required(), ipAddressRule]"
        :label="$t('modals.addZtnaRule.placeholders.ipAddress')"
      />
      <div class="d-flex side-by-side-inputs mb-2">
        <v-combobox
          ref="combobox"
          v-model="localValue.item.ports"
          v-model:search="currentInputValue"
          class="ports-input mr-2"
          :label="$t('modals.addZtnaRule.placeholders.port')"
          variant="outlined"
          multiple
          :rules="[portsRule]"
          chips
          no-filter
          hide-no-data
          @update:search="handleCommaDelimiterPaste"
          @blur="handleBlur"
        >
          <template #chip="{ props, item }">
            <v-chip
              v-bind="props"
              variant="flat"
              color="indigo-faint"
              closable
              close-icon="$closeCircle"
            >
              <div class="d-flex align-center justify-space-between">
                <span class="ml-1 mr-2" v-text="item.raw" />
              </div>
            </v-chip>
          </template>
        </v-combobox>

        <v-select
          class="mb-2"
          v-model="localValue.item.protocols"
          :items="PROTOCOLS"
          variant="outlined"
          :rules="[required()]"
          :label="$t('modals.addZtnaRule.placeholders.protocol')"
        />
      </div>
      <div class="subtitle1 mb-4">
        {{ $t("modals.addZtnaRule.titles.deviceLabels") }}
      </div>
      <v-autocomplete
        v-model="localValue.item.labels"
        class="mt-4"
        :items="labels"
        :label="$t('modals.addZtnaRule.placeholders.labels')"
        :placeholder="$t('network.virtualOffice.labelsAutocompletePlaceholder')"
        variant="outlined"
        multiple
        return-object
        chips
        closable-chips
        clear-on-select
        data-testid="device-settings-tamper-resistance-labels"
        item-value="id"
        item-title="name"
      >
        <template v-slot:chip="{ props, item }">
          <v-chip
            v-bind="props"
            :closable="true"
            variant="flat"
            size="default"
            :color="item.raw.color"
            close-icon="$closeCircle"
            :text="item.raw.name"
            class="label-chip"
          >
            <div class="d-flex align-center">
              <span class="ml-1 mr-2 body2" v-text="item.title" />
            </div>
          </v-chip>
        </template>
        <template v-slot:item="{ item, props }">
          <v-list-item v-bind="props" title="">
            <template #default>
              <div class="d-flex align-center label-popup-block">
                <div class="color-preview mr-2" :style="{ 'background-color': item.raw.color }" />
                <span v-text="item.raw.name" />
              </div>
            </template>
          </v-list-item>
        </template>
      </v-autocomplete>
    </v-form>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, type PropType, ref, watch } from "vue";
import type { VuetifyFormRef } from "@/types";
import { useI18n } from "vue-i18n";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import { maxCharactersRule, required } from "@/_helpers/validators";
import { storeToRefs } from "pinia";
import { useDevicesSettingsStore } from "@/_store/devices-settings.module";
import Patterns from "@/constants/patterns";
import { hasSeparator } from "@/_helpers/utils";
import uniq from "lodash/uniq";
import trim from "lodash/trim";
import { Protocols } from "@/_store/network/ztna.module";

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<DialogDataConfig>,
      required: true,
    },
  },
  emits: ["update:valid", "update:localValue"],
  setup(props, { emit }) {
    const form = ref<VuetifyFormRef>();
    const combobox = ref();
    const currentInputValue = ref("");
    const { t } = useI18n();
    const formValid = ref(null);
    const localValue = ref(
      props.config.item
        ? {
            item: {
              ...props.config.item,
              ips: props.config.item.ips[0],
            },
          } // TODO: Fix ips and ports plural and singular with backend
        : {
            item: {
              protocols: "",
              ports: [],
              ips: "",
              description: "",
              name: "",
            },
          }
    );
    const deviceSettingsStore = useDevicesSettingsStore();
    const { getLabels } = deviceSettingsStore;
    const { labels } = storeToRefs(deviceSettingsStore);

    const PROTOCOLS = [Protocols.All, Protocols.TCP, Protocols.UDP];

    const portsRule = (value: string[]) => {
      if (!value) return true;
      if (value.some((port: string) => !Patterns.PORT.test(port))) {
        return t("validations.port");
      }
      return true;
    };

    const ipAddressRule = (value: string) => {
      if (!value) return true;
      if (!Patterns.IP.test(value)) {
        return t("validations.ipAddress");
      }
      return true;
    };
    const handleBlur = () => {
      if (!currentInputValue.value) return;
      const hasCommaSeparator = hasSeparator(currentInputValue.value);
      if (hasCommaSeparator) {
        currentInputValue.value = "";
      }
    };

    const handleCommaDelimiterPaste = (newVal: string) => {
      const hasCommaSeparator = hasSeparator(newVal);
      if (!hasCommaSeparator) return;
      const ports = newVal
        .split(",")
        .filter((item: string) => item)
        .map((e) => trim(e));

      localValue.value.item.ports = uniq([...localValue.value.item.ports].concat(ports));
      currentInputValue.value = "";
      combobox.value.search = "";
    };
    onMounted(async () => {
      await getLabels();
    });

    watch(
      () => localValue,
      () => {
        emit("update:localValue", {
          ...localValue.value.item,
          // TODO: change this on phase2
          ips: [localValue.value.item.ips],
        });
      },
      { deep: true, immediate: true }
    );

    watch(
      formValid,
      () => {
        emit("update:valid", formValid.value);
      },
      { immediate: true }
    );

    watch(currentInputValue, (newVal) => {
      if (!newVal) {
        return;
      }
      const hasCommaSeparator = hasSeparator(newVal);
      if (hasCommaSeparator) {
        handleCommaDelimiterPaste(newVal);
      }
    });
    return {
      handleCommaDelimiterPaste,
      maxCharactersRule,
      combobox,
      handleBlur,
      PROTOCOLS,
      currentInputValue,
      ipAddressRule,
      localValue,
      required,
      formValid,
      portsRule,
      form,
      labels,
    };
  },
});
</script>
<style lang="scss" scoped>
.side-by-side-inputs {
  height: 70px;
  .ports-input {
    flex: 3;
  }
}
</style>

<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <div v-if="showMacOsDisclaimer" class="warning-block d-flex align-center mb-4">
      <v-icon class="mr-3 ml-4" icon="$warning"></v-icon>
      <span class="body2">{{ $t("modals.addDevicePosturePolicy.macOsDisclaimer") }}</span>
    </div>
    <div class="subtitle1 mb-4">
      {{ $t("modals.addDevicePosturePolicy.selectPolicyType") }}
    </div>
    <v-select
      v-model="localValue.policyType"
      :items="policies"
      :disabled="isInEditMode"
      class="mb-6"
      variant="outlined"
    >
      <template #item="{ item, props }">
        <v-list-item
          v-bind="props"
          :title="$t(`devicesSettings.devicePostureTab.posturePolicies.${item.title}`)"
        ></v-list-item>
      </template>
      <template #selection="{ item }">
        {{ $t(`devicesSettings.devicePostureTab.posturePolicies.${item.title}`) }}
      </template>
    </v-select>

    <component :is="component" ref="componentRef" v-model:config="localValue" />
    <div class="subtitle1 mb-4">
      {{ $t("modals.addDevicePosturePolicy.applyPolicyToDevices") }}
    </div>
    <v-autocomplete
      v-model="localValue.labels"
      class="mb-3"
      :items="labels"
      :label="$t('modals.addDevicePosturePolicy.labels')"
      :placeholder="$t('modals.addDevicePosturePolicy.labels')"
      :rules="required"
      variant="outlined"
      multiple
      return-object
      item-text="name"
    >
      <template v-slot:chip="{ props, item }">
        <v-chip
          v-bind="props"
          :closable="true"
          variant="flat"
          size="default"
          :color="item.raw.color"
          close-icon="$closeCircle"
          :text="item.raw.name"
          class="label-chip"
        >
          <div class="d-flex align-center">
            <span class="ml-1 mr-2">{{ item.raw.name }}</span>
          </div>
        </v-chip>
      </template>
      <template #item="{ item, props }">
        <v-list-item v-bind="props" title="">
          <span class="color-preview mr-2" :style="{ 'background-color': item.raw.color }" />
          <span>{{ item.raw.name }} </span>
        </v-list-item>
      </template>
    </v-autocomplete>
  </v-form>
</template>
<script lang="ts">
import UsbLockdownModal from "@/components/modals/device-posture/UsbLockdownModal.vue";
import UserAccountPostureModal from "@/components/modals/device-posture/UserAccountPostureModal.vue";
import WifiPostureModal from "@/components/modals/device-posture/WifiPostureModal.vue";
import DefaultPostureModal from "@/components/modals/device-posture/DefaultPostureModal.vue";
import {
  DevicePostureAction,
  DevicePostureOsType,
  DevicePostureSettings,
  DeviceVulnerability,
  OsType,
  WifiPostureConnectionType,
} from "@/constants/devices";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import type { PropType } from "vue";
import { useDevicesSettingsStore } from "@/_store/devices-settings.module";
import { storeToRefs } from "pinia";
import defaultsDeep from "lodash/defaultsDeep";
import { useI18n } from "vue-i18n";
import type {
  DevicePostureCombinedType,
  DevicePostureFormData,
} from "@/_store/device-posture/devices-posture-settings.module";
import type { VuetifyFormRef } from "@/types";

interface DevicePostureDialogConfig {
  item: DevicePostureCombinedType & {
    policyType: DevicePostureSettings;
    osType: DevicePostureOsType;
  };
  action: DevicePostureAction;
}

export default defineComponent({
  components: {
    DefaultPostureModal,
    WifiPostureModal,
    UserAccountPostureModal,
    UsbLockdownModal,
  },
  props: {
    config: {
      type: Object as PropType<DevicePostureDialogConfig>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const policies = computed(() => {
      if (props.config.item.osType === DevicePostureOsType.MAC_OS) {
        return [
          DeviceVulnerability.DEVICE_PASSWORD_MISSING,
          DeviceVulnerability.FIREWALL_DISABLED,
          DeviceVulnerability.ENCRYPTION_DISABLED,
          DeviceVulnerability.GATEKEEPER_UNAVAILABLE,
          DeviceVulnerability.APPLE_MOBILE_FILE_INTEGRITY_UNAVAILABLE,
          DeviceVulnerability.SYSTEM_INTEGRITY_PROTECTION_UNAVAILABLE,
          DevicePostureSettings.WIFI,
          DevicePostureSettings.USB_LOCKDOWN,
          DevicePostureSettings.USER_ACCOUNT_POLICY,
        ];
      }
      return [
        DeviceVulnerability.UAC_NOTIFICATIONS_MISSING,
        DeviceVulnerability.DEVICE_PASSWORD_MISSING,
        DeviceVulnerability.FIREWALL_DISABLED,
        DeviceVulnerability.ENCRYPTION_DISABLED,
        DeviceVulnerability.DEVELOPMENT_MODE_ENABLED,
        DeviceVulnerability.NON_GENUINE_WINDOWS,
        DevicePostureSettings.WIFI,
        DevicePostureSettings.USER_ACCOUNT_POLICY,
        DevicePostureSettings.USB_LOCKDOWN,
      ];
    });

    const deviceSettingsModule = useDevicesSettingsStore();
    const i18n = useI18n();

    const defaultItem = {
      policyType: policies.value[0],
      osType: OsType.WINDOWS,
      postureValue: "",
      connectionType: WifiPostureConnectionType.ALL_ENCRYPTED,
      blockPortableDevices: props.config.item.osType === DevicePostureOsType.WINDOWS,
      blockMassStorage: true,
      networks: [],
      labels: [],
      passwordMustMeetComplexity: false,
      minPasswdLen: null,
      minPasswdAge: null,
      maxPasswdAge: null,
      passwordHistLen: null,
      lockoutDuration: null,
      lockoutThreshold: null,
    };
    const localValue = ref<DevicePostureFormData>(
      defaultsDeep({ ...props.config.item }, defaultItem)
    );
    const form = ref<VuetifyFormRef>();
    const componentRef = ref<{ validate?: () => boolean }>();
    const { labels } = storeToRefs(deviceSettingsModule);
    const { getLabels } = deviceSettingsModule;
    const valid = ref(true);
    const componentValid = ref(true);

    const isInEditMode = computed(() => {
      return props.config.action === DevicePostureAction.EDIT;
    });

    const showMacOsDisclaimer = computed(() => {
      return localValue.value.policyType === DeviceVulnerability.GATEKEEPER_UNAVAILABLE;
    });

    const component = computed(() => {
      switch (localValue.value.policyType) {
        case DevicePostureSettings.WIFI:
          return "WifiPostureModal";
        case DevicePostureSettings.USER_ACCOUNT_POLICY:
          return "UserAccountPostureModal";
        case DevicePostureSettings.USB_LOCKDOWN:
          return "UsbLockdownModal";
        default:
          return "DefaultPostureModal";
      }
    });

    onMounted(async () => {
      await getLabels();
    });

    const validate = async () => {
      const validationResult = await form.value?.validate();
      let componentValid = true;

      if (componentRef.value?.validate) {
        componentValid = await componentRef.value?.validate();
      }

      const isValid = validationResult?.valid && componentValid;
      emit("update:valid", isValid);
      return isValid;
    };

    watch(
      valid,
      async (value) => {
        if (value === null) {
          return;
        }

        emit("update:valid", value);
      },
      { immediate: false }
    );

    watch(
      localValue,
      async (value) => {
        emit("update:localValue", value);
      },
      { deep: true }
    );

    return {
      localValue,
      required: [(v: string[]) => (!v || v.length === 0 ? i18n.t("validations.required") : true)],
      labelSearchTerm: null,
      labels,
      policies,
      isInEditMode,
      component,
      form,
      validate,
      valid,
      componentRef,
      componentValid,
      showMacOsDisclaimer,
    };
  },
});
</script>
<style lang="scss" scoped>
.color-preview {
  width: 40px !important;
  height: 40px !important;
  min-width: 40px !important;
  border-radius: 4px !important;
}

:deep(*) {
  .v-input__slot {
    padding-right: 8px !important;
  }
  .v-input__append-inner {
    margin-top: 0 !important;
    padding-left: 8px !important;
    height: 56px;
    display: flex;
    align-items: center;
  }

  .label-chip {
    .icon-rules-close:before {
      color: var(--v-primary-base) !important;
    }
  }
}
</style>

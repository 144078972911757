<template>
  <div class="content-md margin-auto mt-4">
    <v-breadcrumbs :items="breadCrumbsItems" data-testid="breadcrumbs" divider="" />
    <div class="d-flex justify-space-between align-center mb-5 mt-4">
      <div class="headline5 d-flex align-center text-primary">
        <span>{{ $t("connectors.title") }}</span>
      </div>
      <div>
        <v-btn
          color="primary"
          rounded
          elevation="0"
          size="large"
          class="add-btn"
          data-testid="invite-admin-button"
          v-if="shouldShowActionButton()"
          @click="handleCurrentTabAction()"
        >
          <v-icon color="white" class="mr-2" icon="$add"></v-icon>
          <span>{{ currentCreationButtonText }}</span>
        </v-btn>
      </div>
    </div>
    <page-tabs
      :tabs="tabs"
      :with-translation="false"
      @update:tab="onTabUpdate($event)"
      class="mb-4"
    >
    </page-tabs>
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { useAccountStore } from "@/_store";
import { computed, defineComponent, ref } from "vue";
import { useDialogsStore } from "@/_store/dialogs.module";
import { ModalWidth } from "@/constants/modals";
import { useI18n } from "vue-i18n";
import {
  componentDialogsConfigConstructor,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { WorkspaceType } from "@/constants/workplaces";
import { useRoute } from "vue-router";
import { ConnectorActions } from "@/constants/connectors";
import type { CoroTab } from "@/components/PageTabs.vue";
import PageTabs from "@/components/PageTabs.vue";
import AddPsaConnectorModal from "@/components/modals/psa-modals/AddPsaConnectorModal.vue";
import { usePsaStore } from "@/_store/connectors/psa.module";
import { storeToRefs } from "pinia";
import { useApiCredentialsStore } from "@/_store/connectors/api-credentials.module";
import AddApiKey from "@/components/modals/AddApiKey.vue";
import { useSiemStore } from "@/_store/connectors/siem.module";
import AddSiemConnectorModal from "@/components/modals/connectors/AddSiemConnectorModal.vue";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import { useWebhooksStore } from "@/_store/connectors/webhooks.module";
import AddWebhookModal from "@/components/modals/connectors/AddWebhookModal.vue";
import { WebhookAction } from "@/constants/webhooks";

enum ConnectorsPageTabs {
  SIEM = "siem",
  API_KEYS = "api-keys",
  WEBHOOKS = "webhooks",
  PSA = "psa",
}

export default defineComponent({
  components: {
    PageTabs,
  },
  setup() {
    const accountStore = useAccountStore();
    const dialogsStore = useDialogsStore();
    const psaStore = usePsaStore();
    const siemStore = useSiemStore();
    const webhooksStore = useWebhooksStore();
    const apiKeysStore = useApiCredentialsStore();
    const { addApiKey } = apiKeysStore;
    const { webhooks } = storeToRefs(webhooksStore);
    const { loading, connectors } = storeToRefs(psaStore);
    const { apiKeys } = storeToRefs(apiKeysStore);
    const { connectors: siemConnectors } = storeToRefs(siemStore);
    const { addSiemConnector } = siemStore;
    const i18n = useI18n();

    const { account } = accountStore;
    const handleCurrentTabAction = () => {
      const isActionRestricted = isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.CONNECTORS
      );

      if (currentTab.value === ConnectorsPageTabs.PSA) {
        const dialogConfig = componentDialogsConfigConstructor({
          action: ConnectorActions.ADD_PSA,
          component: AddPsaConnectorModal,
          width: ModalWidth.LARGE,
          callback: () => {},
          disable: isActionRestricted,
        });

        dialogsStore.openDialog({
          ...dialogConfig,
          header: {
            title: i18n.t("connectors.psa.addPsaConnector.title"),
            close: true,
          },
          footer: null,
        });

        return;
      }

      if (currentTab.value === ConnectorsPageTabs.API_KEYS) {
        const dialogConfig = componentDialogsConfigConstructor({
          action: ConnectorActions.ADD_API_KEY,
          component: AddApiKey,
          width: ModalWidth.MEDIUM,
          disable: isActionRestricted,
          callback: addApiKey,
        });

        dialogsStore.openDialog({
          ...dialogConfig,
          header: {
            title: i18n.t("modals.addApiKey.title"),
            close: true,
          },
          footer: null,
          persistOnAction: true,
        });

        return;
      }

      if (currentTab.value === ConnectorsPageTabs.SIEM) {
        const dialogConfig = componentDialogsConfigConstructor({
          action: ConnectorActions.ADD_SIEM,
          component: AddSiemConnectorModal,
          width: ModalWidth.MEDIUM,
          callback: addSiemConnector,
          disable: isActionRestricted,
        });

        dialogsStore.openDialog({
          ...dialogConfig,
          header: {
            title: i18n.t("modals.addSiemConnector.title"),
            close: true,
          },
        });

        return;
      }

      if (currentTab.value === ConnectorsPageTabs.WEBHOOKS) {
        const dialogConfig = componentDialogsConfigConstructor({
          item: {},
          action: WebhookAction.ADD,
          component: AddWebhookModal,
          width: ModalWidth.MEDIUM,
          callback: webhooksStore.addWebhook,
          scrollable: true,
        });

        dialogsStore.openDialog({
          ...dialogConfig,
          header: {
            title: i18n.t("modals.addWebhook.title"),
            close: true,
          },
        });

        return;
      }
    };
    const route = useRoute();
    const path = computed(() => route.path);
    const currentCreationButtonText = computed(() => {
      switch (currentTab.value) {
        case ConnectorsPageTabs.API_KEYS: {
          return i18n.t("connectors.apiKeys.add");
        }
        case ConnectorsPageTabs.WEBHOOKS: {
          return i18n.t("connectors.webhooks.add");
        }
        default: {
          return i18n.t("connectors.siem.add");
        }
      }
    });
    const tabs: CoroTab[] = [
      {
        id: 0,
        name: i18n.t("connectors.tabs.apiKeys"),
        route: ConnectorsPageTabs.API_KEYS,
      },
      {
        id: 1,
        name: i18n.t("connectors.tabs.webhooks"),
        route: ConnectorsPageTabs.WEBHOOKS,
      },
    ];

    const currentTab = ref(ConnectorsPageTabs.SIEM);

    if (path.value.includes(ConnectorsPageTabs.API_KEYS)) {
      currentTab.value = ConnectorsPageTabs.API_KEYS;
    } else if (path.value.includes(ConnectorsPageTabs.PSA)) {
      currentTab.value = ConnectorsPageTabs.PSA;
    }

    if (account.workspaceType === WorkspaceType.CHANNEL) {
      tabs.push({
        id: 2,
        name: i18n.t("connectors.tabs.psa"),
        route: ConnectorsPageTabs.PSA,
      });
    }

    tabs.push({
      id: 3,
      name: i18n.t("connectors.tabs.siem"),
      route: ConnectorsPageTabs.SIEM,
    });

    const shouldShowActionButton = () => {
      switch (currentTab.value) {
        case ConnectorsPageTabs.API_KEYS:
          return apiKeys.value.length;
        case ConnectorsPageTabs.PSA:
          return connectors.value.length;
        case ConnectorsPageTabs.SIEM:
          return siemConnectors.value.length;
        case ConnectorsPageTabs.WEBHOOKS:
          return webhooks.value.length;
        default:
          return false;
      }
    };

    const onTabUpdate = (tab: CoroTab) => {
      currentTab.value = tab.route as ConnectorsPageTabs;
    };

    return {
      breadCrumbsItems: [
        {
          title: i18n.t("general.backToControlPanel"),
          disabled: false,
          to: { path: "/portal/settings" },
        },
      ],
      coronetSkeletonLoaderTypes,
      currentTab,
      onTabUpdate,
      currentCreationButtonText,
      handleCurrentTabAction,
      connectors,
      tabs,
      loading,
      shouldShowActionButton,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(*) {
  tr {
    height: 54px;
  }
}

.icon-dots:before {
  color: rgb(var(--v-theme-primary)) !important;
}

.coronet-icon {
  height: 40px;
  width: 40px;
}

.status-column {
  width: 200px;
}
</style>

<template>
  <div class="content-md margin-auto mt-4">
    <div class="mb-3">
      <v-breadcrumbs :items="breadcrumbsItems" />
    </div>

    <div class="d-flex align-center justify-space-between mb-4">
      <div class="d-flex align-center">
        <span class="headline5" data-testid="devices-page-title">
          {{ title }}
        </span>
        <v-chip class="ml-2 chip--counter">
          {{ totalDevices }}
        </v-chip>
      </div>
    </div>

    <div class="d-flex justify-end mb-4">
      <filter-wrapper
        :show-clear-button="showClearFiltersButton"
        @clear-filters-clicked="clearFilters(clearFiltersCallback)"
      >
        <div class="d-flex align-center">
          <v-select
            v-model="localFilters.labels"
            :items="labelItems"
            bg-color="white"
            menu-icon="icon-triangle"
            item-value="id"
            item-title="name"
            :menu-props="{ maxHeight: '300' }"
            class="label-filter filter-menu mr-2"
            :class="{ 'filter-active': localFilters.labels?.length }"
            :placeholder="$t('devices.filters.groups')"
            :no-data-text="$t('devices.filters.label.noData')"
            data-testid="devices-page-label-filter"
            density="compact"
            variant="outlined"
            multiple
            rounded
            hide-details
            background-color="white"
            return-object
          >
            <template #selection="{ index }">
              <span v-if="index === 0" class="body2">{{ $t("devices.filters.groups") }}</span>
            </template>
            <template #label>
              <span v-if="localFilters.labels?.length" class="filter-label">
                {{ localFilters.labels.length }}
              </span>
            </template>
            <template #item="{ item, props }">
              <v-list-subheader v-if="'subheader' in item.raw" :title="item.raw.name" />
              <v-divider v-else-if="'divider' in item.raw" v-bind="props" />
              <v-list-item v-else v-bind="props" title="">
                <template v-slot:prepend="{ isActive }">
                  <v-list-item-action start>
                    <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
                  </v-list-item-action>
                </template>

                <v-list-item-title
                  :data-testid="`devices-page-label-filter-${item}-item`"
                  class="d-flex align-center label-popup-block"
                  title=""
                >
                  <span
                    class="color-preview mr-2"
                    :style="{ 'background-color': item.raw.color }"
                  />
                  <span>{{ item.raw.name }}</span>
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
          <v-select
            v-model="localFilters.osVersion"
            :items="osVersions"
            :menu-props="{ maxHeight: '300' }"
            class="os-version-filter filter-menu mr-2 fit"
            :class="{ 'filter-active': localFilters.osVersion }"
            :placeholder="$t('devices.filters.osVersion')"
            data-testid="devices-page-os-version-filter"
            density="compact"
            item-value="name"
            item-title="name"
            rounded
            hide-details
            background-color="white"
          >
            <template #selection="{ item }">
              <span class="body2">
                {{ item.title }}
              </span>
            </template>
            <template #item="{ item, props }">
              <v-list-subheader v-if="item.raw.subheader" :title="item.raw.name" />
              <v-list-item
                v-else
                :data-testid="`devices-page-os-version-filter-${item}-item`"
                v-bind="props"
              >
              </v-list-item>
            </template>
          </v-select>
          <v-select
            v-model="localFilters.clientVersion"
            :items="clientVersions"
            :menu-props="{ maxHeight: '300' }"
            class="agent-version-filter filter-menu mr-2 fit"
            :class="{ 'filter-active': localFilters.clientVersion }"
            :placeholder="$t('devices.filters.clientVersion')"
            data-testid="devices-page-client-version-filter"
            density="compact"
            item-value="name"
            item-title="name"
            outlined
            rounded
            hide-details
            background-color="white"
          >
            <template #selection="{ item }">
              <span class="body2">
                {{ `Coro ${item.title}` }}
              </span>
            </template>
            <template #item="{ item, props }">
              <v-list-subheader v-if="item.raw.subheader" :title="item.raw.name" />
              <v-list-item
                v-else
                v-bind="props"
                :data-testid="`devices-page-client-version-filter-${item}-item`"
              >
              </v-list-item>
            </template>
          </v-select>
          <v-select
            v-model="localFilters.vulnerabilities"
            :items="Object.values(DeviceVulnerability)"
            :menu-props="{ maxHeight: '300' }"
            class="vulnerabilities-filter filter-menu mr-2"
            :class="{
              'filter-active': localFilters.vulnerabilities && localFilters.vulnerabilities.length,
            }"
            :placeholder="$t('devices.filters.vulnerability')"
            data-testid="devices-page-vulnerabilities-filter"
            density="compact"
            outlined
            multiple
            rounded
            hide-details
            background-color="white"
          >
            <template #selection="{ index }">
              <span v-if="index === 0" class="body2">{{
                $t("devices.filters.vulnerability")
              }}</span>
            </template>
            <template #label>
              <span v-if="localFilters.vulnerabilities?.length" class="filter-label">{{
                localFilters.vulnerabilities.length
              }}</span>
            </template>
            <template #item="{ item, props }">
              <v-list-item
                v-bind="props"
                :title="$t(`tickets.eventTriggers.${camelCase(item.raw)}`)"
              >
                <template v-slot:prepend="{ isActive }">
                  <v-list-item-action start>
                    <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </template>
          </v-select>
          <v-combobox
            v-model="localFilters.search"
            variant="outlined"
            clearable
            clear-icon="icon-x"
            background-color="white"
            :items="suggestions"
            :placeholder="$t('general.search')"
            class="search-field"
            hide-details
            density="compact"
            rounded
            bg-color="white"
            menu-icon=""
            persistent-clear
            data-testid="tickets-page-search-field"
            :class="{
              'filter-active': localFilters.search,
            }"
          >
          </v-combobox>
        </div>
      </filter-wrapper>
    </div>
    <list-with-preview :show-skeleton-loader="showSkeletonLoader">
      <template #list>
        <devices-list-table
          :items="devices"
          :total-items="totalDevices"
          :loading="loading"
          :pagination="pagination"
          @selection-changed="setSelection($event)"
          @page-changed="onPageChange"
          @update-preview="onPreviewUpdate($event)"
        ></devices-list-table>
      </template>
      <template #preview>
        <device-preview :device="preview"></device-preview>
      </template>
    </list-with-preview>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from "vue";
import { useSelectorStore } from "@/_store/selector.module";
import { FilterContext, useFiltersStore } from "@/_store/filters.module";
import { useI18n } from "vue-i18n";
import { useFilters } from "@/composables/useFilters";
import { storeToRefs } from "pinia";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { type DeviceListItem, useDevicesStore } from "@/_store/devices.module";
import { useDevicesSettingsStore } from "@/_store/devices-settings.module";
import FilterWrapper from "@/components/FilterWrapper.vue";
import DevicePreview from "@/components/devices/DevicePreview.vue";
import DevicesListTable from "@/components/tables/DevicesListTable.vue";
import { camelCase } from "lodash";
import ListWithPreview from "@/components/ListWithPreview.vue";
import type { Pagination } from "@/types";
import { SearchSuggestion } from "@/constants/general";
import { DeviceVulnerability, OsType } from "@/constants/devices";
import isEqual from "lodash/isEqual";

const filterContext = FilterContext.DEVICES;
const suggestions = [SearchSuggestion.TICKET_ID];
export default defineComponent({
  components: {
    ListWithPreview,
    DevicesListTable,
    DevicePreview,
    FilterWrapper,
  },
  props: {
    hasVulnerabilities: {
      type: String,
      required: false,
    },
    search: {
      type: String,
      required: false,
    },
    widget: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const devicesStore = useDevicesStore();
    const selectorStore = useSelectorStore();
    const filtersStore = useFiltersStore();
    const devicesSettingsStore = useDevicesSettingsStore();
    const i18n = useI18n();
    const preview = ref<DeviceListItem | null>(null);
    const osVersions = ref<Array<{ subheader?: true; name: string }>>([]);
    const clientVersions = ref<Array<{ subheader?: true; name: string }>>([]);

    const { localFilters, filtersUpdating, showClearFiltersButton, clearFilters } =
      useFilters(filterContext);

    const { setSelection, deselectAllPages } = selectorStore;

    const {
      loading,
      devices,
      totalDevices,
      pagination,
      deviceDetailsLoading,
      showSkeletonLoader,
      osVersions: osVersionsFromStore,
      clientVersions: clientVersionsFromStore,
    } = storeToRefs(devicesStore);

    const { selection } = storeToRefs(selectorStore);

    const devicesFilters = computed(() => {
      return filtersStore.filters.devicesFilters;
    });

    const labelItems = computed(() => {
      const labels = devicesSettingsStore.labels ?? [];
      const statuses = devicesStore.statuses ?? [];
      const result = [];
      if (statuses.length) {
        result.push({ subheader: true, name: i18n.t("devices.filters.status") }, ...statuses, {
          divider: true,
        });
      }
      result.push({ subheader: true, name: i18n.t("devices.filters.labels") }, ...labels);
      return result;
    });

    const title = computed(() => {
      if (devicesFilters.value.hasVulnerabilities === false) {
        return i18n.t("devices.advancedTitle", {
          n: i18n.t("devices.noIssues"),
        });
      }
      if (devicesFilters.value.hasVulnerabilities === true) {
        return i18n.t("devices.advancedTitle", {
          n: i18n.t("devices.criticalIssues"),
        });
      }
      return i18n.t("devices.defaultTitle");
    });

    const onPreviewUpdate = (item: DeviceListItem) => {
      preview.value = item ? { ...item } : null;
    };
    watch(
      filtersUpdating,
      async (value) => {
        if (value) {
          devicesStore.resetPagination();
          await devicesStore.getDevices();
        }
      },
      { deep: true, immediate: true }
    );

    onMounted(async () => {
      const filtersWithQuery = {
        ...{ ...localFilters.value },
        hasVulnerabilities: props.hasVulnerabilities ? props.hasVulnerabilities === "true" : null,
        search: props.search ? props.search : localFilters.value.search,
        widget: props.widget ?? null,
      };
      await devicesStore.init();
      populateVersionsList();
      // If filters are not equal, then update filters which triggers the watcher `filtersUpdating`
      if (!isEqual(filtersWithQuery, localFilters.value)) {
        localFilters.value = filtersWithQuery;
      } else {
        // else - do request manually
        await devicesStore.getDevices(true);
      }
    });

    const onPageChange = async (pagination: Pagination) => {
      devicesStore.setPagination(pagination);
      await devicesStore.getDevices();
    };

    function populateVersionsList() {
      const osTypesForVersions = [OsType.WINDOWS, OsType.OSX, OsType.IOS, OsType.ANDROID] as const;
      osVersions.value = [];
      clientVersions.value = [];
      osTypesForVersions.forEach((neededVersion) => {
        const lowercaseVersion = neededVersion.toLowerCase() as Lowercase<
          Exclude<OsType, OsType.MAC_OS>
        >;
        if (osVersionsFromStore.value[lowercaseVersion]?.length) {
          osVersions.value.push(
            {
              subheader: true,
              name: i18n.t(`general.osVersion.${lowercaseVersion}`),
            },
            ...(osVersionsFromStore.value[lowercaseVersion] ?? []).map((v) => ({ name: v }))
          );
        }

        if (clientVersionsFromStore.value?.[lowercaseVersion]?.length) {
          clientVersions.value.push(
            {
              subheader: true,
              name: i18n.t(`general.osVersion.${lowercaseVersion}`),
            },
            ...(clientVersionsFromStore.value[lowercaseVersion] ?? [])
              .slice()
              .sort()
              .reverse()
              .map((v) => ({ name: v }))
          );
        }
      });
    }

    const clearFiltersCallback = async () => {
      await devicesStore.getDevices();
      populateVersionsList();
    };

    onUnmounted(() => {
      filtersStore.resetFilters(FilterContext.DEVICES);
      deselectAllPages();
    });

    return {
      DeviceVulnerability,
      breadcrumbsItems: [
        {
          title: `‹ ${i18n.t("general.home")}`,
          disabled: false,
          to: { path: "/portal/dashboard" },
        },
      ],
      coronetSkeletonLoaderTypes,
      preview,
      loading,
      devices,
      totalDevices,
      pagination,
      deviceDetailsLoading,
      selection,
      title,
      localFilters,
      labelItems,
      showSkeletonLoader,
      filtersUpdating,
      showClearFiltersButton,
      clearFilters,
      setSelection,
      suggestions,
      osVersions,
      clientVersions,
      onPreviewUpdate,
      onPageChange,
      clearFiltersCallback,
      camelCase,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(*) {
  .v-chip-group .v-chip {
    min-width: 32px;
  }

  .clear-btn {
    z-index: 0 !important;
  }

  .label-popup-block span {
    flex: unset !important;
  }
}

.search-field {
  max-width: 280px;
}

.agent-version-filter {
  min-width: 135px !important;
}

.vulnerabilities-filter {
  min-width: 142px !important;
}

.label-filter {
  width: 105px !important;
}

.os-version-filter {
  min-width: 125px !important;
}

.os-type-filter {
  width: 117px !important;
}

.isolation-status-filter {
  width: 154px !important;
}

.color-preview {
  width: 20px !important;
  height: 20px !important;
  min-width: 20px !important;
  border-radius: 2px !important;
}
</style>

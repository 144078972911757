<template>
  <div>
    <div v-if="mitreData.length" class="mt-5">
      <div class="d-flex align-center mb-4">
        <div class="headline6">{{ $t("ticketDetails.fullDetails.edrAffectedDevices.mitre") }}</div>
        <v-chip class="ml-2 chip--counter">
          {{ mitreData.length }}
        </v-chip>
      </div>
      <v-table>
        <template #default>
          <tbody>
            <template v-for="(item, index) in mitreData" :key="`row-${index}`">
              <tr :id="`${index}`">
                <td class="pt-2 pb-2">
                  <div class="subtitle1">{{ item.title }}</div>
                  <div class="body2">{{ item.description }}</div>
                  <a class="coro-link" target="_blank" :href="item.link">
                    {{ item.techniqueId }}
                  </a>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-table>
    </div>
    <!--    &lt;!&ndash;    @TODO uncomment when time will come&ndash;&gt;-->
    <!--    <div v-if="insights.length" class="mt-11">-->
    <!--          <div class="d-flex align-center mb-4">-->
    <!--            <div class="subtitle1">{{ $t("ticketDetails.fullDetails.edrAffectedDevices.insights") }}</div>-->
    <!--            <v-chip class="ml-2 chip&#45;&#45;counter">-->
    <!--              {{ insights.length }}-->
    <!--            </v-chip>-->
    <!--          </div>-->
    <!--          <v-simple-table>-->
    <!--            <template #default>-->
    <!--              <tbody>-->
    <!--                <template v-for="(item, index) in insights">-->
    <!--                  <tr :id="`${index}`" :key="`row-${index}`">-->
    <!--                    <td class="pt-2 pb-2">-->
    <!--                      <div class="coro-body2">{{ item }}</div>-->
    <!--                    </td>-->
    <!--                  </tr>-->
    <!--                </template>-->
    <!--              </tbody>-->
    <!--            </template>-->
    <!--          </v-simple-table>-->
    <!--        </div>-->
    <div v-if="edrAffectedDevices.length" class="mt-11">
      <div class="d-flex align-center mb-4">
        <div class="headline6">{{ $t("ticketDetails.findings") }}</div>
        <v-chip class="ml-2 chip--counter">
          {{ edrAffectedDevicesTotal }}
        </v-chip>
      </div>
      <table-wrapper>
        <v-data-table-server
          :headers="tableHeaders"
          :items="edrAffectedDevices"
          :single-expand="false"
          v-model:expanded="expanded"
          :loading="edrAffectedDevicesLoading"
          disable-pagination
          hide-default-footer
          :items-length="edrAffectedDevicesTotal"
          item-value="enrollmentCode"
          show-expand
          fixed-header
          expand-icon="icon-chevron"
        >
          <template #headers>
            <tr>
              <th v-if="showExpander" class="w-10">
                <v-fab size="24" icon="expand-all-icon" color="primary" class="expand-all-icon">
                  <v-icon
                    :class="{ rotated: expandAll }"
                    @click="expandAll = !expandAll"
                    icon="$chevronDown"
                  ></v-icon>
                </v-fab>
              </th>
              <th class="w-80">
                {{ $t("ticketDetails.fullDetails.edrAffectedDevices.device") }}
              </th>
              <th>
                {{ $t("ticketDetails.fullDetails.edrAffectedDevices.executionTime") }}
              </th>
              <th>
                {{ $t("ticketDetails.fullDetails.edrAffectedDevices.processGraph") }}
              </th>
            </tr>
          </template>
          <template #item="{ item, index, isExpanded, toggleExpand, internalItem }">
            <tr :id="`${index}`">
              <td v-if="showExpander" class="w-10">
                <v-icon
                  class="ml-1"
                  :class="{ rotated: isExpanded(internalItem) }"
                  @click="toggleExpand(internalItem)"
                  icon="$chevronDown"
                >
                </v-icon>
              </td>
              <td class="w-80 pb-2 pt-2">
                <div class="d-flex justify-center flex-column">
                  <div class="subtitle1">
                    {{ item.hostname }}
                  </div>
                  <div class="body2">
                    {{ item.processName }}
                  </div>
                  <div class="body2 text-indigo-medium">
                    {{ item.userName }}
                  </div>
                </div>
              </td>
              <td>
                <span class="body2 text-indigo-medium">{{
                  getFormattedDateTime(item.executionTime, "MMM, DD YYYY, h:mm A")
                }}</span>
              </td>
              <td>
                <div
                  v-if="item.hasProcessGraphData"
                  class="coro-link"
                  @click="viewProcessGraph(item)"
                >
                  {{ `${$t("general.view")} ›` }}
                </div>
              </td>
            </tr>
          </template>
          <template #expanded-row="{ columns, item }">
            <td class="w-10"></td>
            <td :colspan="columns.length" class="pl-4 word-break-all white-space-normal">
              <div class="expandable-content">
                <div class="mr-4 mt-6 mb-6 instance-details">
                  <div class="subtitle1">
                    {{ $t("ticketDetails.fullDetails.edrAffectedDevices.commandLine") }}
                  </div>
                  <ellipsified-copy-text
                    max-width="500px"
                    class="body2 text-indigo-medium"
                    :text="item.commandLine"
                  />
                  <div class="subtitle1 mt-4">
                    {{ $t("ticketDetails.fullDetails.edrAffectedDevices.path") }}
                  </div>
                  <ellipsified-copy-text
                    max-width="500px"
                    class="body2 text-indigo-medium"
                    :text="item.filePath"
                  />
                </div>
              </div>
            </td>
          </template>
        </v-data-table-server>
      </table-wrapper>
      <div v-if="findingsToLoad > 0" class="load-more-section">
        <div class="coro-link cursor-pointer" @click="loadMoreItems()">
          {{ $t("ticketDetails.plusNFindings", { n: findingsToLoad }) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { getFormattedDateTime } from "@/_helpers/utils";
import EllipsifiedCopyText from "@/components/EllipsifiedCopyText.vue";
import { computed, defineComponent, onMounted, type PropType, ref, watch } from "vue";
import type { TicketDetails } from "@/_store/tickets/tickets.module";
import { type EdrDetectionAffectedDevice, useTicketStore } from "@/_store/tickets/ticket.module";
import { storeToRefs } from "pinia";
import TableWrapper from "@/components/TableWrapper.vue";
import { useRouter } from "vue-router";
import { RouteName } from "@/constants/routes";

export default defineComponent({
  components: {
    EllipsifiedCopyText,
    TableWrapper,
  },
  props: {
    ticket: {
      type: Object as PropType<TicketDetails>,
      required: true,
    },
  },
  setup(props) {
    const ticketStore = useTicketStore();
    const router = useRouter();
    const { edrAffectedDevices, edrAffectedDevicesLoading, edrAffectedDevicesTotal } =
      storeToRefs(ticketStore);
    const page = ref(0);
    const expanded = ref<string[]>([]);
    const expandAll = ref(false);

    const mitreData = computed(() => {
      return props.ticket.sections?.edrMetaData?.mitre ?? [];
    });

    const showExpander = computed(() => {
      return props.ticket.sections?.edrMetaData?.showExpandedFindings;
    });

    const findingsToLoad = computed(() => {
      return edrAffectedDevicesTotal.value - (edrAffectedDevices.value ?? []).length;
    });

    const viewProcessGraph = (item: EdrDetectionAffectedDevice) => {
      router.push({
        name: RouteName.PROCESS_GRAPH,
        query: {
          enrollmentCode: item.enrollmentCode,
          processHash: item.processHash,
          processCreationTime: item.processCreationTime,
          pid: item.pid,
          eventId: props.ticket.eventId,
        },
      });
    };

    const getItems = async (virtualScroll = false) => {
      await ticketStore.getEdrAffectedDevices({
        page: page.value,
        virtualScroll,
      });
    };

    const loadMoreItems = () => {
      page.value += 1;
      getItems(true);
    };

    onMounted(async () => {
      await getItems();
    });

    watch(expandAll, (newVal) => {
      if (newVal) {
        expanded.value = [...edrAffectedDevices.value.map((v) => v.enrollmentCode)];
      } else {
        expanded.value = [];
      }
    });

    return {
      tableHeaders: [
        { title: "", key: "data-table-expand", sortable: false },
        {
          key: "name",
          sortable: false,
        },
        { key: "execution-time", sortable: false },
      ],
      page,
      expanded,
      expandAll,
      edrAffectedDevices,
      edrAffectedDevicesLoading,
      edrAffectedDevicesTotal,
      mitreData,
      showExpander,
      getFormattedDateTime,
      findingsToLoad,
      loadMoreItems,
      viewProcessGraph,
    };
  },
});
</script>

<style lang="scss" scoped>
.expand-all-icon {
  .v-icon::before {
    color: rgb(var(--v-theme-white)) !important;
  }
}

.load-more-section {
  border-left: 1px solid rgb(var(--v-theme-indigo-pale));
  border-right: 1px solid rgb(var(--v-theme-indigo-pale));
  border-bottom: 1px solid rgb(var(--v-theme-indigo-pale));
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(*) {
  .v-data-table__expanded__content {
    box-shadow: unset !important;
  }

  .v-btn--fab.v-size--small {
    height: 30px;
    width: 30px;
  }
  .v-btn {
    padding: 0 !important;
  }

  .v-table__wrapper {
    box-shadow: unset !important;
    border-radius: unset !important;
    border: 1px solid rgb(var(--v-theme-indigo-faint));
  }

  .full-details-wrapper {
    border: none !important;
  }

  .findings-wrapper {
    padding: 0 !important;
  }
}
</style>

<template>
  <div v-if="records.length">
    <h6 class="headline6 mb-4">
      {{ $t("ticketDetails.fullDetails.title") }}
    </h6>
    <div class="full-details-wrapper">
      <v-data-table-server
        :items="sortedRecords"
        :single-expand="singleExpand"
        v-model:expanded="expanded"
        hide-default-footer
        item-value="uniqueId"
        show-expand
        fixed-header
        :items-length="sortedRecords.length"
      >
        <template #headers>
          <tr>
            <th></th>
            <th class="text-left" data-testid="tickets-page-anomaly-detection-type">
              {{ $t("ticketDetails.fullDetails.anomalyDetection.type") }}
            </th>
            <th class="text-center" data-testid="tickets-page-anomaly-detection-time">
              {{ $t("ticketDetails.fullDetails.anomalyDetection.time") }}
            </th>
            <th class="text-center" data-testid="tickets-page-anomaly-detection-country-ip">
              {{ $t("ticketDetails.fullDetails.anomalyDetection.countryAndIp") }}
            </th>
          </tr>
        </template>
        <template #item="{ item, toggleExpand, isExpanded, internalItem, index }">
          <tr :id="`${index}`">
            <td class="w-10">
              <v-icon
                v-if="shouldHaveExpander(item)"
                class="ml-1 text-primary"
                icon="$dropdown"
                :class="{ rotated: isExpanded(internalItem) }"
                @click="toggleExpand(internalItem)"
              >
              </v-icon>
            </td>
            <td class="pa-4 pl-4 text-no-wrap">
              <div
                class="inner-circle mr-4"
                v-if="ticket.eventType !== TicketType.USER_INACTIVITY"
                :style="getCircleStyle(item.suspicious)"
              ></div>
              <span
                class="subtitle2"
                :data-testid="`tickets-page-anomaly-detection-type-row-${index}`"
              >
                {{ $t(`ticketDetails.fullDetails.anomalyDetection.${item.type}`) }}
              </span>
            </td>
            <td class="pa-4">
              <div class="d-flex flex-column justify-center align-center">
                <span
                  class="body2"
                  :data-testid="`tickets-page-anomaly-detection-date-row-${index}`"
                >
                  {{ getFormattedDateTime(item.originalTime, "ddd, MMM, DD") }}
                </span>
                <span
                  class="body2"
                  :data-testid="`tickets-page-anomaly-detection-time-row-${index}`"
                >
                  {{ getFormattedDateTime(item.originalTime, "h:mm A") }}
                </span>
              </div>
            </td>
            <td class="pa-4">
              <div class="d-flex flex-column justify-center align-center">
                <span
                  class="body2"
                  :data-testid="`tickets-page-anomaly-detection-country-row-${index}`"
                >
                  <span v-if="item.regionName">{{ item.regionName }}, </span>
                  {{ item.country }}
                </span>
                <span
                  class="body2"
                  :class="getThreatLevelColor(item.threatLevel)"
                  :data-testid="`tickets-page-anomaly-detection-ip-row-${index}`"
                  >{{ item.ip }}</span
                >
              </div>
            </td>
          </tr>
        </template>
        <template #expanded-row="{ columns, item }">
          <tr>
            <td></td>
            <td :colspan="columns.length - 1" class="pa-4">
              <div v-if="item.orgName">
                <span class="subtitle2">{{
                  $t(`ticketDetails.fullDetails.anomalyDetection.orgName`)
                }}</span>
                <div class="body2">
                  {{ item.orgName }}
                </div>
              </div>
              <div v-if="item.threatDetectionDate" class="mt-4">
                <span class="subtitle2">{{
                  $t(`ticketDetails.fullDetails.anomalyDetection.threatType`)
                }}</span>
                <div class="body2">
                  {{ getThreatType(item.threatTypes) }}
                </div>
                <div class="caption">
                  {{ $t(`ticketDetails.fullDetails.anomalyDetection.lastDetected`) }}
                  {{ getFormattedDateTime(item.threatDetectionDate, "MMM DD, YYYY") }}
                </div>
              </div>
              <div v-if="item.proxyType" class="mt-4">
                <span class="subtitle2">{{
                  $t(`ticketDetails.fullDetails.anomalyDetection.proxy`)
                }}</span>
                <div class="body2">
                  {{ item.proxyType }}
                </div>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table-server>
    </div>
  </div>
</template>

<script lang="ts">
import { uniqueId } from "lodash";
import { getFormattedDateTime } from "@/_helpers/utils";
import { computed, defineComponent, type PropType, ref } from "vue";
import type { AnomalyDetection, TicketDetails } from "@/_store/tickets/tickets.module";
import { AnomalyIpThreatLevel, TicketType } from "@/constants/tickets";
import { getThreatLevelColor } from "@/_store/tickets/adapters";

export default defineComponent({
  props: {
    ticket: {
      type: Object as PropType<TicketDetails>,
      required: true,
    },
  },
  setup(props) {
    const expanded = ref([]);
    const singleExpand = ref(false);
    const records = computed(() => props.ticket.sections?.anomalyDetectionMetaData?.records ?? []);
    const sortedRecords = computed<(AnomalyDetection & { uniqueId: string })[]>(() => {
      return records.value
        .slice()
        .sort((a, b) => b.originalTime - a.originalTime)
        .map((item) => ({
          ...item,
          uniqueId: uniqueId(),
        }));
    });

    const shouldHaveExpander = (detailRecord: AnomalyDetection) => {
      const { orgName, threatDetectionDate, proxyType } = detailRecord;

      return orgName || threatDetectionDate || proxyType;
    };

    const getThreatType = (threatTypes: string[]) => threatTypes.join(", ");

    const getCircleStyle = (suspicious: boolean) => {
      if (suspicious) {
        return {
          "background-color": "rgb(var(--v-theme-red-dark))",
          "border-color": "rgb(var(--v-theme-red-dark))",
        };
      }
      return {
        "background-color": "rgb(var(--v-theme-white))",
        "border-color": "rgb(var(--v-theme-green-light))",
      };
    };

    return {
      getThreatLevelColor,
      records,
      sortedRecords,
      AnomalyIpThreatLevel,
      expanded,
      singleExpand,
      TicketType,
      getFormattedDateTime,
      shouldHaveExpander,
      getThreatType,
      getCircleStyle,
    };
  },
});
</script>

<style lang="scss" scoped>
.full-details-wrapper {
  border: 1px solid rgb(var(--v-theme-indigo-faint));
}
</style>

<template>
  <v-form ref="form" v-model="valid">
    <v-row>
      <v-col :cols="6">
        <v-text-field
          v-model="localValue.item.companyName"
          type="text"
          data-testid="edit-workspace-modal-company-name"
          class="mt-6"
          :rules="companyNameRules"
          variant="outlined"
          :label="$t('modals.editWorkspace.companyName')"
        ></v-text-field>
        <v-text-field
          v-model="localValue.item.displayName"
          type="text"
          data-testid="edit-workspace-modal-display-name"
          class="mt-3"
          counter="30"
          :rules="displayNameRules"
          :label="$t('modals.editWorkspace.displayName')"
          :placeholder="$t('modals.editWorkspace.displayName')"
          :hint="$t('modals.editWorkspace.displayNameHint')"
          persistent-hint
          variant="outlined"
        ></v-text-field>
        <v-text-field
          v-if="showLimitField"
          v-model="localValue.item.maxProtectedUsers"
          :rules="limitProtectedUsersRules"
          type="number"
          data-testid="edit-workspace-modal-limit-protected-users"
          class="mt-3"
          :label="$t('modals.editWorkspace.limitProtectedUsers')"
          :placeholder="$t('modals.editWorkspace.limitProtectedUsersPlaceholder')"
          :hint="$t('modals.editWorkspace.limitProtectedUsersHint')"
          persistent-hint
          variant="outlined"
          @keydown="filterKey"
          @paste.prevent
        ></v-text-field>
        <v-select
          v-model="localValue.item.languageCode"
          :items="Object.values(WorkspaceLocale)"
          class="mt-4"
          :rules="[required()]"
          :label="$t('modals.createWorkspace.language.label')"
          :placeholder="$t('modals.createWorkspace.language.placeholder')"
          persistent-hint
          variant="outlined"
        >
          <template #selection="{ item }">
            <span class="body2">
              {{ $t(`general.localeDisplayNames.${item.raw}`) }}
            </span>
          </template>
          <template #item="{ item, props }">
            <v-list-item v-bind="props" :title="$t(`general.localeDisplayNames.${item.raw}`)">
            </v-list-item>
          </template>
        </v-select>

        <v-switch
          v-if="showBrandingSettings"
          v-model="localValue.item.supportEnabled"
          class="mb-2"
          :ripple="false"
          density="compact"
          hide-details
          color="primary"
          inset
          flat
        >
          <template #label>
            <div class="body2">
              {{ $t("modals.createWorkspace.showSupportLink") }}
            </div>
          </template>
        </v-switch>
        <v-switch
          v-if="showScanUnprotectedUsers"
          v-model="localValue.item.scanUnprotectedUsers"
          class="mb-2"
          :ripple="false"
          hide-details
          density="compact"
          color="primary"
          inset
        >
          <template #label>
            <div class="body2">
              {{ $t("modals.createWorkspace.scanUnprotectedUsers") }}
            </div>
          </template>
        </v-switch>
        <v-switch
          v-model="localValue.item.showDisabledModules"
          class="mb-2"
          :ripple="false"
          color="primary"
          hide-details
          density="compact"
          inset
        >
          <template #label>
            <div class="body2">
              {{ $t("modals.createWorkspace.showDisabledModules") }}
            </div>
          </template>
        </v-switch>
        <v-switch
          v-if="isGlobalAdmin"
          v-model="localValue.item.isCoroEmployeeWorkspace"
          class="mb-2"
          :ripple="false"
          density="compact"
          hide-details
          color="primary"
          inset
          flat
        >
          <template #label>
            <div class="body2">
              {{ $t("modals.createWorkspace.coroEmployeeWorkspace") }}
            </div>
          </template>
        </v-switch>
        <template v-if="!hideNotificationPolicyAndDelete">
          <div class="subtitle1 mt-3 mb-4">
            {{ $t("modals.editWorkspace.notifications") }}
          </div>
          <v-radio-group v-model="localValue.item.notificationsLevel">
            <v-radio
              v-for="notificationLevel in notificationLevels"
              class="mb-2"
              :key="notificationLevel"
              :value="notificationLevel"
              :data-testid="`edit-workspace-modal-${notificationLevel}-radio`"
            >
              <template #label>
                <span
                  class="body1 notification-label"
                  v-html="
                    getNotificationLevelText(
                      localValue.item.type,
                      notificationLevel,
                      domainDisplayName
                    )
                  "
                ></span>
              </template>
            </v-radio>
          </v-radio-group>
        </template>
        <template v-if="showBrandingSettings">
          <div class="subtitle1 mt-3 mb-4">
            {{ $t("modals.editWorkspace.branding.title") }}
          </div>
          <v-text-field
            v-model="localValue.item.branding.alias"
            type="text"
            data-testid="edit-workspace-modal-alias"
            class="mt-3"
            counter="40"
            :rules="aliasRules"
            :label="$t('modals.editWorkspace.branding.aliasLabel')"
            :placeholder="$t('modals.editWorkspace.branding.aliasLabel')"
            :hint="aliasHint"
            persistent-hint
            variant="outlined"
          ></v-text-field>
          <v-text-field
            v-model="localValue.item.branding.brandColor"
            type="text"
            data-testid="edit-workspace-modal-color-input"
            class="mt-3"
            :rules="brandColorRules"
            :label="$t('modals.editWorkspace.branding.brandColorLabel')"
            :placeholder="$t('modals.editWorkspace.branding.brandColorLabel')"
            :hint="$t('modals.editWorkspace.branding.brandColorHint')"
            persistent-hint
            variant="outlined"
            @update:model-value="localValue.item.branding.brandColor = $event?.toUpperCase()"
          >
            <template v-slot:append-inner>
              <div
                class="color-preview"
                :style="{
                  'background-color': localValue.item.branding.brandColor,
                }"
              ></div>
            </template>
          </v-text-field>
          <v-text-field
            v-model="localValue.item.branding.noReplyEmail"
            type="text"
            data-testid="edit-workspace-modal-no-reply-email"
            class="mt-3"
            readonly
            :label="$t('modals.editWorkspace.branding.noReplyEmailLabel')"
            :placeholder="$t('modals.editWorkspace.branding.noReplyEmailLabel')"
            :hint="$t('modals.editWorkspace.branding.noReplyEmailHint')"
            persistent-hint
            variant="outlined"
          ></v-text-field>
          <v-text-field
            v-model="localValue.item.branding.supportEmail"
            type="text"
            data-testid="edit-workspace-modal-support-email"
            class="mt-3"
            counter="100"
            :rules="supportEmailRules"
            :label="$t('modals.editWorkspace.branding.supportEmailLabel')"
            :placeholder="$t('modals.editWorkspace.branding.supportEmailLabel')"
            :hint="$t('modals.editWorkspace.branding.supportEmailHint')"
            persistent-hint
            variant="outlined"
          ></v-text-field>
          <v-textarea
            v-model="localValue.item.branding.companyAddress"
            type="text"
            data-testid="edit-workspace-modal-company-address"
            class="mt-3"
            :label="$t('modals.editWorkspace.branding.companyAddressLabel')"
            :placeholder="$t('modals.editWorkspace.branding.companyAddressLabel')"
            :hint="$t('modals.editWorkspace.branding.companyAddressHint')"
            persistent-hint
            variant="outlined"
          ></v-textarea>
        </template>
      </v-col>
      <v-col :cols="6">
        <div v-if="showBrandingSettings" class="mt-6 ml-12 pl-6">
          <logo-uploader
            class="mb-5"
            :brand-color="localValue.item.branding.brandColor"
            :default-logo="localValue.item.branding.headerIconUrl"
            @logo-changed="handleImageChange($event)"
          ></logo-uploader>
          <logo-uploader
            color="primary"
            brand-color="#eeeef3"
            :title="$t('logoUploader.emailIcon')"
            :hint="$t('logoUploader.emailImageHint')"
            :default-logo="localValue.item.branding.emailIconUrl || defaultLogoUrl.PRIMARY"
            @logo-changed="handleEmailImageChange($event)"
          ></logo-uploader>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script lang="ts">
import { WorkspaceType, WorkspaceLocale } from "@/constants/workplaces";
import cloneDeep from "lodash/cloneDeep";
import LogoUploader, { defaultLogoUrl } from "../../LogoUploader.vue";
import { computed, defineComponent, onMounted, type PropType, ref, watch } from "vue";
import { useMspWorkspaceModal } from "@/composables/useMspWorkspaceModal";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import type { MspWorkspaceDialogItem } from "@/_store/msp.module";
import { useAccountStore } from "@/_store";
import { storeToRefs } from "pinia";
import { required } from "@/_helpers/validators";

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<DialogDataConfig<MspWorkspaceDialogItem>>,
      required: true,
    },
  },
  components: {
    LogoUploader,
  },
  emits: ["update:localValue", "update:valid"],
  setup(props, { emit }) {
    const accountStore = useAccountStore();
    const { isGlobalAdmin } = storeToRefs(accountStore);
    const localValue = ref(cloneDeep(props.config));
    const valid = ref(true);
    const form = ref();
    const {
      limitProtectedUsersRules,
      domainRules,
      companyNameRules,
      displayNameRules,
      brandColorRules,
      supportEmailRules,
      aliasRules,
      filterKey,
      aliasHint,
      notificationLevels,
      getNotificationLevelText,
    } = useMspWorkspaceModal(localValue);

    const showBrandingSettings = computed(
      () =>
        props.config.item.type === WorkspaceType.CHANNEL &&
        isGlobalAdmin.value &&
        !props.config.item.parentDomain
    );
    const showLimitField = computed(
      () => props.config.item.type === WorkspaceType.CHILD && accountStore.hasMspPermissions
    );
    const hideNotificationPolicyAndDelete = computed(
      () => isGlobalAdmin.value && props.config.item.type !== WorkspaceType.REGULAR
    );
    const domainDisplayName = computed(() => {
      return localValue.value.item.type === WorkspaceType.REGULAR
        ? "coro.net"
        : localValue.value.item.parentDomain;
    });

    const showScanUnprotectedUsers = computed(() => {
      if (localValue.value.item.type === WorkspaceType.CHILD) {
        return localValue.value.item.parentScanUnprotectedUsers;
      }
      return showBrandingSettings.value;
    });

    const validate = async () => {
      const validationResult = await form.value?.validate();
      return validationResult?.valid;
    };

    const handleImageChange = (image: File) => {
      localValue.value.item.image = image;
    };
    const handleEmailImageChange = (image: File) => {
      localValue.value.item.emailImage = image;
    };

    watch(
      () => localValue.value.item.branding.alias,
      (alias) => {
        localValue.value.item.branding.noReplyEmail = alias ? `no-reply@${alias}.coro.net` : "";
      }
    );

    watch(
      localValue,
      (newVal) => {
        emit("update:localValue", newVal);
      },
      { deep: true }
    );

    watch(valid, (value: boolean | null) => {
      if (value === null) {
        return;
      }
      emit("update:valid", value);
    });

    onMounted(() => {
      emit("update:localValue", localValue.value);
    });

    return {
      valid,
      form,
      validate,
      limitProtectedUsersRules,
      domainRules,
      companyNameRules,
      displayNameRules,
      brandColorRules,
      supportEmailRules,
      aliasRules,
      showBrandingSettings,
      showLimitField,
      hideNotificationPolicyAndDelete,
      domainDisplayName,
      aliasHint,
      showScanUnprotectedUsers,
      handleImageChange,
      handleEmailImageChange,
      filterKey,
      localValue,
      defaultLogoUrl,
      notificationLevels,
      isGlobalAdmin,
      WorkspaceLocale,
      required,
      getNotificationLevelText,
    };
  },
});
</script>

<style lang="scss" scoped>
.v-switch {
  .v-label {
    opacity: 1 !important;
  }
}

.color-preview {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

.notification-label {
  width: 500px;
}
</style>

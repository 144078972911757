<template>
  <v-stepper-vertical hide-actions v-model="currentStep">
    <template v-slot:default>
      <v-stepper-vertical-item
        value="1"
        :elevation="0"
        :title="$t('service.boxEnforcement.stepOne.title')"
      >
        <div>
          <div
            class="body2 mt-2"
            data-testid="box-step1-description"
            v-html="$t('service.boxEnforcement.stepOne.description')"
          ></div>
          <div class="mt-5">
            <v-btn
              rounded
              color="primary"
              width="120"
              data-testid="box-step1-continue-btn"
              @click="currentStep++"
            >
              {{ $t("cloudApplications.buttons.continue") }}
            </v-btn>
          </div>
        </div>
      </v-stepper-vertical-item>

      <v-stepper-vertical-item
        value="2"
        :elevation="0"
        :title="$t('service.boxEnforcement.stepTwo.title')"
      >
        <v-card variant="flat">
          <v-form ref="form" v-model="boxStep2Valid" class="mt-4" lazy-validation>
            <v-file-input
              :label="$t('service.boxEnforcement.stepTwo.label')"
              accept=".json"
              :rules="boxRules"
              variant="outlined"
              prepend-icon=""
              clear-icon="icon-x"
              @update:modelValue="uploadConfig($event as File)"
            >
            </v-file-input>
            <div class="mt-5">
              <v-btn
                :loading="fileLoading"
                rounded
                color="primary"
                width="120"
                data-testid="box-step2-confirm-btn"
                @click="connectBox()"
              >
                {{ $t("general.confirm") }}
              </v-btn>
              <v-btn
                variant="text"
                color="primary"
                data-testid="box-step2-back-btn"
                @click="currentStep--"
              >
                {{ $t("general.back") }}
              </v-btn>
            </div>
          </v-form>
        </v-card>
      </v-stepper-vertical-item>
    </template>
  </v-stepper-vertical>
</template>

<script lang="ts">
import { defineComponent, ref, type PropType } from "vue";
import { useCloudAppsStore } from "@/_store/cloud-apps/cloud-apps.module";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { Service, ServiceStatus } from "@/constants/cloud-apps";
import type { ConnectAppModalConfig } from "@/views/settings/cloud-apps/CloudAppsPage.vue";
import { useAccountStore } from "@/_store";

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<ConnectAppModalConfig>,
      required: true,
    },
  },
  emits: ["complete-connection"],
  setup(props, { emit }) {
    const cloudAppsModule = useCloudAppsStore();
    const { completeServiceConnection } = useAccountStore();
    const i18n = useI18n();
    const currentStep = ref(1);
    const fileLoading = ref(false);
    const boxStep2Valid = ref(false);

    const { boxConfigFileError } = storeToRefs(cloudAppsModule);
    const { uploadBoxConfiguration } = cloudAppsModule;

    if (props.config.item.serviceStatus === ServiceStatus.INCOMPLETE) {
      currentStep.value = 2;
    }

    const uploadConfig = async (file: File) => {
      // after clearing input file can be null
      if (file) {
        fileLoading.value = true;
        await uploadBoxConfiguration(file);
        fileLoading.value = false;
      }
    };
    const connectBox = async () => {
      if (boxStep2Valid.value) {
        await completeServiceConnection(props.config.item.name as Service);
        emit("complete-connection");
      }
    };

    return {
      currentStep,
      boxStep2Valid,
      uploadConfig,
      fileLoading,
      connectBox,
      boxRules: [
        (value: File[]) => {
          if (boxConfigFileError.value) {
            return i18n.t("validations.boxConfigurationFile");
          }

          if (value[0]?.type !== "application/json") {
            return i18n.t("validations.json");
          }
          return true;
        },
        (v: File[]) => !!v[0]?.size || i18n.t("validations.required"),
      ],
    };
  },
});
</script>

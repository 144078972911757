<template>
  <div class="pl-4">
    <v-card class="account-form__wrapper pa-4">
      <div class="subtitle1">
        {{ $t("myAccount.notifications.generalNotifications") }}
      </div>
      <v-checkbox
        v-model="localValue.dailyEmailUpdates"
        data-testid="notifications-daily-email-updates-checkbox"
        class="mt-4 ml-13"
        density="compact"
        :label="$t('myAccount.notifications.dailyEmailUpdates')"
      />
      <v-checkbox
        v-model="localValue.criticalTickets"
        data-testid="notifications-critical-tickets-checkbox"
        class="mt-1 ml-13"
        :label="$t('myAccount.notifications.criticalTickets')"
      />
      <v-checkbox
        v-model="localValue.releaseNotes"
        data-testid="release-notes-checkbox"
        class="mt-1 ml-13"
        :label="$t('myAccount.notifications.releaseNotes')"
      />
      <v-checkbox
        v-model="localValue.productAnnouncements"
        data-testid="product-announcements-checkbox"
        class="mt-1 ml-13"
        :label="$t('myAccount.notifications.productAnnouncements')"
      />
      <v-divider class="mt-4 mb-6"></v-divider>
      <div class="subtitle1 mb-2">
        {{ $t("myAccount.notifications.specificNotifications") }}
      </div>
      <template v-for="category in eventTypesConfig" :key="category.header">
        <div class="category-wrapper d-flex align-center">
          <v-icon class="category-icon mr-2">{{ category.icon }}</v-icon>
          <div class="subtitle2">{{ category.header.toUpperCase() }}</div>
        </div>
        <div v-for="type in sortTriggers(category.types)" :key="type">
          <!-- Malware on endpoint and endpoint vulnerability is merged amount of triggers, so they are handled differently -->
          <v-checkbox
            v-if="type === eventTypes.MALWARE_IN_DEVICE"
            v-model="malwareOnEndpoint"
            :data-testid="`notifications-${kebabCase(type)}-checkbox`"
            class="mt-1 ml-13"
            :label="$t(`tickets.eventTypes.${type}`)"
          />
          <v-checkbox
            v-else-if="type === eventTypes.ENDPOINT_VULNERABILITY"
            v-model="endpointVulnerability"
            :data-testid="`notifications-${kebabCase(type)}-checkbox`"
            class="mt-1 ml-13"
            :label="$t(`tickets.eventTypes.${type}`)"
          />
          <v-checkbox
            v-else-if="type === eventTypes.SUSPECTED_COMPLIANCE_VIOLATION_BY_DEVICE"
            v-model="deviceDlpVulnerability"
            :data-testid="`notifications-${kebabCase(type)}-checkbox`"
            class="mt-1 ml-13"
            :label="$t(`tickets.eventTypes.${type}`)"
          />
          <v-checkbox
            v-else
            v-model="localValue.eventTriggers[type]"
            :data-testid="`notifications-${kebabCase(type)}-checkbox`"
            class="mt-1 ml-13"
            :label="$t(`tickets.eventTriggers.${type}`)"
            density="compact"
          />
        </div>
      </template>
    </v-card>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import kebabCase from "lodash/kebabCase";
import type { NotificationsSettings } from "@/_store/my-account.module";
import { useMyAccountStore } from "@/_store/my-account.module";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { debounce } from "lodash";
import { i18n } from "@/plugins/i18n";
import { CoroIcons } from "@/constants/coro-icon";
import { TicketTrigger, TicketType } from "@/constants/tickets";

const endpointVulnerabilities = [
  TicketTrigger.UAC_NOTIFICATIONS_MISSING,
  TicketTrigger.DEVICE_PASSWORD_MISSING,
  TicketTrigger.ENCRYPTION_DISABLED,
  TicketTrigger.DEVELOPMENT_MODE_ENABLED,
  TicketTrigger.FIREWALL_DISABLED,
  TicketTrigger.NON_GENUINE_WINDOWS,
  TicketTrigger.GATEKEEPER_UNAVAILABLE,
  TicketTrigger.APPLE_MOBILE_FILE_INTEGRITY_UNAVAILABLE,
  TicketTrigger.SYSTEM_INTEGRITY_PROTECTION_UNAVAILABLE,
];

const deviceDlpVulnerabilities = [
  TicketTrigger.DEVICE_DLP_NPI,
  TicketTrigger.DEVICE_DLP_PCI,
  TicketTrigger.DEVICE_DLP_PHI,
  TicketTrigger.DEVICE_DLP_PII,
];

const malwareVulnerabilities = [TicketTrigger.MALWARE_ON_ENDPOINT, TicketTrigger.INFECTED_PROCESS];

const eventTypesConfig = [
  {
    header: i18n.global.t("myAccount.notifications.cloudSecurity"),
    icon: `$${CoroIcons.CLOUD_SECURITY}`,
    types: [
      TicketTrigger.ABNORMAL_ADMIN_ACTIVITY,
      TicketTrigger.SUSPECTED_IDENTITY_COMPROMISE,
      TicketTrigger.MALWARE_IN_CLOUD_DRIVE,
      TicketTrigger.MASS_DELETE,
      TicketTrigger.MASS_DOWNLOAD,
      TicketTrigger.ACCESS_PERMISSIONS_VIOLATION,
      TicketTrigger.SUSPECTED_BOT_ATTACKS,
      TicketTrigger.USER_INACTIVITY,
    ],
  },
  {
    header: i18n.global.t("myAccount.notifications.emailSecurity"),
    icon: `$${CoroIcons.EMAILS}`,
    types: [
      TicketTrigger.MALWARE_IN_EMAIL_ATTACHMENTS,
      TicketTrigger.MISSING_REQUIRED_AUTHENTICATION,
      TicketTrigger.BLOCKLISTED_SENDER,
      TicketTrigger.CROWDBLOCKED_SENDER,
      TicketTrigger.USER_IMPERSONATION,
      TicketTrigger.DOMAIN_IMPERSONATION,
      TicketTrigger.BRAND_IMPERSONATION,
      TicketTrigger.SUSPICIOUS_CONTENT,
      TicketTrigger.SPAM,
      TicketTrigger.SUSPICIOUS_METADATA,
      TicketTrigger.FORBIDDEN_ATTACHMENT_TYPE,
      TicketTrigger.DOMAIN_SPOOFING,
      TicketTrigger.REPORTED_BY_USER,
    ],
  },
  {
    header: i18n.global.t("myAccount.notifications.endpointSecurity"),
    icon: `$${CoroIcons.ENDPOINT_SECURITY}`,
    types: [
      TicketType.MALWARE_IN_DEVICE,
      TicketType.ENDPOINT_VULNERABILITY,
      TicketTrigger.WIFI_PHISHING,
      TicketTrigger.FORBIDDEN_NETWORK_CONNECTION,
    ],
  },
  {
    header: i18n.global.t("myAccount.notifications.userDataGovernance"),
    icon: `$${CoroIcons.USER_DATA_GOVERNANCE}`,
    types: [
      TicketTrigger.DLP_PII,
      TicketTrigger.DLP_PHI,
      TicketTrigger.DLP_PCI,
      TicketTrigger.DLP_NPI,
      TicketTrigger.CRITICAL_DATA_KEYWORDS,
      TicketTrigger.CRITICAL_DATA_FILE_TYPES,
      TicketTrigger.CRITICAL_DATA_PASSWORD,
      TicketTrigger.CRITICAL_DATA_CERTIFICATE,
      TicketTrigger.CRITICAL_DATA_SOURCE_CODE,
    ],
  },
  {
    header: i18n.global.t("myAccount.notifications.endpointDataGovernance"),
    icon: `$${CoroIcons.ENDPOINT_DATA_GOVERNANCE}`,
    types: [TicketType.SUSPECTED_COMPLIANCE_VIOLATION_BY_DEVICE],
  },
  {
    header: i18n.global.t("myAccount.notifications.edr"),
    icon: `$${CoroIcons.EDR}`,
    types: [
      TicketTrigger.EDR_DETECTION_COMMAND_AND_CONTROL,
      TicketTrigger.EDR_DETECTION_CREDENTIAL_ACCESS,
      TicketTrigger.EDR_DETECTION_DEFENCE_EVASION,
      TicketTrigger.EDR_DETECTION_DISCOVERY,
      TicketTrigger.EDR_DETECTION_EXECUTION,
      TicketTrigger.EDR_DETECTION_INITIAL_ACCESS,
      TicketTrigger.EDR_DETECTION_PERSISTENCE,
      TicketTrigger.EDR_DETECTION_PRIVILEGE_ESCALATION,
    ],
  },
];

export default defineComponent({
  setup() {
    const i18n = useI18n();
    const myAccountStore = useMyAccountStore();
    const { myAccount } = storeToRefs(myAccountStore);
    const { updateNotificationsSettings } = myAccountStore;
    const localValue = ref<NotificationsSettings>({
      eventTriggers: {},
      couldBeUpdated: false,
      criticalTickets: false,
      dailyEmailUpdates: false,
      releaseNotes: false,
      productAnnouncements: false,
    });

    const malwareOnEndpoint = computed({
      get() {
        return areAllVulnerabilitiesChecked(malwareVulnerabilities);
      },
      set(newVal) {
        updateVulnerabilities(malwareVulnerabilities, newVal);
      },
    });

    const endpointVulnerability = computed({
      get() {
        return areAllVulnerabilitiesChecked(endpointVulnerabilities);
      },
      set(newVal) {
        updateVulnerabilities(endpointVulnerabilities, newVal);
      },
    });

    const deviceDlpVulnerability = computed({
      get() {
        return areAllVulnerabilitiesChecked(deviceDlpVulnerabilities);
      },
      set(newVal) {
        updateVulnerabilities(deviceDlpVulnerabilities, newVal);
      },
    });

    const areAllVulnerabilitiesChecked = (vulnerabilities: string[]) => {
      const vulnerabilitiesChecked = vulnerabilities.reduce((acc, curr) => {
        return localValue.value.eventTriggers?.[curr] ? acc + 1 : acc;
      }, 0);
      return vulnerabilitiesChecked === vulnerabilities.length;
    };

    const updateVulnerabilities = (vulnerabilities: string[], value: boolean) => {
      vulnerabilities.forEach((v) => {
        localValue.value.eventTriggers[v] = value;
      });
    };

    const sortTriggers = (triggers: string[]) => {
      // If $te (Translation exists) take event trigger translation, else event type translation
      const getTriggerOrTypeTranslation = (item: string) =>
        i18n.te(`tickets.eventTriggers.${item}`)
          ? i18n.t(`tickets.eventTriggers.${item}`)
          : i18n.t(`tickets.eventTypes.${item}`);
      return triggers.slice().sort((a, b) => {
        return getTriggerOrTypeTranslation(a).localeCompare(getTriggerOrTypeTranslation(b));
      });
    };

    watch(
      localValue,
      debounce((newVal) => {
        if (!isEmpty(newVal) && !isEqual(newVal, myAccount.value.notificationsSettings)) {
          updateNotificationsSettings(newVal);
        }
      }, 500),
      { deep: true }
    );

    watch(
      () => myAccount.value.notificationsSettings,
      (newVal) => {
        localValue.value = cloneDeep(newVal);
      },
      { deep: true, immediate: true }
    );

    return {
      eventTypes: TicketType,
      localValue,
      eventTypesConfig,
      kebabCase,
      sortTriggers,
      malwareOnEndpoint,
      endpointVulnerability,
      deviceDlpVulnerability,
    };
  },
});
</script>

<style scoped lang="scss">
.category-icon {
  font-size: 40px;
}

.category-wrapper:not(.category-wrapper:first-child) {
  margin-top: 16px;
}
</style>

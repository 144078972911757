<template>
  <div class="content-md margin-auto">
    <div class="mb-3">
      <v-breadcrumbs data-testid="go-home-link" :items="breadCrumbsItems" />
    </div>

    <div class="d-flex align-center justify-space-between mb-1">
      <div class="d-flex align-center">
        <span class="headline5" data-testid="ticket-log-page-title">
          {{ $t("general.ticketLog") }}
        </span>
      </div>
    </div>
    <div class="d-flex justify-space-between align-center mb-6 flex-wrap">
      <v-tabs
        v-model="localFilters.status"
        align-with-title
        class="overflow-visible"
        color="orange-base"
        background-color="white"
        slider-color="orange-base"
        :height="48"
        :show-arrows="false"
      >
        <v-tab
          v-for="item in tabs"
          :key="item.id"
          class="mr-8 opacity-100"
          :height="42"
          :value="item.status"
          variant="plain"
        >
          {{ item.name }}
        </v-tab>
      </v-tabs>
      <div class="d-flex flex-grow-1 justify-end">
        <filter-wrapper
          :show-clear-button="showClearFiltersButton"
          @clear-filters-clicked="clearFilters()"
        >
          <v-select
            v-model="localFilters.widget"
            :items="widgets"
            :menu-props="{ maxHeight: '300' }"
            class="widget-filter filter-menu mr-2"
            :class="{ 'filter-active': localFilters.widget }"
            :placeholder="$t('ticketDetails.filters.widgets.placeholder')"
            data-testid="tickets-page-widget-type-filter"
            density="compact"
            variant="outlined"
            rounded
            hide-details
            background-color="white"
            @update:model-value="localFilters.eventTriggers = []"
          >
            <template #selection="{ item }">
              <span class="body2">
                {{ $t(`ticketDetails.filters.widgets.${item.title}`) }}
              </span>
            </template>
            <template #item="{ item, props }">
              <v-list-item
                v-bind="props"
                :title="$t(`ticketDetails.filters.widgets.${item.title}`)"
                :data-testid="`tickets-page-widget-type-filter-${item}-item`"
              >
              </v-list-item>
            </template>
          </v-select>
          <v-select
            v-model="localFilters.eventTriggers"
            :items="triggers"
            :menu-props="{ maxHeight: '300' }"
            class="filter-menu mr-2"
            :class="{ 'filter-active': !!localFilters.eventTriggers?.length }"
            :placeholder="$t('ticketDetails.filters.type')"
            data-testid="tickets-page-trigger-filter"
            density="compact"
            variant="outlined"
            multiple
            rounded
            hide-details
            background-color="white"
            item-value="value"
            item-title="value"
          >
            <template #selection="{ index }">
              <span v-if="index === 0" class="body2">{{
                $t("manageWorkspaces.filters.type")
              }}</span>
            </template>
            <template #label>
              <span v-if="localFilters.eventTriggers?.length" class="filter-label">
                {{ localFilters.eventTriggers.length }}
              </span>
            </template>
            <template #item="{ item, props }">
              <v-list-subheader v-if="item.raw.type === 'subheader'" :title="item.raw.value" />
              <v-list-item v-else v-bind="props">
                <template v-slot:prepend="{ isActive }">
                  <v-list-item-action start>
                    <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
                  </v-list-item-action>
                </template>

                <template v-slot:title>
                  {{ $t(`tickets.eventTriggers.${item.title}`) }}
                </template>
              </v-list-item>
            </template>
          </v-select>
          <date-range-picker
            v-model="localFilters.eventTimeRange"
            class="daterange-picker mr-2"
            :class="{
              'filter-active': localFilters.eventTimeRange.start && localFilters.eventTimeRange.end,
            }"
            :presets="presets"
            :placeholder="$t('ticketDetails.filters.during')"
            :input-props="{
              'append-icon': 'icon-triangle',
              'hide-details': true,
            }"
            next-icon="icon-expand"
            data-testid="tickets-page-time-range-filter"
            prev-icon="icon-minimize"
            density="compact"
            variant="outlined"
          />
          <v-combobox
            v-model="localFilters.search"
            density="compact"
            variant="outlined"
            filled
            rounded
            clearable
            clear-icon="icon-x"
            background-color="white"
            :items="suggestions"
            :placeholder="$t('general.search')"
            class="search-field"
            prepend-inner-icon="icon-search"
            append-icon=""
            hide-details
            data-testid="tickets-page-search-field"
            @keyup.enter.prevent
            @keydown.enter.prevent
          >
          </v-combobox>
        </filter-wrapper>
      </div>
    </div>

    <list-with-preview :show-skeleton-loader="showLoader">
      <template #list>
        <tickets-list-table
          :items="tickets"
          :total-items="allTickets"
          :selection="selection"
          :pagination="pagination"
          :loading="loading"
          :margin-top="150"
          :tickets-filters="localFilters"
          @page-changed="onPageChanged($event)"
          @update-preview="ticketPreview = $event"
        ></tickets-list-table>
      </template>
      <template #preview>
        <ticket-preview :ticket="ticketPreview"></ticket-preview>
      </template>
    </list-with-preview>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { FilterContext } from "@/_store/filters.module";
import { useTicketsPage } from "@/composables/useTicketsPage";
import ListWithPreview from "@/components/ListWithPreview.vue";
import FilterWrapper from "@/components/FilterWrapper.vue";
import DateRangePicker from "@/components/DateRangePicker.vue";
import TicketsListTable from "@/components/tables/TicketsListTable.vue";
import TicketPreview from "@/views/tickets/TicketPreview.vue";
import { TicketsModuleFilter } from "@/constants/tickets";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { dateRangePresets } from "@/constants/date-range-picker";

export default defineComponent({
  components: {
    FilterWrapper,
    DateRangePicker,
    ListWithPreview,
    TicketsListTable,
    TicketPreview,
  },
  setup() {
    const {
      localFilters,
      filtersUpdating,
      showClearFiltersButton,
      clearFilters,
      getTickets,
      triggers,
      showLoader,
      tickets,
      allTickets,
      selection,
      pagination,
      loading,
      onPageChanged,
      tabs,
      currentTab,
      ticketPreview,
      breadCrumbsItems,
    } = useTicketsPage(FilterContext.TICKETS, false);

    return {
      localFilters,
      filtersUpdating,
      showClearFiltersButton,
      clearFilters,
      getTickets,
      triggers,
      showLoader,
      tickets,
      allTickets,
      selection,
      pagination,
      loading,
      onPageChanged,
      tabs,
      currentTab,
      ticketPreview,
      breadCrumbsItems,
      suggestions: [
        "violator:",
        "domain:",
        "affected-user:",
        "process-name:",
        "process-hash:",
        "path:",
        "command-line:",
      ],
      coronetSkeletonLoaderTypes,
      widgets: Object.values(TicketsModuleFilter),
      presets: dateRangePresets,
      filterContext: FilterContext.TICKETS,
    };
  },
});
</script>

<style lang="scss" scoped>
.autocomplete {
  position: absolute;
  color: rgb(var(--v-theme-indigo-medium-base)) !important;
  user-select: none;
  width: 100%;
  font-size: 16px;
  line-height: 1.5;
  top: 8px;
  left: 52px;

  &-container {
    position: relative;
  }
}

.search-field {
  max-width: 280px;
}

.widget-filter {
  width: 240px !important;
}

.filter-menu {
  width: 100px;
}

.daterange-picker {
  width: 110px;
}

.daterange-picker.filter-active {
  width: 240px;
}

:deep(*) {
  .nav-tabs {
    max-width: 300px;
  }

  .v-chip-group .v-chip {
    min-width: 32px;
  }

  .v-list-item__title {
    font-size: 16px !important;
    color: rgb(var(--v-theme-primary)) !important;
  }

  .clear-btn {
    z-index: 0 !important;
  }

  .search-field {
    .v-text-field__slot {
      z-index: 10 !important;
    }
  }

  .v-date-range__input-field .v-input__slot {
    padding: 0 8px 0 16px !important;
  }
}
</style>

<template>
  <div class="d-flex flex-column justify-center align-center">
    <coro-icon
      data-testid="service-icon"
      class="connect-service-modal__service-icon"
      :icon-name="config.item.name!"
    >
    </coro-icon>
    <div class="headline5 mb-6 mt-2">{{ connectionCompletedText }}</div>
    <v-btn rounded size="large" color="primary" @click="completeConnection()">
      {{ !!config.item.signup ? $t("general.goToDashboard") : $t("general.close") }}
    </v-btn>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";
import { useI18n } from "vue-i18n";
import CoroIcon from "@/components/CoroIcon.vue";
import type { ConnectAppModalConfig } from "@/views/settings/cloud-apps/CloudAppsPage.vue";
import { useAccountStore } from "@/_store";

export default defineComponent({
  components: {
    CoroIcon,
  },
  props: {
    config: {
      type: Object as PropType<ConnectAppModalConfig>,
      required: true,
    },
  },
  emits: ["dismissed"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const { completeSignup } = useAccountStore();
    const connectionCompletedText = computed(() => {
      return i18n.t("cloudAppsSettings.connectionCompleted", {
        service: i18n.t(`services.${props.config.item.name}`),
      });
    });

    const completeConnection = async () => {
      if (props.config.item.signup) {
        await completeSignup(props.config.item.languageCode);
      }
      emit("dismissed");
    };

    return {
      completeConnection,
      connectionCompletedText,
    };
  },
});
</script>

<style lang="scss"></style>

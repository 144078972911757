import { defineStore } from "pinia";
import { checkIfWorkspaceAvailableForCustomer, getWorkspaceFromRedirect } from "@/_helpers/utils";
import { useAccountStore } from "@/_store/index";
import type { WorkspaceItem } from "@/_store/workspaces.module";
import { useWorkspacesStore } from "@/_store/workspaces.module";
import router from "@/_helpers/router";
import cloneDeep from "lodash/cloneDeep";

interface RedirectsState {
  redirectUrl: string;
  redirectPath: string;
  shouldHandleSocAutomationRedirect: boolean;
  socAutomationAction: string;
  socAutomationPolicyType: string;
  socAutomationEnrollmentCode: string;
  socAutomationOsType: string;
  shouldHandleDashboardRedirect: boolean;
  workspace: Partial<WorkspaceItem>;
  workspaceId: string;
  navigationFromSocAutomation: boolean;
  navigationFromReport: boolean;
}

export interface SocAutomationParams {
  workspaceId: string;
  action: string;
  policyType: string;
  enrollmentCode: string;
  osType: string;
}

const defaultState: RedirectsState = {
  redirectUrl: "",
  redirectPath: "",
  shouldHandleSocAutomationRedirect: false,
  socAutomationAction: "",
  socAutomationPolicyType: "",
  socAutomationEnrollmentCode: "",
  socAutomationOsType: "",
  shouldHandleDashboardRedirect: false,
  workspace: {} as WorkspaceItem,
  navigationFromSocAutomation: false,
  navigationFromReport: false,
  workspaceId: "",
};

export const useRedirectsStore = defineStore("redirects", {
  state: (): RedirectsState =>
    cloneDeep({
      ...defaultState,
    }),
  persist: {
    paths: ["redirects"],
  },
  getters: {
    hasNavigationFromEmail: (state) =>
      state.navigationFromReport || state.navigationFromSocAutomation,
  },
  actions: {
    async handleRedirectFromReport() {
      const savedRedirectUrl = this.redirectUrl;
      const navigationFromReport = this.navigationFromReport;
      if (savedRedirectUrl && navigationFromReport) {
        const workspace = this.workspace;
        const useWorkspaceFromReport = await checkIfWorkspaceAvailableForCustomer(
          workspace?.workspaceId!
        );
        if (useWorkspaceFromReport && workspace && workspace.workspaceId && workspace.name) {
          await this.setWorkspace(workspace);
          localStorage.setItem("report-navigation", `reportNav${Math.random()}`);
          router.push(this.redirectUrl).catch(() => {});
          if (!savedRedirectUrl.startsWith("/portal/tickets")) {
            router.replace(this.redirectPath).catch(() => {});
          }
          this.$reset();
        }
      }
    },
    async handleRedirectFromSocAutomation({
      workspaceId,
      action,
      policyType,
      enrollmentCode,
      osType,
    }: SocAutomationParams) {
      const workspace = await getWorkspaceFromRedirect(workspaceId);
      this.shouldHandleSocAutomationRedirect = Boolean(workspace);
      const navigationFromSocAutomation = this.navigationFromSocAutomation;
      if (workspace && navigationFromSocAutomation) {
        this.workspaceId = workspaceId;
        this.socAutomationAction = action;
        this.socAutomationPolicyType = policyType;
        this.socAutomationEnrollmentCode = enrollmentCode;
        this.socAutomationOsType = osType;
        await this.setWorkspace(workspace);
        localStorage.setItem("report-navigation", `reportNav${Math.random()}`);
      }
    },
    async handleSocAutomationAfterLogin() {
      const savedRedirectUrl = this.redirectUrl;
      const navigationFromSocAutomation = this.navigationFromSocAutomation;
      if (savedRedirectUrl && navigationFromSocAutomation) {
        const workspace = await getWorkspaceFromRedirect(this.workspace?.workspaceId!);
        this.shouldHandleSocAutomationRedirect = Boolean(workspace);
        if (workspace) {
          await this.setWorkspace(workspace);
          await router.push(this.redirectUrl);
          localStorage.setItem("report-navigation", `reportNav${Math.random()}`);
        }
      }
    },
    async handleRedirectToDashboard(workspaceId: string) {
      const workspace = await getWorkspaceFromRedirect(workspaceId);
      this.shouldHandleSocAutomationRedirect = Boolean(workspace);
      if (workspace) {
        await this.setWorkspace(workspace);
        localStorage.setItem("report-navigation", `reportNav${Math.random()}`);
      }
    },
    setRedirectUrl(redirectUrl: string) {
      this.redirectUrl = redirectUrl;
    },
    resetRedirects() {
      this.$reset();
    },
    async setWorkspace(workspace: Partial<WorkspaceItem>) {
      const accountStore = useAccountStore();
      const workspacesStore = useWorkspacesStore();
      accountStore.$patch({
        account: {
          workplace: workspace.workspaceId,
          customerName: workspace.name,
          workspaceType: workspace.type,
          appLogo: workspace.headerIconUrl,
          domain: workspace.domain,
          brandColor: workspace.branding?.brandColor,
          supportEnabled: workspace.supportEnabled,
          isCoronetWorkspace: workspace.isCoronetWorkspace,
          languageCode: workspace.languageCode,
        },
      });
      await workspacesStore.getCurrentWorkspace();
    },
  },
});

import type { AccessPermissionItem, Group } from "@/_store/cloud-apps/access-permissions.module";
import type { EmailProxyDomain } from "@/_store/email-security/email-proxy.module";
import { i18n } from "@/plugins/i18n";
import { Service } from "@/constants/cloud-apps";
import type { UserExceptionsFilterItem } from "@/_store/users-settings/user-exceptions-tab.module";

export function convertPayloadForBE(payload: AccessPermissionItem): AccessPermissionItem {
  // Remap groups from `name` and `service` to `groupName` and `serviceName` respectively
  const groups: Partial<Group>[] = payload.monitoredScope.groups.map((group) => {
    return {
      ...group,
      groupName: group.groupName || group.name,
      name: undefined,
      serviceName: group.serviceName || group.service,
      service: undefined,
    };
  });
  return { ...payload, monitoredScope: { ...payload.monitoredScope, groups: groups as Group[] } };
}

export const createUsersFiltersWithProxyDomains = (
  domains: EmailProxyDomain[]
): (UserExceptionsFilterItem | { divider: true } | { subheader: string })[] => {
  const result: (UserExceptionsFilterItem | { divider: true } | { subheader: string })[] = [];
  if (domains.length) {
    const domainFilterItems: UserExceptionsFilterItem[] = domains.map(({ name }) => ({
      type: "domain",
      name,
    }));
    result.push(
      { subheader: i18n.global.t("usersSettings.filters.inboundGateway") },
      ...domainFilterItems,
      { divider: true }
    );
  }
  const servicesFilterItems: UserExceptionsFilterItem[] = [
    Service.BOX,
    Service.DROPBOX,
    Service.G_SUITE,
    Service.OFFICE_365,
    Service.SLACK,
    Service.SALES_FORCE,
  ].map((name) => ({
    name,
    type: "cloudService",
  }));

  result.push(
    { subheader: i18n.global.t("usersSettings.filters.cloudServices") },
    ...servicesFilterItems
  );

  return result;
};

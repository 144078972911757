import { defineStore } from "pinia";
import api from "@/_helpers/api";
import { axiosInstance } from "@/plugins/https";
import type { Pagination } from "@/types";
import type { DeviceLabel } from "@/_store/devices-settings.module";

export interface ZtnaRule {
  id?: string;
  name: string;
  description: string;
  ips: string[];
  ports: Array<string>;
  protocols: Protocols;
  labels: Array<DeviceLabel>;
}

export enum Protocols {
  All = "All",
  UDP = "UDP",
  TCP = "TCP",
}

interface ZtnaState {
  loading: boolean;
  rules: ZtnaRule[];
  pagination: Pagination;
  total: number;
  enabled: boolean;
}

const defaultZtnaState: ZtnaState = {
  loading: false,
  enabled: false,
  rules: [],
  pagination: {
    page: 0,
    pageSize: 25,
  },
  total: 0,
};

export const useZtnaStore = defineStore("ztna", {
  state: () => ({ ...defaultZtnaState }),
  actions: {
    async initZtna() {
      try {
        await this.getZtnaStatus();
      } catch (e) {
        console.error(e);
      }
    },

    async toggleZtnaEnabled(enable: boolean): Promise<void> {
      this.loading = true;
      const requestConfig = api.toggleZtnaEnabled(enable);
      try {
        await axiosInstance.request(requestConfig);
        await this.getZtnaStatus();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async getZtnaStatus() {
      this.loading = true;
      const requestConfig = api.ztnaStatus();
      try {
        const {
          data: { enabled },
        } = await axiosInstance.request(requestConfig);
        this.enabled = enabled;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getZtnaRules() {
      this.loading = true;
      const requestConfig = api.ztnaRules();
      try {
        const { data } = await axiosInstance.request({
          ...requestConfig,
          ...this.pagination,
          params: { ...this.pagination },
        });
        this.rules = data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async updateZtnaRule(rule: ZtnaRule) {
      this.loading = true;
      const requestConfig = api.ztnaRules(rule.id);
      try {
        await axiosInstance.request({ ...requestConfig, method: "PUT", data: rule });
        await this.getZtnaRules();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async deleteZtnaRule({ id }: ZtnaRule) {
      this.loading = true;
      const requestConfig = api.ztnaRules(id);
      try {
        await axiosInstance.request({ ...requestConfig, method: "DELETE" });
        await this.getZtnaRules();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async saveZtnaRule(data: ZtnaRule) {
      this.loading = true;
      const requestConfig = api.ztnaRules();
      try {
        await axiosInstance.request({
          ...requestConfig,
          method: "POST",
          data,
        });
        await this.getZtnaRules();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});

import type { UserExceptionsFilters } from "@/_store/users-settings/user-exceptions-tab.module";
import {
  type JoinedViaFilterOptions,
  type JoinedViaFilterParams,
  JoinedViaFilterType,
  UserSettingsAppStatus,
  UserSettingsJoinedVia,
  type UsersSettingsFilters,
} from "@/_store/users-settings/users-tab.module";
import type { Service } from "@/constants/cloud-apps";
import type { UserSettingsFilterItem } from "@/_store/users-settings/users-settings.module";
import { i18n } from "@/plugins/i18n";
import { flatten } from "lodash";

export const splitAndConvertUsersFilters = (filters: UserExceptionsFilters) => {
  const convertedFilters: {
    cloudServices: string;
    inboundGatewayDomains: string;
  } = {
    cloudServices: filters.cloudServices
      .filter((v) => v.type === "cloudService")
      .map((v) => v.name as Service)
      .join(","),
    inboundGatewayDomains: filters.cloudServices
      .filter((v) => v.type === "domain")
      .map((v) => v.name)
      .join(","),
  };

  return convertedFilters;
};

export const getUserSettingsFilters = (filters: UsersSettingsFilters) => {
  const denormalizedFilters = filters.joinedVia.reduce((acc, curr: UserSettingsFilterItem) => {
    if (acc[curr.type]) {
      acc[curr.type] += `,${curr.value}`;
    } else {
      acc[curr.type] = curr.value;
    }

    return acc;
  }, {} as Partial<JoinedViaFilterParams>);

  const convertedFilters: {
    connectedCloudApplications?: string;
    isActive?: boolean;
  } = {};

  if ([UserSettingsAppStatus.ACTIVE, UserSettingsAppStatus.INACTIVE].includes(filters.status!)) {
    const status = filters.status;

    convertedFilters.isActive = status === UserSettingsAppStatus.ACTIVE;
  }

  if (filters.connectedApps) {
    convertedFilters.connectedCloudApplications = filters.connectedApps?.join(",");
  }

  return {
    ...convertedFilters,
    ...denormalizedFilters,
  };
};

function getJoinedViaInboundGatewaysOptions(
  inboundGateway: Record<string, number>,
  searchQuery: string
) {
  return [
    {
      subheader: i18n.global.t(`usersSettings.filters.joinedVia.inboundGateway`),
    },
    ...Object.entries(inboundGateway)
      .map(([groupKey, value]) => ({
        name: groupKey,
        amount: value,
        value: groupKey,
        type: JoinedViaFilterType.JOINED_VIA_INBOUND_GATEWAY_DOMAINS,
      }))
      .filter(({ name }) => name.toLowerCase().includes(searchQuery)),
  ];
}

function getJoinedViaGeneralOptions(groupNames: { [key in UserSettingsJoinedVia]?: number }) {
  const allowedOptions = [
    UserSettingsJoinedVia.CSV,
    UserSettingsJoinedVia.INDIVIDUALLY,
    UserSettingsJoinedVia.ADMIN,
  ];
  return [
    {
      subheader: i18n.global.t(`usersSettings.filters.joinedVia.general.subtitle`),
    },
    ...Object.entries(groupNames)
      .filter(([groupKey]) => allowedOptions.includes(groupKey as UserSettingsJoinedVia))
      .map(([groupKey, amount]) => ({
        name: i18n.global.t(`usersSettings.filters.joinedVia.general.${groupKey}`),
        value: groupKey,
        amount,
        type: JoinedViaFilterType.JOINED_VIA,
      })),
  ];
}

function getJoinedViaGroupsOptions(
  key: string,
  total: number,
  groupNames: { total: number; [subkey: string]: number }
) {
  return [
    {
      subheader: i18n.global.t(`usersSettings.filters.joinedVia.cloudServices.${key}.subtitle`),
    },
    ...Object.entries(groupNames).map(([groupKey, value]) => ({
      name: groupKey,
      value: groupKey,
      type: JoinedViaFilterType.JOINED_VIA_GROUPS,
      amount: value,
    })),
  ];
}

export const createUserSettingsJoinedViaFilterOptions = (
  search: string,
  groups: JoinedViaFilterOptions
): (UserSettingsFilterItem | { subheader: string })[] => {
  const result: (UserSettingsFilterItem | { subheader: string })[] = [];

  if (Object.keys(groups).length) {
    const { inboundGateway, ...cloudServices } = groups;
    const searchQuery = search.trim().toLowerCase();

    const cloudServicesOptions = Object.entries(cloudServices)
      .filter(([, value]) => Object.keys(value).length)
      .map(([key, value]) => {
        // TODO: fix this any
        const { total, ...groupNames } = value as any;
        let item: (UserSettingsFilterItem | { subheader: string })[];
        if (key === "general") {
          item = getJoinedViaGeneralOptions(groupNames);
        } else {
          item = getJoinedViaGroupsOptions(key, total, groupNames);
        }

        item = (item as UserSettingsFilterItem[]).filter(({ name }) =>
          name ? name.toLowerCase().includes(searchQuery) : true
        );

        if (item.length === 1) {
          item = [];
        }

        return item;
      });

    let inboundGatewayOptions: (UserSettingsFilterItem | { subheader: string })[] = [];

    if (inboundGateway) {
      inboundGatewayOptions = getJoinedViaInboundGatewaysOptions(inboundGateway, searchQuery);
    }

    if (inboundGatewayOptions.length === 1) {
      inboundGatewayOptions = [];
    }

    result.push(...flatten(cloudServicesOptions), ...inboundGatewayOptions);
  }

  return result;
};

export enum Service {
  CORONET = "coronet",
  OFFICE_365 = "office365Enforcement",
  DROPBOX = "dropboxEnforcement",
  G_SUITE = "googleDirectoryEnforcement",
  BOX = "boxEnforcement",
  SALES_FORCE = "salesforceEnforcement",
  SLACK = "slackEnforcement",
  PROXY = "emailProxyEnforcement",
  GOOGLE = "google",
}

export enum ServiceAction {
  CONNECT = "connectService",
  DISCONNECT = "disconnectService",
  CONNECTION_STATUS = "connectionStatus",
  REMOVE = "removeService",
  GRANT_PRIVILEGES = "grantPrivileges",
  ACCESS_RESTRICTIONS = "accessService",
  NEW_OFFICE_ACCOUNT_ERROR = "newOfficeAccountError",
}

export enum ServiceStatus {
  CONNECTED = "connected",
  CONNECTED_NOT_SECURE = "connectedNotSecure",
  DISCONNECTED = "disconnected",
  INCOMPLETE = "incomplete",
  NOT_CONNECTED = "notConnected",
  ENABLED = "enabled",
}

export enum AppType {
  TEAM_MANAGEMENT_KEY = "teamManagementKey",
  TEAM_FILE_ACCESS_KEY = "teamFileAccessKey",
  DEFAULT = "default",
}

export enum AccessPermissionAction {
  ADD = "addAccessPermissions",
  EDIT = "editAccessPermissions",
  DELETE = "deleteAccessPermissions",
}

export enum AccessPermissionType {
  ALL_USERS = "all",
  SPECIFIC_GROUPS = "group",
  SPECIFIC_USERS = "user",
}

export enum ThirdPartyAppsAction {
  BLOCK_APP = "blockApp",
}

export const CLOUD_SERVICES = [
  Service.BOX,
  Service.DROPBOX,
  Service.G_SUITE,
  Service.OFFICE_365,
  Service.SLACK,
  Service.SALES_FORCE,
];

export enum GSuiteErrorCode {
  GENERIC = "generic",
  IS_NOT_ADMIN = "notAdmin",
}

export enum OfficeErrorCode {
  IS_NOT_ADMIN = "notAdmin",
  ACCOUNT_ALREADY_EXIST = "company_already_registered",
  IS_NEW_ACCOUNT = "new_office_account",
  ACCESS_DENIED = "access_denied",
}

<template>
  <div class="content-md margin-auto mt-4">
    <div class="mb-3">
      <v-breadcrumbs :items="breadcrumbsItems" />
    </div>

    <div class="d-flex align-center justify-space-between mb-4">
      <div class="d-flex align-center">
        <span class="headline5" data-testid="protected-users-page-title">
          {{ title }}
        </span>
      </div>
    </div>

    <div class="d-flex justify-end mb-8">
      <filter-wrapper
        :show-clear-button="showClearFiltersButton"
        @clear-filters-clicked="clearFilters(getItems)"
      >
        <v-text-field
          v-model="localFilters.search"
          density="compact"
          variant="outlined"
          background-color="white"
          :placeholder="$t('general.search')"
          :class="{ 'filter-active': localFilters.search?.length }"
          class="search-field"
          prepend-inner-icon="$search"
          hide-details
          clearable
          persistent-clear
          clear-icon="$x"
        />
      </filter-wrapper>
    </div>
    <list-with-preview :show-skeleton-loader="usersLoading">
      <template #list>
        <users-list-table
          :items="items"
          :total-items="total"
          :loading="loading"
          :pagination="pagination"
          @page-changed="onPageChange"
          @update-preview="onPreviewUpdate($event)"
        />
      </template>
      <template #preview>
        <users-preview :user="preview"></users-preview>
      </template>
    </list-with-preview>
  </div>
</template>

<script lang="ts">
import uniq from "lodash/uniq";
import FilterWrapper from "@/components/FilterWrapper.vue";
import UsersListTable from "@/components/tables/UsersListTable.vue";
import ListWithPreview from "@/components/ListWithPreview.vue";
import UsersPreview from "@/components/UsersPreview.vue";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { type UserListItem, useUsersStore } from "@/_store/users.module";
import { storeToRefs } from "pinia";
import { useSelectorStore } from "@/_store/selector.module";
import { FilterContext, useFiltersStore } from "@/_store/filters.module";
import { computed, defineComponent, onUnmounted, onMounted, watch, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useFilters } from "@/composables/useFilters";
const filterContext = FilterContext.USERS;

export default defineComponent({
  components: {
    FilterWrapper,
    UsersListTable,
    ListWithPreview,
    UsersPreview,
  },
  props: {
    hasVulnerabilities: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const usersStore = useUsersStore();
    const selectorStore = useSelectorStore();
    const filtersStore = useFiltersStore();
    const i18n = useI18n();
    const preview = ref(null as UserListItem | null);
    const { getItems, onPageChange } = usersStore;

    const { localFilters, filtersUpdating, showClearFiltersButton, clearFilters, updateFilters } =
      useFilters(filterContext);

    const { deselectAllPages } = selectorStore;

    const { loading, items, total, pagination, usersLoading, userDetailsLoading } =
      storeToRefs(usersStore);

    const { selection } = storeToRefs(selectorStore);

    const protectedUsersFilters = computed(() => {
      return filtersStore.filters.usersFilters;
    });

    const hasVulnerabilitiesInSelection = computed(() => {
      let vulnerabilitiesInRows: string[] = [];
      if (!selection.value.isAllPages) {
        selection.value.include.forEach((user) => {
          const vulnerabilities = user.vulnerabilities.map((v: { issue: string }) => v.issue);
          vulnerabilitiesInRows = vulnerabilitiesInRows.concat(vulnerabilities);
        });
        return uniq(vulnerabilitiesInRows).length > 0;
      }
      return true;
    });

    const title = computed(() => {
      if (protectedUsersFilters.value.hasVulnerabilities === false) {
        return i18n.t("usersPage.dynamicTitle", {
          n: i18n.t("usersPage.noIssues"),
        });
      } else if (protectedUsersFilters.value.hasVulnerabilities === true) {
        return i18n.t("usersPage.dynamicTitle", {
          n: i18n.t("usersPage.criticalIssues"),
        });
      }
      return i18n.t("usersPage.title");
    });

    const onPreviewUpdate = (item: UserListItem) => {
      preview.value = item ? { ...item } : null;
    };

    watch(
      filtersUpdating,
      async (value) => {
        if (value) await getItems();
      },
      { deep: true, immediate: true }
    );

    onMounted(async () => {
      updateFilters({
        ...{ ...localFilters.value },
        hasVulnerabilities: props.hasVulnerabilities
          ? props.hasVulnerabilities === "true"
          : undefined,
      });
      await getItems({ showSkeletonLoader: true });
    });

    onUnmounted(() => {
      deselectAllPages();
    });

    return {
      breadcrumbsItems: [
        {
          title: `‹ ${i18n.t("general.home")}`,
          disabled: false,
          to: { path: "/portal/dashboard" },
        },
      ],
      coronetSkeletonLoaderTypes,
      preview,
      loading,
      items,
      total,
      pagination,
      usersLoading,
      userDetailsLoading,
      selection,
      protectedUsersFilters,
      hasVulnerabilitiesInSelection,
      title,
      localFilters,
      filtersUpdating,
      showClearFiltersButton,
      clearFilters,
      onPageChange,
      onPreviewUpdate,
      getItems,
    };
  },
});
</script>

<style lang="scss" scoped>
.search-field {
  max-width: 280px;
}
</style>

import {
  AddonOverviewWidgetType,
  OverviewWidgetAppearance,
  OverviewWidgetSize,
  OverviewWidgetType,
  WidgetType,
} from "@/constants/dashboard";
import { CoroIcons } from "@/constants/coro-icon";
import { RolePermissionsScope } from "@/_store/roles.module";
import { SubscriptionAddon, SubscriptionModule } from "@/constants/workplaces";
import { i18n } from "@/plugins/i18n";
import { isAccessRestricted } from "@/_helpers/utils";
import type { WidgetData } from "@/_store/dashboard/dashboard.module";

export interface OverviewWidgetConfiguration {
  type: string;
  icon: string;
  size: "large" | "small";
  appearance: "number" | "settings" | "blank";
  disabled?: boolean;
  restricted?: boolean;
  isProtectionEnabled?: boolean;
  issuesCount?: number;
  resolvedIssuesCount?: number;
  index: number;
  description?: string;
}

const overviewWidgetIconName = {
  CHECK: "circleCheck",
  ALERT: "redAlert",
};

const cloudAppsWidgetAdapter = (responseData: { data: WidgetData }[]) => {
  return responseData[0].data;
};

const deviceWidgetAdapter = (responseData: { data: WidgetData }[]) => {
  return responseData[0].data;
};

const emailsWidgetAdapter = (responseData: { data: WidgetData }[]) => {
  const isEmailServicesConnected =
    responseData[0]?.data.hasConnectedServices || responseData[1]?.data?.isProxyActive;
  return {
    ...responseData[0]?.data,
    isEmailServicesConnected,
  };
};

const dataWidgetAdapter = (responseData: { data: WidgetData }[]) => {
  const isProtectionEnabled = responseData[0]?.data.hasConnectedServices;
  return {
    ...responseData[0].data,
    isProtectionEnabled,
  };
};

const edrWidgetAdapter = (responseData: { data: WidgetData }[]) => {
  return {
    ...responseData[0].data,
  };
};

const endpointDataGovernanceWidgetAdapter = (responseData: { data: WidgetData }[]) => {
  const isProtectionEnabled = responseData[0].data.protectedDevices > 0;
  return {
    ...responseData[0].data,
    isProtectionEnabled,
  };
};

const cloudAppsOverviewWidgetAdapter = (
  responseData: { data: WidgetData }[],
  disabled: boolean
): OverviewWidgetConfiguration => {
  const { activeVulnerabilities, resolvedVulnerabilities, hasConnectedServices } =
    responseData[0].data;

  return {
    type: OverviewWidgetType.CLOUD_SECURITY,
    icon: CoroIcons.CLOUD_SECURITY,
    size: OverviewWidgetSize.LARGE,
    appearance: disabled ? OverviewWidgetAppearance.SETTINGS : OverviewWidgetAppearance.NUMBER,
    isProtectionEnabled: hasConnectedServices,
    issuesCount: getVulnerabilitiesSum(activeVulnerabilities),
    resolvedIssuesCount: getVulnerabilitiesSum(resolvedVulnerabilities),
    disabled,
    restricted: isAccessRestricted(
      RolePermissionsScope.PROTECTION,
      SubscriptionModule.CLOUD_SECURITY
    ),
    index: 0,
  };
};

const emailsOverviewWidgetAdapter = (
  responseData: { data: WidgetData }[],
  disabled: boolean
): OverviewWidgetConfiguration => {
  const { activeVulnerabilities, resolvedVulnerabilities, hasConnectedServices } =
    responseData?.[0]?.data ?? {};
  const isEmailServicesConnected = hasConnectedServices || responseData[1]?.data?.isProxyActive;

  return {
    type: OverviewWidgetType.EMAIL_SECURITY,
    icon: CoroIcons.EMAILS,
    size: OverviewWidgetSize.LARGE,
    appearance: disabled ? OverviewWidgetAppearance.SETTINGS : OverviewWidgetAppearance.NUMBER,
    issuesCount: getVulnerabilitiesSum(activeVulnerabilities),
    resolvedIssuesCount: getVulnerabilitiesSum(resolvedVulnerabilities),
    isProtectionEnabled: isEmailServicesConnected,
    disabled,
    restricted: isAccessRestricted(
      RolePermissionsScope.PROTECTION,
      SubscriptionModule.EMAIL_SECURITY
    ),
    index: 2,
  };
};

const endpointSecurityOverviewWidgetAdapter = (
  responseData: { data: WidgetData }[],
  disabled: boolean
): OverviewWidgetConfiguration => {
  const { activeVulnerabilities, resolvedVulnerabilities, protectedDevices } = responseData[0].data;

  return {
    type: OverviewWidgetType.ENDPOINT_SECURITY,
    icon: CoroIcons.ENDPOINT_SECURITY,
    size: OverviewWidgetSize.LARGE,
    appearance: disabled ? OverviewWidgetAppearance.SETTINGS : OverviewWidgetAppearance.NUMBER,
    issuesCount: getVulnerabilitiesSum(activeVulnerabilities),
    resolvedIssuesCount: getVulnerabilitiesSum(resolvedVulnerabilities),
    isProtectionEnabled: protectedDevices > 0,
    disabled,
    restricted: isAccessRestricted(
      RolePermissionsScope.PROTECTION,
      SubscriptionModule.ENDPOINT_SECURITY
    ),
    index: 3,
  };
};

const endpointDataGovernanceOverviewWidgetAdapter = (
  responseData: { data: WidgetData }[],
  disabled: boolean
): OverviewWidgetConfiguration => {
  const { activeVulnerabilities, resolvedVulnerabilities, protectedDevices } = responseData[0].data;

  return {
    type: OverviewWidgetType.ENDPOINT_DATA_GOVERNANCE,
    appearance: disabled ? OverviewWidgetAppearance.SETTINGS : OverviewWidgetAppearance.NUMBER,
    icon: CoroIcons.ENDPOINT_DATA_GOVERNANCE,
    size: OverviewWidgetSize.LARGE,
    issuesCount: getVulnerabilitiesSum(activeVulnerabilities),
    resolvedIssuesCount: getVulnerabilitiesSum(resolvedVulnerabilities),
    isProtectionEnabled: protectedDevices > 0,
    disabled,
    restricted: isAccessRestricted(
      RolePermissionsScope.PROTECTION,
      SubscriptionModule.ENDPOINT_DATA_GOVERNANCE
    ),
    index: 4,
  };
};

const userDataGovernanceOverviewWidgetAdapter = (
  responseData: { data: WidgetData }[],
  disabled: boolean
): OverviewWidgetConfiguration => {
  const { activeVulnerabilities, resolvedVulnerabilities, hasConnectedServices } =
    responseData[0].data;

  return {
    type: OverviewWidgetType.USER_DATA_GOVERNANCE,
    icon: CoroIcons.USER_DATA_GOVERNANCE,
    size: OverviewWidgetSize.LARGE,
    appearance: disabled ? OverviewWidgetAppearance.SETTINGS : OverviewWidgetAppearance.NUMBER,
    issuesCount: getVulnerabilitiesSum(activeVulnerabilities),
    resolvedIssuesCount: getVulnerabilitiesSum(resolvedVulnerabilities),
    isProtectionEnabled: hasConnectedServices,
    disabled,
    restricted: isAccessRestricted(
      RolePermissionsScope.PROTECTION,
      SubscriptionModule.USER_DATA_GOVERNANCE
    ),
    index: 4,
  };
};

const networkOverviewWidgetAdapter = (_: any, disabled: boolean): OverviewWidgetConfiguration => {
  return {
    type: OverviewWidgetType.NETWORK,
    icon: CoroIcons.NETWORK,
    size: OverviewWidgetSize.LARGE,
    appearance: OverviewWidgetAppearance.SETTINGS,
    description: i18n.global.t("dashboard.widgets.overviewWidget.types.network.description"),
    disabled,
    restricted: isAccessRestricted(RolePermissionsScope.PROTECTION, SubscriptionModule.NETWORK),
    index: 6,
  };
};

const mdmOverviewWidgetAdapter = (_: any, disabled: boolean): OverviewWidgetConfiguration => {
  return {
    type: OverviewWidgetType.MOBILE_DEVICE_MANAGEMENT,
    icon: CoroIcons.MOBILE_DEVICE_MANAGEMENT,
    size: OverviewWidgetSize.LARGE,
    appearance: OverviewWidgetAppearance.BLANK,
    disabled,
    restricted: isAccessRestricted(RolePermissionsScope.PROTECTION, SubscriptionModule.MDM),
    index: 7,
  };
};

const edrOverviewWidgetAdapter = (
  responseData: { data: WidgetData }[],
  disabled: boolean
): OverviewWidgetConfiguration => {
  return {
    type: OverviewWidgetType.EDR,
    icon: CoroIcons.EDR,
    size: OverviewWidgetSize.LARGE,
    appearance: OverviewWidgetAppearance.BLANK,
    disabled,
    restricted: isAccessRestricted(RolePermissionsScope.PROTECTION, SubscriptionModule.EDR),
    index: 8,
  };
};

const addonInboundGatewayOverviewWidgetAdapter = (
  disabled: boolean
): OverviewWidgetConfiguration => {
  return {
    type: AddonOverviewWidgetType.INBOUND_GATEWAY,
    icon: overviewWidgetIconName.CHECK,
    size: OverviewWidgetSize.SMALL,
    appearance: OverviewWidgetAppearance.SETTINGS,
    description: i18n.global.t(
      `dashboard.widgets.overviewWidget.types.${AddonOverviewWidgetType.INBOUND_GATEWAY}.description`
    ),
    disabled,
    restricted: isAccessRestricted(
      RolePermissionsScope.PROTECTION,
      SubscriptionAddon.INBOUND_GATEWAY
    ),
    index: 1,
  };
};

const addonSwgOverviewWidgetAdapter = (disabled: boolean): OverviewWidgetConfiguration => {
  return {
    type: AddonOverviewWidgetType.SECURE_WEB_GATEWAY,
    icon: overviewWidgetIconName.CHECK,
    size: OverviewWidgetSize.SMALL,
    appearance: OverviewWidgetAppearance.SETTINGS,
    description: i18n.global.t(
      `dashboard.widgets.overviewWidget.types.${AddonOverviewWidgetType.SECURE_WEB_GATEWAY}.description`
    ),
    disabled,
    restricted:
      isAccessRestricted(RolePermissionsScope.PROTECTION, SubscriptionModule.NETWORK) ||
      isAccessRestricted(RolePermissionsScope.PROTECTION, SubscriptionAddon.SWG),
    index: 2,
  };
};

const addonWifiPhishingOverviewWidgetAdapter = (disabled: boolean): OverviewWidgetConfiguration => {
  return {
    type: AddonOverviewWidgetType.WIFI_PHISHING,
    icon: overviewWidgetIconName.CHECK,
    size: OverviewWidgetSize.SMALL,
    appearance: OverviewWidgetAppearance.SETTINGS,
    description: i18n.global.t(
      `dashboard.widgets.overviewWidget.types.${AddonOverviewWidgetType.WIFI_PHISHING}.description`
    ),
    disabled,
    restricted:
      isAccessRestricted(RolePermissionsScope.PROTECTION, SubscriptionModule.ENDPOINT_SECURITY) ||
      isAccessRestricted(RolePermissionsScope.PROTECTION, SubscriptionAddon.WIFI_PHISHING),
    index: 3,
  };
};

const addonCoroMailOverviewWidgetAdapter = (disabled: boolean): OverviewWidgetConfiguration => {
  return {
    type: AddonOverviewWidgetType.SECURED_MESSAGES,
    icon: overviewWidgetIconName.CHECK,
    size: OverviewWidgetSize.SMALL,
    appearance: OverviewWidgetAppearance.SETTINGS,
    description: i18n.global.t(
      `dashboard.widgets.overviewWidget.types.${AddonOverviewWidgetType.SECURED_MESSAGES}.description`
    ),
    disabled,
    restricted:
      isAccessRestricted(RolePermissionsScope.PROTECTION, SubscriptionModule.EMAIL_SECURITY) ||
      isAccessRestricted(RolePermissionsScope.PROTECTION, SubscriptionAddon.SECURED_MESSAGES),
    index: 4,
  };
};

export const widgetAdapters = {
  [WidgetType.CLOUD_SECURITY]: cloudAppsWidgetAdapter,
  [WidgetType.ENDPOINT_SECURITY]: deviceWidgetAdapter,
  [WidgetType.EMAIL_SECURITY]: emailsWidgetAdapter,
  [WidgetType.ENDPOINT_DATA_GOVERNANCE]: endpointDataGovernanceWidgetAdapter,
  [WidgetType.USER_DATA_GOVERNANCE]: dataWidgetAdapter,
  [WidgetType.EDR]: edrWidgetAdapter,
};

export const overviewWidgetAdapters = {
  [OverviewWidgetType.CLOUD_SECURITY]: cloudAppsOverviewWidgetAdapter,
  [OverviewWidgetType.ENDPOINT_SECURITY]: endpointSecurityOverviewWidgetAdapter,
  [OverviewWidgetType.EMAIL_SECURITY]: emailsOverviewWidgetAdapter,
  [OverviewWidgetType.ENDPOINT_DATA_GOVERNANCE]: endpointDataGovernanceOverviewWidgetAdapter,
  [OverviewWidgetType.USER_DATA_GOVERNANCE]: userDataGovernanceOverviewWidgetAdapter,
  [OverviewWidgetType.EDR]: edrOverviewWidgetAdapter,
  [OverviewWidgetType.NETWORK]: networkOverviewWidgetAdapter,
  [OverviewWidgetType.MOBILE_DEVICE_MANAGEMENT]: mdmOverviewWidgetAdapter,
};

export const addonOverviewWidgetAdapters = {
  [AddonOverviewWidgetType.WIFI_PHISHING]: addonWifiPhishingOverviewWidgetAdapter,
  [AddonOverviewWidgetType.SECURE_WEB_GATEWAY]: addonSwgOverviewWidgetAdapter,
  [AddonOverviewWidgetType.INBOUND_GATEWAY]: addonInboundGatewayOverviewWidgetAdapter,
  [AddonOverviewWidgetType.SECURED_MESSAGES]: addonCoroMailOverviewWidgetAdapter,
};

function getVulnerabilitiesSum(vulnerabilities: { count: number }[]): number {
  if (!vulnerabilities) {
    return 0;
  }
  return vulnerabilities.reduce((sum, current) => {
    return sum + Number(current.count);
  }, 0);
}

export const sortOverviewWidgets = (overviewWidgets: OverviewWidgetConfiguration[]) => {
  return overviewWidgets.sort((a, b) => a.index - b.index);
};

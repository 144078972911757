import moment from "moment";
import { getSelectionAsStringsArray } from "@/_helpers/utils";
import {
  AnomalyIpThreatLevel,
  SocStatus,
  TicketAction,
  TicketStatus,
  TicketTrigger,
  ViolationDirection,
} from "@/constants/tickets";
import { i18n } from "@/plugins/i18n";
import api from "@/_helpers/api";
import type {
  ApplyTicketBulkActionPayload,
  Ticket,
  TicketDetails,
} from "@/_store/tickets/tickets.module";
import type { ISelection } from "@/_store/selector.module";
import {
  FilterContext,
  type SocTicketsFilters,
  type TicketsFilters,
  useFiltersStore,
} from "@/_store/filters.module";
import type { AxiosRequestConfig } from "axios";
import type { Pagination } from "@/types";
import { Service } from "@/constants/cloud-apps";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import { type SnackbarItemConfig, SnackbarTypes, useSnackbarStore } from "@/_store";
import router from "@/_helpers/router";
import { RouteName } from "@/constants/routes";
import { isGlobalAccessRestricted } from "@/_helpers/global-permissions";
import { GlobalRoleScopes, GlobalRoleScopeSection } from "@/_store/roles.module";

export const getUsersToAddFromPotentiallyProtectable = (
  potentiallyProtectableUsers: string[]
): { users: { email: string; service: Service }[] } => {
  const mappedUsers = potentiallyProtectableUsers.map((email) => {
    return {
      email,
      service: Service.CORONET,
    };
  });
  return {
    users: mappedUsers,
  };
};

export function getTicketsRequest(isSoc = false, pagination: Pagination) {
  const filtersStore = useFiltersStore();

  if (isSoc) {
    const socTicketsFilters = convertSocFiltersForBackend({
      ...filtersStore.filters.socTicketsFilters,
    });

    return {
      ...api.getSocTickets(),
      params: {
        ...socTicketsFilters,
        sort: "lastEventTime,desc",
        page: pagination.page,
        pageSize: pagination.pageSize,
      },
    };
  }

  const ticketsFilters = convertTicketFiltersForBackend({ ...filtersStore.filters.ticketsFilters });

  return {
    ...api.getTickets(),
    params: {
      ...ticketsFilters,
      sort: "lastEventTime,desc",
      page: pagination.page,
      pageSize: pagination.pageSize,
    },
  };
}

export function getTicketDetailsRequest(isSoc: boolean, eventId: string) {
  if (isSoc) {
    return {
      ...api.socTicket(eventId),
    };
  }
  return {
    ...api.ticket(eventId),
  };
}

export function getTicketBulkActionRequest(
  isSoc: boolean,
  payload: ApplyTicketBulkActionPayload
): AxiosRequestConfig {
  const filtersStore = useFiltersStore();
  const filters = filtersStore.filters.ticketsFilters;
  if (isSoc) {
    return {
      ...api.socTicketBulkAction(),
      data: {
        action: payload.action,
        socTicketDtos: convertSelectionForSocBulkAction(payload.selection),
      },
    };
  }
  const ticketsFilters = convertFiltersForBackendForBulkAction({
    ...filters,
  });
  return {
    ...api.ticketBulkAction(),
    method: "post",
    data: {
      action: payload.action,
      selection: getSelectionAsStringsArray(payload.selection, "eventId"),
      filter: {
        ...ticketsFilters,
      },
    },
  };
}

export function addWorkspaceHeaderIfNeeded(
  request: AxiosRequestConfig,
  workspaceId?: string
): AxiosRequestConfig {
  if (workspaceId) {
    return {
      ...request,
      headers: {
        Workspace: workspaceId,
      },
    };
  }

  return request;
}

export const convertTicketFiltersForBackend = (filters: TicketsFilters) => {
  const {
    eventTriggers: eventTriggersArray,
    status,
    eventTimeRange,
    widget,
    search,
    protectedUsersOnly,
  } = filters;

  const processed = status === TicketStatus.ALL ? undefined : status === TicketStatus.CLOSED;
  const eventTriggers = eventTriggersArray?.join(",");
  const fromTime = eventTimeRange?.start
    ? moment(eventTimeRange.start).startOf("day").utc().valueOf()
    : undefined;
  const toTime = eventTimeRange?.end
    ? moment(eventTimeRange.end).endOf("day").utc().valueOf()
    : undefined;

  return {
    processed,
    widget,
    search,
    eventTriggers,
    fromTime,
    toTime,
    protectedUsersOnly,
  };
};

function convertSocFiltersForBackend(filters: SocTicketsFilters) {
  const { socStatuses, workspaceId, search: ticketId } = filters;
  const { eventTriggers, fromTime, toTime, widget } = convertTicketFiltersForBackend(filters);
  return {
    eventTriggers,
    fromTime,
    toTime,
    widget,
    processed: filters.status === TicketStatus.ALL ? null : filters.status === TicketStatus.CLOSED,
    workspaceId: workspaceId ? workspaceId : undefined,
    ticketId,
    socStatuses: (socStatuses ?? []).filter((v) => v !== SocStatus.NEW_COMMENTS).join(","),
    hasUnreadComment: (socStatuses ?? []).includes(SocStatus.NEW_COMMENTS) ? true : undefined,
  };
}

function convertFiltersForBackendForBulkAction(filters: TicketsFilters) {
  const protectedUsersOnly = filters.protectedUsersOnly;
  const widget = filters.widget;
  const search = filters.search;
  const eventTriggers = filters.eventTriggers?.length ? filters.eventTriggers : [];
  const processed =
    filters.status === TicketStatus.CLOSED
      ? true
      : filters.status === TicketStatus.OPEN
        ? false
        : undefined;
  const fromTime = filters.eventTimeRange?.start
    ? moment(filters.eventTimeRange.start).startOf("day").utc().valueOf()
    : undefined;
  const toTime = filters.eventTimeRange?.end
    ? moment(filters.eventTimeRange.end).endOf("day").utc().valueOf()
    : undefined;
  return {
    protectedUsersOnly,
    widget,
    search,
    processed,
    eventTriggers,
    fromTime,
    toTime,
  };
}

export function convertSelectionForSocBulkAction(selection: ISelection<Ticket>) {
  return selection.include.map(({ eventId, eventType, workspaceId }) => {
    return {
      eventId,
      eventType,
      workspaceId,
    };
  });
}

export function buildTicketActionRequest(
  payload: DialogDataConfig<TicketDetails & { name?: string; closeTicket?: boolean }>,
  isSoc: boolean | undefined
) {
  const actionUrl = isSoc
    ? api.socTicketAction(payload.item.eventId)
    : api.ticketAction(payload.item.eventId);

  return {
    ...actionUrl,
    data: {
      action: payload.action,
      service: getServiceName(payload.item),
      closeTicket: payload.item.closeTicket,
    },
  };
}

export function addEncryptDriveMetadata(
  request: AxiosRequestConfig,
  payload: DialogDataConfig<TicketDetails & { name?: string; closeTicket?: boolean }>
) {
  request.data.metadata = {
    driveLetter: payload.item?.sections?.deviceMetaData?.unencryptedFileStorage?.name,
  };
}

export function handleTicketActionSnackbar(
  payload: DialogDataConfig<TicketDetails & { name?: string; closeTicket?: boolean }, TicketAction>,
  isSoc: boolean | undefined
) {
  const createSnackbarMessage = (
    users: string[],
    violationDirection: ViolationDirection
  ): SnackbarItemConfig => {
    const getSnackbarTranslationKey = (payload: {
      action?: TicketAction;
      item?: Ticket | TicketDetails;
    }) => {
      const applySpecificApproveDescription = [
        TicketTrigger.MISSING_REQUIRED_AUTHENTICATION,
        TicketTrigger.CROWDBLOCKED_SENDER,
        TicketTrigger.BLOCKLISTED_SENDER,
      ].includes(payload.item!.eventTrigger);

      if (payload.action === TicketAction.GENERAL_APPROVE && applySpecificApproveDescription) {
        return `snackbar.messages.event.specificApprove.${payload.item?.eventTrigger}`;
      }

      return `snackbar.messages.event.${payload.action}`;
    };

    const snackbarItem = payload.item?.name;
    const dlpShortTriggerName = i18n.global.t(
      `ticketDetails.fullDetails.accessViolations.${payload.item.eventTrigger}`
    );

    const quantity =
      payload.action === TicketAction.ADD_TO_DATA_GOVERNANCE_PERMISSIONS ? users.length : 1;

    return {
      html: i18n.global.t(
        getSnackbarTranslationKey(payload),
        {
          item: snackbarItem,
          users: users.join(", "),
          violationDirection: i18n.global.t(`modals.${payload.action}.${violationDirection}`),
          trigger: dlpShortTriggerName,
        },
        quantity
      ),
      type: SnackbarTypes.SUCCESS,
    } as SnackbarItemConfig;
  };

  const createClosedTicketSnackbar = (): SnackbarItemConfig => {
    const item = payload.item?.name;

    return {
      html: i18n.global.t("snackbar.messages.event.markAsProcessed", { item }, 1),
      type: SnackbarTypes.SUCCESS,
    } as SnackbarItemConfig;
  };

  const snackbarStore = useSnackbarStore();
  const users = payload.item?.sections?.userDataAccessViolation?.users ?? [];
  const violationDirection =
    payload.item?.sections?.userDataAccessViolation?.violationDirection ?? ViolationDirection.SHARE;

  const snackbarMessage = createSnackbarMessage(users, violationDirection);
  const closedTicketSnackbar = createClosedTicketSnackbar();

  const ticketsWithButtonInSnackbar = [
    TicketAction.MARK_AS_PROCESSED,
    TicketAction.MARK_AS_UNPROCESSED,
  ];

  if (ticketsWithButtonInSnackbar.includes(payload.action!)) {
    snackbarStore.add(
      snackbarWithViewBtn(snackbarMessage, { action: payload.action!, item: payload.item }, isSoc)
    );
  } else if (payload.item.closeTicket) {
    snackbarStore.add(
      snackbarWithViewBtn(
        closedTicketSnackbar,
        { action: payload.action!, item: payload.item },
        isSoc
      )
    );
  } else {
    snackbarStore.add(snackbarMessage);
  }
}

export function handleBulkActionSnackbar(
  { action, closeTicket, item, selection: { include } }: ApplyTicketBulkActionPayload,
  appliedActions: number,
  isSoc: boolean | undefined
) {
  const snackbarStore = useSnackbarStore();

  const ticketsWithButtonInSnackbar = [
    TicketAction.MARK_AS_PROCESSED,
    TicketAction.MARK_AS_UNPROCESSED,
  ];

  const snackbarMessage = (quantity: number = 1) =>
    ({
      html: i18n.global.t(`snackbar.messages.event.${action}`, { item, quantity }, quantity),
      type: SnackbarTypes.SUCCESS,
    }) as SnackbarItemConfig;

  const shouldShowViewButton =
    include.length === 1 && (ticketsWithButtonInSnackbar.includes(action) || closeTicket);
  if (shouldShowViewButton) {
    snackbarStore.add(
      snackbarWithViewBtn(
        snackbarMessage(),
        {
          action,
          item: include[0],
          closeTicket,
        },
        isSoc
      )
    );
  } else {
    snackbarStore.add(snackbarMessage(appliedActions));
  }
}

function getServiceName(item: TicketDetails) {
  if (item.sections?.serviceName) {
    return item.sections.serviceName;
  }
  return "";
}

function snackbarWithViewBtn(
  snackbar: SnackbarItemConfig,
  payload: {
    action: TicketAction;
    item?: { eventId: string };
    closeTicket?: boolean; // Used in soc
  },
  isSoc: boolean | undefined
) {
  snackbar.callback = async () => {
    const routeName = isSoc ? RouteName.SOC_TICKETS : RouteName.TICKETS;
    const context = isSoc ? FilterContext.SOC_TICKETS : FilterContext.TICKETS;
    const status =
      payload.action === TicketAction.MARK_AS_UNPROCESSED ? TicketStatus.OPEN : TicketStatus.CLOSED;

    const filtersStore = useFiltersStore();

    filtersStore.setFilters(context, {
      ...filtersStore.filters[context],
      status,
      search: payload.item?.eventId,
    });
    await router.push({
      name: routeName,
      query: { status, search: payload.item?.eventId },
    });
  };
  snackbar.callbackName = i18n.global.t("general.view");
  return snackbar;
}

export const getThreatLevelColor = (threatLevel?: AnomalyIpThreatLevel): string[] => {
  if (isGlobalAccessRestricted(GlobalRoleScopes.GLOBAL_SCOPE, GlobalRoleScopeSection.SOC_PORTAL)) {
    return [];
  }

  if (threatLevel === AnomalyIpThreatLevel.HIGH) {
    return ["text-red-dark"];
  } else if (threatLevel === AnomalyIpThreatLevel.MEDIUM) {
    return ["text-yellow-base"];
  }
  return [];
};

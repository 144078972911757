import { defineStore } from "pinia";
import { axiosInstance } from "@/plugins/https";
import api from "@/_helpers/api";
import type { GetProcessGraphParams } from "@/_helpers/api.types";
import type { ProcessGraphAction, EdrProcessStatus } from "@/constants/edr";

interface ProcessGraphModuleState {
  processGraph: ProcessGraphNode[];
  details: ProcessGraphNodeDetails;
  isProcessGraphLoading: boolean;
  isDetailsLoading: boolean;
}

interface ProcessGraphNodeDetails {
  processId: string;
  processName: string;
  pid?: number;
  userName?: string;
  executionTime?: number;
  commandLineArguments?: string;
  persistence?: string[];
  persistenceStringified?: string;
  imageFileLocation?: string;
  hash: string;
  fileSize?: number;
  fileCreationTime?: number;
  lastModifiedTime?: number;
  signatureStatus?: string;
  verificationStatus?: string;
  status?: EdrProcessStatus;
  lastStatusModifiedTime?: number;
  actions: ProcessGraphAction[];
}

export interface ProcessGraphNode {
  id: string;
  name: string;
  parentId: string;
  anchorProcess: boolean;
  childProcessesCount: number;
}

const defaultMyAccountState: ProcessGraphModuleState = {
  processGraph: [],
  details: {
    processId: "",
    processName: "",
    pid: 0,
    userName: "",
    executionTime: 0,
    commandLineArguments: "",
    persistence: [],
    imageFileLocation: "",
    hash: "",
    fileSize: 0,
    fileCreationTime: 0,
    lastModifiedTime: 0,
    signatureStatus: "",
    verificationStatus: "",
    actions: [],
    persistenceStringified: "",
  },
  isProcessGraphLoading: true,
  isDetailsLoading: true,
};

export const useProcessGraphStore = defineStore("processGraph", {
  state: (): ProcessGraphModuleState => ({ ...defaultMyAccountState }),
  actions: {
    resetState() {
      this.$reset();
    },
    async getProcessGraphData(params: GetProcessGraphParams) {
      const request = {
        ...api.getProcessGraph(params),
      };
      try {
        const { data } = await axiosInstance.request(request);
        this.processGraph = data;
      } catch (e) {
        console.error(e);
      } finally {
        this.isProcessGraphLoading = false;
      }
    },
    async getProcessGraphDetails(processId: string) {
      const request = {
        ...api.getProcessGraphDetails(processId),
      };
      this.isDetailsLoading = true;
      try {
        const { data } = await axiosInstance.request(request);
        this.details = data;
        this.details.processId = processId;
        this.details.persistenceStringified = this.details.persistence?.join("<br><br>");
      } catch (e) {
        console.error(e);
      } finally {
        this.isDetailsLoading = false;
      }
    },
    async performProcessGraphAction(payload: {
      processId: string;
      ticketId: string;
      action: ProcessGraphAction;
    }) {
      const request = {
        ...api.processGraphAction(payload.processId, payload.ticketId, payload.action),
      };
      try {
        await axiosInstance.request(request);
        await this.getProcessGraphDetails(this.details.processId);
      } catch (e) {
        console.error(e);
      }
    },
  },
});

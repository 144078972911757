import type { Ref } from "vue";
import { computed, ref, watch } from "vue";
import {
  bundlesContent,
  bundlesForAddons,
  bundlesForModules,
  modulesAndAddonsInBundleConfiguration,
  SubscriptionAddon,
  SubscriptionBundle,
  SubscriptionModule,
} from "@/constants/workplaces";
import isNil from "lodash/isNil";
import intersection from "lodash/intersection";
import difference from "lodash/differenceBy";
import type { SubscriptionModuleInfo } from "@/_store/subscription.module";
import type { Subscription } from "@/_store/msp.module";
import type { GenericCallback } from "@/types";
import uniq from "lodash/uniq";
import { getSelectedModules } from "@/_helpers/utils";

export const endpointBundles = [
  SubscriptionBundle.ENDPOINT_PROTECTION,
  SubscriptionBundle.MANAGED_ENDPOINT_PROTECTION,
];

export const emailBundles = [
  SubscriptionBundle.EMAIL_PROTECTION,
  SubscriptionBundle.MANAGED_EMAIL_PROTECTION,
];

export const accessBundles = [SubscriptionBundle.SASE, SubscriptionBundle.MANAGED_SASE];

export const coroEssentialsBundles = [
  SubscriptionBundle.CORO_ESSENTIALS,
  SubscriptionBundle.MANAGED_CORO_ESSENTIALS,
];

export const coroClassicBundles = [
  SubscriptionBundle.CORO_CLASSIC,
  SubscriptionBundle.MANAGED_CORO_CLASSIC,
];

export const coroCompleteBundles = [
  SubscriptionBundle.CORO_COMPLETE,
  SubscriptionBundle.MANAGED_CORO_COMPLETE,
];

export function useSubscriptionEditor(
  subscription: Ref<Subscription>,
  subscriptionChangedCallback: GenericCallback
) {
  const endpointBundle = ref();
  const emailBundle = ref();
  const accessBundle = ref();
  const coroEssentialsBundle = ref();
  const coroClassicBundle = ref();
  const coroCompleteBundle = ref();

  const selectedBundles = computed<SubscriptionBundle[]>(() => {
    return [
      accessBundle.value,
      emailBundle.value,
      endpointBundle.value,
      coroEssentialsBundle.value,
      coroClassicBundle.value,
      coroCompleteBundle.value,
    ].filter((v) => !isNil(v));
  });

  const hasAtLeastOneSocAddon = computed<boolean>(() => {
    const selectedAddons = getSelectedAddons();
    return selectedAddons.some((addon: SubscriptionAddon) => {
      return isSocAddon(addon);
    });
  });

  const hasAtLeastOneModuleIncludedInBundle = computed<boolean>(() => {
    const selectedModules = getSelectedModules(subscription.value);
    return selectedModules.some((module: SubscriptionModule) => {
      return moduleIncludedInBundle(module);
    });
  });

  const isBundleSelected = (value: SubscriptionBundle) =>
    subscription.value.bundles.includes(value);

  const moduleIncludedInBundle = (module: string) =>
    intersection(bundlesForModules[module as SubscriptionModule], subscription.value.bundles)
      .length > 0;

  const isSocAddon = (addon: SubscriptionAddon) => {
    const socAddons = [
      SubscriptionAddon.SOC_ENDPOINT,
      SubscriptionAddon.SOC_EMAIL,
      SubscriptionAddon.SOC_NETWORK,
      SubscriptionAddon.SOC_ENDPOINT_DATA_GOVERNANCE,
      SubscriptionAddon.MDR,
      SubscriptionAddon.SOC_CLOUD,
      SubscriptionAddon.SOC_USER_DATA_GOVERNANCE,
    ];
    return socAddons.includes(addon);
  };

  const addonIncludedInBundle = (addon: string) =>
    intersection(
      bundlesForAddons[addon as Exclude<SubscriptionAddon, "soc" | "outboundGateway">],
      subscription.value.bundles
    ).length > 0;

  const handleModuleAdding = (currentBundles: SubscriptionBundle[]) => {
    currentBundles.forEach((bundle) => {
      const modulesAndAddonsInBundle = modulesAndAddonsInBundleConfiguration[bundle];
      const modules = Object.keys(modulesAndAddonsInBundle) as SubscriptionModule[];
      modules.forEach((module) => {
        const moduleSetting = subscription.value.modules[module];
        moduleSetting.enabled = true;
        moduleSetting.addons = moduleSetting.addons.map((item) => {
          const enabled = item.enabled
            ? true
            : modulesAndAddonsInBundle[module]!.includes(item.name as SubscriptionAddon);
          return {
            ...item,
            enabled,
          };
        });
      });
    });
  };

  const syncAddons = (modulesToSync: SubscriptionModule[]) => {
    const selectedAddons = getSelectedAddons();
    selectedBundles.value.forEach((bundle) => {
      const modulesAndAddonsInBundle = modulesAndAddonsInBundleConfiguration[bundle];
      const modules = intersection(modulesToSync, Object.keys(modulesAndAddonsInBundle));
      modules.forEach((module) => {
        const moduleSetting = subscription.value.modules[module as SubscriptionModule];
        moduleSetting.addons = moduleSetting.addons.map((item) => {
          return {
            ...item,
            enabled: selectedAddons.includes(item.name as SubscriptionAddon),
          };
        });
      });
    });
  };

  // Function declarations below because used when creating variables/computed, not to put the function in the start of the script
  function getSelectedAddons() {
    const accessAddons = accessBundle.value
      ? bundlesContent[accessBundle.value as SubscriptionBundle].addons
      : [];
    const emailAddons = emailBundle.value
      ? bundlesContent[emailBundle.value as SubscriptionBundle].addons
      : [];
    const endpointAddons = endpointBundle.value
      ? bundlesContent[endpointBundle.value as SubscriptionBundle].addons
      : [];
    const coroEssentialsAddons = coroEssentialsBundle.value
      ? bundlesContent[coroEssentialsBundle.value as SubscriptionBundle].addons
      : [];
    const coroClassicAddons = coroClassicBundle.value
      ? bundlesContent[coroClassicBundle.value as SubscriptionBundle].addons
      : [];
    const coroCompleteAddons = coroCompleteBundle.value
      ? bundlesContent[coroCompleteBundle.value as SubscriptionBundle].addons
      : [];
    return uniq([
      ...accessAddons,
      ...emailAddons,
      ...endpointAddons,
      ...coroEssentialsAddons,
      ...coroClassicAddons,
      ...coroCompleteAddons,
    ]);
  }

  const handleModuleDeletion = (
    turnedOffModule: SubscriptionBundle,
    currentBundles: SubscriptionBundle[]
  ) => {
    const turnedOffModules = bundlesContent[turnedOffModule].modules;
    const currentModules = currentBundles.flatMap((v) => bundlesContent[v].modules);
    const modulesToDisable = difference(turnedOffModules, currentModules);
    const modulesToSync = intersection(currentModules, turnedOffModules);
    modulesToDisable.forEach((module) => {
      const moduleSetting: SubscriptionModuleInfo = subscription.value.modules[module];
      moduleSetting.enabled = false;
      moduleSetting.addons = moduleSetting.addons.map((item) => {
        return {
          ...item,
          enabled: false,
        };
      });
    });
    syncAddons(modulesToSync);
  };

  watch(selectedBundles, (newVal) => {
    subscription.value.bundles = newVal;
  });

  watch(
    () => subscription.value.bundles,
    (newVal, oldValue) => {
      const turnedOffBundle: SubscriptionBundle = difference(oldValue, newVal)[0];
      if (turnedOffBundle) {
        handleModuleDeletion(turnedOffBundle, newVal);
      } else {
        handleModuleAdding(newVal);
      }
      subscriptionChangedCallback(subscription.value);
    },
    { deep: true }
  );

  return {
    endpointBundle,
    emailBundle,
    accessBundle,
    coroEssentialsBundle,
    coroClassicBundle,
    coroCompleteBundle,
    selectedBundles,
    isBundleSelected,
    moduleIncludedInBundle,
    addonIncludedInBundle,
    handleModuleAdding,
    syncAddons,
    getSelectedAddons,
    isSocAddon,
    getSelectedModules,
    handleModuleDeletion,
    hasAtLeastOneSocAddon,
    hasAtLeastOneModuleIncludedInBundle,
  };
}

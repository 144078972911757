<template>
  <div>
    <v-row v-for="(pair, index) in localValue" :key="index">
      <v-col class="key-part" :cols="config.key.cols" :sm="config.key.sm" :md="config.key.md">
        <template v-if="config.key.inputType === KeyValueInputType.TEXT">
          <v-text-field
            v-model.trim="pair[config.key.field]"
            :rules="config.key.rules"
            :label="pair[config.key.field] ? keyLabel : ''"
            :placeholder="config.key.placeholder || keyLabel"
            :disabled="config.key.disabled"
            variant="outlined"
          />
        </template>
      </v-col>
      <v-col
        class="value-part"
        :cols="config.value.cols"
        :sm="config.value.sm"
        :md="config.value.md"
      >
        <template v-if="config.value.inputType === KeyValueInputType.TEXT">
          <v-text-field
            v-model.trim="pair[config.value.field]"
            :rules="config.value.rules"
            :label="pair[config.value.field] ? valueLabel : ''"
            :placeholder="config.value.placeholder || valueLabel"
            :append-icon="showRemoveButton ? '$x' : ''"
            variant="outlined"
            @click:append="removePair(index as number)"
          />
        </template>
        <template v-else-if="config.value.inputType === KeyValueInputType.SELECT">
          <v-select
            v-model="pair[config.value.field]"
            :rules="config.value.rules"
            :label="pair[config.value.field] ? valueLabel : ''"
            :placeholder="config.value.placeholder || valueLabel"
            :items="config.value.items"
            :append-icon="showRemoveButton ? '$x' : ''"
            variant="outlined"
            @click:append="removePair(index as number)"
          >
            <template #selection="data">
              {{ data.item.title }}
            </template>
            <template #item="{ props, item }">
              <v-list-item v-bind="props" :title="item.title"></v-list-item>
            </template>
          </v-select>
        </template>
      </v-col>
    </v-row>
    <a v-if="showAddBtn" class="d-inline-block body2 mt-2 coro-link" @click="addPair()">
      {{ $t("modals.addSiemConnector.addBtn") }}
    </a>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import type { PropType, Ref } from "vue";
import defaultsDeep from "lodash/defaultsDeep";
import isEqual from "lodash/isEqual";

export enum KeyValueInputType {
  TEXT = "text",
  SELECT = "select",
}

const defaultConfig = {
  rules: [],
  placeholder: "",
  sm: 6,
  inputType: KeyValueInputType.TEXT,
  items: [],
};

const defaultKeyConfig = {
  field: "key",
  cols: 3,
  md: 3,
  disabled: false,
  ...defaultConfig,
};

const defaultValueConfig = {
  field: "value",
  cols: 9,
  md: 9,
  ...defaultConfig,
};

export default defineComponent({
  name: "KeyValueInput",
  props: {
    value: {
      type: Array as PropType<Record<string, string>[]>,
      default: () => [],
    },
    keyLabel: {
      type: String,
      required: true,
    },
    keyConfig: {
      type: Object,
      default: defaultKeyConfig,
    },
    valueLabel: {
      type: String,
      default: "",
    },
    valueConfig: {
      type: Object,
      default: defaultValueConfig,
    },
    showAddBtn: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:value"],
  setup(props, { emit }) {
    const localValue: Ref<Record<string, string>[]> = ref(props.value);

    const config = ref({
      key: defaultsDeep(props.keyConfig, defaultKeyConfig),
      value: defaultsDeep(props.valueConfig, defaultValueConfig),
    });

    watch(
      () => props.keyConfig,
      (updatedKeyConfig, oldValue) => {
        if (!isEqual(updatedKeyConfig, oldValue)) {
          config.value.key = defaultsDeep(updatedKeyConfig, defaultKeyConfig);
        }
      }
    );

    watch(
      () => props.value,
      (updatedValue, oldValue) => {
        if (!isEqual(updatedValue, oldValue)) {
          localValue.value = JSON.parse(JSON.stringify(updatedValue));
        }
      }
    );

    const showRemoveButton = computed(() => localValue.value.length > 1);

    const addPair = () => {
      localValue.value.push({
        [config.value.key.field]: "",
        [config.value.value.field]: "",
      });
    };

    const removePair = (index: number) => {
      localValue.value.splice(index, 1);
    };

    watch(
      localValue,
      (value, oldValue) => {
        if (!isEqual(value, oldValue)) {
          emit("update:value", value);
        }
      },
      { deep: true }
    );

    onMounted(() => {
      if (!localValue.value.length) {
        addPair();
      }
    });

    return {
      localValue,
      config,
      KeyValueInputType,
      addPair,
      removePair,
      showRemoveButton,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(.key-part) {
  .v-field {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .v-field__outline__end {
    border-right: none;
  }

  .v-field:hover {
    .v-field__outline__end {
      border-right: 1px solid rgb(var(--v-theme-primary));
    }
  }

  .v-field--error {
    .v-field__outline__end {
      border-right: 2px solid rgb(var(--v-theme-red-dark));
    }
  }

  .v-field--focused:not(.v-field--error) {
    .v-field__outline__end {
      border-right: 2px solid rgb(var(--v-theme-primary));
    }
  }
}

:deep(.value-part) {
  .v-field {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

:deep(*) {
  .key-part {
    padding: 0 0 0 12px;

    .error--text fieldset {
      border-right: 1px solid rgb(var(--v-theme-red-dark)) !important;
    }

    .v-input--is-focused:not(.error--text) fieldset {
      border-right-width: 1px solid rgb(var(--v-theme-primary)) !important;
    }
  }
  .value-part {
    padding: 0 12px 0 0;
  }
}
</style>

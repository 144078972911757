<template>
  <div v-if="modelNotEmpty">
    <div class="subtitle1 my-2">{{ $t("general.filters") }}</div>
    <filter-wrapper :show-clear-button="false" class="mt-2">
      <div v-if="exportType === ExportCategory.ACTIVITY_LOGS" class="d-flex flex-column">
        <v-select
          v-model="model.activityLogs.types"
          :items="activityLogTypes"
          bg-color="white"
          menu-icon="icon-triangle"
          item-value="id"
          item-title="name"
          :menu-props="{ maxHeight: '300' }"
          class="filter-menu filter mb-2"
          :class="{ 'filter-active': model.activityLogs.types?.length }"
          :placeholder="$t('activityLogs.filters.types')"
          density="compact"
          variant="outlined"
          multiple
          rounded
          hide-details
          background-color="white"
          return-object
        >
          <template #selection="{ index }">
            <span v-if="index === 0" class="body2">{{ $t("activityLogs.filters.types") }}</span>
          </template>
          <template #label>
            <span v-if="model.activityLogs.types?.length" class="filter-label">
              {{ model.activityLogs.types.length }}
            </span>
          </template>
          <template #item="{ item, props }">
            <v-list-item v-bind="props" title="">
              <template v-slot:prepend="{ isActive }">
                <v-list-item-action start>
                  <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
                </v-list-item-action>
              </template>

              <v-list-item-title title="">
                {{ $t(`activityLogs.filters.type.${item.raw}`) }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-select>
      </div>
      <div v-else-if="exportType === ExportCategory.USERS" class="d-flex flex-column">
        <v-select
          v-model="model.users.cloudServices"
          :items="usersFilterItems"
          class="filter-menu cloud-services-filter mr-2"
          :class="{ 'filter-active': model.users.cloudServices?.length > 0 }"
          :placeholder="$t('general.cloudApps')"
          density="compact"
          variant="outlined"
          multiple
          rounded
          hide-details
          background-color="white"
          return-object
        >
          <template #selection="{ index }">
            <span v-if="index === 0" class="body2">{{ $t("general.cloudApps") }}</span>
          </template>
          <template #label>
            <span v-if="model.users.cloudServices?.length" class="filter-label">
              {{ model.users.cloudServices?.length }}
            </span>
          </template>
          <template #item="{ item, props }">
            <v-list-subheader v-if="'subheader' in item.raw" :title="item.raw.subheader" />
            <v-divider v-else-if="'divider' in item.raw" v-bind="props" />
            <v-list-item v-else v-bind="props" :title="$t(`services.${item.raw.name}`)">
              <template v-slot:prepend="{ isActive }">
                <v-list-item-action start>
                  <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
                </v-list-item-action>
              </template>
            </v-list-item>
          </template>
        </v-select>
      </div>
      <div v-else-if="exportType === ExportCategory.TICKETS" class="d-flex flex-column">
        <v-select
          v-model="model.tickets.widget"
          :items="widgets"
          :menu-props="{ maxHeight: '300' }"
          class="filter-menu widget-filter mt-2 fit"
          :class="{ 'filter-active': model.tickets.widget }"
          :placeholder="$t('tickets.filters.widgets.placeholder')"
          density="compact"
          item-value="name"
          item-title="name"
          rounded
          hide-details
          background-color="white"
        >
          <template #selection="{ item }">
            <span class="body2">
              {{ $t(`tickets.filters.widgets.${item.title}`) }}
            </span>
          </template>
          <template #item="{ item, props }">
            <v-list-item v-bind="props" :title="$t(`tickets.filters.widgets.${item.title}`)">
            </v-list-item>
          </template>
        </v-select>
        <v-select
          v-model="model.tickets.eventTriggers"
          :items="triggers"
          :menu-props="{ maxHeight: '300' }"
          class="filter-menu triggers-filter mt-2"
          :class="{
            'filter-active': !!model.tickets.eventTriggers?.length,
          }"
          :placeholder="$t('tickets.filters.type')"
          density="compact"
          variant="outlined"
          item-value="name"
          item-title="name"
          multiple
          rounded
          hide-details
          background-color="white"
        >
          <template #selection="{ index }">
            <span v-if="index === 0" class="body2">{{ $t("tickets.filters.type") }}</span>
          </template>
          <template #label>
            <span v-if="model.tickets.eventTriggers?.length" class="filter-label">{{
              model.tickets.eventTriggers.length
            }}</span>
          </template>
          <template #item="{ item, props }">
            <v-list-subheader v-if="item.raw.subheader" :title="item.raw.name" />
            <v-list-item v-else v-bind="props" title="">
              <template v-slot:prepend="{ isActive }">
                <v-list-item-action start>
                  <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
                </v-list-item-action>
              </template>

              <v-list-item-title :title="''">
                {{ $t(`tickets.eventTriggers.${item.raw.name}`) }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-select>
      </div>
      <div v-else-if="exportType === ExportCategory.DEVICES" class="d-flex flex-column">
        <v-select
          v-model="model.devices.labels"
          :items="labelItems"
          bg-color="white"
          menu-icon="icon-triangle"
          item-value="id"
          item-title="name"
          :menu-props="{ maxHeight: '300' }"
          class="filter filter-menu mt-2"
          :class="{ 'filter-active': model.devices.labels?.length }"
          :placeholder="$t('devices.filters.groups')"
          :no-data-text="$t('devices.filters.label.noData')"
          density="compact"
          variant="outlined"
          multiple
          rounded
          hide-details
          background-color="white"
          return-object
        >
          <template #selection="{ index }">
            <span v-if="index === 0" class="body2">{{ $t("devices.filters.groups") }}</span>
          </template>
          <template #label>
            <span v-if="model.devices.labels?.length" class="filter-label">
              {{ model.devices.labels.length }}
            </span>
          </template>
          <template #item="{ item, props }">
            <v-list-subheader v-if="'subheader' in item.raw" :title="item.raw.name" />
            <v-divider v-else-if="'divider' in item.raw" v-bind="props" />
            <v-list-item v-else v-bind="props" title="">
              <template v-slot:prepend="{ isActive }">
                <v-list-item-action start>
                  <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
                </v-list-item-action>
              </template>

              <v-list-item-title class="d-flex align-center label-popup-block" title="">
                <span class="color-preview mr-2" :style="{ 'background-color': item.raw.color }" />
                <span>{{ item.raw.name }}</span>
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-select>
        <v-select
          v-model="model.devices.osVersion"
          :items="osVersions"
          :menu-props="{ maxHeight: '300' }"
          class="os-version-filter filter-menu mt-2 fit"
          :class="{ 'filter-active': model.devices.osVersion }"
          :placeholder="$t('devices.filters.osVersion')"
          data-testid="devices-page-os-version-filter"
          density="compact"
          item-value="name"
          item-title="name"
          rounded
          hide-details
          background-color="white"
          clearable
          clear-icon="$x"
        >
          <template #selection="{ item }">
            <span class="body2">
              {{ item.title }}
            </span>
          </template>
          <template #item="{ item, props }">
            <v-list-subheader v-if="item.raw.subheader" :title="item.raw.name" />
            <v-list-item
              v-else
              :data-testid="`devices-page-os-version-filter-${item}-item`"
              v-bind="props"
            >
            </v-list-item>
          </template>
        </v-select>
        <v-select
          v-model="model.devices.clientVersion"
          :items="clientVersions"
          :menu-props="{ maxHeight: '300' }"
          class="filter filter-menu mt-2 fit"
          :class="{ 'filter-active': model.devices.clientVersion }"
          :placeholder="$t('devices.filters.clientVersion')"
          data-testid="devices-page-client-version-filter"
          density="compact"
          variant="outlined"
          item-value="name"
          item-title="name"
          rounded
          hide-details
          background-color="white"
          clearable
          clear-icon="$x"
        >
          <template #selection="{ item }">
            <span class="body2">
              {{ `Coro ${item.title}` }}
            </span>
          </template>
          <template #item="{ item, props }">
            <v-list-subheader v-if="item.raw.subheader" :title="item.raw.name" />
            <v-list-item
              v-else
              v-bind="props"
              :data-testid="`devices-page-client-version-filter-${item}-item`"
            >
            </v-list-item>
          </template>
        </v-select>
        <v-select
          v-model="model.devices.vulnerabilities"
          :items="Object.values(DeviceVulnerability)"
          :menu-props="{ maxHeight: '300' }"
          class="filter filter-menu mt-2"
          :class="{
            'filter-active': model.devices.vulnerabilities && model.devices.vulnerabilities.length,
          }"
          :placeholder="$t('devices.filters.vulnerability')"
          density="compact"
          variant="outlined"
          multiple
          rounded
          hide-details
          background-color="white"
        >
          <template #selection="{ index }">
            <span v-if="index === 0" class="body2">{{ $t("devices.filters.vulnerability") }}</span>
          </template>
          <template #label>
            <span v-if="model.devices.vulnerabilities?.length" class="filter-label">{{
              model.devices.vulnerabilities.length
            }}</span>
          </template>
          <template #item="{ item, props }">
            <v-list-item v-bind="props" :title="$t(`tickets.eventTriggers.${camelCase(item.raw)}`)">
              <template v-slot:prepend="{ isActive }">
                <v-list-item-action start>
                  <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
                </v-list-item-action>
              </template>
            </v-list-item>
          </template>
        </v-select>
      </div>
    </filter-wrapper>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  type PropType,
  type Ref,
  ref,
  toRefs,
  watch,
} from "vue";
import { ExportCategory } from "@/_store/exports.module";
import { defaultFiltersState } from "@/_store/filters.module";
import FilterWrapper from "@/components/FilterWrapper.vue";
import { ActivityLogsType } from "@/constants/activity-logs";
import { TicketTrigger, eventTriggerFilterMap, TicketsModuleFilter } from "@/constants/tickets";
import { type DeviceStatusFilterItem, useDevicesStore } from "@/_store/devices.module";
import { useDevicesSettingsStore } from "@/_store/devices-settings.module";
import { DeviceVulnerability, OsType } from "@/constants/devices";
import { storeToRefs } from "pinia";
import { camelCase } from "lodash";
import { useI18n } from "vue-i18n";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import type { ScheduledExportItemFilters } from "@/_store/scheduled-reports.module";
import { createUsersFiltersWithProxyDomains } from "@/_store/cloud-apps/adapters";
import { defaultUsersExceptionsTabFilters } from "@/_store/users-settings/user-exceptions-tab.module";

export default defineComponent({
  components: { FilterWrapper },
  props: {
    exportType: {
      type: String as PropType<ExportCategory>,
      required: true,
    },
    filters: {
      type: Object as PropType<ScheduledExportItemFilters>,
      required: true,
    },
  },
  emits: ["update:filters"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const { exportType } = toRefs(props);
    const model: Ref<ScheduledExportItemFilters> = ref(cloneDeep(props.filters));
    const devicesStore = useDevicesStore();
    const devicesSettingsStore = useDevicesSettingsStore();
    const osVersions = ref<Array<{ subheader?: true; name: string }>>([]);
    const clientVersions = ref<Array<{ subheader?: true; name: string }>>([]);
    const { osVersions: osVersionsFromStore, clientVersions: clientVersionsFromStore } =
      storeToRefs(devicesStore);
    const modelNotEmpty = computed(() => {
      return !isEmpty(model.value);
    });
    const usersFilterItems = computed(() => {
      return createUsersFiltersWithProxyDomains([]);
    });

    const triggers = computed<{ subheader?: boolean; name: string }[]>(() => {
      const ticketsFilters = model.value.tickets;
      return ticketsFilters.widget
        ? [
            {
              subheader: true,
              name: i18n.t(`tickets.filters.widgets.${ticketsFilters.widget}`),
            },
            ...sortTriggers(eventTriggerFilterMap[ticketsFilters.widget]).map((v) => ({
              name: v,
            })),
          ]
        : Object.entries(eventTriggerFilterMap).flatMap(([widget, triggers]) => [
            {
              subheader: true,
              name: i18n.t(`tickets.filters.widgets.${widget}`),
            },
            ...sortTriggers(triggers).map((v) => ({ name: v })),
          ]);
    });

    const sortTriggers = (triggers: TicketTrigger[]): TicketTrigger[] => {
      // If $te (Translation exists) take event trigger translation, else event type translation
      const getTriggerOrTypeTranslation = (item: string) =>
        i18n.te(`tickets.eventTriggers.${item}`)
          ? i18n.t(`tickets.eventTriggers.${item}`)
          : i18n.t(`tickets.eventTypes.${item}`);
      return triggers.slice().sort((a, b) => {
        return getTriggerOrTypeTranslation(a).localeCompare(getTriggerOrTypeTranslation(b));
      });
    };

    const labelItems = computed(() => {
      const labels = devicesSettingsStore.labels ?? [];
      const statuses = devicesStore.statuses ?? [];

      const result = [];
      if (statuses.length) {
        result.push({ subheader: true, name: i18n.t("devices.filters.status") }, ...statuses, {
          divider: true,
        });
      }
      result.push({ subheader: true, name: i18n.t("devices.filters.labels") }, ...labels);
      return result as (
        | { divider: true }
        | { name: string; subheader: true }
        | DeviceStatusFilterItem
      )[];
    });

    const populateVersionsList = () => {
      const osTypesForVersions = [OsType.WINDOWS, OsType.OSX, OsType.IOS, OsType.ANDROID] as const;
      osVersions.value = [];
      clientVersions.value = [];
      osTypesForVersions.forEach((neededVersion) => {
        const lowercaseVersion = neededVersion.toLowerCase() as Lowercase<
          Exclude<OsType, OsType.MAC_OS>
        >;
        if (osVersionsFromStore.value[lowercaseVersion]?.length) {
          osVersions.value.push(
            {
              subheader: true,
              name: i18n.t(`general.osVersion.${lowercaseVersion}`),
            },
            ...osVersionsFromStore.value[lowercaseVersion]!.map((v) => ({
              name: v,
            }))
          );
        }

        if (clientVersionsFromStore.value?.[lowercaseVersion]?.length) {
          clientVersions.value.push(
            {
              subheader: true,
              name: i18n.t(`general.osVersion.${lowercaseVersion}`),
            },
            ...clientVersionsFromStore.value[lowercaseVersion]!.slice()
              .sort()
              .reverse()
              .map((v) => ({ name: v }))
          );
        }
      });
    };

    onMounted(async () => {
      await devicesStore.init();
      populateVersionsList();
    });

    watch(
      () => model.value,
      (newVal) => {
        emit("update:filters", newVal);
      },
      { deep: true }
    );

    watch(
      exportType,
      (newVal) => {
        switch (newVal) {
          case ExportCategory.ACTIVITY_LOGS:
            model.value.activityLogs = {
              ...defaultFiltersState.activityLogsFilters,
            };
            break;
          case ExportCategory.TICKETS:
            model.value.tickets = { ...defaultFiltersState.ticketsFilters };
            break;
          case ExportCategory.DEVICES:
            model.value.devices = { ...defaultFiltersState.devicesFilters };
            break;
          case ExportCategory.USERS:
            model.value.users = { ...defaultUsersExceptionsTabFilters };
            break;
        }
      },
      { deep: true }
    );

    return {
      model,
      modelNotEmpty,
      camelCase,
      activityLogTypes: Object.values(ActivityLogsType),
      widgets: Object.values(TicketsModuleFilter),
      triggers,
      DeviceVulnerability,
      clientVersions,
      osVersions,
      labelItems,
      ExportCategory,
      usersFilterItems,
    };
  },
});
</script>

<style lang="scss" scoped>
.filter,
.filter-menu,
.range-picker {
  width: 240px !important;
}
</style>

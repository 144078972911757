<template>
  <div>
    <div class="subtitle1 mb-1">
      {{ $t("modals.exportProtectedUsers.subtitle") }}
    </div>
    <v-radio-group v-model="localValue.type" hide-details>
      <v-radio
        v-for="choice in [ExportChoice.ALL, ExportChoice.SPECIFIC]"
        :key="choice"
        class="mb-3"
        :value="choice"
        :ripple="false"
        :label="$t(`modals.exportProtectedUsers.choices.${choice}`)"
      ></v-radio>
    </v-radio-group>
    <filter-wrapper :show-clear-button="false" class="mt-2">
      <v-select
        v-model="localValue.filters.cloudServices"
        :items="filterItems"
        class="filter-menu cloud-services-filter mr-2"
        :disabled="localValue.type === ExportChoice.ALL"
        :class="{ 'filter-active': localValue.filters.cloudServices?.length > 0 }"
        :placeholder="$t('general.cloudApps')"
        density="compact"
        variant="outlined"
        multiple
        rounded
        hide-details
        background-color="white"
        return-object
      >
        <template #selection="{ index }">
          <span v-if="index === 0" class="coro-body2">{{ $t("general.cloudApps") }}</span>
        </template>
        <template #label>
          <span v-if="localValue.filters.cloudServices?.length" class="filter-label">
            {{ localValue.filters.cloudServices?.length }}
          </span>
        </template>
        <template #item="{ item, props }">
          <v-list-subheader v-if="'subheader' in item.raw" :title="item.raw.subheader" />
          <v-divider v-else-if="'divider' in item.raw" v-bind="props" />
          <v-list-item v-else v-bind="props" :title="$t(`services.${item.raw.name}`)">
            <template v-slot:prepend="{ isActive }">
              <v-list-item-action start>
                <v-checkbox-btn density="compact" :model-value="isActive"></v-checkbox-btn>
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>
      </v-select>
    </filter-wrapper>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType, ref, watch } from "vue";
import { ExportChoice, type ExportDialogPayload } from "@/_store/exports.module";
import FilterWrapper from "@/components/FilterWrapper.vue";
import type { DialogDataConfig } from "@/_store/dialogs.module";
import { createUsersFiltersWithProxyDomains } from "@/_store/cloud-apps/adapters";
import type { UserExceptionsFilters } from "@/_store/users-settings/user-exceptions-tab.module";

export default defineComponent({
  components: { FilterWrapper },
  props: {
    config: {
      type: Object as PropType<DialogDataConfig>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const localValue = ref<ExportDialogPayload<UserExceptionsFilters>>({
      type: ExportChoice.ALL,
      filters: {
        searchTerm: "",
        cloudServices: [],
      },
    });

    const filterItems = computed(() => {
      return createUsersFiltersWithProxyDomains([]);
    });

    watch(
      localValue,
      (newVal) => {
        emit("update:localValue", newVal);
      },
      { deep: true, immediate: true }
    );

    return {
      localValue,
      ExportChoice,
      filterItems,
    };
  },
});
</script>

<style lang="scss" scoped>
.filter-menu {
  width: 240px !important;
}
</style>

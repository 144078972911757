import { i18n } from "@/plugins/i18n";
import moment from "moment";
export const getCurrentDate = () => moment().format("YYYY-MM-DD");
export const currentDate = moment().format("YYYY-MM-DD");
export const firstDayThisMonth = moment().startOf("month").format("YYYY-MM-DD");
export const lastDayThisMonth = moment().endOf("month").format("YYYY-MM-DD");
export const sixDaysAgo = moment().subtract(6, "days").format("YYYY-MM-DD");
export const twoDaysAgo = moment().subtract(2, "days").format("YYYY-MM-DD");
export const thirtyDaysAgo = moment().subtract(29, "days").format("YYYY-MM-DD");
export const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
export const getNinetyDaysAgo = () => moment().subtract(90, "days").format("YYYY-MM-DD");
export const getNDaysAgo = (amount: number) =>
  moment().subtract(amount, "days").format("YYYY-MM-DD");

export const dateRangePresets = [
  {
    label: i18n.global.t("dateRangePicker.today"),
    value: [getCurrentDate(), currentDate],
  },
  {
    label: i18n.global.t("dateRangePicker.yesterday"),
    value: [yesterday, yesterday],
  },
  {
    label: i18n.global.t("dateRangePicker.lastThreeDays"),
    value: [twoDaysAgo, getCurrentDate()],
  },
  {
    label: i18n.global.t("dateRangePicker.lastSevenDays"),
    value: [sixDaysAgo, getCurrentDate()],
  },
  {
    label: i18n.global.t("dateRangePicker.lastThirtyDays"),
    value: [thirtyDaysAgo, getCurrentDate()],
  },
  {
    label: i18n.global.t("dateRangePicker.thisMonth"),
    value: [firstDayThisMonth, lastDayThisMonth],
  },
];

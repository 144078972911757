import { useAccountStore } from "@/_store/account.module";
import {
  addonsToModuleMap,
  defaultSubscriptionModulesConfig,
  SubscriptionAddon,
  SubscriptionModule,
  SubscriptionType,
  WorkspaceLocale,
} from "@/constants/workplaces";
import {
  type ModulesSettings,
  type SubscriptionAddonInfo,
  type SubscriptionModuleInfo,
  useSubscriptionStore,
} from "@/_store/subscription.module";
import type { DialogConfig } from "@/_store/dialogs.module";
import { defaultDialogsConfig } from "@/_store/dialogs.module";
import defaultsDeep from "lodash/defaultsDeep";
import cloneDeep from "lodash/cloneDeep";
import uniqBy from "lodash/uniqBy";
import { ModalWidth } from "@/constants/modals";
import { i18n } from "@/plugins/i18n";
import type {
  ComponentDialogConfig,
  ConfirmationDialogConfig,
  ListDialogConfig,
  TimeFrame,
} from "@/types";
import type { ISelection } from "@/_store/selector.module";
import { computed, type Ref, toRaw, type WritableComputedRef } from "vue";
import moment, { type MomentInput } from "moment/moment";
import type { UnixTimeFrame } from "@/_helpers/api.types";
import type { WorkspaceItem } from "@/_store/workspaces.module";
import { useWorkspacesStore } from "@/_store/workspaces.module";
import { RolePermissionAccessMode } from "@/constants/roles";
import { isObject } from "lodash";
import type {
  NestedWorkspacePermissions,
  RolePermissionsScope,
  WorkspaceRolePermissions,
} from "@/_store/roles.module";
import { OsType } from "@/constants/devices";
import type { AxiosRequestConfig } from "axios";
import { Service, ServiceStatus } from "@/constants/cloud-apps";
import type { CloudAppService } from "@/_store/cloud-apps/cloud-apps.module";
import get from "lodash/get";
import api from "@/_helpers/api";
import { countriesList, usaStatesList } from "@/constants/countries";
import { axiosInstance } from "@/plugins/https";
import isEmpty from "lodash/isEmpty";
import { TicketType } from "@/constants/tickets";
import ListModal from "@/components/modals/network/ListModal.vue";
import type { Subscription } from "@/_store/msp.module";
import { useMyAccountStore } from "@/_store/my-account.module.ts";
import { useFiltersStore } from "@/_store/filters.module.ts";
import sortBy from "lodash/sortBy";
import unionBy from "lodash/unionBy";
import router from "@/_helpers/router.ts";
import { RouteName } from "@/constants/routes.ts";

/**
 * Returns true if the provided parameter is the valid JSON.
 * @param item
 */
export const isJson = (item: any): boolean => {
  item = typeof item !== "string" ? JSON.stringify(item) : item;

  try {
    item = JSON.parse(item);
  } catch {
    return false;
  }

  return typeof item === "object" && item !== null;
};

/**
 * Parses JWT token
 * @param token
 * @returns {null|any}
 */
export const parseJwt = (token: string | null) => {
  try {
    if (!token) return;
    return JSON.parse(atob(token.split(".")[1]));
  } catch {
    return null;
  }
};

/**
 * Helper to handle virtual (aka infinite) scroll.
 * Merges old data with new data which arrived if we reached the end of scroll.
 * @param currentData
 * @param newData
 * @param id
 * @returns {Array|*}
 */
export const handleVirtualScrollData = <T>(
  currentData: Array<T>,
  newData: Array<T>,
  id: keyof T
): T[] => {
  if (currentData) {
    return uniqBy([...currentData, ...newData], id);
  }
  return newData;
};

export const generateDeviceId = () => {
  return `${window.crypto.getRandomValues(new Uint32Array(1))[0]}${+new Date().getTime()}`;
};

/**
 * Extracts Coro subdomain except for reserved subdomains (qa,dev,prod,beta,local).
 * @param hostname
 * @returns {null|string}
 */
export const extractCoroSubdomain = (hostname: string): null | string => {
  const subdomain = hostname.split(".coro.net")[0];
  const reservedSubdomains = [
    "bugatti-qa",
    "bugatti-dev",
    "secure",
    "beta",
    "localhost",
    "dev2",
    "major-dev-1",
    "major-dev-2",
    "minor-dev",
    "major-qa",
    "minor-qa",
    "hotfix-dev-qa",
    "major-beta",
    "minor-beta",
    "hotfix-beta",
  ];
  return reservedSubdomains.includes(subdomain) ? null : subdomain;
};

export const handleFaviconAndTitle = (subdomain: string) => {
  const subdomainsWithCustomFaviconAndTitle = ["domain-test", "keplersafe"];
  const domainsToDispayName: Record<string, string> = {
    keplersafe: "Kepler Safe",
    "domain-test": "Domain Test",
  };
  if (subdomainsWithCustomFaviconAndTitle.includes(subdomain)) {
    const favicon16 = document.getElementById("favicon-16") as HTMLLinkElement;
    const favicon32 = document.getElementById("favicon-16") as HTMLLinkElement;
    const faviconShortcut = document.getElementById("fav-shortcut") as HTMLLinkElement;
    const title = document.getElementById("browser-tab-title") as HTMLTitleElement;
    const favManifest = document.getElementById("fav-manifest") as HTMLLinkElement;
    const favBrowserConfig = document.getElementById("fav-browserconfig") as HTMLMetaElement;
    const awsUrl = "https://coro-favicon.s3.amazonaws.com";
    favicon16.href = `${awsUrl}/${subdomain}/favicon-16x16.png`;
    favicon32.href = `${awsUrl}/${subdomain}/favicon-32x32.png`;
    faviconShortcut.href = `${awsUrl}/${subdomain}/favicon.ico`;
    favManifest.href = `${awsUrl}/${subdomain}/site.webmanifest`;
    favBrowserConfig.content = `${awsUrl}/${subdomain}/browserconfig.xml`;
    title.innerText = `${domainsToDispayName[subdomain]} Console`;
  }
};

/**
 * Checks if module is disabled.
 * @param moduleName
 * @returns {boolean}
 */
export const isModuleDisabled = (moduleName: SubscriptionModule): boolean => {
  const subscriptionStore = useSubscriptionStore();
  return subscriptionStore.subscription.modules[moduleName]
    ? !subscriptionStore.subscription.modules[moduleName].enabled
    : true;
};
/**
 * Checks if addon is disabled by checking if module or addon is disabled.
 * @param addonName
 * @returns {boolean}
 */
export const isAddonDisabled = (addonName: SubscriptionAddon): boolean => {
  const subscriptionStore = useSubscriptionStore();
  const addonModuleName = addonsToModuleMap[addonName];
  if (!addonModuleName || isModuleDisabled(addonModuleName)) return true;
  const addon = subscriptionStore.subscription.modules[addonModuleName].addons.find(
    (v) => v.name === addonName
  );
  return addon ? !addon.enabled : true;
};

/**
 * Generic dialogs config constructor, use it when custom dialog config should be created
 * @param config
 */
export const dialogsConfigConstructor = (config: Partial<DialogConfig>): DialogConfig => {
  const defaultState = cloneDeep(defaultDialogsConfig);
  return defaultsDeep(config, defaultState);
};

/**
 * Creates an array consisting of numbers between start and end
 * @param {number} start - From which value range should start
 * @param {number} stop - Where range ends
 * @returns {number[]}
 * @example
 * createNumberArrayRange(3, 5) // [3,4,5]
 * createNumberArrayRange(5, 9) // [5,6,7,8,9]
 */
export const createNumberArrayRange = (start: number, stop: number) => {
  return Array.from({ length: stop - start + 1 }, (value, index) => start + index);
};

export const arrayToLowerCase = (array: string[]) => {
  return array.map((item) => item?.toLowerCase());
};
/**
 * Dialogs config constructor for confirmation dialogs, covers most of the cases of confirmation dialogs
 * @param item
 * @param action
 * @param callback
 * @param disable
 * @param disclaimer
 * @param text
 */
export const confirmationDialogsConfigConstructor = ({
  item,
  action,
  callback,
  disable,
  disclaimer,
  width,
  text,
}: ConfirmationDialogConfig): DialogConfig => {
  const dialogConfig = {
    width: width || ModalWidth.SMALL,
    header: {
      title: i18n.global.t(`modals.${action}.title`, { ...item }),
      close: true,
    },
    content: {
      html: text ?? i18n.global.t(`modals.${action}.description`, { ...item }),
    },
    disable: disable ?? isWorkspaceFrozen(),
    disclaimer: getDisclaimerText(disable, disclaimer),
    footer: {
      buttons: [
        {
          title: i18n.global.te(`modals.${action}.cancelBtn`)
            ? i18n.global.t(`modals.${action}.cancelBtn`)
            : i18n.global.t("general.cancel"),
          spacer: "before",
          type: "text",
          cancel: true,
        },
        {
          title: i18n.global.t(`modals.${action}.actionBtn`),
          color: "primary",
          callback: callback,
          cancel: false,
        },
      ],
    },
    data: {
      item,
      action,
    },
  };
  return dialogsConfigConstructor(dialogConfig);
};
/**
 * Generic constructor for dialogs with custom components
 * @param item
 * @param action
 * @param component
 * @param width
 * @param callback
 * @param disable
 * @param disclaimer
 * @param hideFooter
 * @param scrollable
 */
export const componentDialogsConfigConstructor = <T = any>({
  item,
  action,
  component,
  width,
  callback,
  closeCallback,
  disable,
  disclaimer,
  hideFooter,
  scrollable,
}: ComponentDialogConfig<T>): DialogConfig => {
  const dialogConfig: DialogConfig = {
    width,
    header: {
      title: i18n.global.t(`modals.${action}.title`, { ...item }),
      close: true,
    },
    content: { component },
    disable: disable ?? isWorkspaceFrozen(),
    footer: {
      buttons: [
        {
          title: i18n.global.t("general.cancel"),
          spacer: "before",
          type: "text",
          cancel: true,
        },
        {
          title: i18n.global.t(`modals.${action}.actionBtn`),
          color: "primary",
          callback,
          cancel: false,
        },
      ],
    },
    disclaimer: getDisclaimerText(disable, disclaimer),
    data: {
      item,
      action,
    },
    closeCallback,
    scrollable,
  };

  if (hideFooter) {
    dialogConfig.footer = null;
  }

  return dialogsConfigConstructor(dialogConfig);
};

export const listDialogConstructor = <T>({
  item,
  action,
  width,
}: ListDialogConfig<{ items: T[] }>): DialogConfig => {
  const dialogConfig: DialogConfig = {
    width: width ?? ModalWidth.MEDIUM,
    disable: false,
    disclaimer: "",
    header: {
      title: i18n.global.t(`modals.${action}.title`, { ...item, n: item?.items.length }),
      close: true,
    },
    content: {
      component: ListModal,
    },
    data: {
      item,
      action,
    },
    scrollable: true,
    footer: null,
  };
  return dialogsConfigConstructor(dialogConfig);
};

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const socAddonsEnabled = () => {
  return [
    SubscriptionAddon.SOC_EMAIL,
    SubscriptionAddon.SOC_CLOUD,
    SubscriptionAddon.SOC_ENDPOINT,
    SubscriptionAddon.SOC_ENDPOINT_DATA_GOVERNANCE,
    SubscriptionAddon.SOC_USER_DATA_GOVERNANCE,
    SubscriptionAddon.MDR,
  ].some((module) => !isAddonDisabled(module));
};

/**
 * Returns default disclaimer text for dialog component.
 * @param disable
 * @param disclaimer
 * @returns {string}
 */
export function getDisclaimerText(disable: boolean = false, disclaimer: string = ""): string {
  if (disable || isWorkspaceFrozen()) {
    return getDefaultDisclaimerText();
  }

  return disclaimer;
}

export function getDefaultDisclaimerText() {
  if (isWorkspaceFrozen()) {
    return i18n.global.t("general.subscriptionExpired");
  }

  return i18n.global.t("general.noPermissions");
}

/**
 * Transforms selection items from objects to array of strings.
 * @param selection
 * @param key
 * @returns {{include: string[], isAllPages: boolean, exclude: string[]}}
 */
export const getSelectionAsStringsArray = (
  selection: ISelection,
  key = "id"
): ISelection<string> => {
  return {
    isAllPages: selection.isAllPages,
    include: selection.include.map((item) => item[key]),
    exclude: selection.exclude.map((item) => item[key]),
  };
};

/**
 * Returns if event/ticket belongs to the Device type. Maintains the same logic as BE has.
 * @returns {boolean}
 * @param eventType
 */
export const isDeviceTicket = (eventType: TicketType): boolean => {
  return [
    TicketType.MALWARE_IN_DEVICE,
    TicketType.ENDPOINT_VULNERABILITY,
    TicketType.WIFI_PHISHING,
    TicketType.WIFI_FORBIDDEN_NETWORK,
    TicketType.SUSPECTED_COMPLIANCE_VIOLATION_BY_DEVICE,
  ].includes(eventType);
};

/**
 * Checks if string contains only white spaces
 * @param str
 * @returns {boolean}
 */
export const onlySpaces = (str: string) => {
  return str?.length ? str.trim().length === 0 : true;
};

export const downloadFile = (blob: Blob, fileName: string) => {
  if (!blob || !fileName) {
    console.warn("Can't download, blob or fileName is empty");
    return;
  }

  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.click();
  URL.revokeObjectURL(url);
  link.remove();
};

export const downloadFileByLink = (downloadLink: string) => {
  const linkEl = document.createElement("a");
  linkEl.href = downloadLink;
  document.body.appendChild(linkEl);
  linkEl.click();
  document.body.removeChild(linkEl);
};

export const convertTimeFrameToUnix = ({ start, end }: TimeFrame): UnixTimeFrame => {
  return {
    fromTime: moment(start).startOf("day").utc().valueOf(),
    toTime: moment(end).endOf("day").utc().valueOf(),
  };
};

/**
 * Get the number of days until a given timestamp.
 * If the date is the same day, it returns the days as is.
 * If the date is in the past, it prepends a '-' sign.
 *
 * @param {number} timestamp - The target timestamp in milliseconds.
 * @returns {string} - The formatted number of days until the timestamp.
 */
export const getDaysUntil = (timestamp: number): string => {
  const now = moment();
  const targetDate = moment(timestamp);

  // Handle same day, past, and future cases
  if (now.isSame(targetDate, "day")) {
    return i18n.global.t("general.days", { n: 0 }, 0);
  }

  return targetDate.isBefore(now) ? `-${targetDate.from(now, true)}` : targetDate.from(now, true);
};

/**
 * Creates two-way binding model with the same structure as BE response. Uses callback to update value (HTTP request in store).
 * @param initialValue - An initial value of the model. Should be a Ref.
 * @param updateCallback - Callback which will be called when a resulting variable will be updated.
 * @param restCallbackParameters - Rest of the parameters that are needed for the callback.
 */
export function useTwoWayBinding<T extends object, K extends any[], V>(
  initialValue: Ref<T>,
  updateCallback: (value: T, ...args: K) => V,
  ...restCallbackParameters: K
): WritableComputedRef<T> {
  const localModel = computed<T>({
    get() {
      return new Proxy<T>(initialValue.value, {
        set(obj, key, value) {
          localModel.value = { ...obj, [key]: value };
          return true;
        },
      });
    },
    set(newValue) {
      updateCallback(newValue, ...restCallbackParameters);
    },
  });
  return localModel;
}

export const getBetaTag = (addonOrModule: string) => {
  const workplacesStore = useWorkspacesStore();
  return (workplacesStore.betaComponents ?? []).find((comp) => comp.name === addonOrModule);
};

export const isWorkspaceFrozen = () => {
  const subscriptionStore = useSubscriptionStore();

  return subscriptionStore.subscription.currentPlan.subscriptionType === SubscriptionType.FREEZE;
};
// Make all properties non optional so that parameters
// to functions can be passed properly (`keyof` of selected scope) and autocomplete works
type RequiredWorkspaceRolePermissions = Required<WorkspaceRolePermissions>;

export function isWorkspaceFrozenOrActionRestricted<
  T extends RolePermissionsScope,
  K extends keyof RequiredWorkspaceRolePermissions[T],
  V extends RequiredWorkspaceRolePermissions[T][K] extends NestedWorkspacePermissions
    ? NonNullable<RequiredWorkspaceRolePermissions[T][K]["editAccessModePermission"]>
    : undefined,
>(scopeName: T, key: NonNullable<K>, nestedPermissionKey?: keyof V) {
  return isWorkspaceFrozen() || isActionRestricted(scopeName, key, nestedPermissionKey);
}

export function isActionRestricted<
  T extends RolePermissionsScope,
  K extends keyof RequiredWorkspaceRolePermissions[T],
  V extends RequiredWorkspaceRolePermissions[T][K] extends NestedWorkspacePermissions
    ? NonNullable<RequiredWorkspaceRolePermissions[T][K]["editAccessModePermission"]>
    : undefined,
>(scopeName: T, key: NonNullable<K>, nestedPermissionKey?: keyof V) {
  const permissions = getPermissionsByScopeNameAndKey(scopeName, key);
  // Filter case when permissions doesn't exist
  if (!permissions) return true;

  // Filter case when permissions is just string
  if (typeof permissions === "string") {
    return permissions !== RolePermissionAccessMode.EDIT;
  }

  // Filter case when permissions is an object of such shape
  // {
  //   accessMode: RolePermissionAccessMode;
  //   editAccessModePermission?: {[key: string]: boolean}
  // };

  if (typeof permissions === "object") {
    // If no key provided, then check if it's edit or not
    if (!nestedPermissionKey) {
      return permissions.accessMode !== RolePermissionAccessMode.EDIT;
    }

    // If key is provided, then check the particular action
    if (
      nestedPermissionKey &&
      permissions.accessMode === RolePermissionAccessMode.EDIT &&
      permissions.editAccessModePermission
    ) {
      return permissions.editAccessModePermission
        ? // @ts-ignore
          !permissions.editAccessModePermission[nestedPermissionKey]
        : true;
    }
  }

  return true;
}
export function isAccessRestricted<
  T extends RolePermissionsScope = RolePermissionsScope,
  K extends
    keyof RequiredWorkspaceRolePermissions[T] = keyof WorkspaceRolePermissions[RolePermissionsScope],
>(scopeName: T, key: K) {
  const permissions = getPermissionsByScopeNameAndKey(scopeName, key);
  if (!permissions) return true;

  if (typeof permissions === "string") {
    return permissions === RolePermissionAccessMode.NO_ACCESS;
  }
  return permissions.accessMode === RolePermissionAccessMode.NO_ACCESS;
}
export function getPermissionsByScopeNameAndKey<
  T extends RolePermissionsScope = RolePermissionsScope,
  K extends
    keyof RequiredWorkspaceRolePermissions[T] = keyof WorkspaceRolePermissions[RolePermissionsScope],
>(scopeName: T, key: K) {
  const accountStore = useAccountStore();
  const currentWorkspacePermissions = toRaw(accountStore.account.currentWorkspacePermissions);
  return get(currentWorkspacePermissions, [scopeName, key]) as
    | NestedWorkspacePermissions
    | RolePermissionAccessMode
    | undefined;
}
export const hasSeparator = (str: string, separator = ",") => !!str?.includes(separator);

export const clearFileTypes = (fileTypes: string[]) => {
  return fileTypes.map((type) => {
    return type.replace(/(^[^A-Z0-9]+)|([^A-Z0-9]+$)/gi, "");
  });
};

/**
 * WARNING: I'm using fetch here because I want to avoid axios interceptor to intercept this requests.
 * These requests are public and does not require adding headers.
 */

const fixedEncodeURIComponent = (str: string) =>
  encodeURIComponent(str).replace(/[!'()*]/g, (c) => `%${c.charCodeAt(0).toString(16)}`);

// helper for creating query string
const buildQuery = (params: object) => {
  if (!isObject(params)) return "";

  let query: string = Object.keys(params as object)
    .map((key) => {
      const paramValue = params[key as keyof typeof params];

      if (typeof paramValue === "object") {
        return buildQuery(paramValue);
      }

      return `${key}=${fixedEncodeURIComponent(paramValue)}`;
    })
    .filter((p) => !!p)
    .join("&");

  if (query) {
    query = `?${query}`;
  }

  return query;
};

export const getDownloadLink = async ({
  id,
  os,
  version,
}: {
  id: string;
  os?: string;
  version?: string;
}) => {
  const awsLambdaUrl =
    "https://a9yicb7x92.execute-api.us-east-1.amazonaws.com/bugatti-prod-get-url";
  const queryString = buildQuery({
    uuid: id,
    os,
    version,
    env: import.meta.env.VITE_CLIENT_ENV,
  });
  const response = await fetch(`${awsLambdaUrl}/${queryString}`);
  return await response.json();
};

export const getConvertedOSTypeForAWS = (osType: OsType.WINDOWS | OsType.OSX): "win" | "macos" => {
  const osTypesMap = {
    [OsType.WINDOWS]: "win",
    [OsType.OSX]: "macos",
  } as const;
  return osTypesMap[osType];
};
/**
 * Checks if semver version is greater that target semver version.
 * @param {string} targetVersion
 * @param {string} currentVersion
 * @returns {boolean}
 * @example
 * isNewerOrSameVersion('1.0', '2.0') // true
 * isNewerOrSameVersion('1.10', '1.10') // true
 * isNewerOrSameVersion('1.10', '1.11') // true
 * isNewerOrSameVersion('1.0', '1.0.1') // true
 * isNewerOrSameVersion('1.0.1', '1.0.10')// true
 * isNewerOrSameVersion('1.0.1', '1.0.1')// false
 * isNewerOrSameVersion('2.0', '1.0') // false
 * isNewerOrSameVersion('2', '1.0')// false
 * isNewerOrSameVersion('2.0.0.0.0.1', '2.1') // true
 * isNewerOrSameVersion('2.0.0.0.0.1', '2.0')// false
 * isNewerOrSameVersion('1.10.1.1', '1.10.1.2') // true
 * isNewerOrSameVersion('1.10', undefined) // false
 */
export function isNewerOrSameVersion(targetVersion: string, currentVersion: string): boolean {
  if (targetVersion === currentVersion) return true;
  const targetVersionParts = targetVersion.split(".");
  const currentVersionParts = currentVersion?.split(".") ?? [];
  const maxLength = Math.max(targetVersionParts.length, currentVersionParts.length);
  for (let i = 0; i < maxLength; i++) {
    const current = parseInt(currentVersionParts[i] || "0"); // parse int

    const target = parseInt(targetVersionParts[i] || "0"); // parse int
    if (current < target) return false;
    if (current > target) return true;
  }
  return true;
}

export function addWorkspaceHeaderIfNeeded(request: AxiosRequestConfig, workspaceId?: string) {
  if (workspaceId) {
    request.headers = {
      Workspace: workspaceId,
    };
  }
}

/**
 * Formats a given date or the current date/time using Moment.js.
 *
 * @param {MomentInput} [date] - The date to format. If omitted, the current date/time is used.
 * @param {string} [format="llll"] - The desired format for the date. Defaults to `"llll"`.
 *
 * ### Recommended Formats
 * While any valid Moment.js format string can be used, the following are recommended for consistency:
 * - `'LT'` → `4:29 PM`
 * - `'LTS'` → `4:29:59 PM`
 * - `'L'` → `11/15/2024`
 * - `'l'` → `11/15/2024`
 * - `'LL'` → `November 15, 2024`
 * - `'ll'` → `Nov 15, 2024`
 * - `'LLL'` → `November 15, 2024 4:29 PM`
 * - `'lll'` → `Nov 15, 2024 4:29 PM`
 * - `'LLLL'` → `Friday, November 15, 2024 4:29 PM`
 * - `'llll'` → `Fri, Nov 15, 2024 4:29 PM` (default)
 *
 * ### Examples
 * ```typescript
 * getFormattedDateTime(new Date(), "LL"); // Outputs: "November 15, 2024"
 * getFormattedDateTime();                // Outputs the current date/time in "Fri, Nov 15, 2024 4:29 PM" format
 * getFormattedDateTime("2024-11-15", "L"); // Outputs: "11/15/2024"
 * ```
 *
 * @returns {string | undefined} The formatted date/time as a string, or `undefined` if no date is provided.
 */
export const getFormattedDateTime = (date?: MomentInput, format: string = "llll") => {
  if (date) {
    return moment(date).format(format);
  }
};

/**
 * Formats a given date relative to the current date/time using Moment.js.
 *
 * @param {MomentInput} [date] - The date to format. If omitted, the function returns `undefined`.
 *
 * ### Description
 * This function uses Moment.js's `.calendar()` method to format a given date in a relative, human-readable way.
 * It outputs strings such as "Today at 4:30 PM", "Yesterday at 3:00 PM", or "Last Monday at 2:15 PM" depending
 * on the proximity of the date to the current time.
 *
 * ### Examples
 * ```typescript
 * formatDistanceToNow(new Date());
 * // Outputs: "Today at 4:29 PM" (assuming the current time is 4:29 PM)
 *
 * formatDistanceToNow("2024-11-14");
 * // Outputs: "Thursday at 12:00 AM" (or a similar string based on your locale)
 *
 * formatDistanceToNow(moment().subtract(1, "days").toDate());
 * // Outputs: "Yesterday at 4:29 PM"
 *
 * formatDistanceToNow();
 * // Outputs: undefined (no date provided)
 * ```
 *
 * @returns {string | undefined} A human-readable string describing the relative time, or `undefined` if no date is provided.
 */
export const formatDistanceToNow = (date?: MomentInput) => {
  if (date) {
    return moment(date).calendar();
  }
};

// TODO: investigate https://medium.com/@rado.sabo/abortcontroller-abort-ongoing-calls-in-vue-with-axios-interceptor-584c9f0566a6
// if it's suitable for our case
export function createAbortController(controller?: AbortController): AbortController {
  if (controller) {
    controller.abort();
  }
  controller = new AbortController();
  return controller;
}

export const checkEmailServiceConnection = (services: CloudAppService[]) => {
  return services.some((service) => {
    const isOfficeOrGoogle =
      service.name === Service.OFFICE_365 || service.name === Service.G_SUITE;
    return isOfficeOrGoogle && service.serviceStatus === ServiceStatus.CONNECTED;
  });
};

export const getWorkspaceFromRedirect = async (
  workspaceId: string
): Promise<WorkspaceItem | null> => {
  if (!workspaceId) {
    return null;
  }

  const { baseURL } = api.getCurrentWorkspace;
  const resultUrl = `${baseURL}workspaces/current`;

  try {
    // Fetch is used to avoid endless loop in the interceptor since backend returns 401 if workspace doesn't exist
    const data = await fetch(resultUrl, {
      headers: {
        Authorization: `Bearer ${useAccountStore().account.token}`,
        Workspace: workspaceId,
      },
    });

    if (!data.ok) {
      return null;
    }

    return data.json();
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const setWorkspace = async (workspace: WorkspaceItem): Promise<void> => {
  const accountStore = useAccountStore();
  const workspaceStore = useWorkspacesStore();

  accountStore.setWorkspace(workspace.workspaceId);
  accountStore.setCustomerName(workspace.name);
  accountStore.setWorkspaceType(workspace.type);
  accountStore.setAppLogo(workspace.headerIconUrl);
  accountStore.setDomain(workspace.domain);
  accountStore.setBrandColor(workspace.branding?.brandColor);
  accountStore.setSupportEnabled(workspace.supportEnabled);
  accountStore.setIsCoronetWorkspace(workspace.isCoronetWorkspace);

  await workspaceStore.getCurrentWorkspace();
};

export const formatCurrency = (num?: number, currencyIsoCode?: string): string | undefined => {
  if (num || num === 0) {
    return i18n.global.n(num, {
      style: "currency",
      currency: currencyIsoCode,
      maximumFractionDigits: 1,
      minimumFractionDigits: 0,
    });
  }
};

export function hasSocAddonInModule(module: SubscriptionModuleInfo) {
  return Boolean(
    module.addons.find((addon: SubscriptionAddonInfo) => {
      return (addon.name.includes("soc") || addon.name === SubscriptionAddon.MDR) && addon.enabled;
    })
  );
}

export function humanizeFileSize(size: number | undefined) {
  if (!size) return "0 B";
  const i = Math.floor(Math.log(size) / Math.log(1000));
  return +(size / Math.pow(1000, i)).toFixed(2) + " " + ["B", "kB", "MB", "GB", "TB"][i];
}

export function getCountryOrStateName(key: string): string {
  if (usaStatesList[key]) {
    return `US - ${usaStatesList[key]}`;
  }

  if (countriesList[key]) {
    return countriesList[key];
  }

  return "";
}

/**
 * Extracts domain from the email address (everything after '@' symbol). Returns undefined if domain is not found.
 * @param {string} email  Email address
 * @returns {string|undefined}
 */
export const getDomainFromEmail = (email: string | undefined): string | undefined => {
  const [, domain] = email?.split("@") ?? [];
  return domain || undefined;
};

/**
 * Retrieves the current operating system version based on the user's browser navigator information.
 * @returns {string} The OS version, either 'win', 'macos', or null if the OS is unrecognized.
 */
export const getOSVersion = (): "win" | "macos" | null => {
  let osName = null;
  if (navigator.appVersion.indexOf("Win") !== -1) {
    osName = "win" as const;
  }
  if (navigator.appVersion.indexOf("Mac") !== -1) {
    osName = "macos" as const;
  }
  return osName;
};

/**
 * Makes request to workspaces and identifies if workspace is available for particular customer.
 * @param workspaceId
 * @returns {Promise<boolean>}
 */
export const checkIfWorkspaceAvailableForCustomer = async (workspaceId: string) => {
  if (!workspaceId) {
    return false;
  }

  const workspacePermissionsRequest = {
    ...api.getWorkspacePermissions(workspaceId),
  };

  try {
    const { data } = await axiosInstance.request(workspacePermissionsRequest);
    return !isEmpty(data);
  } catch (e) {
    console.error(e);
    return false;
  }
};

/**
 * Returns days remaining in free trial
 * @param trialEnds {number} timestamp
 * @returns {string} "x days" or undefined
 */
export const getDaysUntilTrialEnds = (trialEnds: number | null) => {
  const currentTime = moment().utc();
  const trialEndsTime = moment(trialEnds).utc();
  // 'moment().duration().humanize' receives thresholds as param -
  // it means that everything more a second should be shown as an hour and until 365 days we show days, not months (180 days, not 6 months) as per Carmel's request
  // The case more than 365 days shouldn't worry us as it's for Trial and maximum trial should be only 2 months (60 days)
  return moment.duration(trialEndsTime.diff(currentTime)).humanize({ d: 365, m: 1, s: 1, ss: 1 });
};

/**
 * Converts a given size in bytes to a human-readable format.
 *
 * @param {number} bytes - The size in bytes to be converted.
 * @param {number} [decimals=2] - The number of decimal places to include in the output. Defaults to 2 if not provided.
 * @param {boolean} [kib=false] - If true, uses binary (kibibyte) units; if false, uses decimal (kilobyte) units. Defaults to false.
 * @returns {string} The formatted size as a string, e.g., "12.34 MB" or "0 Bytes" if the input is 0.
 *
 * @example
 * // Convert 1500 bytes to human-readable format in decimal
 * console.log(convertSize(1500)); // "1.5 KB"
 *
 * @example
 * // Convert 1500 bytes to human-readable format in binary
 * console.log(convertSize(1500, 2, true)); // "1.46 KiB"
 *
 * @example
 * // Convert 0 bytes
 * console.log(convertSize(0)); // "0 Bytes"
 *
 * @example
 * // Handle non-numeric input
 * console.log(convertSize('abc')); // "Not a number"
 */
export const convertSize = (bytes: number, decimals: number = 2, kib: boolean = false) => {
  kib = kib || false;
  if (bytes === 0) return "0 Bytes";
  if (isNaN(parseFloat(bytes.toString())) && !isFinite(bytes)) return "Not an number";
  const k = kib ? 1024 : 1000;
  const dm = decimals != null && !isNaN(decimals) && decimals >= 0 ? decimals : 2;
  const sizes = kib
    ? ["Bytes", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB", "BiB"]
    : ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB", "BB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

/**
 * Remove malicious links from email content so that Google malware scanner doesn't analyze them and mark site as dangerous
 * @param {string} emailContent
 */
export const removeMaliciousLinksFromContent = (emailContent: string) => {
  const urlRegex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;

  // Replace <wbr> tags
  emailContent = emailContent.replace(/<wbr>/g, "");

  // Replace URLs
  return emailContent.replace(urlRegex, "");
};

export const getSelectedModules = (subscription: Subscription): SubscriptionModule[] => {
  return Object.keys(subscription.modules)
    .map((val) => {
      const name = val as SubscriptionModule;
      return {
        name,
        enabled: subscription.modules[name].enabled,
      };
    })
    .filter((v) => v.enabled)
    .map((v) => v.name);
};

export const getSelectedAddons = (subscription: Subscription): SubscriptionAddon[] => {
  const selectedAddons: SubscriptionAddon[] = [];
  Object.keys(subscription.modules).forEach((val) => {
    subscription.modules[val as SubscriptionModule].addons.forEach((addon) => {
      if (addon.enabled) {
        selectedAddons.push(addon.name);
      }
    });
  });
  return selectedAddons;
};

/**
 * Determines the browser's language and maps it to the `WorkspaceLocale` enum.
 * If the browser's language is not listed in the `WorkspaceLocale` enum,
 * the function defaults to `WorkspaceLocale.EN_US`.
 *
 * @returns {WorkspaceLocale} The browser language mapped to `WorkspaceLocale`, or `WorkspaceLocale.EN_US` if no match is found.
 *
 * @example
 * // Assume the browser's language is "en-GB"
 * const locale = getBrowserLocale();
 * console.log(locale); // Output: WorkspaceLocale.EN_GB
 *
 * @example
 * // Assume the browser's language is "fr" (not in WorkspaceLocale)
 * const locale = getBrowserLocale();
 * console.log(locale); // Output: WorkspaceLocale.EN_US
 */
export const getBrowserLocale = (): WorkspaceLocale => {
  const browserLanguage = navigator.language || navigator.languages[0];

  if (Object.values(WorkspaceLocale).includes(browserLanguage as WorkspaceLocale)) {
    return browserLanguage as WorkspaceLocale;
  }

  return WorkspaceLocale.EN_US;
};

export const resetPersistedStores = () => {
  useSubscriptionStore().$reset();
  useMyAccountStore().$reset();
  useFiltersStore().$reset();
};
/**
 * Merges the default subscription modules configuration with the provided module settings.
 *
 * This function ensures that enabled states are converted to booleans and adds unique addons
 * from both the new and default configurations, sorted by name.
 *
 * Note: lodash's defaultsDeep function behaves such that if a value exists in both
 * arrays, it will add the value again instead of maintaining uniqueness.
 *
 * @returns {ModulesSettings} Merged modules settings with defaults applied.
 */
export const setDefaultModules = (newModules: ModulesSettings): ModulesSettings => {
  const defaultModules = cloneDeep(defaultSubscriptionModulesConfig);
  const modules: ModulesSettings = {} as ModulesSettings;

  Object.keys(defaultModules).forEach((key) => {
    const module = key as SubscriptionModule;
    const currentAddons: SubscriptionAddonInfo[] = get(newModules[module], "addons", []);
    const defaultAddons: SubscriptionAddonInfo[] = defaultModules[module].addons;

    // Use unionBy to merge the addons uniquely by their name
    modules[module] = {
      enabled: Boolean(newModules[module]?.enabled),
      addons: sortBy(unionBy(currentAddons, defaultAddons, "name"), ["name"]),
    };
  });

  return modules;
};

export const navigateWithReload = (routeName: RouteName) => {
  const resolvedRoute = router.resolve({ name: routeName });
  window.location.href = resolvedRoute.href;
};

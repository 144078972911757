<template>
  <div class="content-md margin-auto mt-4">
    <v-skeleton-loader
      class="skeleton-loader--cloud-apps"
      :loading="showSkeletonLoader"
      :type="coronetSkeletonLoaderTypes.CLOUD_APPS_SETTINGS"
    >
      <v-progress-linear
        :style="{ visibility: isLoading ? 'visible' : 'hidden' }"
        class="allow-blocklist-tab-content"
        indeterminate
        height="2px"
      />
      <v-card class="card-wrapper">
        <div class="subtitle1">{{ $t("cloudSecuritySettings.inactiveUsers") }}</div>
        <div class="text-indigo-medium">
          {{ $t("cloudSecuritySettings.inactiveUsersSubtitle") }}
        </div>
        <v-text-field
          class="mt-4 days-input"
          :label="$t('cloudSecuritySettings.numberOfDays')"
          :rules="[minNumber(), maxNumber(MAX_DAYS_OF_USER_INACTIVITY)]"
          v-model.number="localValue.inactiveDays"
          type="number"
          @blur="updateInactiveDays()"
        ></v-text-field>
      </v-card>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, type Ref, ref } from "vue";
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import {
  type CloudAppsSettings,
  useCloudAppsSettingsStore,
} from "@/_store/cloud-apps/cloud-apps-settings.module";
import { storeToRefs } from "pinia";
import { maxNumber, minNumber } from "@/_helpers/validators";

export default defineComponent({
  setup() {
    const MAX_DAYS_OF_USER_INACTIVITY = 365;
    const cloudAppsSettingsStore = useCloudAppsSettingsStore();

    const { showSkeletonLoader, settings, isLoading } = storeToRefs(cloudAppsSettingsStore);
    const localValue: Ref<CloudAppsSettings> = ref({
      inactiveDays: null,
    });

    onMounted(async () => {
      await cloudAppsSettingsStore.getSettings();
      localValue.value = { ...settings.value };
    });

    const updateInactiveDays = async () => {
      if (
        localValue.value.inactiveDays !== settings.value.inactiveDays &&
        localValue.value.inactiveDays! > 0 &&
        localValue.value.inactiveDays! < MAX_DAYS_OF_USER_INACTIVITY
      ) {
        await cloudAppsSettingsStore.updateSetting({
          inactiveDays: localValue.value.inactiveDays,
        });
      }
    };

    return {
      minNumber,
      maxNumber,
      updateInactiveDays,
      localValue,
      isLoading,
      showSkeletonLoader,
      MAX_DAYS_OF_USER_INACTIVITY,
      coronetSkeletonLoaderTypes,
    };
  },
});
</script>

<style lang="scss" scoped>
.card-wrapper {
  padding: 24px 16px;
}

.days-input {
  width: 250px;
}
</style>

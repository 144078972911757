<template>
  <div
    class="d-flex align-center"
    :style="{ 'max-width': maxWidth, width: width }"
    @mouseover="showCopyBtn = true"
    @mouseleave="showCopyBtn = false"
    @click="copyText()"
  >
    <v-tooltip v-if="showTooltip" open-delay="300" location="top">
      <template #activator="{ props }">
        <div class="ellipsis" v-if="!maxCharactersLength">
          <span v-bind="props" data-testid="ellipsified-text">{{ text }}</span>
        </div>
        <div v-else data-testid="ellipsified-text" v-bind="props">
          {{
            text.length <= maxCharactersLength ? text : text.slice(0, maxCharactersLength) + "..."
          }}
        </div>
      </template>
      <span>{{ text }}</span>
    </v-tooltip>
    <template v-else>
      <div class="ellipsis" v-if="!maxCharactersLength">
        <span data-testid="ellipsified-text">{{ text }}</span>
      </div>
      <div v-else data-testid="ellipsified-text">
        {{ text.length <= maxCharactersLength ? text : text.slice(0, maxCharactersLength) + "..." }}
      </div>
    </template>
    <v-icon
      v-if="persistentCopyBtn || (showCopyBtn && !hideCopyBtn)"
      size="x-small"
      color="primary"
      class="cursor-pointer ml-1"
      icon="$copy"
    >
    </v-icon>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { copyToClipboard } from "@/_helpers/utils";
import { SnackbarTypes, useSnackbarStore } from "@/_store/snackbar.module";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    width: {
      type: String,
      default: "100%",
    },
    maxWidth: {
      type: String,
      default: "100%",
    },
    text: {
      type: String,
      required: true,
    },
    maxCharactersLength: {
      type: Number,
      default: 0,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    hideCopyBtn: {
      type: Boolean,
      default: false,
    },
    persistentCopyBtn: {
      type: Boolean,
      default: false,
    },
    copiableText: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const snackbarStore = useSnackbarStore();
    const i18n = useI18n();
    const showCopyBtn = ref(false);
    const copyText = () => {
      if (props.hideCopyBtn) return;
      copyToClipboard(props.copiableText ? props.copiableText : props.text);
      snackbarStore.add({
        html: i18n.t("snackbar.messages.general.copiedToClipboard"),
        type: SnackbarTypes.INFO,
      });
    };
    return {
      showCopyBtn,
      copyText,
    };
  },
});
</script>

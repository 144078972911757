<template>
  <div v-if="deviceMetaData">
    <div class="subtitle1" data-testid="ticket-preview-title">
      {{ usersTitle }}
    </div>
    <div class="body1 mb-4" data-testid="ticket-preview-users" v-html="users"></div>

    <div class="subtitle1">{{ $t("ticketDetails.lastSeen") }}</div>
    <div class="body1 mb-4" data-testid="ticket-preview-last-seen">
      {{ getFormattedDateTime(ticketDetails.endTime) }}
    </div>

    <div class="headline6 mb-4">{{ $t("ticketDetails.device") }}</div>
    <div class="subtitle1">{{ $t("deviceDetails.device.modelAndId") }}</div>
    <div class="body1" data-testid="ticket-preview-device-model">
      {{ deviceMetaData.model }}
    </div>
    <div v-if="hideLink" class="body1 mb-4" data-testid="ticket-preview-enrollment-code">
      {{ deviceMetaData.enrollmentCode }}
    </div>
    <div
      v-else
      class="body1 coro-link mb-4"
      data-testid="ticket-preview-enrollment-code"
      @click="onDeviceLinkClick()"
    >
      {{ deviceMetaData.enrollmentCode }}
    </div>

    <div class="subtitle1">{{ $t("deviceDetails.device.os") }}</div>
    <div class="body1 mb-4" data-testid="ticket-preview-os-version">
      {{ deviceMetaData.osVersionDisplayName }}
    </div>
    <div class="subtitle1">{{ $t("deviceDetails.device.hostName") }}</div>
    <div class="body1" data-testid="ticket-preview-hostname">
      {{ deviceMetaData.hostname }}
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, type PropType } from "vue";
import type { TicketDetails } from "@/_store/tickets/tickets.module";
import { useTicketPreviewGeneralInfo } from "@/composables/useTicketPreviewGeneralInfo";
import { isAccessRestricted } from "@/_helpers/utils";
import { useI18n } from "vue-i18n";
import { Service } from "@/constants/cloud-apps";
import { TicketsModal } from "@/constants/tickets";
import { ProxyDirection } from "@/constants/email-proxy";
import { RolePermissionsScope } from "@/_store/roles.module";
import { RouteName } from "@/constants/routes";
import { useRouter } from "vue-router";

export default defineComponent({
  props: {
    ticketDetails: {
      type: Object as PropType<TicketDetails>,
      required: true,
    },
    isSoc: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter();
    const i18n = useI18n();
    const hideLink = computed(() => {
      return isAccessRestricted(RolePermissionsScope.VIEWS, "devicesView");
    });
    const deviceMetaData = computed(() => props.ticketDetails?.sections?.deviceMetaData);

    const { getFormattedDateTime, addUsersNotAllowed, usersToAdd, addUsers, openListModal } =
      useTicketPreviewGeneralInfo(props.ticketDetails);
    const users = computed(() => {
      const lastLoggedInUsers = props.ticketDetails?.lastLoggedInUsers ?? [];
      return lastLoggedInUsers.length
        ? lastLoggedInUsers.join(", <br>")
        : props.ticketDetails.sections.triggeredUser;
    });
    const usersTitle = computed(() => {
      const lastLoggedInUsers = props.ticketDetails?.lastLoggedInUsers?.length;
      return lastLoggedInUsers
        ? i18n.t("ticketDetails.lastUsersOnDevice")
        : i18n.t("ticketDetails.users");
    });

    const serviceNameTitle = computed(() => {
      const serviceName = i18n.t(`services.${props.ticketDetails.sections.serviceName}`);
      if (props.ticketDetails.sections.serviceName === Service.PROXY) {
        const direction =
          props.ticketDetails.sections.emailMetaData?.emailProxyDirection === ProxyDirection.INBOUND
            ? i18n.t("emailProxy.directions.inbound")
            : i18n.t("emailProxy.directions.outbound");

        return `${direction} ${serviceName}`;
      }
      return serviceName;
    });

    const onDeviceLinkClick = () => {
      if (props.isSoc && props.ticketDetails.workspaceId) {
        router.push({
          name: RouteName.DEVICES,
          query: {
            search: deviceMetaData.value?.enrollmentCode,
            workspaceId: props.ticketDetails.workspaceId,
          },
        });
      } else {
        router.push({
          name: RouteName.DEVICES,
          query: {
            search: deviceMetaData.value?.enrollmentCode,
          },
        });
      }
    };

    return {
      users,
      getFormattedDateTime,
      addUsersNotAllowed,
      usersToAdd,
      addUsers,
      hideLink,
      deviceMetaData,
      serviceNameTitle,
      openListModal,
      TicketsModal,
      Service,
      onDeviceLinkClick,
      usersTitle,
    };
  },
});
</script>

<style lang="scss" scoped>
.service-icon {
  width: 40px;
  height: 40px;
}
</style>

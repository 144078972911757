<template>
  <div class="body1 mb-10">{{ $t("modals.getVpnMobileApp.description") }}</div>
  <div>
    <div class="os-type mb-6" v-for="(osType, index) in osTypes" :key="index">
      <div class="os-title subtitle1 mb-1 ml-3">
        <v-icon size="large" class="mr-3" :icon="osType.icon"></v-icon>
        {{ osType.title }}
      </div>
      <div class="link-snippet py-3 px-4 rounded d-flex justify-space-between align-center">
        <div>
          <v-icon class="mr-4" icon="$trailingLink"></v-icon>
          <span class="body2">{{ osType.link }}</span>
        </div>
        <v-btn rounded color="primary" @click="copyToClipboard(osType.link)">
          <template #append>
            {{ $t("general.copy") }}
            <v-icon class="ml-2" icon="$copy"></v-icon>
          </template>
        </v-btn>
      </div>
      <v-divider v-if="index !== 1" class="my-6" />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { copyToClipboard } from "@/_helpers/utils";
import { useI18n } from "vue-i18n";

export default defineComponent({
  methods: { copyToClipboard },
  setup() {
    const { t } = useI18n();
    const osTypes = [
      {
        icon: "$apple",
        title: t(`modals.getVpnMobileApp.osTypes.ios`),
        link: "https://apps.apple.com/us/app/coro-mobile-protection/id6587572891",
      },
      {
        icon: "$android",
        title: t(`modals.getVpnMobileApp.osTypes.android`),
        link: "https://play.google.com/store/apps/details?id=net.coro.mobileprotection",
      },
    ];
    onMounted(() => {});
    return {
      osTypes,
    };
  },
});
</script>

<style scoped lang="scss">
.os-type {
  .os-title {
    .v-icon {
      position: relative;
      bottom: 2px;
    }
  }
  .link-snippet {
    background-color: rgb(var(--v-theme-indigo-pale));
  }
}
</style>

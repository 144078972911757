export enum EmailSettingsAction {
  ADD_SECURITY_AWARENESS_CONFIGURATION = "addSecurityAwarenessConfiguration",
  EDIT_SECURITY_AWARENESS_CONFIGURATION = "editSecurityAwarenessConfiguration",
  REMOVE_SECURITY_AWARENESS_CONFIGURATION = "removeSecurityAwarenessConfiguration",
}

export enum ScanSensitivityLevels {
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
}
export enum EmailAddInsProvider {
  OUTLOOK = "outlook",
  GMAIL = "gmail",
}

export enum QuarantineFolder {
  SUSPECTED = "suspected",
  TRASH = "trash",
}

export enum EmailError {
  GLOBAL_DOMAINS_NOT_SUPPORTED = "globalDomainsNotSupported",
  OWN_EMAIL_OR_DOMAIN_NOT_SUPPORTED = "ownEmailOrDomainNotSupported",
}

export enum SecurityMode {
  QUARANTINE_EMAIL = "quarantineEmail",
  WARN_RECIPIENT = "warnRecipient",
}

export enum ScanOptions {
  SCAN_MALWARE = "scanMalware",
  SCAN_SPAM = "scanSpam",
  SCAN_PHISHING = "scanPhishing",
  SCAN_NEWLY_REGISTERED_DOMAINS = "scanNewlyRegisteredDomains",
  SCAN_ENCRYPTED_ATTACHMENTS = "scanEncryptedAttachments",
  SCAN_UNVERIFIED_SENDER = "scanUnverifiedSender",
  SCAN_UNKNOWN_EXTERNAL_SENDER = "scanUnknownExternalSender",
}

export enum AttachmentFileExtension { // should be a better name :smile:
  SEVEN_ZIP = "7z",
  BAT = "bat",
  BIN = "bin",
  CHM = "chm",
  CMD = "cmd",
  COM = "com",
  CPL = "cpl",
  DLL = "dll",
  DMG = "dmg",
  EXE = "exe",
  GADGET = "gadget",
  G7 = "g7",
  GZ = "gz",
  HTA = "hta",
  HTML = "html",
  HTM = "htm",
  INF = "inf",
  ISO = "iso",
  JAR = "jar",
  JS = "js",
  JSE = "jse",
  INK = "ink",
  LNK = "lnk",
  MHT = "mht",
  MSC = "msc",
  MSH = "msh",
  MSH1 = "msh1",
  MSH1XML = "msh1xml",
  MSH2 = "msh2",
  MSH2XML = "msh2xml",
  MSHL = "mshl",
  MSHXML = "mshxml",
  MSI = "msi",
  MSP = "msp",
  PIF = "pif",
  PS = "ps",
  PS1 = "ps1",
  PS1XML = "ps1xml",
  PS2 = "ps2",
  PS2XML = "ps2xml",
  PSC1 = "psc1",
  PSC2 = "psc2",
  PSCL = "pscl",
  PSL = "psl",
  PY = "py",
  RAR = "rar",
  REG = "reg",
  SCF = "scf",
  SCR = "scr",
  SH = "sh",
  TAR = "tar",
  VB = "vb",
  VBE = "vbe",
  VBS = "vbs",
  ZIP = "zip",
  WAS = "was",
  WS = "ws",
  WSC = "wsc",
  WSF = "wsf",
  WSH = "wsh",
}

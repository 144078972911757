import { computed } from "vue";
import { getUsersToAddFromPotentiallyProtectable } from "@/_store/tickets/adapters";
import type { TicketDetails } from "@/_store/tickets/tickets.module";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import {
  getFormattedDateTime,
  isWorkspaceFrozenOrActionRestricted,
  listDialogConstructor,
} from "@/_helpers/utils";
import { useTicketsStore } from "@/_store/tickets/tickets.module";
import { useUsersSettingsTabStore } from "@/_store/users-settings/users-tab.module";
import { debounce } from "lodash";
import type { TicketsModal } from "@/constants/tickets";
import { useDialogsStore } from "@/_store/dialogs.module";
import type { Service } from "@/constants/cloud-apps";

export function useTicketPreviewGeneralInfo(ticketDetails: TicketDetails) {
  const ticketsStore = useTicketsStore();
  const dialogsStore = useDialogsStore();

  const isSoc = computed(() => ticketsStore.isSoc);

  const showAddToProtectionBtn = computed(() => {
    const showActionBtn = ticketDetails.actions?.length > 0;
    return showActionBtn && ticketDetails.potentiallyProtectableUsers.length > 0;
  });

  const usersToAdd = computed(
    () => getUsersToAddFromPotentiallyProtectable(ticketDetails.potentiallyProtectableUsers).users
  );

  const addUsersNotAllowed = computed(() =>
    isWorkspaceFrozenOrActionRestricted(
      RolePermissionsScope.WORKSPACE_MANAGEMENT,
      WorkspaceManagementScopeSections.USERS
    )
  );

  const addUsersToProtection = async (users: { email: string; service: Service }[]) => {
    await useUsersSettingsTabStore().addUsers({ users });
  };

  const addUsers = debounce(async (users: { email: string; service: Service }[]) => {
    await addUsersToProtection(users);
  }, 500);

  const openListModal = <T>(modal: TicketsModal, items: T[]) => {
    dialogsStore.openDialog(listDialogConstructor({ action: modal, item: { items } }));
  };

  return {
    getFormattedDateTime,
    isSoc,
    showAddToProtectionBtn,
    usersToAdd,
    addUsersNotAllowed,
    addUsers,
    openListModal,
  };
}

export enum TelemetryType {
  REGISTRY_KEY = "registryKey",
  SCHEDULED_TASK = "scheduledTask",
  ACCOUNT_EVENT = "accountEvent",
  USB_DEVICE_ACTIVITY = "usbDeviceActivity",
  GROUP_POLICY_CHANGES = "groupPolicyChanges",
}

export enum EdrAction {
  BLOCK_PROCESS = "blockProcess",
  UNBLOCK_PROCESS = "unblockProcess",
  ENABLE_NETWORK_BLOCK_MODE = "enableNetworkBlockMode",
  DISABLE_NETWORK_BLOCK_MODE = "disableNetworkBlockMode",
  ISOLATE_DEVICE = "isolateDevice",
  SHUTDOWN_DEVICE = "shutdownDevice",
  REBOOT_DEVICE = "rebootDevice",
  VIEW_LOG = "viewLog",
  ALLOW_PROCESS = "allowProcess",
}

export enum EdrProcessStatus {
  ALLOWED = "allowed",
  BLOCKED = "blocked",
}

export enum ProcessGraphAction {
  ALLOW_PROCESS = "allowProcess",
  BLOCK_PROCESS = "blockProcess",
}

<template>
  <div class="text-center margin-auto downloads-page mt-16" data-testid="downloads-page-container">
    <v-skeleton-loader
      class="skeleton-loader--downloads"
      :loading="showSkeletonLoader"
      type="text, avatar, button, list-item@2, paragraph"
    >
      <div class="d-flex justify-center">
        <v-card>
          <v-card-title>
            <div class="mt-4 headline5">
              <span
                class="white-space-normal"
                v-html="$t('downloadsPage.title', { customer })"
              ></span>
            </div>
          </v-card-title>
          <v-card-text>
            <div class="mt-11 mb-12 d-flex align-center flex-column">
              <v-img
                src="/images/logos/coro-icon.svg"
                width="100%"
                max-width="70"
                class="mb-3"
                contain
              ></v-img>
              <v-btn color="primary" :href="downloadLinks[osVersion!]" rounded size="large">
                {{ $t("downloadsPage.downloadBtn") }}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </div>
      <div
        class="mt-7 mb-8 caption text-indigo-medium text-left"
        v-html="$t('downloadsPage.footerDescription', { customer })"
      ></div>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { coronetSkeletonLoaderTypes } from "@/constants/skeleton-loader";
import { getOSVersion } from "@/_helpers/utils";
import { computed, defineComponent, onMounted } from "vue";
import { useDownloadsStore } from "@/_store/downloads.module";
import { storeToRefs } from "pinia";

/**
 * @deprecated There is a possibility that this component is not used anymore, so keeping it just for old times sake.
 */
export default defineComponent({
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const osVersion = computed(() => getOSVersion());
    const downloadsStore = useDownloadsStore();
    const { downloadLinks, showSkeletonLoader, customer } = storeToRefs(downloadsStore);

    onMounted(async () => {
      await downloadsStore.init({ id: props.code });
    });

    return {
      osVersion,
      coronetSkeletonLoaderTypes,
      downloadLinks,
      showSkeletonLoader,
      customer,
    };
  },
});
</script>

<style scoped lang="scss">
.downloads-page {
  width: 568px;
  &__coronet-icon {
    width: 64px;
    height: 64px;
    border-radius: 12px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.download-title {
  word-break: break-word;
}
</style>

<template>
  <v-stepper-vertical hide-actions v-model="currentStep" v-if="!config.item.signup">
    <template v-slot:default>
      <v-stepper-vertical-item
        value="1"
        :elevation="0"
        error-icon="$warning"
        :rules="firstStepRules"
        :title="$t('service.googleDirectoryEnforcement.step1.title')"
      >
        <template #subtitle>
          <span v-if="errorCode" class="caption text-red-dark" data-testid="google-step1-error">{{
            $t(`service.errors.${errorCode}`, { email })
          }}</span>
        </template>
        <template #icon="{ hasError, step }">
          <v-icon v-if="hasError" icon="$warning" size="24" class="mb-1"></v-icon>
          <span v-else>{{ step }}</span>
        </template>
        <v-card variant="flat">
          <div
            class="body2 mt-2"
            data-testid="office-step1-description"
            v-html="gSuiteStep1Description"
          ></div>
          <div class="mt-5">
            <v-btn
              v-if="isNextStep && !errorCode"
              rounded
              color="primary"
              width="120"
              data-testid="office-step1-continue-btn"
              @click="currentStep++"
            >
              {{ $t("cloudApplications.buttons.continue") }}
            </v-btn>
            <v-btn
              v-else
              rounded
              color="primary"
              width="120"
              :loading="isLoading"
              data-testid="office-step1-connect-btn"
              @click="connect({ service: config.item.name as Service })"
            >
              {{ $t("cloudApplications.buttons.connectService") }}
            </v-btn>
          </div>
        </v-card>
      </v-stepper-vertical-item>

      <v-stepper-vertical-item
        value="2"
        :elevation="0"
        :title="$t('service.googleDirectoryEnforcement.step2.title')"
      >
        <v-card variant="flat">
          <div
            data-testid="google-step2-description1"
            class="body2 mb-2"
            v-html="$t('service.googleDirectoryEnforcement.step2.description1')"
          />
          <div
            data-testid="google-step2-description2"
            class="body2 mb-2"
            v-html="$t('service.googleDirectoryEnforcement.step2.description2')"
          />
          <div
            data-testid="google-step2-description3"
            class="body2 mb-2"
            v-html="$t('service.googleDirectoryEnforcement.step2.description3')"
          />

          <v-text-field
            :value="account.googleClientId"
            class="copy-text-input client-id-copy-field"
            readonly
            variant="outlined"
            density="compact"
            color="primary"
            data-testid="google-step2-copy-field"
          >
            <template #append-inner>
              <v-btn
                width="116"
                color="primary"
                class="ma-0"
                @click="
                  onCopyToClipboard(account.googleClientId);
                  idCopiedToClipboard = true;
                "
              >
                <template v-if="idCopiedToClipboard">
                  <v-icon class="mr-2" color="white" icon="$check"></v-icon>
                  <span>{{ $t("general.copied") }}</span>
                </template>
                <span v-else>{{ $t("general.copy") }}</span>
              </v-btn>
            </template>
          </v-text-field>

          <div
            class="body2 mb-2"
            v-html="$t('service.googleDirectoryEnforcement.step2.description4')"
          />

          <div class="scopes-copy-field__wrapper">
            <div class="scopes-copy-field__links" data-testid="google-step2-scopes">
              {{ gSuiteScopes }}
            </div>
            <v-btn
              data-testid="google-step2-copy-scopes-btn"
              width="104"
              height="40"
              tile
              color="primary"
              class="ma-0 scopes-copy-field__btn"
              @click="onCopyToClipboard(gSuiteScopes)"
            >
              <template v-if="scopesCopiedToClipboard">
                <v-icon class="mr-2" color="white" icon="$check"></v-icon>
                <span>{{ $t("general.copied") }}</span>
              </template>
              <span v-else>{{ $t("general.copy") }}</span>
            </v-btn>
          </div>
          <div
            class="body2 mt-2"
            v-html="$t('service.googleDirectoryEnforcement.step2.description5')"
          />
          <div class="mt-5">
            <v-btn
              rounded
              color="primary"
              width="120"
              data-testid="google-step2-complete-btn"
              @click="onCompleteServiceConnectionClick()"
            >
              {{ $t("general.confirm") }}
            </v-btn>
            <v-btn
              class="ml-2"
              variant="text"
              color="primary"
              data-testid="google-step2-back-btn"
              @click="currentStep = 1"
            >
              {{ $t("general.back") }}
            </v-btn>
          </div>
        </v-card>
      </v-stepper-vertical-item>
    </template>
  </v-stepper-vertical>
  <div v-else>
    <div
      class="body2 mb-2"
      data-testid="google-signup-description1"
      v-html="$t('service.googleDirectoryEnforcement.step2.description1')"
    />
    <div
      class="body2 mb-2"
      data-testid="google-signup-description2"
      v-html="$t('service.googleDirectoryEnforcement.step2.description2')"
    />
    <div
      class="body2 mb-2"
      data-testid="google-signup-description3"
      v-html="$t('service.googleDirectoryEnforcement.step2.description3')"
    />
    <v-text-field
      :value="account.googleClientId"
      class="copy-text-input client-id-copy-field"
      readonly
      variant="outlined"
      density="compact"
      color="primary"
      data-testid="google-step2-copy-field"
    >
      <template #append-inner>
        <v-btn
          width="116"
          color="primary"
          class="ma-0"
          height="38"
          @click="
            onCopyToClipboard(account.googleClientId);
            idCopiedToClipboard = true;
          "
        >
          <template v-if="idCopiedToClipboard">
            <v-icon class="mr-2" color="white" icon="$check"></v-icon>
            <span>{{ $t("general.copied") }}</span>
          </template>
          <span v-else>{{ $t("general.copy") }}</span>
        </v-btn>
      </template>
    </v-text-field>
    <div
      class="body2 mb-2"
      data-testid="google-signup-description4"
      v-html="$t('service.googleDirectoryEnforcement.step2.description4')"
    />
    <div class="body2 mb-2" v-html="$t('service.googleDirectoryEnforcement.step2.description4')" />

    <div class="scopes-copy-field__wrapper">
      <div class="scopes-copy-field__links" data-testid="google-step2-scopes">
        {{ gSuiteScopes }}
      </div>
      <v-btn
        data-testid="google-step2-copy-scopes-btn"
        width="104"
        height="40"
        tile
        color="primary"
        class="ma-0 scopes-copy-field__btn signup-scopes-copy-btn"
        @click="onCopyToClipboard(gSuiteScopes)"
      >
        <template v-if="scopesCopiedToClipboard">
          <v-icon class="mr-2" color="white" icon="$check"></v-icon>
          <span>{{ $t("general.copied") }}</span>
        </template>
        <span v-else>{{ $t("general.copy") }}</span>
      </v-btn>
    </div>
    <div
      class="body2 mt-2"
      data-testid="google-signup-description5"
      v-html="$t('service.googleDirectoryEnforcement.step2.description5')"
    />
    <div class="mt-5">
      <v-btn
        rounded
        color="primary"
        width="120"
        data-testid="google-signup-confirm-btn"
        @click="onSignupComplete()"
      >
        {{ $t("general.confirm") }}
      </v-btn>
      <v-btn
        variant="text"
        color="primary"
        data-testid="google-signup-cancel-btn"
        @click="$emit('dismissed')"
      >
        {{ $t("general.cancel") }}
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref, type PropType } from "vue";
import { useCloudAppsStore } from "@/_store/cloud-apps/cloud-apps.module";
import { useAccountStore } from "@/_store";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { Service, ServiceStatus } from "@/constants/cloud-apps";
import { copyToClipboard } from "@/_helpers/utils";
import type { ConnectAppModalConfig } from "@/views/settings/cloud-apps/CloudAppsPage.vue";

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<ConnectAppModalConfig>,
      required: true,
    },
  },
  emits: ["complete-connection", "complete", "dismissed"],
  setup(props, { emit }) {
    const idCopiedToClipboard = ref(false);
    const scopesCopiedToClipboard = ref(false);

    const cloudAppsModule = useCloudAppsStore();
    const accountModule = useAccountStore();
    const { completeServiceConnection, fetchGoogleClientId } = accountModule;
    const { account } = storeToRefs(accountModule);
    const { isLoading } = storeToRefs(cloudAppsModule);
    const route = useRoute();
    const currentStep = ref(1);
    const connectionCompleted = ref(false);
    const i18n = useI18n();
    const errorCode = route.query.error;

    const updateCurrentStep = () => {
      // navigating to proper step which returned from backend response url
      if (route.query.step) {
        currentStep.value = Number.parseInt(route.query.step as string, 10);
      }

      // if service is incomplete, navigating to proper step
      if (props.config.item.serviceStatus === ServiceStatus.INCOMPLETE) {
        currentStep.value = 2;
      }
    };

    const isNextStep = computed(() => {
      return route.query.isNextStep === "true";
    });

    const onCompleteServiceConnectionClick = async () => {
      await completeServiceConnection(Service.G_SUITE);
      emit("complete-connection");
    };

    const onSignupComplete = async () => {
      emit("complete-connection");
    };

    onMounted(async () => {
      connectionCompleted.value = props.config.item.serviceStatus === ServiceStatus.CONNECTED;
      updateCurrentStep();
      await fetchGoogleClientId();
    });

    const isGSuite = computed(() => {
      return props.config.item.name === Service.G_SUITE;
    });

    const email = computed(() => {
      return route.query.email;
    });

    const gSuiteStep1Description = computed(() => {
      if (account.value.brandingAlias && errorCode === "generic") {
        return i18n.t(
          "service.googleDirectoryEnforcement.step1.descriptionError.genericWithoutCoro"
        );
      }
      return errorCode
        ? i18n.t(`service.googleDirectoryEnforcement.step1.descriptionError.${errorCode}`, {
            email: email.value,
          })
        : i18n.t("service.googleDirectoryEnforcement.step1.description");
    });

    const onCopyToClipboard = (valueToCopy: string) => {
      copyToClipboard(valueToCopy);
    };

    const firstStepRules = computed(() => (errorCode ? [() => !errorCode] : []));

    return {
      onCompleteServiceConnectionClick,
      currentStep,
      isNextStep,
      errorCode,
      isLoading,
      connect: cloudAppsModule.connect,
      account,
      onCopyToClipboard,
      idCopiedToClipboard,
      scopesCopiedToClipboard,
      gSuiteStep1Description,
      isGSuite,
      email,
      firstStepRules,
      Service,
      onSignupComplete,
      gSuiteScopes: `https://www.googleapis.com/auth/admin.directory.group,
        https://www.googleapis.com/auth/admin.directory.user,
        https://www.googleapis.com/auth/drive,
        https://www.googleapis.com/auth/admin.reports.audit.readonly,
        https://www.googleapis.com/auth/admin.directory.domain,
        https://www.googleapis.com/auth/userinfo.email,
        https://mail.google.com/`,
    };
  },
});
</script>

<style scoped lang="scss">
:deep(*) {
  .signup-scopes-copy-btn {
    right: 175px !important;
  }

  .v-field--appended {
    padding: 0 !important;
  }

  .copy-text-input {
    .v-field {
      height: 36px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      .v-field__field {
        height: 36px;
      }
    }

    .v-btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
</style>

<template>
  <div>
    <div class="coro-subtitle-1 mb-2">
      {{ $t(`modals.addDevicePosturePolicy.wifiSettings.connection`) }}
    </div>
    <v-radio-group v-model="config.connectionType" class="mb-2">
      <v-radio
        v-for="connectionType in wifiConnectionTypeChoices"
        :key="connectionType"
        :label="$t(`modals.addDevicePosturePolicy.wifiSettings.connectionTypes.${connectionType}`)"
        :value="connectionType"
      >
        <template #label>
          <div
            class="subtitle1"
            v-text="
              $t(`modals.addDevicePosturePolicy.wifiSettings.connectionTypes.${connectionType}`)
            "
          />
          <v-tooltip
            v-if="connectionType === WifiPostureConnectionType.SPECIFIC"
            open-delay="300"
            location="top"
          >
            <template #activator="{ props }">
              <v-icon v-bind="props" icon="$info" size="28"> </v-icon>
            </template>
            {{ $t("modals.addDevicePosturePolicy.wifiSettings.specificConnectionDescription") }}
          </v-tooltip>
        </template>
      </v-radio>
    </v-radio-group>
    <v-combobox
      v-model="config.networks"
      class="mb-10 ml-8"
      multiple
      variant="outlined"
      :disabled="networksInputDisabled"
      :rules="specificNetworkRules"
      :key="comboboxKey"
      :label="$t(`modals.addDevicePosturePolicy.wifiSettings.networksPlaceholder`)"
      :placeholder="$t(`modals.addDevicePosturePolicy.wifiSettings.networksPlaceholder`)"
      append-icon=""
    >
      <template v-slot:chip="{ props, item }">
        <v-chip
          v-bind="props"
          :closable="true"
          variant="flat"
          size="default"
          :color="getChipColor(item.raw)"
          close-icon="$closeCircle"
          :text="item.raw.name"
          :disabled="networksInputDisabled"
          class="network-chip"
        >
          <div class="d-flex align-center">
            <span class="ml-1 mr-2">{{ item.raw }}</span>
          </div>
        </v-chip>
      </template>
    </v-combobox>
  </div>
</template>
<script>
import { WifiPostureConnectionType } from "@/constants/devices";
import { computed, defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import Patterns from "@/constants/patterns";

export default defineComponent({
  props: {
    config: {
      type: Object,
      required: true,
    },
    formValid: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["update:valid", "update:value"],
  setup(props, { emit }) {
    const i18n = useI18n();
    const localValue = ref({
      connectionType: WifiPostureConnectionType.ALL_ENCRYPTED,
      networks: [],
    });
    const comboboxKey = ref(0);
    const form = ref({});
    const valid = ref(true);

    watch(
      () => props.config.connectionType,
      async () => {
        comboboxKey.value += 1;
      }
    );
    const networksInputDisabled = computed(() => {
      return props.config.connectionType === WifiPostureConnectionType.ALL_ENCRYPTED;
    });

    const networksRules = [
      (values) => {
        if (!values?.length) return i18n.t("validations.required");

        const hasInvalidEntries = values.some((value) => !Patterns.SSID.test(value));

        return hasInvalidEntries ? i18n.t("validations.ssid") : true;
      },
    ];

    const specificNetworkRules = computed(() => {
      return props.config.connectionType === WifiPostureConnectionType.ALL_ENCRYPTED
        ? []
        : networksRules;
    });

    watch(valid, (value) => {
      if (value === null) {
        return;
      }

      emit("update:valid", value);
    });

    const getChipColor = (email) => {
      const isSSIDValid = Patterns.SSID.test(email);
      return !isSSIDValid ? "chip-error" : "indigo-faint";
    };

    return {
      localValue,
      form,
      valid,
      wifiConnectionTypeChoices: [
        WifiPostureConnectionType.ALL_ENCRYPTED,
        WifiPostureConnectionType.SPECIFIC,
      ],
      networksRules,
      getChipColor,
      networksInputDisabled,
      specificNetworkRules,
      comboboxKey,
      WifiPostureConnectionType,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(*) {
  .network-chip {
    .icon-rules-close:before {
      color: rgb(var(--v-theme-primary)) !important;
    }
  }
}
</style>

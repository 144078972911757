export enum VirtualOfficeAction {
  SETUP = "setupVirtualOffice",
  GET_VPN_MOBILE_APP = "getVpnMobileApp",
  ADD_URL_TO_INCLUDELIST = "addUrlToIncludelist",
  ADD_URL_TO_EXCLUDELIST = "addUrlToExcludelist",
  REMOVE_FROM_INCLUDELIST = "removeUrlFromIncludelist",
  REMOVE_FROM_EXCLUDELIST = "removeUrlFromExcludelist",
  TOGGLE_ENCRYPTION_STRENGTH = "toggleEncryptionStrength",
  SELECT_PREDEFINED_VPN_LABEL = "selectPredefinedVpnLabel",
  VIEW_ALL_IPS = "viewAllIps",
  ADD_URL_TO_URL_LIST = "addUrlToURLList",
  ENABLE_FULL_TUNNELING = "enableFullTunneling",
  DISABLE_FULL_TUNNELING = "disableFullTunneling",
  TOGGLE_FULL_TUNNELING = "toggleFullTunneling",
}

export enum ZtnaAction {
  ADD_ZTNA_RULE = "addZtnaRule",
  TOGGLE_ZTNA = "toggleZtna",
  ENABLE_ZTNA = "enableZtna",
  DISABLE_ZTNA = "disableZtna",
  EDIT_ZTNA_RULE = "editZtnaRule",
  DELETE_ZTNA_RULE = "deleteZtnaRule",
}

export enum SecureWebGatewayAction {
  ADD_URL_TO_ALLOWLIST = "addUrlToAllowlist",
  ADD_URL_TO_BLOCKLIST = "addUrlToBlocklist",
  IMPORT_URL_GROUP_TO_ALLOWLIST = "importUrlGroupToAllowlist",
  IMPORT_URL_GROUP_TO_BLOCKLIST = "importUrlGroupToBlocklist",
  REMOVE_FROM_ALLOWLIST = "removeUrlFromAllowlist",
  REMOVE_FROM_BLOCKLIST = "removeUrlFromBlocklist",
  VIEW_URL_LIST = "viewUrlList",
  ENABLE_URL_GROUP_ALLOW_LIST = "enableUrlGroupAllowList",
  ENABLE_URL_GROUP_BLOCK_LIST = "enableUrlGroupBlockList",
  DISABLE_URL_GROUP_ALLOW_LIST = "disableUrlGroupAllowList",
  DISABLE_URL_GROUP_BLOCK_LIST = "disableUrlGroupBlockList",
  REMOVE_URL_GROUP_FROM_ALLOW_LIST = "removeUrlGroupFromAllowList",
  REMOVE_URL_GROUP_FROM_BLOCK_LIST = "removeUrlGroupFromBlockList",
  TOGGLE_DNS_FILTERING = "toggleDnsFiltering",
  DOWNLOAD_EXAMPLE_FILE = "downloadSwgExampleFile",
}

export enum SecureWebGatewayCategories {
  URL = "url",
  URL_GROUP = "urlGroup",
  LOCKED_URL_GROUP = "lockedUrlGroup",
}

export enum SecureWebGatewayListFilterChoices {
  ALLOWED = "allowed",
  BLOCKED = "blocked",
  ALLOWED_DISABLED = "allowedDisabled",
  BLOCKED_DISABLED = "blockedDisabled",
}

export enum SiteToSiteTunnelsAction {
  ADD = "addTunnel",
  TEST = "testTunnel",
  EDIT = "editTunnel",
  DELETE = "deleteTunnel",
  ENABLE_TUNNEL = "enableTunnel",
  DISABLE_TUNNEL = "disableTunnel",
  DOWNLOAD_LOG = "downloadTunnelLog",
}

export enum VoFirewallAction {
  EDIT = "editVoFirewall",
  DELETE = "deleteVoFirewall",
  ADD = "addVoFirewall",
}

export enum EncryptionStrength {
  AES128 = "AES128",
  AES256 = "AES256",
}

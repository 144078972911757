<template>
  <div>
    <v-menu bottom left>
      <template v-slot:activator="{ props }">
        <v-btn
          rounded
          color="primary"
          density="default"
          size="large"
          v-bind="props"
          append-icon="$dropdown"
        >
          {{ $t("reports.executiveSummaryReport.download.title") }}
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="downloadPdf()">
          <v-list-item-title>
            {{ $t(`reports.executiveSummaryReport.download.toPdf`) }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import type { PropType } from "vue";
// @ts-ignore
import html2pdf from "html2pdf.js";
import { getReportVersion, ReportType, useReportsStore } from "@/_store/reports.module";
import {
  dialogsConfigConstructor,
  getDefaultDisclaimerText,
  isWorkspaceFrozenOrActionRestricted,
} from "@/_helpers/utils";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import { useDialogsStore } from "@/_store/dialogs.module";
import { ModalWidth } from "@/constants/modals";
import ErrorModal from "@/components/modals/ErrorModal.vue";

const reportTypeOptionsMap: Record<
  ReportType,
  { prefix: string; margin: [number, number, number, number]; additionalHtml2PdfOptions?: object }
> = {
  [ReportType.EXECUTIVE_SUMMARY]: {
    prefix: "ExecutiveSummaryReport",
    margin: [10, 10, 10, 0],
  },
  [ReportType.MANAGED_SERVICES_SUMMARY]: {
    prefix: "ManagedServicesSummaryReport",
    margin: [10, 10, 10, 0],
  },
  [ReportType.SECURED_MESSAGES]: {
    prefix: "SecuredMessagesSummaryReport",
    margin: [10, 10, 10, 0],
    additionalHtml2PdfOptions: {
      pagebreak: { mode: ["css", "legacy"], avoid: ".protected-users-entry" },
    },
  },
  [ReportType.DNS_SUMMARY]: {
    prefix: "DNSQueriesSummaryReport",
    margin: [0, 0, 0, 0],
  },
};

export default defineComponent({
  props: {
    reportType: {
      type: String as PropType<ReportType>,
      required: true,
    },
    beforeDownloadHook: {
      type: Function,
      required: false,
    },
    afterDownloadHook: {
      type: Function,
      required: false,
    },
  },
  setup(props) {
    const reportsStore = useReportsStore();
    const dialogsStore = useDialogsStore();
    const actionNotAllowed = computed(() =>
      isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.REPORTS
      )
    );

    const downloadPdf = async () => {
      if (props.beforeDownloadHook) {
        await props.beforeDownloadHook();
      }
      if (actionNotAllowed.value) {
        const errorModal = {
          width: ModalWidth.SMALL,
          header: undefined,
          content: {
            component: ErrorModal,
          },
          footer: null,
          data: {
            item: getDefaultDisclaimerText(),
          },
        };
        const dialogConfig = dialogsConfigConstructor(errorModal);
        dialogsStore.openDialog(dialogConfig);

        return;
      }
      const { margin, prefix, additionalHtml2PdfOptions } = reportTypeOptionsMap[props.reportType];
      const updatedVersion = getReportVersion();
      const filename = `${prefix}_${updatedVersion}.pdf`;
      const options = {
        margin,
        filename,
        jsPDF: { orientation: "landscape", putTotalPages: true },
        html2canvas: { scale: 2 },
        ...additionalHtml2PdfOptions,
      };

      const pdfFile = html2pdf()
        .from(document.getElementById("report-card"))
        .set(options)
        .toPdf()
        .get("pdf")
        .then((pdf: any) => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(100);
            pdf.text(
              "Page " + i + " of " + totalPages,
              pdf.internal.pageSize.getWidth() / 2.3,
              pdf.internal.pageSize.getHeight() - 5
            );
          }
        });
      const pdfBlob = await pdfFile.output("blob");

      try {
        await reportsStore.uploadPdfReport(filename, pdfBlob, props.reportType);
        await pdfFile.save();
        reportsStore.$patch({ reportVersion: updatedVersion });
      } catch (e) {
        console.error(e);
      }

      if (props.afterDownloadHook) {
        props.afterDownloadHook();
      }
    };

    return {
      downloadPdf,
      actionNotAllowed,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(*) {
  .icon-dropdown:before {
    color: inherit !important;
  }
}
</style>

<template>
  <div class="range-bundle-selector" :class="{ 'range-bundle-selector--selected': isSelected }">
    <div class="range-bundle-selector__content">
      <v-icon :icon="iconsMap[unmanagedOption]" size="90" />
      <div class="subtitle1 mt-3 mb-3">
        {{ $t(`manageWorkspaces.bundles.${unmanagedOption}`) }}
      </div>
      <div
        class="body3 text-indigo-medium mt-3 mb-3 text-center range-bundle-selector__description"
      >
        {{ $t(`rangeBundleSelector.${unmanagedOption}.description`) }}
      </div>

      <v-btn
        class="mt-5 mb-5 w-100"
        rounded
        density="default"
        size="large"
        variant="flat"
        color="primary"
        :class="!isSelected ? 'bg-primary' : 'bg-white'"
        @click="isSelected = !isSelected"
      >
        <template v-if="isSelected">
          <v-icon size="24" icon="$check"></v-icon>
          <span class="ml-1">{{ $t("general.selected") }}</span>
        </template>
        <template v-else>
          {{ $t("general.select") }}
        </template>
      </v-btn>

      <div class="d-flex justify-start flex-column w-100">
        <div
          v-for="value in bundlesContent[unmanagedOption].modules"
          :key="value"
          class="mt-1 mb-1"
        >
          <v-icon size="24" icon="$check"></v-icon>
          <span class="ml-1 body2">
            {{ $t(`subscriptions.modules.${value}`) }}
          </span>
        </div>
        <div v-for="value in includedAddons" :key="value" class="mt-1 mb-1">
          <v-icon size="24" icon="$check"></v-icon>
          <span class="ml-1 body2">
            {{ $t(`subscriptions.addons.${value}`) }}
          </span>
        </div>
      </div>
    </div>

    <div class="w-100">
      <v-divider class="mb-4"></v-divider>
      <v-btn-toggle
        v-model="type"
        color="indigo-pale"
        group
        mandatory
        density="comfortable"
        rounded="xl"
      >
        <v-btn :value="rangeTypeOption.UNMANAGED">
          {{ $t("rangeBundleSelector.unmanaged") }}
        </v-btn>
        <v-btn :value="rangeTypeOption.MANAGED">
          {{ $t("rangeBundleSelector.managed") }}
        </v-btn>
      </v-btn-toggle>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, type PropType, ref, watch } from "vue";
import { bundlesContent, SubscriptionAddon, SubscriptionBundle } from "@/constants/workplaces";

export enum RangeTypeOption {
  MANAGED = "managed",
  UNMANAGED = "unmanaged",
}

export default defineComponent({
  props: {
    managedOption: {
      required: true,
      type: String as PropType<SubscriptionBundle>,
    },
    unmanagedOption: {
      required: true,
      type: String as PropType<SubscriptionBundle>,
    },
    modelValue: {
      type: String as PropType<SubscriptionBundle>,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const isSelected = ref(false);
    const type = ref(RangeTypeOption.UNMANAGED);
    const iconsMap: Partial<Record<SubscriptionBundle, string>> = {
      [SubscriptionBundle.CORO_ESSENTIALS]: "icon-all-good",
      [SubscriptionBundle.CORO_COMPLETE]: "icon-all-good",
      [SubscriptionBundle.CORO_CLASSIC]: "icon-all-good",
      [SubscriptionBundle.SASE]: "icon-privatize",
      [SubscriptionBundle.EMAIL_PROTECTION]: "icon-emails",
      [SubscriptionBundle.ENDPOINT_PROTECTION]: "icon-devices",
    };

    const includedAddons = computed(() => {
      return type.value === RangeTypeOption.UNMANAGED
        ? bundlesContent[props.unmanagedOption].addons
        : [...bundlesContent[props.unmanagedOption].addons, SubscriptionAddon.SOC];
    });

    onMounted(() => {
      isSelected.value = [props.managedOption, props.unmanagedOption].includes(props.modelValue);
    });

    watch(type, (newVal) => {
      if (isSelected.value) {
        emit(
          "update:modelValue",
          newVal === RangeTypeOption.MANAGED ? props.managedOption : props.unmanagedOption
        );
      }
    });

    watch(isSelected, (newVal) => {
      if (newVal) {
        emit(
          "update:modelValue",
          type.value === RangeTypeOption.MANAGED ? props.managedOption : props.unmanagedOption
        );
        return;
      }
      emit("update:modelValue", undefined);
    });

    watch(
      () => props.modelValue,
      (newVal) => {
        type.value =
          newVal === props.managedOption ? RangeTypeOption.MANAGED : RangeTypeOption.UNMANAGED;
      },
      { immediate: true }
    );

    return {
      isSelected,
      type,
      bundlesContent,
      iconsMap,
      rangeTypeOption: RangeTypeOption,
      includedAddons,
    };
  },
});
</script>

<style scoped lang="scss">
.v-btn-group {
  border: 1px solid rgb(var(--v-theme-primary)) !important;
  padding: 2px;
  .v-btn--active {
    border-radius: 30px;
  }
  .v-btn__content {
    text-transform: unset !important;
  }
}
.range-bundle-selector {
  background-color: rgb(var(--v-theme-white));
  border-radius: 8px;
  min-height: 700px;
  width: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: start;
  justify-content: space-between;
  padding: 20px;
  &__description {
    height: 50px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: start;
  }
}

.range-bundle-selector--selected {
  border: 1px solid rgb(var(--v-theme-primary));
}
</style>

export enum WorkspaceAction {
  INVITE = "invite",
  SELECT_WORKPLACE = "selectWorkplace",
  JOIN = "join",
  LAUNCH = "launch",
  LAUNCH_WITH_2FA = "launchWorkplaceWith2FASetup",
}

export enum MspWorkspaceAction {
  CREATE_CHILD_WORKSPACE = "createChildMspWorkspace",
  CREATE_CHANNEL_WORKSPACE = "createChannelMspWorkspace",
  CREATE_REGULAR_WORKSPACE = "createRegularWorkspace",
  EDIT_WORKSPACE = "editWorkspace",
  DELETE_WORKSPACE = "deleteWorkspace",
  VIEW_WORKSPACE = "viewWorkspace",
  CONVERT = "convertWorkspace",
  EXPORT_MSP = "exportMsp",
  EDIT_SUBSCRIPTION = "editMspSubscription",
  START_SUBSCRIPTION = "startMspSubscription",
  STOP_SUBSCRIPTION = "stopMspSubscription",
  EDIT_SUBSCRIPTION_CONFIRMATION = "editMspSubscriptionConfirmation",
  EXPORT_MSP_NOTIFICATIONS = "exportMspNotifications",
  EXPORT_MSP_SUMMARY = "exportMspSummary",
  ARCHIVE_WORKSPACE = "archiveMspWorkspace",
  UNARCHIVE_WORKSPACE = "unarchiveMspWorkspace",
  EXTEND_TRIAL = "extendMspTrial",
}
export enum WorkspaceType {
  CHANNEL = "channel",
  CHILD = "child",
  CORO_AGENT = "coroAgent",
  REGULAR = "regular",
}

export enum SubscriptionBundle {
  ENDPOINT_PROTECTION = "endpointProtection",
  MANAGED_ENDPOINT_PROTECTION = "managedEndpointProtection",
  EMAIL_PROTECTION = "emailProtection",
  MANAGED_EMAIL_PROTECTION = "managedEmailProtection",
  SASE = "sase",
  MANAGED_SASE = "managedSase",
  CORO_ESSENTIALS = "coroEssentials",
  MANAGED_CORO_ESSENTIALS = "managedCoroEssentials",
  CORO_CLASSIC = "coroClassic",
  MANAGED_CORO_CLASSIC = "managedCoroClassic",
  CORO_COMPLETE = "coroComplete",
  MANAGED_CORO_COMPLETE = "managedCoroComplete",
}

export enum SubscriptionModule {
  CLOUD_SECURITY = "cloudSecurity",
  ENDPOINT_SECURITY = "endpointSecurity",
  EMAIL_SECURITY = "emailSecurity",
  USER_DATA_GOVERNANCE = "userDataGovernance",
  ENDPOINT_DATA_GOVERNANCE = "endpointDataGovernance",
  EDR = "edr",
  NETWORK = "network",
  MDM = "mdm",
}

export enum SubscriptionAddon {
  SOC_CLOUD = "socCloudSecurity",
  SOC_ENDPOINT = "socEndpointSecurity",
  SOC_EMAIL = "socEmailSecurity",
  SOC_USER_DATA_GOVERNANCE = "socUserDataGovernance",
  SOC_ENDPOINT_DATA_GOVERNANCE = "socEndpointDataGovernance",
  SOC_NETWORK = "socNetwork",
  SOC = "soc",
  MDR = "mdr",
  WIFI_PHISHING = "wifiPhishing",
  SECURED_MESSAGES = "securedMessages",
  INBOUND_GATEWAY = "inboundGateway",
  OUTBOUND_GATEWAY = "outboundGateway",
  SWG = "securedWebGateway",
  ZTNA = "zeroTrustNetworkAccess",
}

export const bundlesContent = {
  [SubscriptionBundle.ENDPOINT_PROTECTION]: {
    addons: [SubscriptionAddon.WIFI_PHISHING],
    modules: [
      SubscriptionModule.ENDPOINT_SECURITY,
      SubscriptionModule.ENDPOINT_DATA_GOVERNANCE,
      SubscriptionModule.EDR,
    ],
  },
  [SubscriptionBundle.MANAGED_ENDPOINT_PROTECTION]: {
    addons: [
      SubscriptionAddon.WIFI_PHISHING,
      SubscriptionAddon.SOC_ENDPOINT,
      SubscriptionAddon.SOC_ENDPOINT_DATA_GOVERNANCE,
      SubscriptionAddon.MDR,
    ],
    modules: [
      SubscriptionModule.ENDPOINT_SECURITY,
      SubscriptionModule.ENDPOINT_DATA_GOVERNANCE,
      SubscriptionModule.EDR,
    ],
  },
  [SubscriptionBundle.EMAIL_PROTECTION]: {
    addons: [
      // SubscriptionAddon.OUTBOUND_GATEWAY, todo: uncomment when implemented
      SubscriptionAddon.SECURED_MESSAGES,
      SubscriptionAddon.INBOUND_GATEWAY,
    ],
    modules: [SubscriptionModule.EMAIL_SECURITY, SubscriptionModule.USER_DATA_GOVERNANCE],
  },
  [SubscriptionBundle.MANAGED_EMAIL_PROTECTION]: {
    addons: [
      // SubscriptionAddon.OUTBOUND_GATEWAY, todo: uncomment when implemented
      SubscriptionAddon.SECURED_MESSAGES,
      SubscriptionAddon.INBOUND_GATEWAY,
      SubscriptionAddon.SOC_EMAIL,
      SubscriptionAddon.SOC_USER_DATA_GOVERNANCE,
    ],
    modules: [SubscriptionModule.EMAIL_SECURITY, SubscriptionModule.USER_DATA_GOVERNANCE],
  },
  [SubscriptionBundle.SASE]: {
    addons: [
      SubscriptionAddon.SWG,
      // SubscriptionAddon.ZTNA todo: uncomment when implemented
    ],
    modules: [SubscriptionModule.CLOUD_SECURITY, SubscriptionModule.NETWORK],
  },
  [SubscriptionBundle.MANAGED_SASE]: {
    addons: [
      SubscriptionAddon.SWG,
      // SubscriptionAddon.ZTNA, todo: uncomment when implemented
      SubscriptionAddon.SOC_CLOUD,
      SubscriptionAddon.SOC_NETWORK,
    ],
    modules: [SubscriptionModule.CLOUD_SECURITY, SubscriptionModule.NETWORK],
  },
  [SubscriptionBundle.CORO_ESSENTIALS]: {
    addons: [SubscriptionAddon.WIFI_PHISHING],
    modules: [
      SubscriptionModule.CLOUD_SECURITY,
      SubscriptionModule.ENDPOINT_SECURITY,
      SubscriptionModule.EDR,
      SubscriptionModule.EMAIL_SECURITY,
    ],
  },
  [SubscriptionBundle.MANAGED_CORO_ESSENTIALS]: {
    addons: [
      SubscriptionAddon.WIFI_PHISHING,
      SubscriptionAddon.MDR,
      SubscriptionAddon.SOC_ENDPOINT,
      SubscriptionAddon.SOC_EMAIL,
      SubscriptionAddon.SOC_CLOUD,
    ],
    modules: [
      SubscriptionModule.CLOUD_SECURITY,
      SubscriptionModule.ENDPOINT_SECURITY,
      SubscriptionModule.EDR,
      SubscriptionModule.EMAIL_SECURITY,
    ],
  },
  [SubscriptionBundle.CORO_CLASSIC]: {
    addons: [SubscriptionAddon.WIFI_PHISHING],
    modules: [
      SubscriptionModule.CLOUD_SECURITY,
      SubscriptionModule.ENDPOINT_SECURITY,
      SubscriptionModule.EMAIL_SECURITY,
      SubscriptionModule.USER_DATA_GOVERNANCE,
      SubscriptionModule.ENDPOINT_DATA_GOVERNANCE,
    ],
  },
  [SubscriptionBundle.MANAGED_CORO_CLASSIC]: {
    addons: [
      SubscriptionAddon.WIFI_PHISHING,
      SubscriptionAddon.SOC_ENDPOINT,
      SubscriptionAddon.SOC_EMAIL,
      SubscriptionAddon.SOC_CLOUD,
      SubscriptionAddon.SOC_USER_DATA_GOVERNANCE,
      SubscriptionAddon.SOC_ENDPOINT_DATA_GOVERNANCE,
    ],
    modules: [
      SubscriptionModule.CLOUD_SECURITY,
      SubscriptionModule.ENDPOINT_SECURITY,
      SubscriptionModule.EMAIL_SECURITY,
      SubscriptionModule.USER_DATA_GOVERNANCE,
      SubscriptionModule.ENDPOINT_DATA_GOVERNANCE,
    ],
  },
  [SubscriptionBundle.CORO_COMPLETE]: {
    addons: [
      SubscriptionAddon.WIFI_PHISHING,
      SubscriptionAddon.SECURED_MESSAGES,
      SubscriptionAddon.INBOUND_GATEWAY,
      SubscriptionAddon.SWG,
    ],
    modules: [
      SubscriptionModule.CLOUD_SECURITY,
      SubscriptionModule.ENDPOINT_SECURITY,
      SubscriptionModule.EMAIL_SECURITY,
      SubscriptionModule.USER_DATA_GOVERNANCE,
      SubscriptionModule.ENDPOINT_DATA_GOVERNANCE,
      SubscriptionModule.EDR,
      SubscriptionModule.NETWORK,
      SubscriptionModule.MDM,
    ],
  },
  [SubscriptionBundle.MANAGED_CORO_COMPLETE]: {
    addons: [
      SubscriptionAddon.WIFI_PHISHING,
      SubscriptionAddon.SECURED_MESSAGES,
      SubscriptionAddon.INBOUND_GATEWAY,
      SubscriptionAddon.SWG,
      SubscriptionAddon.SOC_ENDPOINT,
      SubscriptionAddon.SOC_EMAIL,
      SubscriptionAddon.SOC_CLOUD,
      SubscriptionAddon.SOC_USER_DATA_GOVERNANCE,
      SubscriptionAddon.SOC_ENDPOINT_DATA_GOVERNANCE,
      SubscriptionAddon.SOC_NETWORK,
      SubscriptionAddon.MDR,
    ],
    modules: [
      SubscriptionModule.CLOUD_SECURITY,
      SubscriptionModule.ENDPOINT_SECURITY,
      SubscriptionModule.EMAIL_SECURITY,
      SubscriptionModule.USER_DATA_GOVERNANCE,
      SubscriptionModule.ENDPOINT_DATA_GOVERNANCE,
      SubscriptionModule.EDR,
      SubscriptionModule.NETWORK,
      SubscriptionModule.MDM,
    ],
  },
};

export const modulesAndAddonsInBundleConfiguration: Record<
  SubscriptionBundle,
  { [key in SubscriptionModule]?: SubscriptionAddon[] }
> = {
  [SubscriptionBundle.CORO_CLASSIC]: {
    [SubscriptionModule.CLOUD_SECURITY]: [],
    [SubscriptionModule.ENDPOINT_SECURITY]: [SubscriptionAddon.WIFI_PHISHING],
    [SubscriptionModule.EMAIL_SECURITY]: [],
    [SubscriptionModule.USER_DATA_GOVERNANCE]: [],
    [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: [],
  },
  [SubscriptionBundle.MANAGED_CORO_CLASSIC]: {
    [SubscriptionModule.CLOUD_SECURITY]: [SubscriptionAddon.SOC_CLOUD],
    [SubscriptionModule.ENDPOINT_SECURITY]: [
      SubscriptionAddon.WIFI_PHISHING,
      SubscriptionAddon.SOC_ENDPOINT,
    ],
    [SubscriptionModule.EMAIL_SECURITY]: [SubscriptionAddon.SOC_EMAIL],
    [SubscriptionModule.USER_DATA_GOVERNANCE]: [SubscriptionAddon.SOC_USER_DATA_GOVERNANCE],
    [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: [SubscriptionAddon.SOC_ENDPOINT_DATA_GOVERNANCE],
  },
  [SubscriptionBundle.ENDPOINT_PROTECTION]: {
    [SubscriptionModule.ENDPOINT_SECURITY]: [SubscriptionAddon.WIFI_PHISHING],
    [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: [],
    [SubscriptionModule.EDR]: [],
  },
  [SubscriptionBundle.MANAGED_ENDPOINT_PROTECTION]: {
    [SubscriptionModule.ENDPOINT_SECURITY]: [
      SubscriptionAddon.WIFI_PHISHING,
      SubscriptionAddon.SOC_ENDPOINT,
    ],
    [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: [SubscriptionAddon.SOC_ENDPOINT_DATA_GOVERNANCE],
    [SubscriptionModule.EDR]: [SubscriptionAddon.MDR],
  },
  [SubscriptionBundle.EMAIL_PROTECTION]: {
    [SubscriptionModule.EMAIL_SECURITY]: [
      SubscriptionAddon.SECURED_MESSAGES,
      SubscriptionAddon.INBOUND_GATEWAY,
    ],
    [SubscriptionModule.USER_DATA_GOVERNANCE]: [], // todo: SubscriptionAddon.OUTBOUND_GATEWAY
  },
  [SubscriptionBundle.MANAGED_EMAIL_PROTECTION]: {
    [SubscriptionModule.EMAIL_SECURITY]: [
      SubscriptionAddon.SECURED_MESSAGES,
      SubscriptionAddon.INBOUND_GATEWAY,
      SubscriptionAddon.SOC_EMAIL,
    ],
    [SubscriptionModule.USER_DATA_GOVERNANCE]: [SubscriptionAddon.SOC_USER_DATA_GOVERNANCE], // todo: SubscriptionAddon.OUTBOUND_GATEWAY
  },
  [SubscriptionBundle.SASE]: {
    [SubscriptionModule.CLOUD_SECURITY]: [],
    [SubscriptionModule.NETWORK]: [SubscriptionAddon.SWG], // todo SubscriptionAddon.ZTNA
  },
  [SubscriptionBundle.MANAGED_SASE]: {
    [SubscriptionModule.CLOUD_SECURITY]: [SubscriptionAddon.SOC_CLOUD],
    [SubscriptionModule.NETWORK]: [SubscriptionAddon.SWG, SubscriptionAddon.SOC_NETWORK], // todo SubscriptionAddon.ZTNA
  },
  [SubscriptionBundle.CORO_ESSENTIALS]: {
    [SubscriptionModule.CLOUD_SECURITY]: [],
    [SubscriptionModule.ENDPOINT_SECURITY]: [SubscriptionAddon.WIFI_PHISHING],
    [SubscriptionModule.EDR]: [],
    [SubscriptionModule.EMAIL_SECURITY]: [],
  },
  [SubscriptionBundle.MANAGED_CORO_ESSENTIALS]: {
    [SubscriptionModule.CLOUD_SECURITY]: [SubscriptionAddon.SOC_CLOUD],
    [SubscriptionModule.ENDPOINT_SECURITY]: [
      SubscriptionAddon.WIFI_PHISHING,
      SubscriptionAddon.SOC_ENDPOINT,
    ],
    [SubscriptionModule.EDR]: [SubscriptionAddon.MDR],
    [SubscriptionModule.EMAIL_SECURITY]: [SubscriptionAddon.SOC_EMAIL],
  },

  [SubscriptionBundle.CORO_COMPLETE]: {
    [SubscriptionModule.CLOUD_SECURITY]: [],
    [SubscriptionModule.ENDPOINT_SECURITY]: [SubscriptionAddon.WIFI_PHISHING],
    [SubscriptionModule.EMAIL_SECURITY]: [
      SubscriptionAddon.SECURED_MESSAGES,
      SubscriptionAddon.INBOUND_GATEWAY,
    ],
    [SubscriptionModule.USER_DATA_GOVERNANCE]: [],
    [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: [],
    [SubscriptionModule.EDR]: [],
    [SubscriptionModule.NETWORK]: [SubscriptionAddon.SWG],
    [SubscriptionModule.MDM]: [],
  },
  [SubscriptionBundle.MANAGED_CORO_COMPLETE]: {
    [SubscriptionModule.CLOUD_SECURITY]: [SubscriptionAddon.SOC_CLOUD],
    [SubscriptionModule.ENDPOINT_SECURITY]: [
      SubscriptionAddon.WIFI_PHISHING,
      SubscriptionAddon.SOC_ENDPOINT,
    ],
    [SubscriptionModule.EMAIL_SECURITY]: [
      SubscriptionAddon.SECURED_MESSAGES,
      SubscriptionAddon.INBOUND_GATEWAY,
      SubscriptionAddon.SOC_EMAIL,
    ],
    [SubscriptionModule.USER_DATA_GOVERNANCE]: [SubscriptionAddon.SOC_USER_DATA_GOVERNANCE],
    [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: [SubscriptionAddon.SOC_ENDPOINT_DATA_GOVERNANCE],
    [SubscriptionModule.EDR]: [SubscriptionAddon.MDR],
    [SubscriptionModule.NETWORK]: [SubscriptionAddon.SWG, SubscriptionAddon.SOC_NETWORK],
    [SubscriptionModule.MDM]: [],
  },
};

export const bundlesForModules = {
  [SubscriptionModule.CLOUD_SECURITY]: [
    SubscriptionBundle.SASE,
    SubscriptionBundle.MANAGED_SASE,
    SubscriptionBundle.CORO_ESSENTIALS,
    SubscriptionBundle.MANAGED_CORO_ESSENTIALS,
    SubscriptionBundle.CORO_CLASSIC,
    SubscriptionBundle.MANAGED_CORO_CLASSIC,
    SubscriptionBundle.CORO_COMPLETE,
    SubscriptionBundle.MANAGED_CORO_COMPLETE,
  ],
  [SubscriptionModule.ENDPOINT_SECURITY]: [
    SubscriptionBundle.ENDPOINT_PROTECTION,
    SubscriptionBundle.MANAGED_ENDPOINT_PROTECTION,
    SubscriptionBundle.CORO_ESSENTIALS,
    SubscriptionBundle.MANAGED_CORO_ESSENTIALS,
    SubscriptionBundle.CORO_CLASSIC,
    SubscriptionBundle.MANAGED_CORO_CLASSIC,
    SubscriptionBundle.CORO_COMPLETE,
    SubscriptionBundle.MANAGED_CORO_COMPLETE,
  ],
  [SubscriptionModule.EMAIL_SECURITY]: [
    SubscriptionBundle.CORO_ESSENTIALS,
    SubscriptionBundle.MANAGED_CORO_ESSENTIALS,
    SubscriptionBundle.EMAIL_PROTECTION,
    SubscriptionBundle.MANAGED_EMAIL_PROTECTION,
    SubscriptionBundle.CORO_CLASSIC,
    SubscriptionBundle.MANAGED_CORO_CLASSIC,
    SubscriptionBundle.CORO_COMPLETE,
    SubscriptionBundle.MANAGED_CORO_COMPLETE,
  ],
  [SubscriptionModule.USER_DATA_GOVERNANCE]: [
    SubscriptionBundle.EMAIL_PROTECTION,
    SubscriptionBundle.MANAGED_EMAIL_PROTECTION,
    SubscriptionBundle.CORO_CLASSIC,
    SubscriptionBundle.MANAGED_CORO_CLASSIC,
    SubscriptionBundle.CORO_COMPLETE,
    SubscriptionBundle.MANAGED_CORO_COMPLETE,
  ],
  [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: [
    SubscriptionBundle.ENDPOINT_PROTECTION,
    SubscriptionBundle.MANAGED_ENDPOINT_PROTECTION,
    SubscriptionBundle.CORO_CLASSIC,
    SubscriptionBundle.MANAGED_CORO_CLASSIC,
    SubscriptionBundle.CORO_COMPLETE,
    SubscriptionBundle.MANAGED_CORO_COMPLETE,
  ],
  [SubscriptionModule.EDR]: [
    SubscriptionBundle.ENDPOINT_PROTECTION,
    SubscriptionBundle.MANAGED_ENDPOINT_PROTECTION,
    SubscriptionBundle.CORO_ESSENTIALS,
    SubscriptionBundle.MANAGED_CORO_ESSENTIALS,
    SubscriptionBundle.CORO_COMPLETE,
    SubscriptionBundle.MANAGED_CORO_COMPLETE,
  ],
  [SubscriptionModule.NETWORK]: [
    SubscriptionBundle.SASE,
    SubscriptionBundle.MANAGED_SASE,
    SubscriptionBundle.CORO_COMPLETE,
    SubscriptionBundle.MANAGED_CORO_COMPLETE,
  ],
  [SubscriptionModule.MDM]: [SubscriptionBundle.CORO_COMPLETE],
} as const;

export const bundlesForAddons = {
  [SubscriptionAddon.SOC_CLOUD]: [
    SubscriptionBundle.MANAGED_CORO_ESSENTIALS,
    SubscriptionBundle.MANAGED_SASE,
    SubscriptionBundle.MANAGED_CORO_CLASSIC,
    SubscriptionBundle.MANAGED_CORO_COMPLETE,
  ],
  [SubscriptionAddon.SOC_ENDPOINT]: [
    SubscriptionBundle.MANAGED_ENDPOINT_PROTECTION,
    SubscriptionBundle.MANAGED_CORO_ESSENTIALS,
    SubscriptionBundle.MANAGED_CORO_CLASSIC,
    SubscriptionBundle.MANAGED_CORO_COMPLETE,
  ],
  [SubscriptionAddon.SOC_EMAIL]: [
    SubscriptionBundle.MANAGED_CORO_ESSENTIALS,
    SubscriptionBundle.MANAGED_EMAIL_PROTECTION,
    SubscriptionBundle.MANAGED_CORO_CLASSIC,
    SubscriptionBundle.MANAGED_CORO_COMPLETE,
  ],
  [SubscriptionAddon.SOC_USER_DATA_GOVERNANCE]: [
    SubscriptionBundle.MANAGED_EMAIL_PROTECTION,
    SubscriptionBundle.MANAGED_CORO_CLASSIC,
    SubscriptionBundle.MANAGED_CORO_COMPLETE,
  ],
  [SubscriptionAddon.SOC_ENDPOINT_DATA_GOVERNANCE]: [
    SubscriptionBundle.MANAGED_ENDPOINT_PROTECTION,
    SubscriptionBundle.MANAGED_CORO_CLASSIC,
    SubscriptionBundle.MANAGED_CORO_COMPLETE,
  ],
  [SubscriptionAddon.SOC_NETWORK]: [
    SubscriptionBundle.MANAGED_SASE,
    SubscriptionBundle.MANAGED_CORO_COMPLETE,
  ],
  [SubscriptionAddon.MDR]: [
    SubscriptionBundle.MANAGED_ENDPOINT_PROTECTION,
    SubscriptionBundle.MANAGED_CORO_ESSENTIALS,
    SubscriptionBundle.MANAGED_CORO_COMPLETE,
  ],
  [SubscriptionAddon.WIFI_PHISHING]: [
    SubscriptionBundle.MANAGED_ENDPOINT_PROTECTION,
    SubscriptionBundle.MANAGED_CORO_ESSENTIALS,
    SubscriptionBundle.CORO_ESSENTIALS,
    SubscriptionBundle.ENDPOINT_PROTECTION,
    SubscriptionBundle.CORO_CLASSIC,
    SubscriptionBundle.MANAGED_CORO_CLASSIC,
    SubscriptionBundle.CORO_COMPLETE,
    SubscriptionBundle.MANAGED_CORO_COMPLETE,
  ],
  [SubscriptionAddon.SECURED_MESSAGES]: [
    SubscriptionBundle.MANAGED_EMAIL_PROTECTION,
    SubscriptionBundle.EMAIL_PROTECTION,
    SubscriptionBundle.CORO_COMPLETE,
    SubscriptionBundle.MANAGED_CORO_COMPLETE,
  ],
  [SubscriptionAddon.INBOUND_GATEWAY]: [
    SubscriptionBundle.MANAGED_EMAIL_PROTECTION,
    SubscriptionBundle.EMAIL_PROTECTION,
    SubscriptionBundle.CORO_COMPLETE,
    SubscriptionBundle.MANAGED_CORO_COMPLETE,
  ],
  // [SubscriptionAddon.OUTBOUND_GATEWAY]: [
  //   SubscriptionBundle.MANAGED_EMAIL_PROTECTION,
  //   SubscriptionBundle.EMAIL_PROTECTION
  // ],
  [SubscriptionAddon.SWG]: [
    SubscriptionBundle.MANAGED_SASE,
    SubscriptionBundle.SASE,
    SubscriptionBundle.CORO_COMPLETE,
    SubscriptionBundle.MANAGED_CORO_COMPLETE,
  ],
  [SubscriptionAddon.ZTNA]: [
    SubscriptionBundle.MANAGED_SASE,
    SubscriptionBundle.SASE,
    SubscriptionBundle.CORO_COMPLETE,
    SubscriptionBundle.MANAGED_CORO_COMPLETE,
  ],
} as const;

export const addonsToModuleMap: Record<SubscriptionAddon, SubscriptionModule | null> = {
  [SubscriptionAddon.WIFI_PHISHING]: SubscriptionModule.ENDPOINT_SECURITY,
  [SubscriptionAddon.SWG]: SubscriptionModule.NETWORK,
  [SubscriptionAddon.INBOUND_GATEWAY]: SubscriptionModule.EMAIL_SECURITY,
  [SubscriptionAddon.SECURED_MESSAGES]: SubscriptionModule.EMAIL_SECURITY,
  [SubscriptionAddon.MDR]: SubscriptionModule.EDR,
  [SubscriptionAddon.SOC_CLOUD]: SubscriptionModule.CLOUD_SECURITY,
  [SubscriptionAddon.SOC_ENDPOINT]: SubscriptionModule.ENDPOINT_SECURITY,
  [SubscriptionAddon.SOC_EMAIL]: SubscriptionModule.EMAIL_SECURITY,
  [SubscriptionAddon.SOC_USER_DATA_GOVERNANCE]: SubscriptionModule.USER_DATA_GOVERNANCE,
  [SubscriptionAddon.SOC_ENDPOINT_DATA_GOVERNANCE]: SubscriptionModule.ENDPOINT_DATA_GOVERNANCE,
  [SubscriptionAddon.SOC]: null,
  [SubscriptionAddon.SOC_NETWORK]: SubscriptionModule.NETWORK,
  [SubscriptionAddon.OUTBOUND_GATEWAY]: null,
  [SubscriptionAddon.ZTNA]: null,
} as const;

export const moduleToSocAddonMap: Record<SubscriptionModule, SubscriptionAddon | null> = {
  [SubscriptionModule.EDR]: SubscriptionAddon.MDR,
  [SubscriptionModule.CLOUD_SECURITY]: SubscriptionAddon.SOC_CLOUD,
  [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: SubscriptionAddon.SOC_ENDPOINT_DATA_GOVERNANCE,
  [SubscriptionModule.ENDPOINT_SECURITY]: SubscriptionAddon.SOC_ENDPOINT,
  [SubscriptionModule.EMAIL_SECURITY]: SubscriptionAddon.SOC_EMAIL,
  [SubscriptionModule.USER_DATA_GOVERNANCE]: SubscriptionAddon.SOC_USER_DATA_GOVERNANCE,
  [SubscriptionModule.NETWORK]: SubscriptionAddon.SOC_NETWORK,
  [SubscriptionModule.MDM]: null,
};

export enum SubscriptionType {
  INITIAL = "initial",
  FREEZE = "freeze",
  TRIAL = "trial",
  SUBSCRIPTION = "subscription",
  ARCHIVED = "archived",
}

export const defaultSubscriptionModulesConfig = {
  [SubscriptionModule.CLOUD_SECURITY]: {
    enabled: false,
    addons: [
      {
        enabled: false,
        name: SubscriptionAddon.SOC_CLOUD,
      },
    ],
  },
  [SubscriptionModule.ENDPOINT_SECURITY]: {
    enabled: false,
    addons: [
      {
        enabled: false,
        name: SubscriptionAddon.WIFI_PHISHING,
      },
      {
        enabled: false,
        name: SubscriptionAddon.SOC_ENDPOINT,
      },
    ],
  },
  [SubscriptionModule.EMAIL_SECURITY]: {
    enabled: false,
    addons: [
      {
        enabled: false,
        name: SubscriptionAddon.SECURED_MESSAGES,
      },
      {
        enabled: false,
        name: SubscriptionAddon.INBOUND_GATEWAY,
      },
      {
        enabled: false,
        name: SubscriptionAddon.SOC_EMAIL,
      },
    ],
  },
  [SubscriptionModule.USER_DATA_GOVERNANCE]: {
    enabled: false,
    addons: [
      // todo: uncomment when implemented
      // {
      //   enabled: false,
      //   name: SubscriptionAddon.OUTBOUND_GATEWAY
      // },
      {
        enabled: false,
        name: SubscriptionAddon.SOC_USER_DATA_GOVERNANCE,
      },
    ],
  },
  [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: {
    enabled: false,
    addons: [
      {
        enabled: false,
        name: SubscriptionAddon.SOC_ENDPOINT_DATA_GOVERNANCE,
      },
    ],
  },
  [SubscriptionModule.EDR]: {
    enabled: false,
    addons: [
      {
        enabled: false,
        name: SubscriptionAddon.MDR,
      },
    ],
  },
  [SubscriptionModule.NETWORK]: {
    enabled: false,
    addons: [
      {
        enabled: false,
        name: SubscriptionAddon.SWG,
      },
      // todo: uncomment when implemented
      // {
      //   enabled: false,
      //   name: SubscriptionAddon.ZTNA
      // },
      {
        enabled: false,
        name: SubscriptionAddon.SOC_NETWORK,
      },
    ],
  },
  [SubscriptionModule.MDM]: {
    enabled: false,
    addons: [],
  },
};

export enum SubscriptionsFilterType {
  ADDON = "addon",
  BUNDLE = "bundle",
  MODULE = "module",
}

export enum WorkspaceLocale {
  EN_US = "en-US",
  EN_GB = "en-GB",
  ES = "es",
}

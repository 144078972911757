<template>
  <div>
    <div class="subtitle1 mb-4">
      {{ $t(`modals.addDevicePosturePolicy.userAccountPolicy.password`) }}
    </div>
    <div
      class="policy-rules-wrapper"
      :class="{ 'policy-rules-wrapper--error': isEqualToDefaultPolicy }"
    >
      <div class="d-flex policy-rule">
        <div class="w-60 d-flex align-center subtitle1 policy-rule-name">
          {{ $t(`modals.addDevicePosturePolicy.userAccountPolicy.minimumLength`) }}
        </div>
        <div class="ml-auto">
          <v-select
            v-model="config.minPasswdLen"
            :items="minimumPasswordLengthItems"
            :flat="true"
            variant="solo"
            hide-details
            :placeholder="$t('general.select')"
            class="policy-rule-select"
          >
            <template #prepend-item>
              <v-list-item v-if="config.minPasswdLen" @click="config.minPasswdLen = undefined">
                {{ $t("general.none") }}
              </v-list-item>
            </template>
          </v-select>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="d-flex policy-rule">
        <div class="w-60 d-flex align-center subtitle1 policy-rule-name">
          {{ $t(`modals.addDevicePosturePolicy.userAccountPolicy.minimumAge`) }}
        </div>
        <div class="ml-auto">
          <v-select
            v-model="config.minPasswdAge"
            :items="minimumPasswordAgeItems"
            :flat="true"
            variant="solo"
            hide-details="auto"
            :placeholder="$t('general.select')"
            class="policy-rule-select"
          >
            <template #prepend-item>
              <v-list-item v-if="config.minPasswdAge" @click="config.minPasswdAge = undefined">
                {{ $t("general.none") }}
              </v-list-item>
            </template>
          </v-select>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="d-flex policy-rule">
        <div class="w-60 d-flex align-center subtitle1 policy-rule-name">
          {{ $t(`modals.addDevicePosturePolicy.userAccountPolicy.maximumAge`) }}
        </div>
        <div class="ml-auto">
          <v-select
            v-model="config.maxPasswdAge"
            :items="maximumPasswordAgeItems"
            :flat="true"
            variant="solo"
            hide-details
            :placeholder="$t('general.select')"
            class="policy-rule-select"
          >
            <template #prepend-item>
              <v-list-item v-if="config.maxPasswdAge" @click="config.maxPasswdAge = undefined">
                {{ $t("general.none") }}
              </v-list-item>
            </template>
          </v-select>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="d-flex policy-rule">
        <div class="w-60 d-flex align-center subtitle1 policy-rule-name">
          {{ $t(`modals.addDevicePosturePolicy.userAccountPolicy.enforcePasswordHistory`) }}
        </div>
        <div class="ml-auto">
          <v-select
            v-model="config.passwordHistLen"
            :items="passwordHistoryLengthItems"
            :flat="true"
            variant="solo"
            hide-details
            :placeholder="$t('general.select')"
            class="policy-rule-select"
          >
            <template #prepend-item>
              <v-list-item
                v-if="config.passwordHistLen"
                @click="config.passwordHistLen = undefined"
              >
                {{ $t("general.none") }}
              </v-list-item>
            </template>
          </v-select>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="d-flex policy-rule">
        <div class="w-60 d-flex align-center subtitle1 policy-rule-name">
          <div>{{ $t("modals.addDevicePosturePolicy.userAccountPolicy.passwordComplexity") }}</div>
          <v-tooltip open-delay="300" location="top">
            <template #activator="{ props }">
              <v-icon v-bind="props" icon="$info" size="28"> </v-icon>
            </template>
            {{
              $t("modals.addDevicePosturePolicy.userAccountPolicy.passwordComplexityDescription")
            }}
          </v-tooltip>
        </div>
        <div class="d-flex align-center ml-auto">
          <v-switch
            v-model="config.passwordMustMeetComplexity"
            :ripple="false"
            :hide-details="true"
            class="mr-3"
            color="primary lighten-1"
          />
        </div>
      </div>
    </div>
    <div v-if="isEqualToDefaultPolicy" class="caption text-error mt-2">
      {{ $t("validations.atLeastOneFieldRequired") }}
    </div>

    <div class="subtitle1 mb-4 mt-12">
      {{ $t(`modals.addDevicePosturePolicy.userAccountPolicy.screenLockout`) }}
    </div>

    <div
      class="policy-rules-wrapper"
      :class="{
        'policy-rules-wrapper--error': isEqualToDefaultPolicy || screenLockoutFieldsInvalid,
      }"
    >
      <div class="d-flex policy-rule">
        <div class="w-60 d-flex align-center subtitle1 policy-rule-name">
          {{ $t(`modals.addDevicePosturePolicy.userAccountPolicy.lockoutDuration`) }}
        </div>
        <div class="ml-auto">
          <v-select
            v-model="config.lockoutDuration"
            :items="lockoutDurationItems"
            :flat="true"
            variant="solo"
            hide-details
            :placeholder="$t('general.select')"
            class="policy-rule-select"
          >
            <template #prepend-item>
              <v-list-item
                v-if="config.lockoutDuration"
                @click="config.lockoutDuration = undefined"
              >
                {{ $t("general.none") }}
              </v-list-item>
            </template>
          </v-select>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="d-flex policy-rule">
        <div class="w-60 d-flex align-center subtitle1 policy-rule-name">
          {{ $t(`modals.addDevicePosturePolicy.userAccountPolicy.lockoutThreshold`) }}
        </div>
        <div class="ml-auto">
          <v-select
            v-model="config.lockoutThreshold"
            :items="lockoutThresholdItems"
            :flat="true"
            variant="solo"
            hide-details
            :placeholder="$t('general.select')"
            class="policy-rule-select"
          >
            <template #prepend-item>
              <v-list-item
                v-if="config.lockoutThreshold"
                @click="config.lockoutThreshold = undefined"
              >
                {{ $t("general.none") }}
              </v-list-item>
            </template>
          </v-select>
        </div>
      </div>
    </div>
    <div
      v-if="isEqualToDefaultPolicy || screenLockoutFieldsInvalid"
      class="caption text-error mt-2"
    >
      {{ $t("validations.eitherBothOrNone") }}
    </div>
    <v-divider class="mb-8 mt-6"></v-divider>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { createNumberArrayRange } from "@/_helpers/utils";
import isEqual from "lodash/isEqual";
import { pick } from "lodash";

const defaultItem = {
  passwordMustMeetComplexity: false,
  lockoutDuration: null,
  lockoutThreshold: null,
  passwordHistLen: null,
  minPasswdLen: null,
  minPasswdAge: null,
  maxPasswdAge: null,
};

export default defineComponent({
  props: {
    config: {
      type: Object,
      required: true,
    },
    submitted: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const i18n = useI18n();
    const minimumPasswordLengthItems = createNumberArrayRange(1, 14);
    const passwordHistoryLengthItems = createNumberArrayRange(1, 8);
    const lockoutThresholdItems = createNumberArrayRange(1, 10);
    const isEqualToDefaultPolicy = ref(false);
    const screenLockoutFieldsInvalid = ref(false);

    const userAccountPolicyValues = computed(() => {
      return pick(props.config, [
        "lockoutDuration",
        "lockoutThreshold",
        "passwordHistLen",
        "passwordMustMeetComplexity",
        "minPasswdLen",
        "minPasswdAge",
        "maxPasswdAge",
      ]);
    });
    const formValid = computed(() => {
      return !isEqualToDefaultPolicy.value && !screenLockoutFieldsInvalid.value;
    });
    const minimumPasswordAgeItems = computed(() => {
      return createNumberArrayRange(1, props.config.maxPasswdAge ?? 365);
    });
    const maximumPasswordAgeItems = computed(() => {
      return createNumberArrayRange(props.config.minPasswdAge ?? 1, 365);
    });
    const lockoutDurationItems = computed(() => {
      const minutes = createNumberArrayRange(1, 59).map((n) => {
        return {
          title: i18n.t("general.minutes", { n }, n),
          value: n,
        };
      });

      const hours = createNumberArrayRange(1, 3).map((n) => {
        return {
          title: i18n.t("general.hours", { n }, n),
          value: n * 60,
        };
      });

      return [...minutes, ...hours];
    });

    watch(
      () => props.config,
      (value) => {
        if (isEqualToDefaultPolicy.value) {
          isEqualToDefaultPolicy.value = isEqual(userAccountPolicyValues.value, defaultItem);
        }
        screenLockoutFieldsInvalid.value =
          (value.lockoutDuration && !value.lockoutThreshold) ||
          (!value.lockoutDuration && value.lockoutThreshold);
      },
      { deep: true }
    );

    const validate = () => {
      isEqualToDefaultPolicy.value = isEqual(userAccountPolicyValues.value, defaultItem);

      return formValid.value;
    };

    return {
      minimumPasswordAgeItems,
      maximumPasswordAgeItems,
      lockoutDurationItems,
      minimumPasswordLengthItems,
      passwordHistoryLengthItems,
      lockoutThresholdItems,
      isEqualToDefaultPolicy,
      screenLockoutFieldsInvalid,
      validate,
    };
  },
});
</script>

<style lang="scss" scoped>
.policy-rules-wrapper {
  border-radius: 4px;
  border: 1px solid rgb(var(--v-theme-indigo-faint));

  &--error {
    border-color: rgb(var(--v-theme-error));
    border-radius: 4px;
    border-width: 2px;
  }
}

.policy-rule {
  min-height: 56px;

  &-name {
    padding: 16px 0 16px 16px;
    border-right: 1px solid rgb(var(--v-theme-indigo-pale));
  }

  &-select {
    width: 100px;
  }
}

:deep(*) {
  .v-label {
    color: rgb(var(--v-theme-gray-dark));
  }

  .v-select__selection {
    max-width: unset !important;
    overflow: unset !important;
  }

  .v-field__input {
    justify-content: flex-end;

    input {
      width: 50px !important;
    }
  }

  .v-select__selections input {
    display: none !important;
  }
}
</style>

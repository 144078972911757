<template>
  <div class="bar-chart-wrapper">
    <div class="queries-count">{{ totalCount.toLocaleString() }}</div>
    <div class="subtitle2 mb-8">{{ totalLabel }}</div>
    <Bar :style="chartStyles" class="bar-chart" :options="chartOptions" :data="chartData" />
  </div>
</template>

<script lang="ts">
import { Bar } from "vue-chartjs";
import { Chart as ChartJS, Title, Tooltip, BarElement, CategoryScale, LinearScale } from "chart.js";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import Patterns from "@/constants/patterns";
import { getFormattedDateTime } from "@/_helpers/utils";

ChartJS.register(Title, Tooltip, BarElement, CategoryScale, LinearScale);

export default defineComponent({
  name: "BarChart",
  components: { Bar },
  props: {
    totalCount: {
      type: Number,
      required: true,
    },
    totalLabel: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    barColor: {
      type: String,
      default: "#B1B1E1",
    },
    chartStyles: {
      type: Object,
      default: () => {
        return {
          "max-height": "188px",
        };
      },
    },
  },
  setup(props) {
    const i18n = useI18n();
    return {
      chartData: {
        datasets: [
          {
            data: props.data as Array<any>,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maxBarThickness: 20,
        borderRadius: 10,
        borderSkipped: false,
        backgroundColor: props.barColor,
        scales: {
          x: {
            color: "#E2E2EB",
            ticks: {
              color: "#6A6891",
              font: {
                size: 12,
              },
            },
            grid: {
              display: false,
            },
          },
          y: {
            color: "#E2E2EB",
            ticks: {
              maxTicksLimit: 5,
              precision: 0,
              color: "#6A6891",
              font: {
                size: 12,
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            displayColors: false,
            titleFont: {
              size: 14,
              weight: 400,
              family: "'Source Sans Pro', sans-serif",
            },
            bodyFont: {
              size: 14,
              weight: 400,
              family: "'Source Sans Pro', sans-serif",
            },
            callbacks: {
              title: (tooltipItems: Array<any>) => {
                const tooltip: any = tooltipItems[0];
                const startDate = tooltip.raw.startDate;
                const endDate = tooltip.raw.endDate;
                if (Patterns.TIME.test(tooltip.label)) {
                  return (
                    `${getFormattedDateTime(startDate, "LT")} ${i18n.t("general.until")}` +
                    ` ${getFormattedDateTime(endDate, "LT")}` +
                    ` - ${getFormattedDateTime(startDate, "ll")}`
                  );
                }
                if (Patterns.YEAR.test(tooltip.label)) {
                  return `${getFormattedDateTime(startDate, "ll")} - ${getFormattedDateTime(
                    endDate,
                    "ll"
                  )}`;
                }
                if (getFormattedDateTime(startDate, "ll") !== getFormattedDateTime(endDate, "ll")) {
                  return `${getFormattedDateTime(startDate, "ll")} - ${getFormattedDateTime(
                    endDate,
                    "ll"
                  )}`;
                }

                return getFormattedDateTime(startDate, "ll");
              },
              label: (label: any) => {
                return `${i18n.t("reports.dnsSummaryReport.queries")} ${label.formattedValue}`;
              },
            },
          },
        },
      },
    };
  },
});
</script>
<style scoped lang="scss">
.bar-chart-wrapper {
  background-color: rgb(var(--v-theme-indigo-faint));
  border-radius: 8px;
  padding: 40px 40px 24px 40px;
}

.queries-count {
  font-family: Quicksand, sans-serif;
  font-size: 48px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: -0.6px;
  color: rgb(var(--v-theme-primary));
}
</style>
